import { AccessService } from "src/app/core/services/access.service";
import { Rol } from "../interfaces/roles-interface";
import { GenericResponseList } from "src/app/shared/interfaces/table.interface";
import { getStateBadge } from "src/app/shared/helpers/badgesBuilder";
import { getIcon } from "src/app/shared/helpers/iconBuilder";

export function transforDataRolesList(
    response: GenericResponseList<Rol>,
    _accessService: AccessService
  ) {
    if (response.data == null) response.data = [];
    response.data.map((data) => {
      let status = data.nEstado == 1? 'ACTIVO' : 'INACTIVO';
      data.status = getStateBadge(status);
      data.rolType = getStateBadge(data.sNombre_Tipo_Rol)
      data.detail = getIcon(
        { name: 'visibility', type: 'material' },
        'Detalle',
        'main',
        _accessService.validarPermisoSlug('ROL-DETALLE'),
        'detail'
      );
      data.changeStatus =
        status === 'ACTIVO'
          ? getIcon(
              { name: 'remove_circle_outline', type: 'material' },
              'Desactivar rol',
              'red',
              _accessService.validarPermisoSlug('ROL-CAMBIAR-ESTADO'),
              'cease'
            )
          : getIcon(
              { name: 'settings_backup_restore', type: 'material' },
              'Activar rol',
              'teal',
              _accessService.validarPermisoSlug('ROL-CAMBIAR-ESTADO'),
              'cease'
            );
        data.duplicate = getIcon(
            {name: 'content_copy', type: 'material'},
            'Duplicar rol',
            'orange',
            _accessService.validarPermisoSlug('ROL-CREAR'),
            'duplicate'
        )
    });
    return response;
  }
  