import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { TaskService } from 'src/app/modules/task/services/task.service';
import { SmartAlertResult, mgDialogConfig } from 'src/app/shared/components/smart-alert/interfaces/custom-dialog.interface';
import { SmartAlertService } from 'src/app/shared/components/smart-alert/smart-alert.service';
import { RevisionService } from '../services/revision.service';

export interface CanComponentDeactivate {
  canDeactivate: (config?: mgDialogConfig) => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class EndRevisionGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(
    private _smartAlertService: SmartAlertService,
    private _taskService: TaskService,
    private _revisionService: RevisionService) {}


    canDeactivate(
      component: CanComponentDeactivate,
      currentRoute: ActivatedRouteSnapshot,
      currentState: RouterStateSnapshot,
      nextState?: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      let queryParams = nextState?.url.includes('?force=true')

      if(this._revisionService._revisionSubject.getValue().REVISION[0].sCodigo && !queryParams){
        return this._smartAlertService.init({
          type: "warning",
          title: `Atención`,
          message: `Tiene una revisión en curso, ¿Desea terminarla antes de salir?`,
          showConfirmButton: true,
          showDenyButton: true,
          confirmButtonText: 'Si',
          denyButtonText: 'No'
        }).pipe(
          mergeMap((result: SmartAlertResult) => {
            if (result.confirmStatus) {
              return this._taskService.endRevisionTask().pipe(
                map((responseTareo) => {
                  if (responseTareo.isSuccess) {
                    this._revisionService.setRevision({
                      REVISION: [
                        {
                          sCodigo: null,
                          sDescripcion: "Revisión inactiva",
                          sTipo_Configuracion: "REVISION"
                        }
                      ]
                    });
                    this._smartAlertService.close();
                    return true;
                  } else {
                    this._smartAlertService.close();
                    this._smartAlertService.init({
                      type: "warning",
                      title: `¡Atención!`,
                      message: `${responseTareo.message}`,
                      showCancelButton: true,
                    });
                    return false;
                  }
                })
              );
            } else {
              this._smartAlertService.close();
              return of(true);
            }
          })
        );
      }else{
        return true
      }

    }
}

