import { Component, OnInit } from '@angular/core';
import { IComponentInit, componentInit } from './notifications-center.config';
import { typeSplitValue } from 'src/app/shared/interfaces/buttons.interface';
import { LayoutService } from 'src/app/core/services/layout.service';
import { DateValues } from 'src/app/shared/interfaces/filter-date-range-ymd.interface';

@Component({
  selector: 'mg-notifications-center',
  templateUrl: './notifications-center.component.html',
  styleUrls: ['./notifications-center.component.scss']
})
export class NotificationsCenterComponent implements OnInit{
  component : IComponentInit;
  isScreenSMD$ = this._layoutService.isScreenSMD$;
  isDesktop$ = this._layoutService.isScreenXL$;

  constructor( 
    private _layoutService: LayoutService
  ) { }

  ngOnInit(): void {
    this.component = componentInit;
    this.initFilterReset();
  }

  searchCollaborator(data: any){
    if (typeof data.searchType == "number") {
      this.component.filters.source = data.searchType;
      this.component.filters.text = data.searchData;
      this.formatGetInputs()
    }
  }

  searchDate(e: DateValues){
    this.component.filters.dFecha_Inicio = e.dateStart;
    this.component.filters.dFecha_Fin = e.dateEnd as string;
    this.formatGetInputs();
  }

  searchCategory(e: string | null){
    this.component.filters.category = e
    this.formatGetInputs();
  }

  formatGetInputs(){
    let str = "";

    if (this.component.filters.text != null) {
      str += `&numFilter=${this.component.filters.source}&textFilter=${this.component.filters.text}`;
    }
    if (this.component.filters.category != null) {
      str += `&sFilterOne=${this.component.filters.category}`;
    }

    if (
      this.component.filters.dFecha_Inicio != "" &&
      this.component.filters.dFecha_Fin != ""
    ) {
      str += `&dFecha_Inicio=${this.component.filters.dFecha_Inicio}&dFecha_Fin=${this.component.filters.dFecha_Fin}`;
    }

    if (this.component.filters.refresh) {
      let random = Math.random();
      str += `&refresh=${random}`;
      this.component.filters.refresh = false;
    }

    this.component.getInputs = str;
  }

  initFilterReset() {
    this.component.filters = { ...this.component.Initfilters };
    this.formatGetInputs();
  }

  refreshFilters(e: typeSplitValue){
    switch (e) {
      case typeSplitValue.REFRESH:
        this.component.filters.refresh = true;
        this.formatGetInputs();
        break;

      case typeSplitValue.RESET:
        this.initFilterReset();
        break;
    }
  }
}
