import {
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { timer } from "rxjs";
import { CommonModule } from "@angular/common";
import { scaleInOutAnimation } from "src/app/shared/animations/scale-in-out.animation";
import { StatusTask, TaskFocus, TaskUpdate, TempTaskModal } from "../../interfaces/task-interfaces";
import * as moment from "moment";
import { setTotalHours } from "src/app/shared/helpers/timeAndHours";
import { IconComponent } from "src/app/shared/components/icon/icon.component";
import { groupBy } from "src/app/shared/helpers/groupBy"; 
import { configurations } from "src/app/shared/interfaces/configurations.interface";
import { GenericResponse } from "src/app/shared/interfaces/generic.interface";
import { TaskService } from "../../services/task.service";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { TaskComponent } from "../task/task.component";
import { SmartAlertService } from "src/app/shared/components/smart-alert/smart-alert.service";
import { SmartAlertResult } from "src/app/shared/components/smart-alert/interfaces/custom-dialog.interface";

interface UpdateValidation{ 
  fecha: moment.Moment, 
  nMinutos: number, 
  task: any,
}

@Component({
  selector: "mg-tasks-create-modal",
  templateUrl: "./tasks-create-modal.component.html",
  styleUrls: ["./tasks-create-modal.component.scss"],
  animations: [scaleInOutAnimation],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    MatInputModule,
    TaskComponent,
    MatProgressBarModule,
    MatDialogModule
  ]
})
export class TasksCreateModalComponent implements OnInit {
  bdArray: TempTaskModal[] = [];
  listArray: TempTaskModal[] = [];
  taskFormGroup: FormGroup;
  activityEdit: TempTaskModal | null = null;
  indexActivityEdit: number | null = null;
  mode: "create" | "read" | "update" = "create";
  uuid: number = 0;
  loading: boolean = false;
  public lastItemSelected: any | string = null;
  public lastReqId: any | string = null;
  public lastPrjectId: any | string = null;
  

  valueToMakeAction: string | undefined;
  tempForm: TempTaskModal;

  onAdd = new EventEmitter();
  @ViewChild("topList") private topList: ElementRef;

  maxValueNMinutes =
    this.data.aAllTareoStatus.find(
      (x: StatusTask) => x.dDate == moment().format("YYYY-MM-DD")
    ) != undefined
      ? 1440 -
        this.data.aAllTareoStatus.find(
          (x: StatusTask) => x.dDate == moment().format("YYYY-MM-DD")
        ).nRequired
      : 1440;

  constructor(
    public dialogRef: MatDialogRef<TasksCreateModalComponent>,
    private _taskService: TaskService,
    private _smartAlert: SmartAlertService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.initTaskFormGroup();
  }

  @HostListener("window:keyup.esc") onKeyUp() {
    this.dialogRef.addPanelClass("custom-prevent-close");

    timer(500).subscribe(async () => {
      this.dialogRef.removePanelClass("custom-prevent-close");
    });
  }

  ngOnInit(): void {
    // this.isMediumScreen();
    this.dialogRef.backdropClick().subscribe((_) => {
      this.dialogRef.addPanelClass("custom-prevent-close");
      timer(500).subscribe(async () => {
        this.dialogRef.removePanelClass("custom-prevent-close");
      });
    });
  }

  setTempForm() {
    this.tempForm = this.taskFormGroup.getRawValue();
  }

  getTempForm() {
    this.taskFormGroup.patchValue(this.tempForm);
  }

  setData(data: TempTaskModal) { 
    data.id = this.uuid;
    this.uuid++;

    this.updateValidations({
      fecha: moment(data.dFecha_Registro),
      nMinutos: data.nMinutos,
      task: data,
    });
    this.bdArray.push(data);
    let tempListArray = [...this.listArray];

    tempListArray.push(this.styleInfo(data));
    tempListArray.forEach((task) => {
      task.editState = false;
    });
    this.taskFormGroup.get("sDetalle")!.reset();

    setTimeout(() => {
      this.listArray = tempListArray;
    }, 0);
    timer(50).subscribe(() => {
      this.topList.nativeElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    });
  }

  styleInfo(data: TempTaskModal) {
    let newData = { ...data };
    newData.sFecha_Registro = moment(newData.dFecha_Registro).format(
      "DD/MM/YYYY"
    );
    newData.sHoras = setTotalHours(data.nMinutos);
    return newData;
  }

  taskClick(task: TaskFocus) {
    let ids = this.bdArray.map((x) => x.id);
    this.indexActivityEdit = ids.indexOf(task.id);
    let preActivityEdit = ids.indexOf(this.activityEdit?.id);

    switch (task.action) {
      case "eliminar-actividad":
        let currentActivity = this.bdArray[this.indexActivityEdit];
        this.updateValidations({
          fecha: moment(currentActivity.dFecha_Registro),
          nMinutos: 0 - currentActivity.nMinutos,
          task: currentActivity,
        });
        this.listArray.splice(this.indexActivityEdit, 1);
        this.bdArray.splice(this.indexActivityEdit, 1);
        this.updatePointers();
        break;

      case "editar-actividad":
        this.setTempForm();
        if (preActivityEdit > -1) {
          this.listArray[preActivityEdit].editState = false;
        }
        this.listArray[this.indexActivityEdit].editState = true;
        this.activityEdit = null;
        this.activityEdit = this.bdArray[this.indexActivityEdit];
        this.mode = "update";
        this.updateExtras("add");
        this.taskFormGroup.patchValue(this.activityEdit);
        
        this.lastItemSelected = this.activityEdit.sCodigo_Categoria;
        this.lastReqId = this.activityEdit.sCodigo_Requerimiento;
        this.lastPrjectId = this.activityEdit.sCodigo_Proyecto;

        break;
    }
  }

  initTaskFormGroup() {
    this.taskFormGroup = new FormGroup({
      dFecha_Registro: new FormControl(null),
      nMinutos: new FormControl(null),
      nCantidad: new FormControl(null),
      sCodigo_Tipo_Actividad: new FormControl(null),
      sCodigo_Proyecto: new FormControl(null),
      sCodigo_Requerimiento: new FormControl(null),
      sCodigo_Categoria: new FormControl(null),
      sCodigo_Tipo_Horas: new FormControl(null),

      sNombre_Tipo_Actividad: new FormControl(null),
      sNombre_Proyecto: new FormControl(null),
      sNombre_Categoria: new FormControl(null),
      sNombre_Tipo_Horas: new FormControl(null),
      sNombre_Requerimiento: new FormControl(null),

      sDetalle: new FormControl(null),
      sAnotaciones: new FormControl(null),
    });
  }

  addTask(e: TempTaskModal) {
    if (e !== null) {
      this.setData(e);
    }
    setTimeout(() => {
      this.valueToMakeAction = undefined;
    }, 0);
  }

  updateTask(e: TaskUpdate) {
    setTimeout(() => {
      this.mode = "create";
    }, 0);

    if (e) {
      this.updateValidations({
        fecha: moment(e.form.dFecha_Registro),
        nMinutos: Number(e.form.nMinutos),
        task: e,
      });
      if (e.form.dFecha_Registro !== e.beforeInfo.date) {
        this.updateTaskStatusByDate(e.beforeInfo.date, e.form.nMinutos);
      }

      e.form.id = this.bdArray[this.indexActivityEdit!].id;
      this.bdArray[this.indexActivityEdit!] = e.form;
      this.listArray[this.indexActivityEdit!] = this.styleInfo(e.form);
      let tipo_update = "update_regular";

      if (e.form.sCodigo_Tipo_Horas != "1") {
        tipo_update = "update_extra";
      }

      this.updateExtras(tipo_update);

      setTimeout(() => {
        //this.activityEdit = null;
      }, 0);
      this.updatePointers();
    } else {
      this.updateExtras("diff");
    }
    this.listArray[this.indexActivityEdit!].editState = false;
    setTimeout(() => {
      this.valueToMakeAction = undefined;
      this.activityEdit = this.tempForm;
      this.getTempForm();
    }, 0);
  }

  updateTaskStatusByDate(fecha: moment.Moment, minutos: number) { 
    let dDate = fecha.format("YYYY-MM-DD");
    let sEstado = "FALTA";

    this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == dDate);

    if (this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == dDate) != undefined) {
      this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == dDate).nReady =
        Number(this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == dDate).nReady) -
        minutos;

      let totalALlenar = this.data.aAllTareoStatus.find(
        (x: StatusTask) => x.dDate == dDate
      ).nRequired;
      let llenadoHastaAhora = this.data.aAllTareoStatus.find(
        (x: StatusTask) => x.dDate == dDate
      ).nReady;

      if (totalALlenar - llenadoHastaAhora > 0) {
        sEstado = "INCOMPLETO";
      }
      if (totalALlenar - llenadoHastaAhora <= 0) {
        sEstado = "COMPLETO";
      }
      if (totalALlenar - llenadoHastaAhora == totalALlenar) {
        sEstado = "FALTA";
      }

      this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == dDate).sEstado = sEstado;
      this.data.aAllTareoStatus.find(
        (x: StatusTask) => x.dDate == dDate
      ).nMaxMinutesRegular =
        Number(
          this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == dDate).nRequired
        ) -
        Number(this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == dDate).nReady)
    }

    //@ts-ignore
    let oTareoByStatus: any = groupBy(this.data.aAllTareoStatus, i => i.sEstado);

    for (const key in oTareoByStatus) {
      oTareoByStatus[key] = oTareoByStatus[key].map((value: any) => value.dDate);
    }

    setTimeout(() => {
      this.data.oTareoByStatus.FALTA =
        oTareoByStatus.FALTA == undefined ? [] : oTareoByStatus.FALTA;
      this.data.oTareoByStatus.COMPLETO =
        oTareoByStatus.COMPLETO == undefined ? [] : oTareoByStatus.COMPLETO;
      this.data.oTareoByStatus.INCOMPLETO =
        oTareoByStatus.INCOMPLETO == undefined ? [] : oTareoByStatus.INCOMPLETO;
    }, 0);

    this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == dDate);
  }

  updatePointers() {
    let temBDArray = [...this.bdArray];
    this.bdArray = temBDArray;
    let temlistArray = [...this.listArray];
    this.listArray = temlistArray;
    if (!this.bdArray.length) {
      setTimeout(() => {
        this.mode = "create";
      }, 0);
    }
  }

  async cancel() {
    if (this.bdArray.length) {
      this._smartAlert.init({
        type: "quest",
        title: "¿Salir sin Guardar?",
        showCancelButton: true,
      }).subscribe((result: SmartAlertResult) => {

        if (!result.confirmStatus && !result.denyStatus) {
          return;
        }else{
        this.closeDialog(false);
        this.onAdd.emit(false);
        }

      });
    } else {
      this.closeDialog(true);
    }
  }

  closeDialog(save: boolean) {
    this.dialogRef.close(save);
  }

  confirmSave() {
    this._smartAlert.init({
      type: "quest",
      title: "¿Que acción desea realizar?",
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: "Guardar y continuar",
      denyButtonText: "Guardar y salir",
      cancelButtonText: "Cancelar",
    }).subscribe((result: SmartAlertResult) => {
      if (!result.confirmStatus && !result.denyStatus) return; //cancelado
      this.save(result)
      this._smartAlert.close();
    });
  }

  async save(option: SmartAlertResult) {
    this.loading = true;
    if (this.bdArray.length > 0) {
      let newbdArray = this.bdArray.map((x) => {
        x.dFecha_Registro = moment(x.dFecha_Registro).format("YYYY-MM-DD");
        x.nMinutos = x.nMinutos;
        return x;
      });

      this._taskService.addTask(newbdArray).subscribe((resp) => {
        this.loading = false;
        if (resp.isSuccess) {
          this._smartAlert.init({
            type: "success",
            timer: 1.5,
            timerProgressBar: true,
          })
          if (option.denyStatus) {
            this.closeDialog(true);
            this.onAdd.emit(true);
          } else {
            this.onAdd.emit(true);
   
            this.data =  this.data
            this.bdArray = [];
            this.listArray = [];
          }
        } else {
          this.setDataError(resp);
        }
      })

    } else {
      this._smartAlert.init({
        type: "info",
        showCancelButton: false,
        message: "Debe agregar al menos una tarea.",
      })
    }
  }

  inputsActionEmit(action: string) {
    this.valueToMakeAction = action;
  }

  updateValidations(updateValid: UpdateValidation) {
    let dDate = updateValid.fecha.format("YYYY-MM-DD");
    let sEstado = "FALTA";
    let auxBeforeInfo = {
      minutes: 0,
      date: moment(),
    };

    if (updateValid.task.beforeInfo) {
      auxBeforeInfo = updateValid.task.beforeInfo;
      updateValid.task = updateValid.task.form;
    }

    let horario_regular = this.data.TIPO_HORA.find(
      (x: configurations) => x.sDescripcion == "HORARIO REGULAR"
    );

    if (
      updateValid.task.sCodigo_Tipo_Horas == horario_regular.sCodigo &&
      this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == dDate) != undefined
    ) {
      this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == dDate).nReady =
        Number(this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == dDate).nReady) -
        auxBeforeInfo.minutes +
        updateValid.nMinutos;

      let totalALlenar = this.data.aAllTareoStatus.find(
        (x: StatusTask) => x.dDate == dDate
      ).nRequired;
      let llenadoHastaAhora = this.data.aAllTareoStatus.find(
        (x: StatusTask) => x.dDate == dDate
      ).nReady;

      if (totalALlenar - llenadoHastaAhora > 0) {
        sEstado = "INCOMPLETO";
      }
      if (totalALlenar - llenadoHastaAhora <= 0) {
        sEstado = "COMPLETO";
      }
      if (totalALlenar - llenadoHastaAhora == totalALlenar) {
        sEstado = "FALTA";
      }

      this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == dDate).sEstado = sEstado;

      this.data.aAllTareoStatus.find(
        (x: StatusTask) => x.dDate == dDate
      ).nMaxMinutesRegular =
        Number(
          this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == dDate).nRequired
        ) -
        Number(this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == dDate).nReady);
    }

    if (
      updateValid.task.sCodigo_Tipo_Horas != horario_regular.sCodigo &&
      this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == dDate) != undefined
    ) {
      this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == dDate).nMaxMinutesExtra =
        this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == dDate)
          .nMaxMinutesExtra - updateValid.nMinutos;
    }

    //@ts-ignore
    let oTareoByStatus: any = groupBy(this.data.aAllTareoStatus, i => i.sEstado);

    for (const key in oTareoByStatus) {
      oTareoByStatus[key] = oTareoByStatus[key].map((value: any) => value.dDate);
    }

    setTimeout(() => {
      this.data.oTareoByStatus.FALTA =
          oTareoByStatus.FALTA == undefined ? [] : oTareoByStatus.FALTA;
      this.data.oTareoByStatus.COMPLETO =
        oTareoByStatus.COMPLETO == undefined ? [] : oTareoByStatus.COMPLETO;
      this.data.oTareoByStatus.INCOMPLETO =
        oTareoByStatus.INCOMPLETO == undefined ? [] : oTareoByStatus.INCOMPLETO;
    }, 0);
  }

  updateExtras(type: string) {
    let date = moment(this.activityEdit!.dFecha_Registro).format("YYYY-MM-DD");
    if (
      type == "add" &&
      this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == date) != undefined
    ) {
      this.data.aAllTareoStatus.find(
        (x: StatusTask) => x.dDate == date
      ).nMaxMinutesRegularEditar =
        Number(
          this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == date)
            .nMaxMinutesRegular
        ) + Number(this.activityEdit!.nMinutos);
      this.data.aAllTareoStatus.find(
        (x: StatusTask) => x.dDate == date
      ).nMaxMinutesExtraEditar =
        Number(
          this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == date)
            .nMaxMinutesExtra
        ) + Number(this.activityEdit!.nMinutos);
    } else if (
      type == "diff" &&
      this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == date) != undefined
    ) {
      this.data.aAllTareoStatus.find(
        (x: StatusTask) => x.dDate == date
      ).nMaxMinutesRegularEditar = Number(
        this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == date)
          .nMaxMinutesRegular
      );
      this.data.aAllTareoStatus.find(
        (x: StatusTask) => x.dDate == date
      ).nMaxMinutesExtraEditar = Number(
        this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == date).nMaxMinutesExtra
      );
    } else if (
      type == "update_regular" &&
      this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == date) != undefined
    ) {
      this.data.aAllTareoStatus.find(
        (x: StatusTask) => x.dDate == date
      ).nMaxMinutesExtraEditar -= Number(this.activityEdit!.nMinutos);
    } else if (
      type == "update_extra" &&
      this.data.aAllTareoStatus.find((x: StatusTask) => x.dDate == date) != undefined
    ) {
      this.data.aAllTareoStatus.find(
        (x: StatusTask) => x.dDate == date
      ).nMaxMinutesRegularEditar -= Number(this.activityEdit!.nMinutos);
    }
  }

  setDataError(resp: GenericResponse<any>) {
    if(resp.data){
      if(resp.data.errors?.length >= 1){
        this.uuid = 0;
        resp.data.errors.id = this.uuid;
        this.uuid++;
    
        this.bdArray = [];
        this.listArray = [];
  
        resp.data.errors.forEach((x: any) => {
          x.dFecha_Registro = moment(x.dFecha_Registro);
          this.bdArray.unshift(x);
          let tempListArray = [...this.listArray];
          tempListArray.unshift(this.styleInfo(x));
          this.listArray = tempListArray;
    
          this.taskFormGroup.get("sDetalle")!.reset();
        });
        // swalSmart({
        //   type: "warning",
        //   showCancelButton: false,
        //   text: "Algunas tareas no pudieron ser registradas.",
        // });
      }
      else{
        // swalSmart({
        //   type: "warning",
        //   showCancelButton: false,
        //   text: resp.message,
        // });
      }
    }else{
      // swalSmart({
      //   type: "warning",
      //   showCancelButton: false
      // });
    }
  }
}
