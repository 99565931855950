import { Injectable } from '@angular/core';
import { AccessService } from 'src/app/core/services/access.service';
import { Observable, map } from 'rxjs';
import { environment as env } from 'src/environments/environment';
//import { getIcon } from 'src/app/shared/helpers/iconBuilder';
import { getStateBadge } from 'src/app/shared/helpers/badgesBuilder';
import { HttpClient } from '@angular/common/http';
import { InputsAllRole, Rol, RolDataCreateUpdate, StateRol } from '../interfaces/roles-interface';
import { GenericResponseList } from 'src/app/shared/interfaces/table.interface';
import { transforDataRolesList } from '../helpers/mapDataRolesList';
import { GenericResponse } from 'src/app/shared/interfaces/generic.interface';

@Injectable({
  providedIn: 'root'
})
export class RolesServiceService {

  constructor(private http: HttpClient, private accessService: AccessService) {}

  getAll(
    records: number,
    sort: string,
    order: string,
    numPage: number,
    getInputs = ""
  ) {
    const requestUrl = `${
      env.api_url
    }api/rol/listar?records=${records}&sort=${sort}&order=${order}&numPage=${
      numPage + 1
    }${getInputs}`;
    return this.http.get<GenericResponseList<Rol>>(requestUrl).pipe(
      map((response) => {
       
        return transforDataRolesList(response, this.accessService);
      })
    );
  }

  changeState(data:StateRol): Observable<GenericResponse<string>> {
    return this.http.put<GenericResponse<string>>(env.api_url + "api/rol/actualizar/estado", data);
  };

  getAllDetail(): Observable<Rol> {
    return this.http.get<Rol>(env.api_url + "api/accesos/accessRole");
  }

  getDetail(idRole:number): Observable<GenericResponse<Rol>> {
    const data = this.http.get<GenericResponse<Rol>>(
      `${env.api_url}api/rol/detalle?nId_Role=${idRole}`
    ).pipe( map(response  => {
      return response
    }));
    return data
  };

  create(data: RolDataCreateUpdate){
    return this.http.post<GenericResponse<string>>(env.api_url + "api/rol/crear", data);
  }


  update(data:RolDataCreateUpdate): Observable<GenericResponse<string>> {
    return this.http.put<GenericResponse<string>>(env.api_url + "api/rol/actualizar", data);
  }

  newBackendTest(){
    return this.http.get<any>(env.api_url + "api/accesos/newDoYouHearMe");
  }
};

