import { Injectable } from '@angular/core';
import { ToastInitConfig, ToastInsideConfig, ToastPositions, ToastResponse } from '../toast.config';
import { BehaviorSubject, concatMap, delay, map, Observable, of, Subject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToasService {
  count: number = 0
  private aAllToast: {[key:string]: ToastInsideConfig} = {}
  private ToastSubject$ =  new BehaviorSubject<{[key:string]: ToastInsideConfig}>(this.aAllToast)
  toastObs =  this.ToastSubject$.asObservable()
  private responseToast: {[key:string]: ToastResponse} = {}
  private toastResponseSubject$ = new BehaviorSubject<{[key:string]: ToastResponse}>(this.responseToast)
  responseObs = this.toastResponseSubject$.asObservable()
  private closeAllToast$ =  new BehaviorSubject<boolean>(false)
  closeAllToastObs =  this.closeAllToast$.asObservable()

  constructor() {

   }

  updateToastData(toastData: ToastInsideConfig){
    this.aAllToast[toastData.count!] = toastData
    this.ToastSubject$.next(this.aAllToast)
  }

  async deleteToast(toastData: ToastInsideConfig){
    let response:ToastResponse = {isChecked: toastData.isChecked, confirmRedirection: toastData.confirmRedirection}
    this.responseToast[toastData.count!] = response
    this.toastResponseSubject$.next(this.responseToast)
    await setTimeout(() => {
      delete this.responseToast[toastData.count!]
      this.toastResponseSubject$.next(this.responseToast)
      delete this.aAllToast[toastData.count!]
      this.ToastSubject$.next(this.aAllToast)
    }, 200);
  }

  async closeAllToast() {
    this.closeAllToast$.next(true)
    setTimeout(() => {
      this.closeAllToast$.next(false)
    }, 200);
}


// 
  init(toastData: ToastInitConfig):Observable<ToastResponse>{
    let id = this.count
    this.count ++
    toastData.position = toastData.position ?  toastData.position : 'top-r'
    this.aAllToast[id] = {...toastData, bMostrar: true, count:id, positionCss: ToastPositions[toastData.position]}
      this.ToastSubject$.next(this.aAllToast)
    return this.responseObs.pipe(
      map(resp =>{
        if(resp[id]) return resp[id]
        return {}})
    )
  }

  // init(data: ToastInitConfig): Observable<ToastResponse> {
  //   let id = this.count;
  //   this.count++;
    
  //   let toastData: ToastInsideConfig = {...data}
  //   // Ensure valid position
  //   toastData.positionCss = ToastPositions[toastData.position!] || ToastPositions['top-r'] ;
  
  //   // Prepare toast data
  //   this.aAllToast[id] = {
  //     ...toastData,
  //     bMostrar: true,
  //     count: id,
  //     positionCss: toastData.positionCss,
  //   };
  
  //   // Create a new queue for this specific toast batch
  //   const toastQueue = new Subject<ToastInsideConfig>();
  
  //   // Push the toast data to the queue
  //   toastQueue.next(toastData);
  
  //   // Subscribe to the queue and execute toasts sequentially
  //   toastQueue.pipe(
  //     concatMap(queuedToastData => {
  //       // Simulate delay (replace with actual logic if needed)
  //       return of(queuedToastData).pipe(delay(500)); // Adjust delay as needed
  //     }),
  //     tap(queuedToastData => {
  //       // Update toast data with appropriate position information
  //       this.aAllToast[id].positionCss! = queuedToastData.positionCss!;
  //     })
  //   ).subscribe(() => {
  //     // Emit the entire toast data after processing the queue
  //     this.ToastSubject$.next(this.aAllToast);
  //   });
  
  //   // Return the response observable (modify as needed)
  //   return this.responseObs.pipe(
  //     map(resp => resp[id] || {})
  //   );
  // }
  


  
}
