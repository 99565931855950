import * as moment from "moment";
import { PROJECTS_STATUS } from "src/app/modules/projects-requirements/helpers/pry-rq.enums";
import { getSimpleBadge } from "src/app/shared/helpers/badgesBuilder";
import { setDecimalHours, setTotalHours } from "src/app/shared/helpers/timeAndHours";

export const mapDataToFilterIndicator = (resp:any[], sIdPrincipal:string, arrayName: string, Estate_Property: string, Estate_SubProperty?: string)=>{
  let response:any[] = []
  if(resp.length){
    resp
    .filter((item: any) => item[arrayName].length > 0)
    .map((item:any)=>{
      item[`s${Estate_Property}`] = {...getPryRqState(item[`n${Estate_Property}`]), value: item[`n${Estate_Property}`]}
      item.isExpanded =  false;
      item.nMin_Restantes = 0
      item.nMin_Aprobadas = 0
      item.nMin_Pendiente = 0
      item.nMin_Estimadas = 0
      item[arrayName].map((x:any)=>{
        x.nMin_Restantes = x.nMin_Estimadas - x.nMin_Aprobadas - x.nMin_Pendiente  
        item.nMin_Restantes = item.nMin_Restantes + x.nMin_Restantes
        item.nMin_Aprobadas = item.nMin_Aprobadas + x.nMin_Aprobadas
        item.nMin_Pendiente = item.nMin_Pendiente + x.nMin_Pendiente
        item.nMin_Estimadas = item.nMin_Estimadas + x.nMin_Estimadas

        if(typeof x[`n${Estate_SubProperty}`] == 'number'){
          x[`s${Estate_SubProperty}`] = {...getPryRqState(x[`n${Estate_SubProperty}`]), value: x[`n${Estate_SubProperty}`]}
        }
        x.nMin_Restantes = getHourBadge(x.nMin_Restantes);
        x.nId_Property =  item[sIdPrincipal]
        x.nMin_Aprobadas = getHourBadge(x.nMin_Aprobadas);
        x.tooltipPendientes = setOvertimeTooltip(x.nMin_Pendiente)
        if(x.dFecha_Inicio_Pendiente) x.tooltipPendientes = moment(x.dFecha_Inicio_Pendiente).format('DD-MM-YY');
        if(x.dFecha_Fin_Pendiente) x.tooltipPendientes = moment(x.dFecha_Fin_Pendiente).format('DD-MM-YY')
        if(x.dFecha_Fin_Pendiente && x.dFecha_Inicio_Pendiente) x.tooltipPendientes = `Desde ${moment(x.dFecha_Inicio_Pendiente).format('DD-MM-YY')} hasta ${moment(x.dFecha_Fin_Pendiente).format('DD-MM-YY')}`
        x.nMin_Pendiente = getSimpleBadge(
          String(setHours(x.nMin_Pendiente)), 
          x.tooltipPendientes, 
          'orange')
        x.nMin_Estimadas = getHourBadge(x.nMin_Estimadas)
      })

      item.tooltipPendientes = setOvertimeTooltip(item.nMin_Pendiente)
      if(item.dFecha_Inicio_Pendiente) item.tooltipPendientes = moment(item.dFecha_Inicio_Pendiente).format('DD-MM-YY');
      if(item.dFecha_Fin_Pendiente) item.tooltipPendientes = moment(item.dFecha_Fin_Pendiente).format('DD-MM-YY');
      if(item.dFecha_Inicio_Pendiente && item.dFecha_Fin_Pendiente) item.tooltipPendientes = `Desde ${moment(item.dFecha_Inicio_Pendiente).format('DD-MM-YY')} hasta ${moment(item.dFecha_Fin_Pendiente).format('DD-MM-YY')}`
      
      item.nMin_Aprobadas = {...getHourBadge(item.nMin_Aprobadas), nMin_Aprobadas: item.nMin_Aprobadas};
      item.nMin_Estimadas = {...getHourBadge(item.nMin_Estimadas), nMin_Estimadas: item.nMin_Estimadas};
      item.nMin_Pendiente = {...getSimpleBadge(
        String(setHours(item.nMin_Pendiente)), 
        item.tooltipPendientes, 
        'orange'), nMin_Pendiente:item.nMin_Pendiente}
      item.nMin_Restantes = {...getHourBadge(item.nMin_Restantes), nMin_Restantes: item.nMin_Restantes};

      response.push(item)
    })
   
  }


  return response
}

const getPryRqState =  (nState:number) =>{
  switch(nState){
    case PROJECTS_STATUS.ACTIVO:
      return getSimpleBadge('ACTIVO', 'ACTIVO', 'green', '!font-bold')
    case PROJECTS_STATUS.INACTIVO:
      return getSimpleBadge('INACTIVO', 'INACTIVO','main', '!font-bold')
    case PROJECTS_STATUS.PAUSADO:
      return getSimpleBadge('PAUSADO', 'PAUSADO', 'yellow', '!font-bold')
    case PROJECTS_STATUS.COMPLETADO:
      return getSimpleBadge('COMPLETADO', 'COMPLETADO', 'cyan', '!font-bold')
    case PROJECTS_STATUS.DESESTIMADO:
      return getSimpleBadge('DESESTIMADO', 'DESESTIMADO', 'red', '!font-bold')
    default:
      return ''
  }
}

const getHourBadge = (min:number) => {
  return getSimpleBadge(
    String(setHours(min)), 
    setOvertimeTooltip(min), 
    setColor(min))
}

export const setOvertimeTooltip = (min:number) => {
  let sHours = ''
  if(min<0){
    let newMin = min * -1
    sHours = setTotalHours(newMin)
    return `Hay ${sHours} de sobretiempo`
  }else{
    return String(setHours(min))
  }
}

const setHours = (args:number)=>{
  if(args < 0){
    let value =  args * -1
    return  `-${setDecimalHours(value)}`
  }else{
    return setDecimalHours(args)
  }

}

const setColor = (min:number) =>{
  if(min < 0){
    return 'red'
  }else return 'main'
}