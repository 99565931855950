<div class="flex flex-col justify-between h-full">
    <div class="flex-nowrap flex-col w-full">
        <div class="flex flex-col xs:flex-row xs:gap-2 min-w-full">
            <mg-input-date label="Fecha" [required]="true" placeholder="DD/MM/AAAA" mode="create" [min]="minDate"
                [max]="maxDate" [inputControl]="task.get('dFecha_Registro') | formTransform:'formControl'" enableFilter="WITHOUT_MARKS"
                [aDisabledDays]="data.oTareoByStatus.WITHOUT_MARKS"
                [serie1]="data.oTareoByStatus.WITHOUT_MARKS" titleserie1="Marcas incompletas"
                cssserie1="!bg-mg-gray-light rounded-full p-2" [serie2]="data.oTareoByStatus.INCOMPLETO"
                titleserie2="Incompleto" cssserie2="!bg-mg-yellow rounded-full p-2"
                [serie3]="data.oTareoByStatus.FALTA" titleserie3="Falta Todo" cssserie3="!bg-mg-red rounded-full p-2" class="w-full xs:w-1/2">
            </mg-input-date>
            <mg-input-minutes-task label="Minutos" [required]="true" placeholder="00" [mode]="'create'"
                [calculateHours]="true" [inputControl]="task.get('nMinutos') | formTransform:'formControl'" [hintPosition]=" isSmallScreen ? 'right' : 'down'" [maxValue]="maxValueNMinutes"
                [tasksStatus]="currentValidations.sEstado_Horario " [hint]="true" class="w-full xs:w-1/2">
            </mg-input-minutes-task >
           <!-- <span>{{currentValidations.sEstado_Horario}}</span> -->
        </div>
        <mg-input-select  label="Tipo de Actividad" [inputControl]="task.get('sCodigo_Tipo_Actividad') | formTransform:'formControl'"
            [required]="true" [mode]="isSinAsistencia ? 'read' : 'create'" placeholder="Tipo de Actividad" [aOpciones]="data.TIPO_ACTIVIDAD"
            class="winput hidden">
        </mg-input-select>
        <mg-input-select-group label="Proyecto" [inputControl]="task.get('sCodigo_Proyecto') | formTransform:'formControl'" [required]="true"
        [mode]="isSinAsistencia ? 'read' : 'create'" placeholder="Proyecto" [aOpciones]="optionsGroups">
        </mg-input-select-group>

        <mg-input-select label="Requerimiento" [inputControl]="task.get('sCodigo_Requerimiento') | formTransform:'formControl'" [required]="true"
        [mode]="isSinAsistencia ? 'read' : 'create'" placeholder="Requerimiento" [isLoading]="loadingReq"
            [aOpciones]="data.REQUERIMIENTO_POR_PROYECTO">
        </mg-input-select>
        <mg-input-select label="Categoría" [inputControl]="task.get('sCodigo_Categoria') | formTransform:'formControl'" [required]="true"
        [mode]="isSinAsistencia ? 'read' : 'create'" placeholder="Categoría" [isLoading]="loadingCat" [aOpciones]="data.CATEGORIA_TAREA"


        >
        </mg-input-select>

        <div class="flex flex-col xs:flex-row xs:gap-2">
            <!-- DASHBOARD: -->
            <mg-input-select label="Tipo de Horas" [inputControl]="task.get('sCodigo_Tipo_Horas') | formTransform:'formControl'" [required]="true"
            [mode]="isSinAsistencia ? 'read' : 'create'" placeholder="Tipo de Horas" [aOpciones]="TIPOS_HORA_ARRAY"
                [ngClass]="task.controls['dHora_Inicio']!= undefined ? 'w-full xs:w-1/2':'w-full'"
            >
            </mg-input-select>
            <mg-time *ngIf="task.controls['dHora_Inicio']!= undefined" label="Hora de Inicio" [mode]="isSinAsistencia ? 'read' : 'create'"
                [inputControl]="task.get('dHora_Inicio') | formTransform:'formControl'" [required]="true" [hint]="dHora_Inicio_Hint" [hintPosition]=" isSmallScreen ? 'right' : 'down'"
                [messageHint]="MessageHint!" [currentValidationsDate]="data.aAllTareoStatus" class="w-full xs:w-1/2"
                [autocomplette]="false"
                >
            </mg-time>
        </div>
        <mg-input-textarea label="Descripción de la actividad" [required]="true" placeholder="Describir actividad"
        [mode]="isSinAsistencia ? 'read' : 'create'" [maxlength]="550" class="col-span-4" [inputControl]="task.get('sDetalle') | formTransform:'formControl'"
            (keyup.enter)="keyUpEnter($event)" [minRows]="4" [maxRows]="6">
        </mg-input-textarea>
    </div>
</div>