import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as env } from "../../../../environments/environment";
import { GenericResponse } from 'src/app/shared/interfaces/generic.interface';
import { NotificationsDetail } from '../interfaces/notifications.interface';
import { GenericResponseList } from 'src/app/shared/interfaces/table.interface';
import { Subject } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { SOCKETS_URLS } from 'src/app/shared/interfaces/sockets.interface';
import { localDB } from 'src/app/shared/helpers/localStorage';

export interface PushToken {
  sEndpoint: string, 
  sP256dh :string, 
  sAuth: string, 
  nId_Usuario?: number
}



@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private hubConnection: signalR.HubConnection;
  public notificationReceived = new Subject<any>();

  countNotifications = new BehaviorSubject<number>(0)
  countNotifications$ = this.countNotifications.asObservable()

  constructor(private http: HttpClient) { 
    // this.hubConnection = new signalR.HubConnectionBuilder()
    // .withUrl(env.api_url + SOCKETS_URLS.NOTIFICATIONS+`?userId=${localDB.getItem('idUsuario')}`) // URL del Hub de SignalR en tu backend
    // .build();

    // this.hubConnection.start().then(() => {
    // }).catch(err => console.error(err));

    // this.hubConnection.on('ReceiveNotification', (notifications: any) => {
    //   this.notificationReceived.next(notifications);
    // });
  }

  getAllNotifications(
    records: number,
    sort: string,
    order: string,
    numPage: number,
    getInputs = ""
  ): Observable<GenericResponseList<NotificationsDetail>>{
    const requestUrl = `${env.api_url}api/notificacion/listarCentroDeNotificaciones?records=${records}&sort=${sort}&order=${order}&numPage=${numPage + 1}${getInputs}`;
    return this.http.get<GenericResponseList<NotificationsDetail>>(requestUrl);
  }

  getNotifications(): Observable<GenericResponse<NotificationsDetail[]>> {
    return this.http.get<GenericResponse<NotificationsDetail[]>>(env.api_url + "api/notificacion/listar")
  }
  

  // getNotificationsByUser(nId_User: number): Observable<any> {
  //   return this.http.get<any>(env.api_url + "api/notificacion/listarMasiveUser?nId_User="+ nId_User)
  // }

  updateStateNotifications(ids: number[]): Observable<GenericResponse<string>> {
    const requestUrl = `${env.api_url}api/notificacion/actualizarEstado`;
    return this.http.post<GenericResponse<string>>(requestUrl, ids);
  }

  updateStateNotificationsCondition(ids: number[]): Observable<GenericResponse<string>> {
    const requestUrl = `${env.api_url}api/notificacion/actualizarEstadoVisto`;
    return this.http.post<GenericResponse<string>>(requestUrl, ids);
  }

  // sendDataToEmail(nId_User: number, data : object): Observable<any> {
  //   return this.http.post<any>(
  //     env.api_url + "api/notificacion/sendEmail?nId_User=" + nId_User, data
  //   );
  // }

  savePushToken(pushToken: PushToken){
    const requestUrl = `${env.api_url}api/notificacion/savePushNotifyToken`;
    return this.http.post<GenericResponse<any>>(requestUrl, pushToken);
  }
}
