import { AccessService } from 'src/app/core/services/access.service';
import { getTextWithPic } from 'src/app/shared/helpers/textWithPic';
import { GenericResponseList } from 'src/app/shared/interfaces/table.interface';
import { NameWithPic } from 'src/app/shared/interfaces/textWithPic.interface';
import {
  REQUEST_CATEGORY,
  REQUEST_FREQUENCY_TYPE,
  REQUEST_TYPE,
} from './enums';
import {
  getDateBagde,
  getRoundBadge,
  getSimpleBadge,
  getStateBadge,
} from 'src/app/shared/helpers/badgesBuilder';
import {
  getFirstLetterOfWord,
  setToTitleCase,
} from 'src/app/shared/helpers/transformText';
import { setTotalDays, setTotalHours } from 'src/app/shared/helpers/timeAndHours';
import { localDB } from 'src/app/shared/helpers/localStorage';
import { REQUEST_STATUS } from 'src/app/shared/components/marking/helpers/enums';
import { getIcon } from 'src/app/shared/helpers/iconBuilder';
import { GenericResponse } from 'src/app/shared/interfaces/generic.interface';
import { RequestList, footerList } from '../interfaces/interfaces';
import { configurations } from 'src/app/shared/interfaces/configurations.interface';

export function  transforDataRequestList(
  response: GenericResponseList<RequestList>,
  _accessService: AccessService,
  configurations: {[key:string]: configurations[]}
) {
  if (response.data == null) response.data = [];
  let approvedDaysRequests: number = 0;
  let approvedHoursRequests: number = 0;
  let pendingDaysRequests: number = 0;
  let pendingHoursRequests: number = 0;
  response.data.forEach(function (e) {
    if (e.aHistory) {
      if(e.nEstado == REQUEST_STATUS.ANULADO && e.aHistory.some(x => x.nId_Solicitud == e.nId) && e.nId_Requerimiento){
        let oJson_Old = JSON.parse(e.aHistory.find(x => x.nId_Solicitud == e.nId).sJson_Old)
        if(oJson_Old && oJson_Old.sCodigo_Proyecto){
        }else if(oJson_Old && oJson_Old.nId_Proyecto){
          let proyectos = configurations['PROYECTOS']
          let requerimientos = configurations['REQUERIMIENTOS_FILTRO']
          let lideres_directores = configurations['LIDERES_DIRECTORES']
          let tipo_Hora = configurations['TIPO_HORA']
          let categorias = configurations['CATEGORIAS_ALL']
          let tipo_solicitud =  configurations['TIPO_SOLICITUD']
          let tipo_tarea = tipo_Hora.find(x => x.sCodigo == oJson_Old.sTipo_Hora)!.sDescripcion
          let tipo_sol = tipo_solicitud.find(x => x.sDescripcion.includes(tipo_tarea))
          e.sMotivo = oJson_Old.sDetalle
          e.nId_Lider = oJson_Old.nId_Lider_Pry
          e.sNombre_Lider = lideres_directores.find(x => x.sCodigo == oJson_Old.nId_Lider_Pry)!.sDescripcion
          e.nId_Director = oJson_Old.nId_Director_Pry
          e.sNombre_Director = lideres_directores.find(x => x.sCodigo == oJson_Old.nId_Director_Pry)!.sDescripcion
          e.nId_Proyecto = oJson_Old.nId_Proyecto,
          e.sNombre_Proyecto = proyectos.find(x => x.sCodigo == oJson_Old.nId_Proyecto)!.sDescripcion
          e.nId_Requerimiento = oJson_Old.nId_Requerimiento
          e.sNombre_Requerimiento = requerimientos.find(x => x.sCodigo == oJson_Old.nId_Requerimiento)!.sDescripcion
          e.nId_Categoria_requerimiento = Number(oJson_Old.sId_Categoria)
          e.sNombre_Categoria_requerimiento = categorias.find(x => x.sCodigo == oJson_Old.sId_Categoria)!.sDescripcion
          e.nId_Tipo_Solicitud = Number(tipo_sol!.sCodigo)
          e.sNombre_Tipo_Solicitud = tipo_sol!.sDescripcion.split('-')[0]
        }
      }
    }

    let name_pic: NameWithPic = {
      urlPic: e.sUrl_Foto,
      primaryText: { text: e.sNombre_Colaborador },
    };
    e.icSeeDetails = getIcon(
      { name: 'visibility', type: 'material' },
      'Detalle',
      'main',
      _accessService.validarPermisoSlug('SOLICITUD-DETALLE'),
      'view-request-details'
    );
    e.NameWithPic = getTextWithPic(name_pic);
    e.sNombre_Usuario_Update =
      (e.sNombre_Usuario_Update == '' || e.sNombre_Usuario_Update == null) ? '-' : e.sNombre_Usuario_Update;

    e.bgRequestType = getBadgeForRequestType(
      e.sNombre_Tipo_Solicitud,
      e.nId_Categoria
    );
    e.bgState = getStateBadge(e.sEstado_Solicitud, e.sEstado_Solicitud);
    e.bgRange = getDateBagde(
      e.dFecha_Inicio,
      e.dFecha_Fin,
      e.nTipo_Frecuencia == REQUEST_FREQUENCY_TYPE.POR_HORAS
    );
    e.badgeUpdate = getRoundBadge(
      setToTitleCase(e.sNombre_Usuario_Update),
      getFirstLetterOfWord(e.sNombre_Usuario_Update),
      'm-auto'
    );
    e.bgAmount = getSimpleBadge(
      setCantHoursOrDays(e.nCantidad, e.nTipo_Frecuencia, e.nId_Tipo_Solicitud)
    );
    let approvers = approverRemoveDuplicate(e.aprobadores);
    e.approvers = approvers.approvers;
    e.bgApprovers = approvers.badge;
    e.icCancelRequest = showCancelIconAll(
      e.nId_Categoria,
      e.nId_Tipo_Solicitud,
      e.nEstado,
      _accessService.validarPermisoSlug('SOLICITUD-ANULAR'),
      e.nId_Colaborador
    );

    

    e.btnHistory = e.nFlag_Historico
    ? {...getIcon(
        { name: 'history', type: 'material' },
        'Historial de cambios',
        '',
        true,
        'request_history'
      ), action: 'request_history'}
    : false;

    approvedHoursRequests =
      e.sEstado_Solicitud == 'APROBADO' &&
      sumHoursForApproveOrPending(e.nId_Tipo_Solicitud)
        ? approvedHoursRequests + e.nCantidad
        : approvedHoursRequests;

    pendingHoursRequests =
      ['PENDIENTE', 'PENDIENTE POR DIRECTOR'].includes(e.sEstado_Solicitud) &&
      sumHoursForApproveOrPending(e.nId_Tipo_Solicitud)
        ? pendingHoursRequests + e.nCantidad
        : pendingHoursRequests;

    approvedDaysRequests =
    e.sEstado_Solicitud == 'APROBADO' &&
    sumDaysForApproveOrPending(e.nId_Tipo_Solicitud)
      ? approvedDaysRequests + e.nCantidad
      : approvedDaysRequests;

    pendingDaysRequests =
    ['PENDIENTE', 'PENDIENTE POR DIRECTOR'].includes(e.sEstado_Solicitud) &&
    sumDaysForApproveOrPending(e.nId_Tipo_Solicitud)
      ? pendingDaysRequests + e.nCantidad
      : pendingDaysRequests;

    e.actionMenuCards = [
      {
        label: 'Detalle',
        icon: {
          name: 'visibility',
          type: 'material',
          class: 'text-mg-main-blue'
        },
        type: 'submit',
        action: 'view-request-details',
        visible: _accessService.validarPermisoSlug('SOLICITUD-DETALLE'),
      },
      {
        label: 'Anular solicitud',
        icon: {
          name: 'remove_circle_outline',
          type: 'material',
          class: 'text-mg-gray'
        },
        type: 'submit',
        action: 'cancel-request',
        visible: e.icCancelRequest ? true : false,
      },
    ];
  });
  response.footer = {
    approvedHoursRequests: setTotalHours(approvedHoursRequests),
    pendingHoursRequests: setTotalHours(pendingHoursRequests),
    approvedDaysRequests: setTotalDays(approvedDaysRequests),
    pendingDaysRequests: setTotalDays(pendingDaysRequests),
  };

  return response;
}

export function getBadgeForRequestType(
  sRequestType: string,
  nIdCategory: number
) {
  let color = '';
  switch (nIdCategory) {
    case REQUEST_CATEGORY.PERMISOS:
      color = 'teal';
      break;

    case REQUEST_CATEGORY.LICENCIAS:
      color = 'main';
      break;

    case REQUEST_CATEGORY.VACACIONES:
      color = 'purple';
      break;

    case REQUEST_CATEGORY.HORAS_ADICIONALES:
      color = 'green';
      break;
  }
  return getSimpleBadge(sRequestType, sRequestType, color, 'border !max-w-[13rem]');
}

export function setCantHoursOrDays(
  amount: number,
  idFrecuencyType: number,
  idRequestType: number
): string {
  if ([REQUEST_FREQUENCY_TYPE.POR_DIAS].includes(idFrecuencyType))
    return amount == 1 ? amount + ' día' : amount + ' días';

  if ([REQUEST_FREQUENCY_TYPE.POR_MESES].includes(idFrecuencyType))
    return amount == 1 ? amount + ' mes' : amount + ' meses';

  if (
    [REQUEST_FREQUENCY_TYPE.POR_HORAS].includes(idFrecuencyType) &&
    ![REQUEST_TYPE.EXTEMPORANEA].includes(idRequestType)
  )
    return setTotalHours(amount);

  return '-';
}

export function approverRemoveDuplicate(aApprovers: string | null) {
  let nombres = [];
  let extras:string[] = [];
  let approvers: { id: string; name: string }[] = [];
  let id_aprobador: string = '';
  let parse_aapprovers: { id: string; name: string }[] = [];

  if (aApprovers == 'END') {
    nombres.push(getRoundBadge('Finalizado', '-'));
  } else {
    if (aApprovers != null) {
      parse_aapprovers = JSON.parse(aApprovers);
      parse_aapprovers.map((aprobador) => {
        if (aprobador.id != id_aprobador) {
          let abr = getFirstLetterOfWord(aprobador.name);
          if(nombres.length < 2){
            nombres.push(getRoundBadge(setToTitleCase(aprobador.name), abr));
          }else{
            extras.push(setToTitleCase(aprobador.name))
          }
          approvers.push(aprobador);
        }
        id_aprobador = aprobador.id;
      });
      if(extras.length){
        nombres.push(getRoundBadge(extras.join(', '), `+${extras.length}`));
      }
    }

    if (!nombres.length) {
      nombres.push(getRoundBadge('-', '-'));
    }
  }

  return {
    badge: nombres,
    approvers,
  };
}

export function showCancelIconAll(
  nIdCategoryType: number,
  nIdRequestType: number,
  nIdStatus: number,
  permission: boolean,
  idCollaborator: number
) {
  let idCollaboratorLocal = parseInt(localDB.getItem('idColaborador'));

  if (
    permission &&
    idCollaborator != idCollaboratorLocal &&
    ([
      REQUEST_CATEGORY.VACACIONES,
      REQUEST_CATEGORY.PERMISOS,
      REQUEST_CATEGORY.LICENCIAS,
    ].includes(nIdCategoryType) ||
      [REQUEST_TYPE.EXTEMPORANEA].includes(nIdRequestType)) &&
    ![
      REQUEST_STATUS.ANULADO,
      REQUEST_STATUS.CADUCADO,
      REQUEST_STATUS.RECHAZADO,
    ].includes(nIdStatus)
  ) {
    return getIcon(
      { name: 'remove_circle_outline', type: 'material' },
      'Anular solicitud',
      'gray',
      true,
      'cancel-request'
    );
  }

  if (
    idCollaborator == idCollaboratorLocal &&
    ([REQUEST_CATEGORY.VACACIONES, REQUEST_CATEGORY.PERMISOS].includes(
      nIdCategoryType
    ) ||
      [REQUEST_TYPE.EXTEMPORANEA].includes(nIdRequestType)) &&
    [REQUEST_STATUS.PENDIENTE].includes(nIdStatus)
  ) {
    return getIcon(
      { name: 'remove_circle_outline', type: 'material' },
      'Anular solicitud',
      'gray',
      true,
      'cancel-request'
    );
  }

  return false;
}

export function sumHoursForApproveOrPending(idRequestType: number): boolean {
  if (
    [
      REQUEST_TYPE.PERMISO_POR_HORAS,
      REQUEST_TYPE.HORAS_COMPENSACION,
      REQUEST_TYPE.HORAS_EXTRAS,
      REQUEST_TYPE.HORAS_RECUPERACION,
      REQUEST_TYPE.SIN_GOCE_DE_HABER_POR_HORAS,
    ].includes(idRequestType)
  ) {
    return true;
  }

  return false;
}

export function sumDaysForApproveOrPending(idRequestType: number): boolean {
  if (
    [
      REQUEST_TYPE.PERMISO_POR_DIAS,
      REQUEST_TYPE.RESERVA_VACACIONES,
      REQUEST_TYPE.ADELANTO_DE_VACACIONES,
      REQUEST_TYPE.SIN_GOCE_DE_HABER_POR_DIAS,
    ].includes(idRequestType)
  ) {
    return true;
  }

  return false;
}


export function footerTransform(response: GenericResponse<footerList>,){
  if(response.isSuccess && response.data != null){
    response.data.approvedHoursRequests = setTotalHours(response.data.solicitudesHorasAprobadas);
    response.data.pendingHoursRequests = setTotalHours(response.data.solicitudesHorasPendientes);
    response.data.approvedDaysRequests = setTotalDays(response.data.solicitudesDiasAprobadas);
    response.data.pendingDaysRequests = setTotalDays(response.data.solicitudesDiasPendientes);
  }
  return response;
}