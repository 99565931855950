<div class="max-w-[80rem] mx-auto h-full flex flex-col p-3 sm:px-10 sm:py-6 gap-5">
    <div class="flex flex-col xs:flex-row gap-2 flex-wrap justify-between">
        <div class="flex items-center gap-2">
            <mg-icon iconClass="text-mg-variant" iconName="notifications"></mg-icon>
            <div class="text-xl font-medium italic text-mg-variant">Notificaciones</div>
        </div>
        <mg-suggestions-search-box [searchOptions]="component.searchOptions" 
        [class]="isScreenSMD$ ? 'w-full' :'w-[18rem] flex justify-end'"
            (search)="searchCollaborator($event)" [currentValue]="component.filters.text">
        </mg-suggestions-search-box>
    </div>
    <div class="flex justify-between w-full items-end gap-3">
        <div class="flex gap-3 items-end w-full justify-end sm:justify-start">
            <mg-filter-date-range-ymd class="lg:w-66"
                [aplicateResponsive]="!(isDesktop$ | async)"
                (dateRange)="searchDate($event)" label="Fecha"
                [dStart]="component.filters.dFecha_Inicio"
                [dEnd]="component.filters.dFecha_Fin"
            ></mg-filter-date-range-ymd>
            <ng-container *ngIf="!(isScreenSMD$ | async)">
                <button class="rounded-full text-lg bg-mg-accent text-white h-11 w-11 flex items-center justify-center"  matTooltip="Categorias" matTooltipPosition="right" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="category">
                    <mg-icon iconName="category"></mg-icon>
                </button>
                  <mat-menu #category="matMenu" class="rounded-2xl max-w-full overflow-hidden" xPosition="before">
                    <ng-container *ngFor="let category of component.filterCategory">
                        <button mat-menu-item class="flex gap-2 items-center h-10 rounded-lg" (click)="component.filters.category != category.value ? searchCategory(category.value) : ''"
                            [ngClass]="component.filters.category == category.value ? 'text-mg-gray-dark font-semibold' : 'text-mg-gray'">
                            {{category.label}}
                        </button>
                    </ng-container>
                  </mat-menu>
            </ng-container>
            <ng-container *ngIf="(isScreenSMD$ | async)">
                <ng-container *ngFor="let category of component.filterCategory">
                    <!-- lg:rounded-xl -->
                    <button class="h-11 text-xs sm:text-sm rounded-full  flex items-center justify-center"
                        (click)="component.filters.category != category.value ? searchCategory(category.value) : ''"
                        [ngClass]="component.filters.category == category.value ? 'px-5 text-white bg-mg-accent duration-500 font-bold' : 'text-mg-variant duration-500 bg-mg-accent-light px-5 font-bold'">
                        {{category.label}}
                    </button>
                </ng-container>
            </ng-container>
        </div>
        <mg-split-button [configButtons]="component.refreshFilters" (doThisFunction)="refreshFilters($event)"></mg-split-button>
    </div>
    <div class="w-full h-full overflow-hidden">
        <mg-notifications-list [getInputs]="component.getInputs"></mg-notifications-list>
    </div>
</div>