import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { AuthService } from 'src/app/core/services/auth.service';
import { wsErrorHandler } from './websoketsErrors';

@Injectable({
  providedIn: 'root'
})
export class WSRetryPolicy implements signalR.IRetryPolicy {
  constructor(private _authService: AuthService) {}

  nextRetryDelayInMilliseconds(retryContext: signalR.RetryContext): number | null {
    if(retryContext.retryReason instanceof Error) wsErrorHandler('RETRYCONTEX ERROR', retryContext.retryReason)
    const isAuthenticated = this._authService.isAuthenticated()
    if (!isAuthenticated) {
      return null;
    } else {
      return 10*1000
    }
  }
}

