import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { GenericValidators } from '../validators/generic-validators';
import * as moment from 'moment';
import { getTimeHumanized } from 'src/app/shared/helpers/transformDate';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { IconComponent } from '../../../icon/icon.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { debounceTime, take } from 'rxjs';


@Component({
  selector: 'mg-time',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    IconComponent,
    NgxMaskPipe,
    NgxMaskDirective
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
  providers: [provideNgxMask()]
})
export class TimeComponent implements OnInit, OnChanges, OnDestroy {

  @Input() inputControl: FormControl = new FormControl(null);
  @Input() label: string = 'Input';
  @Input() placeholder: string = '';
  @Input() required: boolean = false;
  @Input() hint: boolean = false;
  @Input() messageHint: string = '';
  @Input() mode: 'create' | 'read' | 'update';
  @Input() withLabel: boolean = true;
  @Input() withIcon: boolean = true;
  @Input() isLoading: boolean = false;
  @Input() showErrors: boolean = true;
  @Input() customStyle = '';
  @Input() isIntoHourRange: [] = [];
  @Input() scheduleHours: {} | null = null;
  @Input() hintPosition?: 'down' | 'right' = 'down';
  @Input() autocomplette: boolean = false
  @Input() minTimeMessage: string  = ''
  @Input() anticipationMinutes: {
    cantMin: number;
    inputControlParent: AbstractControl | null;
  } | null = null;

  @Input() disableAllValidations: boolean = false
  @Input() currentValidationsDate?: any = []; //tareo
  @Input() myOutMarkTime?: any; // registro salida sobretiempo
  @Input() timeFormat: 'Hh:m0' | 'Hh:m0:s0' = 'Hh:m0'
  
  public validator: any[] = [];
  public inputValue: FormControl = new FormControl(null);

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['isIntoHourRange'] ||
      changes['scheduleHours'] 
      // ||      this.anticipationMinutes
    ) {
      this.setValidators();
    }

    if (changes['mode']) {
      this.initMode();
    }

    if (changes['required']) {
      this.setValidators();
    }
    if (changes['inputControl'] && this.inputValue != this.inputControl.value)  {
      this.inputValue.patchValue(this.inputControl.value);
    }
  }

  ngOnInit(): void {
    this.initMode();
    this.setValidators();
    this.setOneError();
    this.inputControl.valueChanges.pipe(take(1)).subscribe(x => {
      this.setValue(x)
    })

    this.inputSelected.valueChanges.subscribe(x => {
      this.setValue(x)
    })


  }

  ngOnDestroy(): void {
    this.inputControl.setErrors(null);
  }


  initMode() {
    switch (this.mode) {
      case 'create':
        this.inputControl.enable();
        this.inputValue.enable();
        break;
      case 'read':
        this.inputControl.disable();
        this.inputValue.disable();
        break;
      case 'update':
        this.inputControl.enable();
        this.inputValue.enable();
        break;
    }
  }

  // setOneError() {
  //   this.inputControl.valueChanges.subscribe((x) => {
  //     if (this.inputControl.errors) {
  //       let claves = Object.keys(this.inputControl.errors);
  //       let clave: string = claves[0];
  //       let error: any = {};
  //       error[clave] = this.inputControl.errors[clave];
  //       this.inputControl.setErrors(error);
  //     } else {
  //       this.inputControl.setErrors(null);
  //     }
  //   });
  // }

  get inputSelected(): FormControl{
    // return this.inputControl;
  //   return !this.showErrors ? this.inputControl : this.inputValue;
    return (!this.autocomplette) ? this.inputControl : this.inputValue;
  }


  setOneError() {
    this.inputControl.valueChanges
    .pipe(
      debounceTime(800),
    )
    .subscribe((x) => {
        // this.setValue(x);
      if (this.inputControl.errors) {
        let claves = Object.keys(this.inputControl.errors);
        let clave: string = claves[0];
        let error: any = {};
        error[clave] = this.inputControl.errors[clave];
        this.inputControl.setErrors(error);
      }
    });

    // this.inputSelected.valueChanges
    // .pipe(
    //   debounceTime(200)
    // )
    // .subscribe((x) => {
    //   this.setValue(x);
    //   if (this.inputSelected.errors) {
    //     let claves = Object.keys(this.inputSelected.errors);
    //     let clave: string = claves[0];
    //     let error: any = {};
    //     error[clave] = this.inputSelected.errors[clave];
    //     this.inputSelected.setErrors(error);
    //   } else {
    //     // this.inputControl.setErrors(null);
    //   };
    // })
  };




  setValue(value?: string) {
    if (value && value.length<4) {
      this.setIsInvalidTimeError();
      return;
    }
    if (value && value.length == 4 && !value.includes(":")) {
      switch (this.timeFormat) {
        case 'Hh:m0':
          let paddedValue = value.padStart(2, '0');
          paddedValue = paddedValue.slice(0, 4);
          paddedValue = paddedValue.padEnd(4, '0');
          let newValue = paddedValue.split('');
          let validatedTime: string = this.disableAllValidations ? newValue.join(':') : this.validateTime(newValue);
          this.inputValue.patchValue(validatedTime);
          this.inputControl.patchValue(this.inputValue.value);
          break;
          
        case 'Hh:m0:s0':
          let paddedValueWithSeconds = value.padStart(2, '0');
          paddedValueWithSeconds = paddedValueWithSeconds.slice(0, 6);
          paddedValueWithSeconds = paddedValueWithSeconds.padEnd(6, '0');
          let hoursMinutesSeconds = `${paddedValueWithSeconds.slice(0, 2)}:${paddedValueWithSeconds.slice(2, 4)}:${paddedValueWithSeconds.slice(4, 6)}`;
          this.inputControl.patchValue(hoursMinutesSeconds);
          this.inputValue.patchValue(hoursMinutesSeconds);
          break;
      }
    }
  }




  setIsInvalidTimeError(): void {
    this.inputSelected.setErrors({isInvalidTime: true});
  }

  validateTime(value: string[]): string {
    if (value.length==4) {
      if (value[0]=="2" && ["4", "5"].includes(value[1])) {
        value.pop();
        value.unshift("0");
      }

      if (value[0]=="2" && parseInt(value[2])>=6) {
        value.pop();
        value.unshift("0");
      }
    }
    return `${value.slice(0,2).join('')}:${value.slice(2).join('')}`;
  }


  get placeholderValue() {
    let placeholderByFormat = this.timeFormat == 'Hh:m0' ? '--:--'  :  '--:--:--'
    return this.mode !== 'read' ? placeholderByFormat : this.placeholder;
  }

  get anticipationMinutesMessage() {
    let messageHumanizade = getTimeHumanized(
      moment().add(this.anticipationMinutes?.cantMin, 'minutes')
    );
    messageHumanizade = messageHumanizade.split('en ')[1];
    return messageHumanizade + ' de anticipación';
  }

  setValidators() {
    this.validator = [];

    if (this.required) {
      this.validator.push(Validators.required);
    }

    if (this.currentValidationsDate?.length) {
      this.validator.push(
        GenericValidators.isIntoSchedule(this.currentValidationsDate)
      );
    }

    if (this.scheduleHours) {
      this.validator.push(GenericValidators.isOutSchedule(this.scheduleHours));
    }

    if (this.isIntoHourRange.length) {
      this.validator.push(GenericValidators.isIntoHourRange(this.isIntoHourRange));
    }

    if (this.anticipationMinutes) {
      this.validator.push(
        GenericValidators.minimumHour(
          this.anticipationMinutes.cantMin,
          this.anticipationMinutes.inputControlParent
        )
      );
    }

    this.inputSelected.setValidators(this.validator);
    this.inputSelected.updateValueAndValidity();
  }

  setValueInTareo(value?: string) {
    setTimeout(() => {this.setValue(value)}, 500);
    // this.setValue(value);
  }
}