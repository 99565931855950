export function addAlpha(color: string, opacity: number) {
    let _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
}

export function lightenColor(hex: string, factor: number) {
    // Parsea el color hexadecimal a RGB
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
  
    // Calcula el nuevo color RGB más claro
    const newR = Math.min(255, r + factor);
    const newG = Math.min(255, g + factor);
    const newB = Math.min(255, b + factor);
  
    // Convierte el nuevo color RGB a hexadecimal
    const newHex = `#${Math.round(newR).toString(16).padStart(2, '0')}${Math.round(newG).toString(16).padStart(2, '0')}${Math.round(newB).toString(16).padStart(2, '0')}`;
  
    return newHex;
}


export function brightnesColor(hexColor: string){
    let c = hexColor.substring(1);
    let rgb = parseInt(c, 16);
    let r = (rgb >> 16) & 0xff;
    let g = (rgb >>  8) & 0xff;
    let b = (rgb >>  0) & 0xff;
  
    let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    return luma < 140 ? true : false;
  }