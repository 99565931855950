import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mg-safari-banner',
  templateUrl: './safari-banner.component.html',
  styleUrls: ['./safari-banner.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class SafariBannerComponent implements OnInit {

  showBanner = false;
  CssHiddenBanner = false
  constructor() { }

  ngOnInit(): void {
    this.showBanner = this.isSafari();
  }

/*   isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  } */

  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent) || /^apple computer, inc.$/i.test(navigator.vendor);
  }

  hidden(){
      this.CssHiddenBanner = true
  }
}