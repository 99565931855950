import * as moment from "moment";
import { SplitButton, typeSplitValue } from "src/app/shared/interfaces/buttons.interface";
import { OptionSearch } from "src/app/shared/interfaces/suggestions-search-box.interface";


const Initfilters: InitFilters = {
    source: "",
    text: "",
    dFecha_Inicio: moment().subtract(1, "months").format("YYYY-MM-DD"),
    dFecha_Fin: moment().format("YYYY-MM-DD"),
    category: null,
    refresh: false
}

const filters: InitFilters = {
    source: "",
    text: "",
    dFecha_Inicio: "",
    dFecha_Fin: "",
    category: "",
    refresh: false
}

  export const refreshFilters: SplitButton[] = [
    {
      type: "button",
      icon: {
        type: 'material',
        class: 'text-white',
        name: 'refresh'
      },
      tooltip: "Actualizar listado",
      value: typeSplitValue.REFRESH,
    },
    {
      type: "action",
      icon: {
        name: "restart_alt",
        class: 'text-mg-main-blue',
        type: 'material'
      },
      tooltip: "Refrescar filtros",
      label: "Refrescar filtros",
      value: typeSplitValue.RESET,
    }
  ];

const filterCategory: IFilterCategory[] = [
    {
        label: "Todas",
        value: null,
    },
    {
        label: "Solicitudes",
        value: "solicitud",
    },
    {
        label: "Tareo",
        value: "tareo",
    },
    {
        label: "Asistencia",
        value: "asistencia",
    },
    {
        label: "PRY/RQ",
        value: "requerimiento",
    },
    {
        label: "Tesorería",
        value: "tesorería",
    }
]

const searchOptions: OptionSearch[] = [
    {

        label: 'Colaboradores',
        placeholder: 'Buscar Colaborador',
        validation: [],
        validation_desc: '',
        type: 1,
        configList: '',
        aSlugList: [],
        iconType: 'person'
    },
]


export const componentInit: IComponentInit = {
    Initfilters,
    filters,
    filterCategory,
    refreshFilters,
    searchOptions,
    getInputs: ''
}

export interface IComponentInit {
    Initfilters: InitFilters;
    filters: InitFilters;
    filterCategory: IFilterCategory[];
    refreshFilters: SplitButton[];
    searchOptions: OptionSearch[];
    getInputs: string;
}

interface IFilterCategory {
    label: string,
    value: null | string,
}

interface InitFilters{
    source: string;
    text: string;
    dFecha_Inicio: string;
    dFecha_Fin: string;
    category: string | null;
    refresh: boolean
}