<div class="flex flex-col h-full gap-2 transition-all duration-300 py-4 bg-mg-accent-base bg-blend-darken"
    [class]="collapsed && isDesktop ? 'w-[4.5rem]': 'w-72'">
    <div class="w-full h-full overflow-x-hidden overflow-y-auto">
        <mg-sidenav-item *ngFor="let item of items;" [item]="item" [level]="0" [isCollapsed]="collapsed"
            [isDesktop]="isDesktop" (clickItem)="clickItemAction($event)" (clickItem2)="clickItemAction($event)"></mg-sidenav-item>
    </div>
    <div class="h-fit">
        <div class="h-[1px] bg-mg-accent-light m-3 rounded-full"></div>
        <div #menuTriggerNotifications="matMenuTrigger" [matMenuTriggerFor]="notifications" *ngIf="isDesktop"
            class="cursor-pointer flex gap-4 px-5 py-3 items-center" [matTooltip]="isDesktop ? 'Notificaciones' : ''"
            matTooltipPosition="right">
            <div [matBadgeHidden]="counterNotifications <= 0"
                [matBadge]="counterNotifications >= 100 ? '99+' : counterNotifications" matBadgeColor="warn">
                <mg-icon iconClass="text-mg-green" iconName="notifications"></mg-icon>
            </div>
        </div>
        <div (click)="logout()" 
            class="item cursor-pointer flex gap-4 px-5 py-3 items-center hover:text-mg-accent-dark text-mg-accent"
            [matTooltip]="isDesktop ? 'Cerrar sesión' : ''" matTooltipPosition="right">
            <mg-icon iconName="logout"></mg-icon>
            <span *ngIf="!(isDesktop)" class="text-[0.9rem] capitalize whitespace-nowrap">Cerrar sesión</span>
        </div>
        <div class="flex gap-4 px-5 py-3 items-center">
            <span *ngIf="isDesktop" class="text-[0.9rem] whitespace-nowrap text-mg-accent font-semibold">v{{env.version}}</span>
            <span *ngIf="!isDesktop" class="text-[0.9rem] whitespace-nowrap text-mg-accent font-semibold">©2022 SMART • Versión {{env.version}}</span>
        </div>
    </div>
</div>

<mat-menu #notifications="matMenu"
    class="!rounded-2xl !max-w-full !w-[40rem] !h-[55rem] !max-h-screen !overflow-hidden" xPosition="before">
    <div class="max-w-full w-[40rem] h-[55rem] max-h-screen overflow-y-auto" (click)="$event.stopPropagation();">
        <mg-notifications-pop-up (badgeCounter)="badgeCounterNotifications($event)" [matMenu]="menuTriggerNotifications"
           ></mg-notifications-pop-up>
    </div>
</mat-menu>
