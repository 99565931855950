import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AccessService } from '../services/access.service';
import { localDB } from '../../shared/helpers/localStorage';
import { SmartAlertService } from 'src/app/shared/components/smart-alert/smart-alert.service';
import { SmartAlertResult } from 'src/app/shared/components/smart-alert/interfaces/custom-dialog.interface';
import { GoogleService } from '../services/googleApi.service';
import { environment as env } from 'src/environments/environment';

@Injectable()
export class Interceptor implements HttpInterceptor {

  private _dialog: MatDialog;
  private _injector: Injector;

  constructor(
    private _accessService: AccessService,
    injector: Injector,
    private _smartAlertService: SmartAlertService,
    private _googleService: GoogleService,
  ) {
    this._injector = injector;
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this._dialog) {
      this._dialog = this._injector.get(MatDialog);
    }

    const token: string = localDB.getItem('token');
    const auth_type: string = localDB.getItem('authType');
    let remoteHost = req.url.split('/')[2];
    let request = this.setRequest(token, auth_type, remoteHost, req);

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401) {
          if (isGoogleApi(remoteHost)) {
            this._googleService.updateValueToken('');
            localDB.removeItem('accessToken');
          } else {
            this._accessService.routeLogin(); // Default behavior for non-Google APIs
          }
        }
        if (err.status == 403) {
          if (isGoogleApi(remoteHost)) {
            this._googleService.updateValueToken('');
            localDB.removeItem('accessToken');
          } else {
            this.swalError403(remoteHost); // Default behavior for non-Google APIs
          }
        }

        // if((err.status > 450 ||  err.status == 0) && window.location.pathname != '/service-unaviable') {
        //   this._smartAlertService.init({
        //     type: 'danger',
        //     title: 'Ocurrió algo inesperado',
        //     message: 'Nuestros servidores no se encuentran disponibles. Por favor, intenta de nuevo más tarde.',
        //   }, true)
        //  }
        return throwError(() => err);
      })
    );
  }

  setRequest(token: string, authType: string, remoteHost: string, req: HttpRequest<unknown>) {
    let request = req;

    let noUse:boolean = req.headers.has('noUse')
    if(!noUse){
      if (token) {
        switch (remoteHost) {
          case env.awsBucked:
          request = req.clone({
            setHeaders: {
              Accept: 'image/webp,*/*,application/pdf'
            },
            responseType: 'blob' // se saca de header ya que no es header
          });
          break;
          case 'www.googleapis.com':
          case 'docs.googleapis.com':
            request = req.clone({
              setHeaders: {
                authorization: `Bearer ${localDB.getItem("accessToken")}`,
              }
            });
            break;
          default:
            if(skipInterceptor(remoteHost)){
              request = req.clone({});
            }else{
              request = req.clone({
                setHeaders: {
                  authorization: `Bearer ${token}`,
                  auth_Type: authType
                }
              });
            }
            break;
        }
      }
    }

    
    return request;
  }

  swalError403(remoteHost: string) {
    if (remoteHost == env.awsBucked) {
      this._smartAlertService.init({
        type: 'warning',
        title: 'Link de archivo caducado',
        message: 'Por favor actualice.',
        showCancelButton: false
      })
    } else {
      this._smartAlertService.init({
        type: 'info',
        message: 'No Autorizado',
        showCancelButton: false
      }).subscribe((result: SmartAlertResult) => {
        if (result.confirmStatus) {
          this._accessService.routeLogin();
        }
      })
    }
  }
}

function isGoogleApi(host: string): boolean {
  return host.startsWith('www.googleapis.com') || host.startsWith('docs.googleapis.com');
}

function skipInterceptor(host: string): boolean {
  return host.includes('execute-api.us-east-1');
}
