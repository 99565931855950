import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { VersionNewsComponent } from 'src/app/core/layout/components/toolbar/components/version-news/version-news.component';
import { environment as env } from 'src/environments/environment';
import { IndexedDBDexieService } from 'src/app/core/indexedDB/service/indexed-db-dexie.service';
import { ConfigEnviromentService } from 'src/app/core/services/configEnviroment.service';
import { localDB } from '../../helpers/localStorage';
import { IconComponent } from '../icon/icon.component';
import { CustomCarouselComponent, Slide } from '../custom-carousel/custom-carousel.component';
import { CookiesService } from 'src/app/core/services/cookies.service';
import { CacheService } from '../../services/cache.service';
import { EventEmitter, Output } from '@angular/core';


@Component({
  selector: 'mg-version-alert',
  templateUrl: './version-alert.component.html',
  styleUrls: ['./version-alert.component.scss'],
  standalone: true,
  imports: [CommonModule, IconComponent, CustomCarouselComponent]
})

export class VersionAlertComponent {
  selectedImage: string = '';
  selectedTitle: string = '';
  selectedDescription: string = '';
  selectedcarouselImage: string = '';
  selectedcarouselTitle: string = '';
  selectedcarouselDescription: string = '';
  public carouselData: any;
  public versionData: any[];
  showUpdateNotification: boolean = false;
  showLogo: boolean = false;
  _cookiesService: CookiesService;
  _cacheService: CacheService;

  constructor(
    public dialogRef: MatDialogRef<VersionNewsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _indexedDB: IndexedDBDexieService,
    private _configEnvService: ConfigEnviromentService
  ) {
    this.versionData = data;
    if (this.versionData.length > 0) {
      this.selectedImage = this.versionData[0]?.sUrl_Fondo || '';
      this.selectedTitle = this.versionData[0]?.sTitle || '';
      this.selectedDescription = this.versionData[0]?.sDescription || '';
      this.selectedcarouselImage = this.versionData[0]?.sUrl_Fondo || '';
      this.selectedcarouselTitle = this.versionData[0]?.sTitle || '';
      this.selectedcarouselDescription = this.versionData[0]?.sDescription || '';
    }
    const currentVersion = env.version;
    const newVersion = this.versionData[0]?.sVersion || '';
    this.showUpdateNotification = newVersion !== currentVersion;
    this.showLogo = !this.showUpdateNotification;

    this.carouselData = {
      slides: this.versionData.map((item, index) => ({
        title: item.sTitle,
        description: item.sDescription,
        media: item.sUrl_Fondo,
        key: index,
      }))
    };
  }

  close(): void {
    this.dialogRef.close();
  }

  showImage(imageKey: string, title: string, description: string): void { 
    this.selectedImage = imageKey;
    this.selectedTitle = title;
    this.selectedDescription = description;
  }
  async onUpdateClick(): Promise<void> {
    this.dialogRef.close(true);
    await this._indexedDB.refreshAllData();
    localDB.cleanCache([
      'horario',
      'c-my-marks',
      'c-red-button',
      'c-extemp-marks',
      'c-assistance',
      'c-requests',
      'celebrate-banner',
      'c-tareo',
      'c-requests-treasury',
    ]);
    await this._configEnvService.loadConfig();
    window.location.reload();
  }
  
  handleSlideChange(slide: Slide) {
    this.selectedcarouselImage = slide.media;
    this.selectedcarouselTitle = slide.title;
    this.selectedcarouselDescription = slide.description;
  }

}