<div class="text-mg-accent md:text-sm text-xs " *ngIf="!isLoading && withLabel">
    {{label}}<span *ngIf="mode!= 'read' && required" class="text-mg-red">*</span>
</div>
<div class="animate-pulse bg-mg-preload rounded-2xl h-4 max-w-1/2" *ngIf="isLoading && withLabel">
</div>
<div [class]="hintPosition == 'down' ? 'flex flex-col' : 'w-full flex gap-2'" *ngIf="!isLoading">
    <mat-form-field appearance="outline" [class]="hintPosition == 'down' ? 'w-full' : 'w-1/2'">
        <div class="w-full flex tall justify-between">
            <span *ngIf="currency" class=" flex-none mr-2 currency md:text-sm text-xs "> S/.</span>
            <input class="flex-grow md:text-sm text-xs " matInput type="number" [placeholder]="placeholderValue"
                [formControl]="inputControl" autocomplete="off" (input)="controlMinutes()">
            <span *ngIf="calculate !== 0" class="flex-none mr-2 currency text-right whitespace-nowrap md:text-sm text-xs "> (
                {{calculate}} hrs)</span>
        </div>
    </mat-form-field>
    <mat-hint *ngIf="hint" [class]="hintPosition == 'down' ? 'w-full -mt-6' : 'center' ? 'w-1/2 m-auto flex items-start'  : 'w-1/2' "   >
        <div class="rounded-lg mt-1 p-2 flex items-center gap-2 w-full" [ngClass]="maxValue == 0 ? 'bg-mg-red-light':'bg-mg-base-background'">
            <mg-icon iconClass="text-base" [ngClass]="maxValue == 0 ? 'text-mg-red-dark':'text-mg-gray-dark'" iconName="info"></mg-icon>
            <div class="text-xxs break-words font-normal text-left" [ngClass]="maxValue == 0 ? 'text-mg-red-dark':'text-mg-gray-dark'">
                <ng-container [ngSwitch]="tasksStatus">
                    <ng-container *ngSwitchCase="'SOLICITUD_POR_DIAS'"><span>Solicitud por días. <br> Disp. {{maxValue}}
                            mins. ( {{calculate2}} hrs).</span></ng-container>
                    <ng-container *ngSwitchCase="'FERIADO'"><span>Feriado. <br> Disp. {{maxValue}} mins. (
                            {{calculate2}} hrs). </span></ng-container>
                    <ng-container *ngSwitchCase="'FUERA_HORARIO_REGULAR'"><span>Día fuera de tu horario. <br> Disp.
                            {{maxValue}} mins. ( {{calculate2}} hrs).</span></ng-container>
                    <ng-container *ngSwitchCase="'SOLICITUD_POR_HORAS'"><span>Solicitud por horas. <br> Disp. {{maxValue}}
                            mins. ( {{calculate2}} hrs). </span></ng-container>
                        <ng-container *ngSwitchCase="'SIN_ASISTENCIA'"><span>No tienes asistencia para este día</span></ng-container>
                    <ng-container *ngSwitchDefault><span>Disp. {{maxValue}} mins. ( {{calculate2}} hrs).
                        </span></ng-container>
                </ng-container>
            </div>
        </div>
    </mat-hint>
</div>

<div class="animate-pulse bg-mg-preload rounded h-[3.25rem] mt-1 min-w-36 mb-5" *ngIf="isLoading">

</div>