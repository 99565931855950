import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { IconType } from '../../interfaces/icon.interface';

@Component({
  selector: 'mg-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class IconComponent {
  @Input() iconType: IconType = 'material';
  @Input() iconName: string;
  @Input() iconClass: string;
}
