import { Component, OnInit } from '@angular/core';
import { MenuItem, UserData } from '../../../../../interfaces/toolbar.interface';
import { localDB } from '../../../../../../shared/helpers/localStorage';
import { encriptar } from '../../../../../../shared/helpers/encryption';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'mg-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss']
})
export class ToolbarUserComponent implements OnInit {
  items: MenuItem[] = [];
  user: UserData;

  ngOnInit(): void {
    this.initUserData();
    this.items= this.initItemsMenu();
  }

  initItemsMenu(): MenuItem[]{
    let id = encriptar(env.securityKey,this.user.idCollabarator);
    return [
      {
      id: '1',
      icon: 'account_circle',
      label: 'Mi perfil',
      description: 'Información personal',
      route: 'colaboradores/detalle',
      queryParms: { id }
    }
  ];
  }

  initUserData(){
    this.user = {
      pic: localDB.getItem('Profile'),
      rol: localDB.getItem('sNombre_Rol'),
      name: localDB.getItem('sNombres'),
      idCollabarator: localDB.getItem('idColaborador')
    }
  }
}