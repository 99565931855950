import * as moment from "moment";
import { Moment } from "moment";

export function getTimeHumanized(date: any): string {
    let dateMomentJs = moment(date);
    let dateHumanized = dateMomentJs.format("DD/MM/YYYY HH:mm");
    let difference = moment().diff(dateMomentJs, "days");
    if (difference <= 7) {
      dateHumanized = moment.duration(dateMomentJs.diff(moment())).humanize(true);
    } else {
      let month = [
        "",
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ];
      let time = dateHumanized.split(" ");
      let date = time[0].split("/");
      dateHumanized = `${date[0]} ${month[Number(date[1])]} ${date[2]} - ${
        time[1]
      }`;
    }
  
    return dateHumanized;
  }

  export function formatDateMMofDDofYYYY(date: Moment) {
    return (
      date.format("DD") +
      " de " +
      date.format("MMMM") +
      " del " +
      date.format("YYYY")
    );
  }