import { AfterViewInit, Component, EventEmitter, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { NotificationsService } from '../../services/notifications.service';
import { catchError, of, startWith, switchMap } from 'rxjs';
import { goToModuleNotifications, groupByDates } from '../../helpers/functions';
import { NotificationsDetail, NotificationsDetailMap } from '../../interfaces/notifications.interface';
import { GenericResponseList } from 'src/app/shared/interfaces/table.interface';
import { getEsPaginatorIntl } from 'src/app/shared/helpers/es-paginator-intl'; 
import { LayoutService } from 'src/app/core/services/layout.service';

@Component({
  selector: 'mg-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useValue: getEsPaginatorIntl() }],
})
export class NotificationsListComponent implements AfterViewInit, OnChanges {
  @Input() getInputs: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  changesGetInputs = new EventEmitter<void>();
  public notificationsData: NotificationsDetailMap[] = [];
  public errorLoadingData: boolean = false;
  public sortBy: string = "dFecha_Creacion";
  public sortDir: string = "desc";
  public loading = false;
  public paginatorOptions = {
    pageSizeOptions: [50, 100],
    pageSize: 50,
    pageLength: 0,
  };
  public isScreenSM$ = this._layoutService.isScreenSM$;
  public spinner: boolean = false;

  constructor(
    private _notificationsService: NotificationsService, 
    private _router: Router,
    private _layoutService: LayoutService
  ) { }

  ngAfterViewInit(): void {
    this.getDataByService();
    this.paginatorChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['getInputs'] && this.paginator) {
      this.paginator.pageIndex = 0;
      this.changesGetInputs.emit();
    }
  }

  getDataByService() {
    this.changesGetInputs.pipe(
      startWith(''),
      switchMap(() => {
        this.spinner = true;
        return this._notificationsService.getAllNotifications(
          this.paginator.pageSize,
          this.sortBy,
          this.sortDir,
          this.paginator.pageIndex,
          this.getInputs
        ).pipe(
          catchError(error => {
            this.spinner = false;
            return of(null);
          })
        );
      })
    ).subscribe({
      next: (resp: any) => {
        if (resp) {
          this.setData(resp);
        }
        this.spinner = false;
      }
    });
  }

  setData(data: GenericResponseList<NotificationsDetail>) {
    if (data.isSuccess) {
      this.paginatorOptions.pageLength = data.paginate.nRegisters;
      this.notificationsData = [];
      this.errorLoadingData = false;
      this.notificationsData = data.data.length
        ? groupByDates(data.data)
        : [];
        this.markAllNotificationsAsSeen();
    } else {
      this.notificationsData = [];
      this.errorLoadingData = true;
    }
  }

  paginatorChanges() {
    this.paginator.page.subscribe(() => {
      this.changesGetInputs.emit();
    });
  }

  goToModule(notification: NotificationsDetail) {
    this.markNotificationAsRead(notification);
    let dataNotifications = goToModuleNotifications(notification);
    if (dataNotifications) this._router.navigate([dataNotifications.url], { queryParams: dataNotifications.newQueryParams });
  }

  markNotificationAsRead(notification: NotificationsDetail) {
    if (notification.nEstado_Notificacion == 1) {
      notification.nEstado_Notificacion = 2;
      this._notificationsService
        .updateStateNotifications([notification.nId_Notificacion])
        .subscribe(() => {});
    }
  }

  markAllNotificationsAsSeen() {
    let ids: number[] = [];
    this.notificationsData.map((notifications) => {
      notifications.notificaciones.map((notification) => {
        if (!notification.bIsRead) {
          ids.push(notification.nId_Notificacion);
        }
      });
    });
    if(ids.length)
      this._notificationsService
        .updateStateNotificationsCondition(ids)
        .subscribe(() => {});
  }
}