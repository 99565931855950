import * as moment from "moment";
import { COLORS_BADGE } from "../enums/global-constants";
import { BadgeStatus, DateBagde, InputBadge, LabelWithiconBadge, getArrayWithBadgeAndIcon } from "../interfaces/badges.interface";
import { Icon } from "../interfaces/icon.interface";
import { getIcon } from "./iconBuilder";
import { IconBadge } from '../interfaces/badges.interface';
import { getFirstLetterOfWord } from "./transformText";
import { FormControl } from "@angular/forms";

export function getStateBadge(label: string, tooltip?: string): BadgeStatus {
    let generalCss =
      // "w-28 overflow-hidden truncate text-center font-medium px-2 py-1.5 rounded-full text-xs border m-auto max-w-[8rem] ";
      "w-28 overflow-hidden truncate text-center font-medium px-2 py-1.5 rounded-full text-xs border m-auto max-w-[12rem] ";
  
    if (label === "PENDIENTE POR DIRECTOR") {
      label = "P. POR DIRECTOR";
    }

    if (label === "PENDIENTE DE PAGO") {
      label = "P. DE PAGO";
    }
  
    if (label === "PENDIENTE POR RRHH") {
      label = "P. POR RRHH";
    }
  
    if (label === "EN TOLERANCIA") {
      label = "TARDANZA";
    }
  
    let badge: BadgeStatus = {
      tooltip,
      label,
      css: generalCss + COLORS_BADGE.main,
    };
  
    if (label == undefined) {
      badge.css = "hidden";
    }
  
    if (
      [
        "PUNTUAL",
        "PRESENCIAL",
        "ACTIVO",
        "APROBADO",
        'PAGADO',
        "APROBADO CLIENTE",
        "PAGADO",
        "REEMBOLSADO",
        "RENOVADO",
        "EXTRAS",
        "HORAS EXTRAS",
        "PERMISO POR DÍAS",
        "SIN SINTOMAS",
        "P. CONFIANZA",
        "PERSONAL CONFIANZA",
        "ENVIADO"
      ].includes(label)
    ) {
      badge.css = generalCss + COLORS_BADGE.green;
    }
  
    if (
      [
        "TARDANZA",
        "INACTIVO",
        "PENDIENTE",
        "P. POR DIRECTOR",
        "P. POR RRHH",
        "P. DE PAGO",
        "POR RENOVAR",
        "BORRADOR"
      ].includes(label)
    ) {
      badge.css = generalCss + COLORS_BADGE.orange;
    }
  
    if (
      [
        "CUMPLEAÑOS",
      ].includes(label)
    ) {
      badge.css = generalCss + COLORS_BADGE.sunshine;
    }
  
    if (["SIN MARCA", "ANULADO", "ELIMINADO"].includes(label)) {
      badge.css = generalCss + COLORS_BADGE.gray;
    }
  
    if (
      [
        "PERMISO",
        "REMOTO",
        "COMPLETADO",
        "EN PROCESO",
        "EXTERNO",
        "ANIVERSARIO",
        "CONTRATO INICIAL",
        "RECUPERACIÓN",
        "HORAS DE RECUPERACIÓN",
        "PERMISO POR HORAS",
        "ANIVERSARIO"
      ].includes(label)
    ) {
      badge.css = generalCss + COLORS_BADGE.teal;
    }
  
    if (
      [
        "VACACIONES",
        "INTERNO",
        "MARCACIÓN EXTEMPORÁNEA",
        "HORARIO REGULAR",
      ].includes(label)
    ) {
      badge.css = generalCss + COLORS_BADGE.purple;
    }
  
    if (["FERIADO", "AVANCE", "HORAS DE AVANCE"].includes(label)) {
      badge.css = generalCss + COLORS_BADGE.pink;
    }
  
    if (
      [
        "FALTA",
        "CESADO",
        "RECHAZADO",
        "OBSERVADO",
        "OBSERVADO CLIENTE",
        "CON SINTOMAS",
        "DESESTIMADO",
      ].includes(label)
    ) {
      badge.css = generalCss + COLORS_BADGE.red;
    }
  
    if (
      [
        "COMPENSACIÓN",
        "PAUSADO",
        "HORAS DE COMPENSACIÓN",
        "PAUSA",
        "POR RENOVAR",
        "CORREGIDO",
      ].includes(label)
    ) {
      badge.css = generalCss + COLORS_BADGE.yellow;
    }
  
    if (["CADUCADO", "FUERA DE HORARIO"].includes(label)) {
      badge.css = generalCss + COLORS_BADGE.coral;
    }

    return badge;
  }


  export function getSimpleBadge(
    label: string,
    tooltip?: string,
    color?: string,
    cssAdditional?: string
  ): BadgeStatus {
    let generalCss =
      "max-w-[12rem] min-w-[7rem] overflow-hidden truncate text-center font-medium px-4 py-1.5 rounded-full text-xs m-auto ";
  
    if (cssAdditional) generalCss = cssAdditional + " " + generalCss;
  
    let css;
    switch (color) {
      case "green":
        css = generalCss + COLORS_BADGE.green;
        break;
      case "red":
        css = generalCss + COLORS_BADGE.red;
        break;
      case "gray":
        css = generalCss + COLORS_BADGE.gray;
        break;
      case "teal":
        css = generalCss + COLORS_BADGE.teal;
        break;
      case "orange":
        css = generalCss + COLORS_BADGE.orange;
        break;
      case "purple":
        css = generalCss + COLORS_BADGE.purple;
        break;
      case "coral":
        css = generalCss + COLORS_BADGE.coral;
        break;
      case "main_dark":
        css = generalCss + COLORS_BADGE.main_dark;
        break;
      case "invisible":
        css = generalCss + "invisible";
        break;
      case "main":
        css = generalCss + COLORS_BADGE.main;
        break;
      case 'blue':
        css = generalCss + COLORS_BADGE.blue;
        break
      case "stripes":
        css = generalCss + COLORS_BADGE.main_stripes;
        break;
      case 'white':
        css = generalCss;
        break
      case 'yellow':
        css = generalCss + COLORS_BADGE.yellow;
        break
      case 'pink':
        css = generalCss + COLORS_BADGE.pink;
        break
      case 'sunshine':
        css = generalCss + COLORS_BADGE.sunshine;
        break
      case 'cyan':
        css = generalCss + COLORS_BADGE.cyan;
        break
      case 'success':
        css = generalCss + COLORS_BADGE.success;
        break
      case 'danger':
        css = generalCss + COLORS_BADGE.danger;
        break
      default:
        css = generalCss + COLORS_BADGE.main;
        break;
    }
  
    return {
      tooltip,
      label,
      css,
    };
  }

  export function getInputBadge(
    label: string | number,
    type: 'text' | 'number' | 'HoursMin' | 'HH:mm',
    value: string | number,
    inputControl: FormControl,
    maxValue?: number,
    minValue?:number,
    mode?: 'update' | 'read',
    tooltip?: string,
    color?: string,
    cssAdditional?: string
  ): InputBadge {
    mode = mode ? mode : 'read'
    let generalCss =
      "max-w-[12rem] min-w-[7rem] overflow-hidden truncate text-center font-medium px-4 py-1.5 rounded-full text-xs m-auto w-full ";
  
    if (cssAdditional) generalCss = cssAdditional + " " + generalCss;
  
    let css;
    switch (color) {
      case "green":
        css = generalCss + COLORS_BADGE.green;
        break;
      case "red":
        css = generalCss + COLORS_BADGE.red;
        break;
      case "gray":
        css = generalCss + COLORS_BADGE.gray;
        break;
      case "teal":
        css = generalCss + COLORS_BADGE.teal;
        break;
      case "orange":
        css = generalCss + COLORS_BADGE.orange;
        break;
      case "purple":
        css = generalCss + COLORS_BADGE.purple;
        break;
      case "coral":
        css = generalCss + COLORS_BADGE.coral;
        break;
      case "main_dark":
        css = generalCss + COLORS_BADGE.main_dark;
        break;
      case "invisible":
        css = generalCss + "invisible";
        break;
      case "main":
        css = generalCss + COLORS_BADGE.main;
        break;
      case 'blue':
        css = generalCss + COLORS_BADGE.blue;
        break
      case "stripes":
        css = generalCss + COLORS_BADGE.main_stripes;
        break;
      case 'white':
        css = generalCss;
        break
      case 'yellow':
        css = generalCss + COLORS_BADGE.yellow;
        break
      case 'pink':
        css = generalCss + COLORS_BADGE.pink;
        break
      case 'sunshine':
        css = generalCss + COLORS_BADGE.sunshine;
        break
      case 'cyan':
        css = generalCss + COLORS_BADGE.cyan;
        break
      case 'success':
        css = generalCss + COLORS_BADGE.success;
        break
      case 'danger':
        css = generalCss + COLORS_BADGE.danger;
        break
      default:
        css = generalCss + COLORS_BADGE.gray;
        break;
    }
  
    return {
      tooltip,
      label,
      value,
      type,
      mode,
      css,
      inputControl,
      maxValue,
      minValue
    };
  }



  export function getArrayWithBadgeAndIcon(
    badge:{
    label: string,
    tooltip: string,
    color: string,
    aditionalCss?: string;
    typeBagde?: 'badge'|'badgeDate',
    label2?: string
    },
    icon?:{
      icon: Omit<Icon, 'class'>,
      tooltip: string,
      action: string,
      color: string,
      typeAction: "modal" | "submit"
    } | null ): getArrayWithBadgeAndIcon[]{
    if(!badge.typeBagde){
      badge.typeBagde = 'badge';
    }
    let arrayBadge: getArrayWithBadgeAndIcon[] = [];
  
    let gBadge = getSimpleBadge(badge.label, badge.tooltip, badge.color, badge.aditionalCss);
  
    if(badge){
      arrayBadge.push({
        type: badge.typeBagde,
        data: {
          css: gBadge.css,
          firstLabel: badge.label,
          twoLabel: badge.label2,
          tooltip: badge.tooltip
        }
      });
    }
   
    let gIcon: IconBadge | null = null;
  
    if(icon){
      gIcon = getIcon(icon.icon, icon.tooltip, icon.color, true, icon.action);
      arrayBadge.push({
        type: "icon",
        typeAction: icon.typeAction,
        data: gIcon,
      });
    }
  
    return arrayBadge;
  }
  export function getDateBagde(
    startDate: string | Date,
    EndDate: string | Date,
    showHour: boolean
  ): DateBagde {
    moment.locale("es");
    let badge = getSimpleBadge("");
    if(startDate && EndDate){
      return {
        data: {
          dFecha_inicio: moment(startDate).format("DD MMM YYYY"),
          dFecha_Fin: moment(EndDate).format("DD MMM YYYY"),
          dHora_inicio: showHour ? moment(startDate).format("HH:mm") : "",
          dHora_fin: showHour ? moment(EndDate).format("HH:mm") : "",
        },
        css: badge.css
      };
    }else{
      return {
        data: {
          dFecha_inicio: null,
          dFecha_Fin: null,
          dHora_inicio: null,
          dHora_fin: null,
        },
        css: badge.css
      };
    }
  }

  export function getLabelWithIconBadge(
    label: string,
    icon: Icon | null,
    color: string = "main",
    addCss: string = "",
    tooltip?:string
  ): LabelWithiconBadge {
    let simpleBadge = getSimpleBadge(label, "", color).css;
    let tool = tooltip ? tooltip : label
    return {
      label: label,
      tooltip: tool,
      icon: icon,
      css: simpleBadge + ' flex gap-1 items-center justify-center ' + addCss
    };
  }

  export const getMultipleBadge = (
    data:{    
    name: string,
    tooltip?: string,
    addCss?: string,
    abbreviation?:string
    }[])=>{
    let badges: BadgeStatus[] = [];

    data.map(badge => {
      badges.push(
        getRoundBadge(
        badge.tooltip? badge.tooltip : badge.name,
        badge.abbreviation ? badge.abbreviation : getFirstLetterOfWord(badge.name),
        badge.addCss ? badge.addCss : ''
        ))
    });

    return badges;
  }
  

export function getRoundBadge(
  tooltip: string,
  abbreviation: string,
  addCss?: string
): BadgeStatus {
  let generalCss =
    "w-10 h-10 flex items-center justify-center font-semibold p-1.5 rounded-full text-sm "  + COLORS_BADGE.main + " ";
  generalCss = addCss != "" ? generalCss + addCss + " " : generalCss;

  let badge: BadgeStatus = {
    tooltip,
    label: abbreviation,
    css: generalCss
  };

  return badge;
}



  
export function getTooltipGraphTime(
  label: string,
  content?: string,
  style?: string,
  extraText?: boolean
) {
  let generalCss =
    "max-w-32 overflow-hidden truncate text-center font-medium p-1.5 rounded-lg text-xs border m-auto ";
  let generalTitleCss = "font-semibold text-sm mb-2 text-center";
  let classSpan = "mt-2 text-xs text-black";
  let endDiv =    extraText ? "<br><br>" +
    '<span class="'+
    classSpan+
    '">' +
    "Estados: Pendiente, Pendiente por Director y Aprobado <span>" +
    "</div>"
    :
    "</div>";

  let toolTip = {
    titleCss: generalTitleCss + " text-mg-main-blue",
    badgeCss: generalCss + COLORS_BADGE.main,
  };

  if (["EXTRAS"].includes(style!)) {
    toolTip.badgeCss = generalCss + COLORS_BADGE.green;
    toolTip.titleCss = generalTitleCss + " text-mg-green-dark";
  }

  if (["TARDANZA"].includes(style!)) {
    toolTip.badgeCss = generalCss + COLORS_BADGE.orange;
    toolTip.titleCss = generalTitleCss + " text-mg-orange-dark";
  }

  if (["SIN MARCA"].includes(style!)) {
    toolTip.badgeCss = generalCss + COLORS_BADGE.gray;
    toolTip.titleCss = generalTitleCss + " text-mg-gray-dark";
  }

  if (["RECUPERACIÓN"].includes(style!)) {
    toolTip.badgeCss = generalCss + COLORS_BADGE.teal;
    toolTip.titleCss = generalTitleCss + " text-mg-teal-dark";
  }

  if (["HORARIO REGULAR"].includes(style!)) {
    toolTip.badgeCss = generalCss + COLORS_BADGE.purple;
    toolTip.titleCss = generalTitleCss + " text-mg-purple-dark";
  }

  if (["AVANCE"].includes(style!)) {
    toolTip.badgeCss = generalCss + COLORS_BADGE.pink;
    toolTip.titleCss = generalTitleCss + " text-mg-pink-dark";
  }

  if (["FALTA"].includes(style!)) {
    toolTip.badgeCss = generalCss + COLORS_BADGE.red;
    toolTip.titleCss = generalTitleCss + " text-mg-red-dark";
  }

  if (["COMPENSACIÓN"].includes(style!)) {
    toolTip.badgeCss = generalCss + COLORS_BADGE.yellow;
    toolTip.titleCss = generalTitleCss + " text-mg-yellow-dark";
  }

  if (["CADUCADO"].includes(style!)) {
    toolTip.badgeCss = generalCss + COLORS_BADGE.coral;
    toolTip.titleCss = generalTitleCss + " text-mg-coral-dark";
  }

  if (["DEFAULT"].includes(style!)) {
    toolTip.badgeCss = generalCss + COLORS_BADGE.main;
    toolTip.titleCss = generalTitleCss + " text-mg-main-blue";
  }

  return (
    '<div class="flex flex-col items-center p-2">' +
    '<div class="' +
    toolTip.titleCss +
    '">' +
    label +
    "</div>" +
    '<div class="' +
    toolTip.badgeCss +
    '">' +
    content +
    " Hrs." +
    "</div>" + 
      endDiv
  );
}
  