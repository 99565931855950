<div class="grid md:grid-cols-5 overflow-hidden w-full h-full xxs-h:grid-cols-5">
    <div class="flex justify-center md:col-span-2 h-full xxs-h:col-span-2">
        <div class="flex flex-col justify-between">
            <div class="absolute top-0 right-0 z-10  w-auto cursor-pointer xxs:inline-block md:hidden xxs-h:hidden mt-4 mr-4" (click)="close()">
                <mg-icon iconClass="text-mg-main-blue" iconName="close"></mg-icon>
            </div>
            <div class="flex flex-col h-full gap-0 text-center md:text-left xxs-h:text-left xxs-h:gap-2 md:gap-6">
                <p class="font-bold text-xl text-mg-main-blue-dark xxxsx:inline-block hidden xxs-h:text-sm">¡Buenas Noticias!</p>
                <p class="text-mg-purple-light font-bold text-md xxxsx:inline-block hidden xxs-h:text-sm">Versión {{ versionData[0]?.sVersion }}</p>
                <div class="overflow-auto max-h-[450px] xxs-h:max-h-[200px] hidden md:inline-block md:pl-12 xxs-h:inline-block xxs-h:pl-0">
                    <div class="flex flex-col text-mg-main-blue-dark w-10/12 gap-3 xxs-h:text-sm xxs-h:w-10/12">
                        <p *ngFor="let item of versionData"
                          (click)="showImage(item.sUrl_Fondo, item.sTitle, item.sDescription)" 
                          class="cursor-pointer indicator"
                          [ngClass]="{'font-bold': selectedImage === item.sUrl_Fondo, 'selected': selectedImage === item.sUrl_Fondo}">
                          {{ item.sTitle }}
                        </p>
                    </div>
                </div>
                <div class="grid md:hidden xxs-h:hidden">
                    <div class="w-full h-full grid">
                        <mg-custom-carousel [data]="carouselData" [autoPlay]="false" (slideChanged)="handleSlideChange($event)"/>
                    </div>
                    <div class="flex text-start w-full py-4">
                        <div class="border border-mg-main-blue-light rounded-xl flex flex-col bg-white py-4 px-6 gap-4">
                            <p class="text-mg-main-blue-dark font-semibold text-sm">{{ selectedcarouselTitle  }}</p>
                            <p class="font-light text-xs">{{ selectedcarouselDescription }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-center items-end md:pr-14">
                <img *ngIf="showLogo" src="../../../../assets/svg/logo.svg" class="w-32">
                <div *ngIf="showUpdateNotification"  (click)="onUpdateClick()" class="text-white bg-mg-main-blue-dark p-4 font-semibold rounded-xl cursor-pointer">
                    <p>Actualizar nueva versión</p>
                </div>
            </div>
        </div>
    </div>
    <div class="relative md:col-span-3 h-[650px] w-full object-fill justify-center md:inline-block hidden xxs-h:col-span-3 xxs-h:flex xxs-h:max-h-[300px]">
      <img *ngIf="selectedImage" 
        class="absolute rounded-xl w-full h-full object-contain"
        [src]="selectedImage" 
        [alt]="selectedImage">
        <div class="absolute top-0 right-0 z-10  w-auto cursor-pointer p-2 bg-mg-main-blue rounded-full" (click)="close()">
            <mg-icon iconClass="text-white" iconName="close"></mg-icon>
        </div>
        <div class="absolute bottom-0 left-0 z-10 w-full p-4">
            <div class="border border-mg-main-blue-light rounded-xl flex flex-col bg-white py-4 px-6 gap-4">
                <p class="text-mg-main-blue-dark font-semibold text-sm xxs-h:hidden">{{ selectedTitle }}</p>
                <p class="font-light text-xs">{{ selectedDescription }}</p>
            </div>
        </div>
    </div>
</div>
