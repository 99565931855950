import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { LayoutService } from 'src/app/core/services/layout.service';
import { localDB } from 'src/app/shared/helpers/localStorage';
import { SplashScreenService } from '../services/splash-screen.service';
import { ThemeService } from '../services/theme.service';
import { MatSidenav } from '@angular/material/sidenav';
import { TaskService } from 'src/app/modules/task/services/task.service';
import { AccessService } from '../services/access.service';
import { initGetBG, initGetColor } from 'src/app/modules/my-board/options-board';
import { PreferencesService } from 'src/app/modules/my-board/service/preferences-service.service';
import { WebSocktesService } from 'src/app/shared/services/webSockets.service';
import { HubData, SOCKETS_EVENTS, SOCKETS_URLS } from 'src/app/shared/interfaces/sockets.interface';
import { RequestsService } from 'src/app/modules/requests/services/request.service';
import { DashboardService } from 'src/app/modules/task/services/dashboard.service';
import { ToastResponse } from 'src/app/shared/components/toast-alert/toast.config';
import { encriptar } from 'src/app/shared/helpers/encryption';
import { ToasService } from 'src/app/shared/components/toast-alert/service/toas-service.service';
import { toCapitalize } from 'src/app/shared/helpers/textFuntions';
import { GoogleService } from '../services/googleApi.service';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'mg-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  loading: boolean = false;
  isDesktop$ = this._layoutService.isScreenXL$;
  sidenavCollapsed$ = this._layoutService.sidenavCollapsed$;
  @ViewChild('sidenav') sidenav: MatSidenav;
  slug: any;

  constructor(
    private _layoutService: LayoutService,
    private _router: Router,
    private _splashScreenService: SplashScreenService,
    private _themeService: ThemeService,
    private _taskService: TaskService,
    private _accessService: AccessService,
    private _activeRoute: ActivatedRoute,
    private _preferencesService: PreferencesService,
    private _webSocketsService: WebSocktesService,
    private _dashboardService: DashboardService,
    private _requestService: RequestsService,
    private _googleService: GoogleService,
    private _toastService: ToasService
  ) {
    this._webSocketsService.initAllConnections()
    this._activeRoute.data.subscribe(route =>{
      localDB.setItem("holidays", JSON.stringify(route['preferences'][1].FERIADOS));
      localDB.setItem("permissions", JSON.stringify(route['preferences'][1].SOLICTID_POR_DIAS[0].sCodigo));
      if(route['preferences'][0] && route['preferences'][0].isSuccess && route['preferences'][0].data ) {
        this._preferencesService.preferencesSubject.next(route['preferences'])
      }
      this._preferencesService.$preferencesObs.subscribe({
      next: (resp:any) =>{
        if(resp[0].isSuccess){
          resp[0].data.oTheme = initGetColor(resp[0].data.oColorBackground.sColor);
          resp[0].data.oBackground_Theme = initGetBG(resp[0].data.oColorBackground.sFondo);
          localDB.setItem('preferences',JSON.stringify(resp[0].data));
        }
      }
    })
    })

    this._splashScreenService.hide();
    this.listiningRouteChange();
    this.getFilterTask()
  }

  ngOnInit(): void {
    if(!localDB.getItem('accessToken')){
      this._googleService.getaccessToken(false).subscribe();
    }
  }

  ngAfterViewInit(): void {
    this.getConfig();
    this.handleIndexDBBySocketsEvents();
    this.GetNotifyProjecStatus()
  }

  handleIndexDBBySocketsEvents(){
   //SOLICITUDES
    let requestData: HubData = {
      hubUrl: SOCKETS_URLS.REQUEST,
      events: [SOCKETS_EVENTS.CREATE, SOCKETS_EVENTS.UPDATE, SOCKETS_EVENTS.DELETE, SOCKETS_EVENTS.MANTEINER],
      connectionData: null,
      BehaviorData:{
       oldData: null,
        sId_Name: 'nId_Solicitud'
      }
    } 
     // ...AGREGAR OTROS MÓDULOS


    this._requestService.getLastRequest.subscribe(resp =>{
      if(resp.message == 'init'){
        this._requestService.requestLastRequest().subscribe()
      }
      requestData.BehaviorData!.oldData = resp
    })
    this._webSocketsService.getHubDataConnections().subscribe({
      next: (connection) => {
        //SOLICITUDES
        requestData.connectionData = connection
        this._webSocketsService.handlHubEventsOnBehaviorS(requestData)
        //this._webSocketsService.handleHubEventsOnIndexedDB(requestData)

        // ...AGREGAR OTROS MÓDULOS
      }
    })
  }

  getConfig() {
    let themeSelected = JSON.parse(localDB.getItem('preferences')).oTheme;
    let bgSelected = JSON.parse(
      localDB.getItem('preferences')
    ).oBackground_Theme;
    if (bgSelected) {
      let myElement = document.getElementById('contentBg');
      if (myElement != null) {
        myElement.style.backgroundImage = `url('${bgSelected.jBackgrounds?.url}')`;
      }
    }
    this._themeService.setTheme(themeSelected!.jColors);
  }

  
  getFilterTask(){
    if(this._accessService.validarPermisoSlug('TEAM-HAVE-SUBORDINATE')){
      this._taskService.pryRqFilterData.subscribe(response =>{})
    }
  }


  listiningRouteChange() {
    this._router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }


  clickItem() {
    this.isDesktop$.subscribe((x) => {
      if (!x) {
        this.sidenav.toggle();
      }
    });
  }


  // TOAST PROYECTOS

  GetNotifyProjecStatus() {
    this._dashboardService.PryHoursExceeded.subscribe((val) => {
      if (val && val.isSuccess) {
        let totalToast: number = 0
        let timerUp: number = 1.5
        val.data.map((item: any, index:number) => {
          totalToast ++ 
          // if(totalToast && totalToast == index +1 ){
              //timerUp =  timerUp + 1.5
              this.callToast(item/*, timerUp*/)
          // }else{
          //   this.callToast(item)
          // }
        });
      }
    });
  }


  callToast(item:any, timerUp?:number){
    this._toastService
    .init({
      type: 'warning',
      message:`El requerimiento <strong> ${toCapitalize(item.sRequerimiento)} </strong> del proyecto 
      <strong> ${toCapitalize(item.sProyecto)} </strong> tiene horas sobrepasadas `,
      labelCheck: 'No volver a mostrar',
      timer:  20 //timerUp ?  20 + timerUp : 20,
    })
    .subscribe({
      next: (params: ToastResponse) => {
        if (params.isChecked == true) {
          var idProyecto = item.nId_Proyecto;
          var idRequerimiento = item.nId_Requerimiento;

          this._dashboardService
            .updatestatusnotification(idProyecto, idRequerimiento)
            .toPromise();
        }
        if (params.confirmRedirection == true) {
          let query =
            'nId_Requerimiento=' + item.nId_Requerimiento;
          let queryParams = query.split('&');
          let url = 'proyectos-requerimientos/lista';
          let newQueryParams: any = new Object();
          queryParams.forEach((param: string) => {
            let [key, value] = param.split('=');
            newQueryParams[encriptar(env.securityKey, key)] =
              encriptar(env.securityKey, value);
          });

          this._router.navigate([url], { queryParams: newQueryParams });  
        }
      },
    });
  }
}