import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
import { FormsModule } from '@angular/forms';
import { mgDialogConfig } from './interfaces/custom-dialog.interface';
import { IconComponent } from '../icon/icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

interface resultDialog{
  confirmStatus: boolean,
  denyStatus: boolean,
  inputValue: string
}

@Component({
  selector: 'mg-smart-alert',
  templateUrl: './smart-alert.component.html',
  styleUrls: ['./smart-alert.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, IconComponent, MatTooltipModule]
})

export class SmartAlertComponent implements OnInit {

  dialoConfig: mgDialogConfig = {
    type: '',
    title: '',
    message: '',
    showCancelButton: false,
    showConfirmButton: true,
    showDenyButton: false,
    cancelButtonText: 'Cancelar',
    confirmButtonText: 'Aceptar',
    denyButtonText: 'Denegar',
    showInput: false,
    inputRequired: false,
    disableClose: true,
    inputPlaceholder: '',
    timer: 0,
    timerProgressBar: false,
  };

  icon: string = ''
  inputValue: string = '';
  errorMessage: string = ''
  result: resultDialog = {
    confirmStatus: false,
    denyStatus: false,
    inputValue: ''
  }

  messageSanitized: any

  @Output() actionStatus = new EventEmitter<resultDialog>();

  progressValue: number = 100; // Valor inicial de la barra de progreso
  interval: any; // Referencia al intervalo de actualización

  constructor(
    @Inject(DIALOG_DATA) public data: mgDialogConfig,
    public dialogRef: DialogRef<SmartAlertComponent>,
    private sanitized: DomSanitizer,
  ){ }
  
  ngOnInit(): void {
    this.dialogRef.disableClose = this.dialoConfig.disableClose;
    if(this.data.message) this.messageSanitized = this.sanitized.bypassSecurityTrustHtml(this.data.message!);
    if(this.data) this.updateConfig(this.data,this.dialoConfig)
    if(this.data.type) this.setType(this.data.type)
    if(this.dialoConfig.timer! > 0) this.startProgressBar(this.dialoConfig.timer!);
  }

  confirm() {
    this.errorMessage = ""
    if (this.dialoConfig.inputRequired && this.dialoConfig.showInput) {
      if (!this.inputValue) {
        this.errorMessage = "El campo es requerido"
      } else if (this.inputValue.length < 15) {
        this.errorMessage = "Mínimo 15 caracteres"
      }else{
        this.actionStatus.emit({confirmStatus: true, denyStatus: false, inputValue: this.inputValue});
      }
    }else{
      this.actionStatus.emit({confirmStatus: true, denyStatus: false, inputValue: this.inputValue});
      this.dialogRef.close()
    }
  }

  cancel() {
    this.actionStatus.emit({ confirmStatus: false, denyStatus: false, inputValue: '' });
    this.dialogRef.close()
  }

  deny(){
    this.actionStatus.emit({confirmStatus: false, denyStatus: true, inputValue: this.inputValue});
  }

  updateConfig(config: mgDialogConfig, config2: mgDialogConfig) {
    for (const prop in config) {
      if (config.hasOwnProperty(prop) && config2.hasOwnProperty(prop)) {
        config2[prop] = config[prop];
      }
    }
  }

  startProgressBar(timeInSeconds: number) {
    this.progressValue = 100;
    let intervalTime = (timeInSeconds/100) * 1000;
    this.interval = setInterval(() => {
      this.progressValue--;
      if (this.progressValue <= 0) {
        clearInterval(this.interval);
        this.dialogRef.close()
      }
    }, intervalTime);
  }

  setType(type: string){
    switch(type){
      case 'warning': 
        this.data.title = this.data.title ? this.data.title : '¡Atención!'
        this.icon = 'warning'
        break;
      case 'danger': 
        this.data.title = this.data.title ? this.data.title : '¡Algo salió mal!'
        this.icon = 'error'
        break;
      case 'success': 
        this.data.title = this.data.title ? this.data.title : '¡Procesado con éxito!'
        this.icon = 'check_circle'
        break;
      case 'info': 
        this.data.title = this.data.title ? this.data.title : '¡Atención!'
        this.icon = 'info'
        break;
      case 'quest': 
        this.data.title = this.data.title ? this.data.title : '¡Atención!'
        this.icon = 'help'
        break;
      default:
        this.icon = ''
        break;
    }
  }

}
