import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { interval, of, startWith, switchMap } from 'rxjs';
import { localDB } from 'src/app/shared/helpers/localStorage';
import { NotificationsService } from '../../services/notifications.service';
import * as moment from 'moment';
import { goToModuleNotifications, groupByDates, setDescriptionForNotification } from '../../helpers/functions';
import {
  NotificationsDetail,
  NotificationsDetailMap,
} from '../../interfaces/notifications.interface';
import { ToasService } from 'src/app/shared/components/toast-alert/service/toas-service.service';
import { ToastResponse } from 'src/app/shared/components/toast-alert/toast.config';
import { clearQueryParamsService } from 'src/app/shared/services/clearQueryParams.service';

@Component({
  selector: 'mg-notifications-pop-up',
  templateUrl: './notifications-pop-up.component.html',
  styleUrls: ['./notifications-pop-up.component.scss'],
})
export class NotificationsPopUpComponent implements OnInit, OnChanges {
  @Output() badgeCounter = new EventEmitter<number>();
  @Input() matMenu: MatMenuTrigger;
  @Input() ReadNotifications: boolean = false
  public loading: boolean = true;
  public errorLoadingData: boolean = false;
  public withoutSeenNotificationCounter: number;
  public unreadNotificationCounter: number;
  public notificationsData: NotificationsDetailMap[] = [];
  public notificationsDataShow: NotificationsDetailMap[] = [];
  public idUserLoggedIn: number = 0;
  public isAll: boolean = true;

  constructor(
    private _notificationsService: NotificationsService,
    private _router: Router,
    private _TotastService: ToasService,
    private _clearQueryParamsService: clearQueryParamsService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['ReadNotifications']){
      if(this.ReadNotifications) {
        // this.markAllNotificationsAsSeen();
        this.badgeCounter.emit(0)
        setTimeout(() => {
          this.ReadNotifications = false
        }, 500);
      }
    }
    if (changes['matMenu']) {
      this.menuIsOpened();
    }
  }

  ngOnInit(): void {
    this.loading = true;
    moment.locale('es');
    this.idUserLoggedIn = parseInt(localDB.getItem('idUsuario'));
    this.getAllNotifications();
    interval(60000).subscribe(() => this.getAllNotifications());


    // this._notificationsService.notificationReceived.subscribe((notifications: any) => {
    //   console.log('Notificación recibida:', notifications);
    //   // Aquí puedes manejar la notificación como desees
    // });
  }

  menuIsOpened() {
    if(this.matMenu){
      this.matMenu.menuOpened.subscribe(() => {
        this.markAllNotificationsAsSeen();
      });
    }
  }

  goToNotificationsCenter() {
    this._router.navigate(['notificaciones']);
    this.closeMatMenu();
  }

  goToModule(notification: NotificationsDetail) {
    this.markNotificationAsRead(notification);
    let dataNotifications = goToModuleNotifications(notification);
    if (dataNotifications) {
    this._router.navigate([dataNotifications.url], {
        queryParams: dataNotifications.newQueryParams,
      });
      this.closeMatMenu();
      setTimeout(() => {
        this._clearQueryParamsService.clearQueryParams()
      }, 500);
    }
  }

  closeMatMenu() {
    this.matMenu.closeMenu();
  }

  emitNotifications = new EventEmitter<any>()

  getAllNotifications() {
    this._notificationsService.getNotifications()
    .subscribe((resp) => {
      if(resp){
        //@ts-ignore
        if (resp.isSuccess) {
          this.notificationsData = [];
          this.errorLoadingData = false;
                  //@ts-ignore
          this.startToastNotifications(resp.data)
                  //@ts-ignore
          this.notificationsData = resp.data.length
                  //@ts-ignore
            ? this.setAllNotifications(resp.data)
            : [];
          this.whatNotificationsToShow();
        } else {
          this.notificationsData = [];
          this.errorLoadingData = true;
        }
        this.loading = false;
      }
    });
  }

  setAllNotifications(data: NotificationsDetail[]) {
    let notificationsDataShowTemp: NotificationsDetailMap[] = [];
    this.withoutSeenNotificationCounter = 0;
    this.unreadNotificationCounter = 0;
    data.forEach((notification) => {
      if (!notification.bIsRead) {
        this.withoutSeenNotificationCounter++;
      }
      if (notification.nEstado_Notificacion == 1) {
        this.unreadNotificationCounter++;
      }
    });
    this._notificationsService.countNotifications.next(this.withoutSeenNotificationCounter);
    notificationsDataShowTemp = groupByDates(data);
    this.badgeCounter.emit(this.withoutSeenNotificationCounter);
    return notificationsDataShowTemp;
  }

  pullNotifications: NotificationsDetail[] = []

  startToastNotifications(notifications:NotificationsDetail[]){
    notifications.map(noti => {

      if(!noti.bIsRead){
        let modulo =  noti.sModulo.toLocaleUpperCase()
        let sMensaje = setDescriptionForNotification(noti.sMensaje, modulo)
        let oMsj = JSON.parse(noti.sMensaje)
        switch(modulo){
          case 'TAREO':
           if(['LLENADO_TAREO', 'LLENADO_TAREO_DIARIO'].includes(oMsj.sAccion.toUpperCase())){
              this._TotastService.init({
              message: sMensaje,
              timer: 10,
              type: 'warning',
              redirectionButtom: false
            })
            this._notificationsService.updateStateNotificationsCondition([noti.nId_Notificacion]).subscribe()
           }
            break
        }
      }
    })
  }

  setIsAll(isAll: boolean) {
    if (this.isAll != isAll) {
      this.isAll = isAll;
      this.whatNotificationsToShow();
    }
  }

  whatNotificationsToShow() {
    this.notificationsDataShow = [];
    if (this.isAll) {
      this.notificationsDataShow = this.notificationsData;
    } else {
      this.notificationsDataShow = this.showUnreadNotifications();
    }
  }

  showUnreadNotifications() {
    let notificationsDataMapShowTemp: NotificationsDetailMap[] = [];
    let notificationsDataShowTemp: NotificationsDetail[] = [];
    this.notificationsData.forEach((notifications) => {
      notifications.notificaciones.forEach(
        (notification: NotificationsDetail) => {
          if (notification.nEstado_Notificacion == 1) {
            notificationsDataShowTemp.push(notification);
          }
        }
      );
    });
    notificationsDataMapShowTemp = groupByDates(notificationsDataShowTemp);
    return notificationsDataMapShowTemp;
  }

  markNotificationAsRead(notification: NotificationsDetail) {
    if (notification.nEstado_Notificacion == 1) {
      notification.nEstado_Notificacion = 2;
      this.unreadNotificationCounter--;
      notification.bIsRead = true;
      this.whatNotificationsToShow();
      this._notificationsService
        .updateStateNotifications([notification.nId_Notificacion])
        .subscribe((resp) => {
          if (!resp.isSuccess) {
            notification.nEstado_Notificacion = 1;
            this.whatNotificationsToShow();
          }
        });
    }
  }

  markAllNotificationsAsRead() {
    this.withoutSeenNotificationCounter = 0;
    this.unreadNotificationCounter = 0;
    this._notificationsService.countNotifications.next(this.unreadNotificationCounter)
    this.badgeCounter.emit(0);
    let idsUnread: number[] = [];
    let idsWithoutSeen: number[] = [];
    this.notificationsData.map((notifications) => {
      notifications.notificaciones.map((notification) => {
        if (notification.nEstado_Notificacion == 1) {
          idsUnread.push(notification.nId_Notificacion);
          notification.nEstado_Notificacion = 2;
        }
        if (!notification.bIsRead) {
          idsWithoutSeen.push(notification.nId_Notificacion);
          notification.bIsRead = true;
        }
      });
    });
    this.whatNotificationsToShow();

    this._notificationsService
      .updateStateNotifications(idsUnread)
      .subscribe((resp) => {
        if (!resp.isSuccess) {
          this.getAllNotifications();
        }
      });

    if (idsWithoutSeen.length)
      this._notificationsService
        .updateStateNotificationsCondition(idsWithoutSeen)
        .subscribe(() => {});
  }

  markAllNotificationsAsSeen() {
    let ids: number[] = [];
    this.notificationsData.map((notifications) => {
      notifications.notificaciones.map((notification: NotificationsDetail) => {
        if (!notification.bIsRead) {
          ids.push(notification.nId_Notificacion);
        }
      });
    });
    this.badgeCounter.emit(0);
    this._notificationsService.countNotifications.next(0);
    if (ids.length) {
      this._notificationsService
        .updateStateNotificationsCondition(ids)
        .subscribe(() => {});
      }
  }
}