import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from "src/environments/environment";
import { BoardConfigurations, ConfigsBoard, ThemesAll, saveBoardConfig } from '../interfaces/board.interfaces';
import { GenericResponse } from 'src/app/shared/interfaces/generic.interface';
import { REFRESH_INDEXED } from 'src/app/core/indexedDB/helpers/enums.enums';

import { BehaviorSubject, Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { defaultConfigsTheme } from '../options-board';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {
  preferencesSubject: any
  $preferencesObs:Observable<GenericResponse<BoardConfigurations>> 

  constructor(
    private http: HttpClient,
    ) {
      //@ts-ignore
      let defaultTheme:BoardConfigurations = defaultConfigsTheme
      this.preferencesSubject =  new BehaviorSubject<GenericResponse<BoardConfigurations>>({
       data: defaultTheme,
       isSuccess: true,
       code: 200,
       message: ''
      })
      this.$preferencesObs = this.preferencesSubject.asObservable()
      this.setBoardConfig()
    }

  getBoardConfig() {
    const url = env.api_url + "api/preferencias/consultar";
    return this.http.get<GenericResponse<BoardConfigurations>>(url).pipe(
      catchError(error => {
        console.error('Error al consultar prefecias:', error);
        return [];
      })
    );

}

  
setBoardConfig(): Observable<GenericResponse<BoardConfigurations>> {
  const url = env.api_url + 'api/preferencias/consultar';
  return this.http.get<GenericResponse<BoardConfigurations>>(url).pipe(
    map(response => {
      this.preferencesSubject.next(response);
      return response
    }),
    catchError(error => {
      console.error('Error al consultar el endpoint HTTP:', error);
      return [];
    })
  );
}



  saveBoardConfig(data: saveBoardConfig) {
    return this.http.put<any>(env.api_url + "api/preferencias/actualizar", data);
  }

  getThemesOption(){
    return this.http.get<GenericResponse<ThemesAll>>(env.api_url + "api/temas/listar");
  }

  getTodayThemes(){
    const url = env.api_url + "api/temas/listar/hoy";
    return this.http.get<any>(url)

  }
}
