import { COLORS_BADGE } from '../enums/global-constants';
import { IconBadge } from '../interfaces/badges.interface';
import { Icon } from '../interfaces/icon.interface';

export function getIcon(
  icon: Omit<Icon, 'class'>,
  tooltip: string,
  color: string,
  permission: boolean,
  action?: string | null,
  hideBg?: boolean,
): IconBadge {
  let iconObj: IconBadge = {
    tooltip: '',
    icon: null,
    css: '',
    action: null,
  };

  if (permission) {
    let generalCss = 'flex justify-center items-center h-8 w-8 rounded-full ';
    let css;
    switch (color) {
      case "green":
        css = generalCss + (hideBg ? COLORS_BADGE.green.replace("!bg-mg-green-light", "") : COLORS_BADGE.green);
        break;
      case "red":
        css = generalCss + COLORS_BADGE.red;
        break;
      case "gray":
        css = generalCss + COLORS_BADGE.gray;
        break;
      case "teal":
        css = generalCss + COLORS_BADGE.teal;
        break;
      case "orange":
        css = generalCss + COLORS_BADGE.orange;
        break;
      case "yellow":
        css = generalCss + COLORS_BADGE.yellow;
        break;
      case "purple":
        css = generalCss + COLORS_BADGE.purple;
        break;
      case "coral":
        css = generalCss + COLORS_BADGE.coral;
        break;
      case "main_dark":
        css = generalCss + COLORS_BADGE.main_dark;
        break;
      case "invisible":
        css = generalCss + "invisible";
        break;
      case "main":
        css = generalCss + COLORS_BADGE.main;
        break;
      case "stripes":
        css = generalCss + COLORS_BADGE.main_stripes;
        break;
      default:
        css = generalCss + COLORS_BADGE.main;
        break;
    }
    iconObj = {
      tooltip,
      icon,
      css,
      action: action ? action : null,
    };
  }

  return iconObj;
}
