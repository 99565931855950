<ng-container *ngIf="isLink(item)">
    <a routerLinkActive="active" (click)="clickItemAction()" [routerLink]="item.route" class="text-mg-accent item cursor-pointer flex gap-4 px-5 py-3 items-center hover:text-mg-accent-dark" [matTooltip]="isDesktop ? item.label : ''" matTooltipPosition="right">
        <mg-icon [iconName]="item.icon"></mg-icon>
        <span *ngIf="!(isDesktop)" class="text-[0.9rem] capitalize whitespace-nowrap">{{ item.label }}</span>
    </a>
</ng-container>
<ng-container *ngIf="isDropdown(item)" >
    <div [ngClass]="{'bg-mg-accent-light rounded-r-3xl' : (isActive || isOpen)}">
        <div (click)="toggleOpen();$event.stopPropagation();" class="flex items-center cursor-pointer px-2 py-2 ml-3" [matTooltip]="isDesktop ? item.label : ''" matTooltipPosition="right">
            <div [class]="(isActive || isOpen) ? 'text-mg-accent-dark' : 'text-mg-accent'" class="flex gap-4 items-center hover:text-mg-accent-dark">
                <mg-icon [iconName]="item.icon"></mg-icon>
                <span *ngIf="!(isDesktop)" class="text-[0.9rem] capitalize whitespace-nowrap">{{ item.label}}</span>
            </div>
            <mg-icon class="flex transition duration-300 ease-in-out" [class]="(isActive || isOpen) ? 'rotate-90 text-mg-accent-dark' : 'rotate-0 text-mg-accent'" iconName="keyboard_arrow_right"></mg-icon>
        </div>
        <div [@dropdown]="isOpen" class="overflow-hidden" >
            <mg-sidenav-item *ngFor="let subItem of item.children" [item]="subItem" [level]="level + 1" [isCollapsed]="isCollapsed" [isDesktop]="isDesktop" (clickItem)="clickItemAction2()"></mg-sidenav-item>
        </div>
    </div>
</ng-container>