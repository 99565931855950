export const enum SOCKETS_URLS {
  SMART = 'ws/signal',
  CONFIGURATIONS = 'ws/configuraciones',
  NOTIFICATIONS = 'ws/notifications',
  REQUEST = 'ws/request',

}



//export type SOCKETS_EVENTS = 'created' | 'updated' | 'deleted' | 'createdrequest'

export enum SOCKETS_EVENTS {
  CREATE =  'created',
  UPDATE = 'updated',
  DELETE = 'deleted',
  MANTEINER = 'manteiner',
  RECONNET = 'reconnected'
}


export interface HubData {
  hubUrl:SOCKETS_URLS, 
  events: SOCKETS_EVENTS[]
  connectionData: any
  indexedDBData?:IndexedHubData
  BehaviorData?: BehaviorSHubData
}

export interface BehaviorSHubData {
  oldData: any,
  sId_Name: string,
  newData?: any
}

export interface IndexedHubData {
  tableName: string,
  keyName: string,
  newData?: any
  sId_Name?: string
}