
export enum REQUEST_FREQUENCY_TYPE {
   SIN_FRECUENCIA = 0,
   POR_HORAS = 1,
   POR_DIAS = 2,
   POR_MESES = 3
}
  
export enum REQUEST_CATEGORY {
   PERMISOS = 1,
   VACACIONES = 2,
   LICENCIAS = 3,
   HORAS_ADICIONALES = 4
}

export enum REQUEST_TYPE {
  PERMISO_POR_HORAS = 1,
  PERMISO_POR_DIAS = 2,
  RESERVA_VACACIONES = 3,
  EXTEMPORANEA = 4,
  CAMBIO_DE_HORARIO = 5,
  HORAS_EXTRAS = 6,
  HORAS_COMPENSACION = 7,
  HORAS_RECUPERACION = 8,
  HORAS_AVANCE = 9,
  VENTA_VACACIONES = 10,
  LACTANCIA = 11,
  DESCANSO_MEDICO_POR_HORAS = 12,
  DESCANSO_MEDICO_POR_DIAS = 17,
  PATERNIDAD = 13,
  MATERNIDAD = 14,
  BENEFICIO_POR_HORAS = 15,
  BENEFICIO_POR_DIAS = 18,
  SIN_GOCE_DE_HABER_POR_HORAS = 16,
  SIN_GOCE_DE_HABER_POR_DIAS = 19,
  TRABAJO_REMOTO_POR_HORAS = 20,
  TRABAJO_REMOTO_POR_DIAS = 21,
  ADELANTO_DE_VACACIONES = 22
}


export enum REQUEST_REASON_TYPE {
  MOTIVOS_PERSONALES = 4,
  MOTIVOS_MEDICOS = 6,
  MOTIVOS_ACADEMICOS = 13,
  OTROS = 17
}
