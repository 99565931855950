export interface ToastConfig {
    primary: string,
    secondary: string,
    icon: string,
    title: string
}


export interface ToastInitConfig {
    type?: TypeToast
    title?: string,
    message?: string,
    showConfirmButton?: boolean,
    timer?: number,
    position?: 'top-r' | 'top-l' | 'bottom-r' | 'bottom-l',
    labelCheck?: string,
    redirectionButtom?: boolean,
}

export type TypeToast = 'warning' | 'danger' | 'success' | 'info'  | ''

export interface ToastInsideConfig extends ToastInitConfig, ToastResponse {
    bMostrar?: boolean,
    count?: number,
    positionCss?: PositionToast
}

export interface ToastResponse {
    isChecked?: boolean,
    confirmRedirection?:boolean
}

export const ToastTheme: {[key:string]: ToastConfig} =  {
    warning: {
        primary: 'bg-mg-warning-light !text-mg-warning-dark',
        secondary: 'bg-mg-warning !text-mg-warning-dark',
        icon: 'warning',
        title: '¡Atención!'
    },
    success: {
        primary: 'bg-mg-success-light !text-mg-success-dark',
        secondary: 'bg-mg-success !text-mg-success-dark',
        icon: 'check_circle',
        title: '¡Procesado con éxito!'
    },
    info: {
        primary: 'bg-mg-info-light !text-mg-info-dark',
        secondary: 'bg-mg-info !text-mg-info-dark',
        icon: 'info',
        title: '¡Atención!'
    },
    danger: {
        primary: 'bg-mg-danger-light !text-mg-danger-dark',
        secondary: 'bg-mg-danger !text-mg-danger-dark',
        icon: 'error',
        title: '¡Algo salió mal!'
    }
}

export interface PositionToast  {
    init: string,
    end: string
}


export const ToastPositions: {[key:string]: PositionToast} = {
    'top-r': {
        init: 'init-top-r',
        end: ' end-top-r'
    },
    'top-l': {
        init: 'init-top-l',
        end: 'end-top-l'
    },
    'bottom-r': {
        init: 'init-bottom-r',
        end: ' end-bottom-r'
    },
    'bottom-l': {
        init: 'init-bottom-l',
        end: ' end-bottom-l'
    },

}