<form [formGroup]="form" (ngSubmit)="submit()" class="shadow-lg rounded-xl">
    <div class="
              flex
              items-center
              bg-white
              rounded-full
              lg:rounded-xl
              overflow-hidden
              w-full
              mx-auto
              h-11">
              <!-- border
              border-mg-main-blue-light -->
              
        <button type="button" *ngIf="searchOptions.length > 1" [matMenuTriggerFor]="menu"
            class="flex items-center p-2 text-mg-main-blue bg-mg-main-blue-light">
            <mg-icon class="flex" [iconName]="optionSearch.iconType" iconClass="w-[24px]"></mg-icon>
            <mg-icon class="flex" iconName="search" iconClass="w-[24px]"></mg-icon>
        </button>

        <button type="submit" [disabled]="!form.valid" class="bg-white rounded-sm text-mg-purple-dark p-2">
            <mg-icon class="flex" iconName="search" iconClass="w-[24px]"></mg-icon>
        </button>
        <input class="
              desktop
              border-0
              h-11
              outline-none
              rtl:pr-4
              placeholder:text-secondary
              bg-card
              flex-auto" matInput formControlName="searchData" (ngModelChange)="updatedValue($event)"
            [matAutocomplete]="auto" matTooltip="{{validation_desc}}" placeholder="{{optionSearch.placeholder}}"
            type="text" />

        <button (click)="reset()" *ngIf="form.valid || isActive" @scaleInOut class="mr-4" type="button">
            <mg-icon class="flex" iconName="close" iconClass="w-[16px] font-bold text-sm"></mg-icon>
        </button>

        <mat-menu #menu="matMenu" class="">
            <button mat-menu-item *ngFor="let option of searchOptions" (click)="changeSelection(option)">
                <mg-icon class="flex" [iconName]="option.iconType" iconClass="text-mg-main-blue w-[24px]"></mg-icon>
                <span [ngClass]="{'font-bold text-mg-main-blue': option == optionSearch}">
                    {{option.label}}
                </span>
            </button>
        </mat-menu>
    </div>
</form>

<mat-autocomplete #auto="matAutocomplete" class="!shadow-md">
    <mat-option (onSelectionChange)="submitOption(itemName.sDescripcion)"
        [matTooltipDisabled]="isSmallScreen"
        [matTooltip]="itemName.sDescripcion | titlecase" [ngClass]="{'hide-autocomplete': !showAutocomplete}"
        *ngFor="let itemName of observable | async" [value]="itemName.sDescripcion | titlecase">
        {{itemName.sDescripcion | titlecase}}
    </mat-option>
</mat-autocomplete>