import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
  } from "@angular/core";
  import { MatDialog } from "@angular/material/dialog";
  import { Router } from "@angular/router";
  import * as moment from "moment";
  import { lastValueFrom, timer } from "rxjs";
  import { switchMap } from "rxjs/operators";
  import { TasksCreateModalComponent } from "src/app/modules/task/components/tasks-create-modal-new/tasks-create-modal.component";
  import { TaskService } from "src/app/modules/task/services/task.service";
  import { MODAL_SIZING_THREE_COLUMNS } from "src/app/shared/enums/dialog-size.enum";
  import { localDB } from "src/app/shared/helpers/localStorage";
  import { ConfigurationsService } from "src/app/shared/services/configurations.service";
  import { ITaskList } from "src/app/modules/task/interfaces/task-interfaces";
  import { SmartAlertService } from "src/app/shared/components/smart-alert/smart-alert.service";
  import { groupArray } from "src/app/core/indexedDB/helpers/formattedData";
  import { REFRESH_INDEXED } from "src/app/core/indexedDB/helpers/enums.enums";
  import { MiniMenuButton } from "src/app/shared/interfaces/buttons.interface";
  
  @Component({
    selector: "mg-Activity",
    templateUrl: "./activity.component.html",
    styleUrls: ["./activity.component.scss"],
    encapsulation: ViewEncapsulation.None,
  })
  export class ActivityComponent implements OnInit, OnChanges {
    @Input() marksIsUpdate = false;
    @Input() editMode = false;
  
    public minDate: Date = new Date("2022-07-01");
    public maxDate: Date = new Date();
    public selectDay: any;
    public configurations: any;
    public aActivities: ITaskList[] = [];
    public loadingCalendar = true;
    public loadingActivities = true;
    public disabledDays: any;
    public actions: MiniMenuButton[] = [];
    public showComponent: boolean = true;
    
    constructor(
      private _configurationsService: ConfigurationsService,
      public dialog: MatDialog,
      private _router: Router,
      private _taskService: TaskService,
      private _smartAlertService: SmartAlertService,
    ) {
      this.actions = [
        {
          idOpt:1,
          iconOpt: 'open_in_new',
          labelOpt: 'Ver Todo',
          permisionOpt: true,
        },
        {
          idOpt:2,
          iconOpt: 'autorenew',
          labelOpt: 'Recargar',
          permisionOpt: true,
        }
      ]
    }
  
    ngOnChanges(changes: SimpleChanges): void {
      if (this.marksIsUpdate == true) {
        this.selectDay = moment();
        this.getConfigurations();
        timer(500).subscribe(async () => {
          this.getActivitiesByDate(this.selectDay.format("YYYY-MM-DD"));
        });
        this.marksIsUpdate = false;
      }
    }
  
    async initMaxMinDate() {
      let confi:any = {}
      try{
       confi =  await lastValueFrom(this._configurationsService.getConfig(REFRESH_INDEXED.EVERY_DAY, ['cierre-tareo']))
      }catch(err){
        console.error(err)
      }finally{
        this.minDate = new Date(confi['CIERRE_TAREO'][0].sDescripcion);
        this.maxDate = new Date();
      }
    }
  
    ngOnInit(): void {
      if (this.editMode && localDB.getTemporalItem('c-tareo')) {
        this.configurations = JSON.parse(localDB.getTemporalItem('c-tareo'))[0];
        this.disabledDays = JSON.parse(localDB.getTemporalItem('c-tareo'))[1];
        this.aActivities = JSON.parse(localDB.getTemporalItem('c-tareo'))[2];
        this.selectDay = moment(JSON.parse(localDB.getTemporalItem('c-tareo'))[3]);
        this.loadingCalendar= false;
        this.loadingActivities = false;
      }else{
        this.selectDay = moment();
        if(!this.isMasterTenantAccessing()){
          this.getActivitiesByDate(this.selectDay.format("YYYY-MM-DD"));
        }
        this.getConfigurations();
      }
      this.initMaxMinDate();
    }

    obtenerHostname(): string {
    const hostname = window.location.hostname;
    if (!hostname) {
      throw new Error("Hostname no encontrado");
    }
    return hostname;
    }
    isMasterTenantAccessing(): boolean {
      const hostname = this.obtenerHostname();
      const parts = hostname.split('.');
      const firstSubdomain = parts[0]; 
      return firstSubdomain === "master"
      }

    createTask() {
      if(this.configurations.PROYECTOS_COLABORADOR != undefined && this.configurations.aAllTareoStatus.length){
        this.initModal(this.configurations);
      }else{
        this._smartAlertService.init({
          type: 'info',
          message: "No tienes proyectos asignados.",
          showCancelButton: false,
          showConfirmButton: true,
          timer: 2
        });
      }
    }
  
    async getConfigurations() {
      this.loadingCalendar = true;
      let aConfiguracionesParam = [
        "tipo_actividad",
        "categoria_tarea",
        "tipo_hora",
      ];
  
      await groupArray([
        this._configurationsService
        .getConfig(REFRESH_INDEXED.NEVER, aConfiguracionesParam),
        this._configurationsService
        .getConfig(REFRESH_INDEXED.EVERY_REFRESH, ['proyectos'], undefined, undefined, undefined, '', localDB.getItem('idColaborador'))
      ]).pipe(
          switchMap((response) => {
            this.configurations = response;
            return this._taskService.getTareoStatus();
          })
        )
        .subscribe((response) => {
          this.configurations.aAllTareoStatus = response.aAllTareoStatus;
          this.configurations.oTareoByStatus = response.oTareoByStatus;
  
          this.disabledDays = this.configurations.oTareoByStatus.WITHOUT_MARKS;
  
          let found = this.configurations.TIPO_ACTIVIDAD.find(
            (element: any) => element.sCodigo == "3"
          );
          if (found === undefined && this.configurations.PROYECTOS_COLABORADOR != undefined) {
            this.configurations.PROYECTOS_CON_ACCESO = this.configurations.PROYECTOS_CON_ACCESO.filter(
              (obj1: any) =>
                this.configurations.PROYECTOS_COLABORADOR.every(
                  (obj2: any) => obj1.sCodigo !== obj2.sCodigo
                )
            );
          }
          this.saveInLocalS(moment())
          this.loadingCalendar = false;
        });
    }
  
    initModal(configurations: any) {
      const dialogRef =  this.dialog
        .open(TasksCreateModalComponent, {
          maxWidth: "90vw",
          height: MODAL_SIZING_THREE_COLUMNS.HEIGHT,
          data: structuredClone(configurations),
          disableClose: true,
        });
  
        dialogRef.componentInstance.onAdd.subscribe((response) => {
          if(response) {
  
            this.getActivitiesByDate(moment(this.selectDay).format("YYYY-MM-DD"));
            this.getConfigurations();
          }
        });
    }
  
    selectDate(e: string) {
      this.selectDay = e;
      this.configurations.dateSelected = moment(e).format("YYYY-MM-DD");
      this.getActivitiesByDate(moment(e).format("YYYY-MM-DD"));
      this.saveInLocalS(e);
    }
  
    getActivitiesByDate(fecha: string) {
      this.loadingActivities = true;
      this.aActivities = [];
      let getInputs = `&dFecha_Inicio=${fecha}&dFecha_Fin=${fecha}&sTipo_listado=TASK-LIST-INDIVIDUAL&sFilterFive=1-2-3`;
  
      this._taskService
      .getAll(50, "dFecha_Registro", "desc", 0, getInputs)
      .subscribe((resp) => {
        if (resp.isSuccess) {
          this.aActivities = resp.data;
          this.saveInLocalS(fecha);
          this.loadingActivities = false;
        }
      });
    }
  
    viewAll() {
      this._router.navigate(["tareo", "lista"]);
    }
  
    saveInLocalS(date: any){
      localDB.setTemporalItem('c-tareo',JSON.stringify([this.configurations,this.disabledDays,this.aActivities,date]));    
    }
  
    reloadData(){
      this.selectDay = moment();
      this.getActivitiesByDate(this.selectDay.format("YYYY-MM-DD"));
      this.getConfigurations();
    }
  
    makeAction(opt: number){
      switch(opt){
        case 1:
          this.viewAll();
          break;
        case 2:
          this.reloadData();
          break;
        default:
          break;
      }
    }
  }