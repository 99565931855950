import { PartialOmit } from './generic.interface';
import { Icon } from './icon.interface';

export interface SplitButton {
  type: 'action' | 'button';
  icon: Icon | null;
  label?: string;
  tooltip: string;
  value: typeSplitValue;
}

export interface GenericButton {
  label?: string;
  icon?: PartialOmit<Icon, "class">;
  tooltip: string;
  color: 'green' | 'blue' | 'purple' | 'orange' | 'yellow' | 'red' | 'default' | 'default-dark' | 'transparent';
  status: 'error' | 'success' | 'initial' | 'loading' | 'disabled' | 'warning';
  validateAccess: string | null;
  key?: string;
  show?: boolean;
  action?: string;
  customStyles?: string;
}

export interface GenericButtonTemplate {
  label?: string;
  icon?: PartialOmit<Icon, "class">;
  tooltip: string;
  color: 'green' | 'blue' | 'purple' | 'orange' | 'yellow' | 'red' | 'default' | 'default-dark' | 'transparent';
  status: 'error' | 'success' | 'initial' | 'loading' | 'disabled' | 'warning';
  validateAccess: string | null;
  key?: string;
  show?: boolean;
  action?: 'view1' | 'view2' |'list';
  customStyles?: string;
}

export enum typeSplitValue{
  RESET = 0,
  REFRESH = 1,
  OTHER = 3,
  TEMPLATE_EXCEL = 4,
  INSERT_EXCEL = 5,
}


export interface MiniMenuButton{
  idOpt: number,
  iconOpt: string,
  labelOpt: string,
  permisionOpt: boolean,
}

export interface ExcelErrorMessages {
  mensaje: string;
}