import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, catchError, forkJoin, map, mergeMap, of, switchMap, throwError } from "rxjs";
import { AccessService } from "src/app/core/services/access.service";
import { environment as env } from "src/environments/environment";
import { localDB } from "src/app/shared/helpers/localStorage";
import { AllCatfilter, AllPry_RqFilter, CatFilter, Observation, PryRqFilter, TempTaskModal, tareoStatus } from "../interfaces/task-interfaces";
import { groupByToArray } from "src/app/shared/helpers/groupBy";
import { enabledToApprove, enabledToDelete, enabledToEdit, enabledToObserve, enableToPay, getDateBagde, getIconDuplicate, obteniendoBadgeHfinHini, showObserver } from "../helpers/functions";
import { getArrayWithBadgeAndIcon, getRoundBadge, getSimpleBadge, getStateBadge } from "src/app/shared/helpers/badgesBuilder";
import * as moment from "moment";
import { getIcon } from "src/app/shared/helpers/iconBuilder";
import { setDecimalHours, setTotalHours } from "src/app/shared/helpers/timeAndHours";
import { COLORS_BADGE } from "src/app/shared/enums/global-constants";
import { SmartAlertService } from "src/app/shared/components/smart-alert/smart-alert.service";

import { NameWithPic } from "src/app/shared/interfaces/textWithPic.interface";
import { getTextWithPic } from "src/app/shared/helpers/textWithPic";
import { GenericResponse } from "src/app/shared/interfaces/generic.interface";
import { mapDataToFilterIndicator } from "src/app/modules/task/helpers/mapDataToFilterIndicator";
import { ACTIVITY_STATUS, STATUS_PAY } from "../helpers/enums";
import { RevisionService } from "src/app/core/services/revision.service";

@Injectable({
  providedIn: "root",
})
export class TaskService {
  data = {}
  private pryRqSubject: any | null = null
  pryRqFilterData: Observable<any | null> = of(null)
  revision: any = null

  constructor(
    private http: HttpClient,
    private _accesService: AccessService,
    private _smartAlert: SmartAlertService,
    private _revisionService: RevisionService

  ) {
    if (_accesService.validarPermisoSlug('TEAM-HAVE-SUBORDINATE')) {
      this.pryRqSubject = new BehaviorSubject<GenericResponse<AllPry_RqFilter> | null>(null)
      this.pryRqFilterData = this.pryRqSubject.asObservable()
      this.getFilterPry_Eq()
      this._revisionService.revision$.subscribe(x =>{
        if (x && x.REVISION[0]) {
          this.revision = x.REVISION[0].sCodigo || null;
        };
      })
    }

  }

  getAll(
    records: number,
    sort: string,
    order: string,
    numPage: number,
    getInputs = ""
  ): Observable<any> {
    let typoListado = getInputs
      .slice(getInputs.search("sTipo_listado=") + 14)
      .split("&");
    // let bIndividual = JSON.parse(typoListado[1].split('=')[1]);
    let idUsuario = parseInt(localDB.getItem("idUsuario"));


    const requestUrl = `${env.api_url
      }api/tareo/listar`;

    let data = {
      numPage: numPage + 1,
      order: order,
      sort: sort,
      records: records,
      numFilter: null,
      textFilter: null,
      sTipo_listado: "",
      sFilterOne: '',
      sFilterTwo: null,
      sFilterThree: null,
      sFilterFour: null,
      sFilterFive: null,
      sFilterSix: null,
      sFilterSeven: null,
      sFilterEight: null,
      dFecha_Inicio: null,
      dFecha_Fin: null,
      bDownload: false,
      bListado_individual: true,
      sFilterNotification: null
    }


    let newInputs = getInputsPart2(getInputs)

    data = mapInputsToSend(newInputs, data)
    this.data = data

    
    let TaskEdit = this._accesService.validarPermisoSlug('TEAM-HAVE-SUBORDINATE') 
    ?  this._accesService.validarPermisoSlug('SOLICITUD-APROBAR-TODAS') || (this._accesService.validarPermisoSlug("TASK-BUTTON-REVISION") && this.revision && data.sTipo_listado == 'TEAM-HAVE-SUBORDINATE' &&  this._accesService.validarPermisoSlug("TASK-BUTTON-UPDATE"))
    : this._accesService.validarPermisoSlug("TASK-BUTTON-UPDATE")

    let TaskObserve = this._accesService.validarPermisoSlug('TEAM-HAVE-SUBORDINATE') 
    ?  this._accesService.validarPermisoSlug('SOLICITUD-APROBAR-TODAS') || (this._accesService.validarPermisoSlug("TASK-BUTTON-REVISION") && this.revision && data.sTipo_listado == 'TEAM-HAVE-SUBORDINATE' &&  this._accesService.validarPermisoSlug("TASK-BUTTON-OBSERVE"))
    : this._accesService.validarPermisoSlug("TASK-BUTTON-OBSERVE")
    
    
    let TaskApprove = this._accesService.validarPermisoSlug('TEAM-HAVE-SUBORDINATE') 
    ?  this._accesService.validarPermisoSlug('SOLICITUD-APROBAR-TODAS') || (this._accesService.validarPermisoSlug("TASK-BUTTON-REVISION") && this.revision && data.sTipo_listado == 'TEAM-HAVE-SUBORDINATE' && this._accesService.validarPermisoSlug("TASK-BUTTON-APPROVE"))
    : this._accesService.validarPermisoSlug("TASK-BUTTON-APPROVE")
    

    let blockListinRevision = ["TASK-LIST-INDIVIDUAL", "TASK-LIST-ALL"]

    return this.http.post<any>(requestUrl, data).pipe(
      map((response: any) => {
        if (response.data == null) response.data = [];
        // if(!response.isSuccess){
        //   this._smartAlertService.init(
        //     {
        //       type: 'warning',
        //       message: response.message,
        //       showCancelButton: false,
        //     }
        //   )
        // }

        response.data.forEach((data: any) => {

          let taskDelete = data.nId_Usuario == idUsuario ? this._accesService.validarPermisoSlug("TASK-BUTTON-DELETE") : this._accesService.validarPermisoSlug("TASK-BUTTON-DELETE-ALL");
          data.nId_Falso = 0;

          let name_pic: NameWithPic = {
            urlPic: data.sUrl_Foto,
            primaryText: { text: data.sNombre_Colaborador },
          };

          data.NameWithPic = getTextWithPic(name_pic);

          if (data.sNombre_Tipo_Horas != 'HORARIO REGULAR') {
            data.badgeHFinHini = obteniendoBadgeHfinHini(
              data.dFecha_Fin,
              data.dFecha_Registro,
              data.dHora_Fin,
              data.dHora_Inicio
            );
          } else {
            data.badgeHFinHini = obteniendoBadgeHfinHini(
              null,
              null,
              null,
              null
            );
          }

          data.badgeRango = data.badgeHFinHini.data ? getDateBagde(data.badgeHFinHini.data.dInicio, data.badgeHFinHini.data.dFin, true) : getDateBagde('', '', false);


          data.sMensaje_Error = null;
          data.icHistorico = data.nContador_Historico
            ? getIcon({
              type: 'material',
              name: 'history',
            }, 'Histórico de cambios', 'main', true)
            : false;

          data.badgeDateWithIconHistoric = data.badgeProjectWithIconHistoric = getArrayWithBadgeAndIcon(
            {
              label: moment(data.dFecha_Registro).format('DD MMM YYYY'),
              tooltip: "",
              color: "main",
              label2: moment(data.dFecha_Registro).format('dddd')
            },
            data.nContador_Historico
              ? {
                icon: {
                  type: 'material',
                  name: 'history'
                },
                tooltip: "Histórico de cambios",
                action: "tareo-historic",
                typeAction: "modal",
                color: COLORS_BADGE.main,
              }
              : null
          );

          data.icEditarTarea = enabledToEdit(
            idUsuario,
            data.nId_Usuario,
            data.sEstado,
            typoListado[0],
            TaskEdit,
            data.revisado,
            data.sCodigo_Tipo_Horas,
            data.nUsuario_Revisor
          );


        data.icPagarTarea = enableToPay(data, typoListado[0], this._accesService.validarPermisoSlug('TASK-BUTTON-PAY'));

          data.icEliminarTarea = enabledToDelete(
            data.sEstado,
            taskDelete,
            typoListado[0],
            data.revisado);

          data.icDuplicarTarea = idUsuario == data.nId_Usuario ? getIconDuplicate(true, data.sEstado, typoListado[0], data.revisado) : false;

          let nIdColaborador: number = parseInt(localDB.getItem('idColaborador')) || -1;
          let showApprovalButton: boolean = false;

          if (this._accesService.validarPermisoSlug("SOLICITUD-APROBAR-TODAS")) {
            showApprovalButton = (nIdColaborador!=data.nId_Colaborador) ? true : false;
            // showApprovalButton = true;
          }

          data.icAprobarTarea = enabledToApprove(
            data.sEstado,
            data.sCodigo_Tipo_Horas,
            TaskApprove,
            typoListado[0],
            data.revisado,
            data.nUsuario_Revisor,
            (data.sAprobadores_Solicitud && data.sAprobadores_Solicitud.includes('[')) ? (JSON.parse(data.sAprobadores_Solicitud).length ? JSON.parse(data.sAprobadores_Solicitud).map((aprobador: any) => aprobador.id) : [0]) : [0],
            showApprovalButton,
          );

          data.icObservarTarea = !data.nEstado_Pago ?  enabledToObserve(
            idUsuario,
            data.nId_Usuario,
            data.nContador_Obs,
            TaskObserve,
            data.sEstado,
            typoListado[0],
            data.revisado,
            data.nUsuario_Revisor
          ): '' ;

          data.icObservations = showObserver(data.nContador_Obs, typoListado[0], data.revisado)

          data.btnAprobarTarea = enabledToApprove(
            data.sEstado,
            data.sCodigo_Tipo_Horas,
            TaskApprove,
            typoListado[0],
            data.revisado,
            data.nUsuario_Revisor,
            (data.sAprobadores_Solicitud && data.sAprobadores_Solicitud.includes('[')) ? (JSON.parse(data.sAprobadores_Solicitud).length ? JSON.parse(data.sAprobadores_Solicitud).map((aprobador: any) => aprobador.id) : [0]) : [0],
            showApprovalButton,
          );

          if (typoListado[0] !== 'TEAM-HAVE-SUBORDINATE') {
            data.badgeHoraTareo = getSimpleBadge(
              setTotalHours(Number(data.nMinutos))
            );
          } else {
            data.badgeHoraTareo = getSimpleBadge(
              setDecimalHours(Number(data.nMinutos))
            );
          }

          data.sColor = null;
          if (data.sEstado == ACTIVITY_STATUS.OBSERVADO || data.sEstado == ACTIVITY_STATUS.OBSERVADO_CLIENTE) {
            data.sColor = "observe";
          }
          if (data.revisado == 1 && blockListinRevision.includes(typoListado[0]!) || (data.revisado == 1 && data.nUsuario_Revisor !== Number(localDB.getItem('idUsuario')) && !blockListinRevision.includes(typoListado[0]!))) {
            data.sColor = "revision";
          }

          let datesState = ``
          let payState = 'Estado de Pago: '
          let dModificacion = (data.dFechaModificacion != null && data.dFechaModificacion != '0001-01-01T00:00:00') ? data.dFechaModificacion : null
          if (dModificacion != null) datesState = `${datesState}    Fecha modificación: ${moment(dModificacion).format('DD-MM-YYYY')}`
          payState =  data.nEstado_Pago ? payState + (data.nEstado_Pago == STATUS_PAY.PENDIENTE_PAGO ? 'P. DE PAGO, ' : 'PAGADO, ') : ''
          let toolTipState = `${payState}${data.sNombre_Estado.length > 9 ? 'Estado' : 'Estado actividad'}: ${data.sNombre_Estado}     Fecha creación: ${moment(data.dFechaCreacion).format('DD-MM-YYYY')}    ${datesState}`
          data.badgeEstados = getStateBadge(data.sNombre_Estado, toolTipState);
          data.badgeTipoHoras = getStateBadge(data.sNombre_Tipo_Horas, data.sNombre_Tipo_Horas);
          data.sNombre_Requerimiento = data.sNombre_Requerimiento
            ? data.sNombre_Requerimiento
            : "-";

          data.actionMenuCards = [
            {
              label: "Eliminar",
              icon: "delete",
              cssIcon: ["text-mg-red"],
              type: "submit",
              action: "eliminar-tarea",
              visible: data.icEliminarTarea.icon ? true : false,
            },
            // {
            //   label: "Editar",
            //   icon: "edit",
            //   cssIcon: ["text-mg-main-blue"],
            //   type: "submit",
            //   action: "editar-tarea",
            //   visible: data.icEditarTarea.icon ? true : false,
            // },
            // {
            //   label: "Duplicar",
            //   icon: "content_copy",
            //   cssIcon: ["text-mg-orange"],
            //   type: "submit",
            //   action: "duplicar-tarea",
            //   visible: data.icDuplicarTarea.icon ? true : false,
            // },
            {
              label: "Aprobar",
              icon: "check_circle",
              cssIcon: ["text-mg-green"],
              type: "submit",
              action: "aprobar-tarea",
              visible: data.icAprobarTarea.icon ? true : false,
            },
            {
              label: "Observar",
              icon: "add_comment",
              cssIcon: ["text-mg-orange"],
              type: "submit",
              action: "observar-tarea",
              visible: data.icObservarTarea.icon ? true : false,
            },
          ]
        });

        return response;
      })
    )
  }

  getFooter(
    getInputs = ""
  ): Observable<any> {
    const requestUrl = `${env.api_url
      }api/tareo/listarTotalGeneral`;
    return this.http.post<any>(requestUrl, this.data);
  }

  getFilterCategories(aId_Requerimiento: number[]): Observable<GenericResponse<AllCatfilter>> {
    let requestUrl = `${env.api_url}api/filtrosTareo/RequerimientosCategorias`
    return this.http.post<GenericResponse<AllCatfilter>>(requestUrl, aId_Requerimiento).pipe(
      map(resp => {
        let response = resp
        if (resp.isSuccess) {
          response = {
            ...resp,
            data: {
              'aRequerimientos': mapDataToFilterIndicator(resp.data['aRequerimientos'], 'nId_Requerimiento', 'aCategorias', 'Estado_Requerimiento')
            }
          };
        } else {
          this._smartAlert.init({
            type: 'danger',
            message: response.message
          })
        }
        return response
      })
    )
  }

  updateFilterPry_Rq(rqs: number[] | null): Observable<GenericResponse<any>> {
    let requestUrl = `${env.api_url}api/filtrosTareo/recalcular`
    // this.pryRqSubject.next(null)

    return this.http.post<GenericResponse<any>>(requestUrl, rqs).pipe(
      map(resp => {
        if (resp.isSuccess && this._accesService.validarPermisoSlug('TEAM-HAVE-SUBORDINATE')) {
          this.getFilterPry_Eq()
        } return resp
      })
    )
  }

  getFilterPry_Eq() {
    let requestUrl = `${env.api_url}api/filtrosTareo/ProyectosRequerimientos`
    this.http.get<GenericResponse<AllPry_RqFilter>>(requestUrl).pipe(
      map(resp => {
        let response = {
          ...resp,
          data: {
            'aProyectos': mapDataToFilterIndicator(resp.data['aProyectos'], 'nId_Proyecto', 'aRequerimiento', 'Estado_Proyecto', 'Estado_Requerimiento'),
            'aEquipos': mapDataToFilterIndicator(resp.data['aEquipos'], 'nId_Proyecto', 'aRequerimiento', 'Estado_Proyecto', 'Estado_Requerimiento'),
            'aTodos': mapDataToFilterIndicator(resp.data['aTodos'], 'nId_Proyecto', 'aRequerimiento', 'Estado_Proyecto', 'Estado_Requerimiento'),
          }
        };
        return response
      })
    ).subscribe({
      next: (response) => {
        this.pryRqSubject.next(response)
      },
      error: (errorResponse) => {
        console.error('Error filtro', errorResponse)
        this.pryRqSubject.next({
          isSuccess: false,
          data: null
        })
      }
    })
  }

  payTaskSelected(tasks:{aTasks: any[], payForm: any}):Observable<GenericResponse<any>>{
    let requestUrl = env.api_url + "api/tareo/actualizar-estado/seleccion"
    return this.http.put<GenericResponse<any>>(requestUrl, tasks)
  }

  payAllTaskSelected(payForm: any):Observable<GenericResponse<any>>{

    let requestUrl = env.api_url + "api/tareo/actualizar-estado/filtro"
    let data = {
      filter: this.data,
      payForm: payForm
    }
    return this.http.put<GenericResponse<any>>(requestUrl, data)
  }

  massiveEditTask(tasks: any[], data: any): Observable<GenericResponse<any>> {
    let dataToSend = {
      tasks: tasks,
      pryRqCatCodes: data
    }
    let requestUrl = env.api_url + "api/tareo/editarPryRqCat/seleccion"
    return this.http.post<any>(requestUrl, dataToSend);
  }

  massiveAllEditTask(data: any): Observable<GenericResponse<any>> {
    let dataToSend = {
      filter: this.data,
      pryRqCatCodes: data
    }
    let requestUrl = env.api_url + "api/tareo/editarPryRqCat/filtro"

    return this.http.post<any>(requestUrl, dataToSend);

  }
  //-------------------------

  getNewTaskToRevision(): Observable<any> {
    return this.http.get<any>(
      env.api_url + "api/tareo/listar/revision-faltantes"
    )
  }

  setTaskToRevision(data: string[] | number[]): Observable<any> {
    return this.http.post<any>(
      env.api_url + "api/tareo/insertar/revision-faltantes", data
    )
  }

  deleteTask(sId_Tasks: string): Observable<any> {
    const requestUrl = `${env.api_url}api/tareo/eliminar?sId_Tasks=${sId_Tasks}`;
    return this.http.delete<any>(requestUrl);
  }

  editTask(nId_Task: number, data: any): Observable<any> {
    return this.http.put<any>(
      env.api_url + "api/tareo/actualizar?nId_Task=" + nId_Task,
      data
    );
  }

  addObserve(data: Observation[]) {
    return this.http.post<any>(env.api_url + "api/observacion/seleccion/crear", data);
  }

  observeAllTask(obs:Observation){
    let data = {
      sObservacion: obs.sObservacion,
      oFilter: this.data
    }
    return this.http.post<any>(env.api_url + "api/observacion/filtro/crear", data);
  }

  getObserve(nId_Task: number) {
    return this.http.get<any>(
      `${env.api_url}api/tareo/listado-observaciones?nId_Task=${nId_Task}`
    );
  }

  getHistorical(nId_Task: number) {
    return this.http.get<any>(
      `${env.api_url}api/tareo/listado-historicos?nId_Task=${nId_Task}`
    );
  }

  addTask(params: TempTaskModal[]) {
    const fechaRegistro: string = params[params.length-1].dFecha_Registro! as string;
    const sLastWorkDay: string = (localDB.getItem('V-HORARIO') && localDB.getItem('V-HORARIO').length > 0) ? JSON.parse(localDB.getItem('V-HORARIO')).dFecha_Jornada : fechaRegistro;

    const requestUrl: string = `${env.api_url}api/tareo/crear`;
    const newParams: TempTaskModal[] = params.map((param: TempTaskModal) => ({
        ...param,
        sLastWorkDay,
    }));

    return this.http.post<any>(requestUrl, newParams);
  }

  approveTask(params: any) {
    const requestUrl = `${env.api_url}api/tareo/aprobar`;
    return this.http.post<any>(requestUrl, params);
  }

  approveAllTask(): Observable<any> {
    const requestUrl = `${env.api_url}api/tareo/aprobarTodas`;
    return this.http.post<any>(requestUrl, this.data);
  }

  endRevisionTask() {
    return this.http.get<any>(
      `${env.api_url}api/tareo/quitar_revision`
    );
  }

  initRevisionTask(records: number,
    sort: string,
    order: string,
    numPage: number,
    getInputs = "") {

    let typoListado = getInputs
      .slice(getInputs.search("sTipo_listado=") + 14)
      .split("&");
    // let bIndividual = JSON.parse(typoListado[1].split('=')[1]);
    let idUsuario = parseInt(localDB.getItem("idUsuario"));

    let TaskEdit = this._accesService.validarPermisoSlug("TASK-BUTTON-UPDATE");

    let TaskObserve = this._accesService.validarPermisoSlug(
      "TASK-BUTTON-OBSERVE"
    );
    let TaskApprove = this._accesService.validarPermisoSlug(
      "TASK-BUTTON-APPROVE"
    );

    const requestUrl = `${env.api_url
      }api/tareo/revisar`;

    let data = {
      numPage: numPage + 1,
      order: order,
      sort: sort,
      records: records,
      numFilter: null,
      textFilter: null,
      sTipo_listado: "",
      sFilterOne: '',
      sFilterTwo: null,
      sFilterThree: null,
      sFilterFour: null,
      sFilterFive: null,
      sFilterSix: null,
      sFilterSeven: null,
      sFilterEight: null,
      dFecha_Inicio: null,
      dFecha_Fin: null,
      bDownload: false,
      bListado_individual: true,
      sFilterNotification: null
    }

    let newInputs = getInputsPart2(getInputs)
    data = mapInputsToSend(newInputs, data)
    this.data = data

    return this.http.post<any>(requestUrl, data)

  }

  getTareoStatus(idCollab?: string | number): Observable<tareoStatus> {
    let idColab =
      idCollab != undefined ? idCollab : parseInt(localDB.getItem("idColaborador"));

    return this.http
      .get<any>(
        env.api_url + "api/configuraciones/tareo_status?nId_User=" + idColab
      )
      .pipe(
        map((response) => {
          let oTareoByStatus = groupByToArray(response.data, "sEstado");

          response.data = response.data.map((x: any) => {
            x.dDate = x.dDate.split("T")[0];
            x.nMaxMinutesRegular =
              x.nRequired - x.nReady < 0
                ? 0
                : x.nRequired - x.nReady;
            x.nMaxMinutesRegularEditar = x.nMaxMinutesRegular;
            x.nMaxMinutesExtra = x.nRequiredNoRegular - x.nReadyNoRegular;
            x.nMaxMinutesExtraEditar = x.nMaxMinutesExtra;
            return x;
          });

          let toDay = moment().format('YYYY-MM-DD');
          let datesWithoutMarks = response.data.filter((x: any) => {
            
            if(x.sEstado_Horario == 'HORARIO_REGULAR' || x.sEstado_Horario == 'SOLICITUD_POR_HORAS'){
              if(x.dDate != toDay && x.nReadyMark != x.nRequiredMark) return true;
              if(x.dDate == toDay && x.nEntryMark == 0) return true;
            }else{
              if(x.dDate != toDay && x.nEntryMark == 1 && x.nReadyMark != x.nRequiredMark) return true;
            } 

            return false; 
          }).map((x: any) => x.dDate);

          for (const key in oTareoByStatus) {
            oTareoByStatus[key] = oTareoByStatus[key].map(
              (value: any) => value.dDate
            );
          }

          oTareoByStatus.WITHOUT_MARKS =
            datesWithoutMarks == undefined ? [] : datesWithoutMarks;

          oTareoByStatus.FALTA =
            oTareoByStatus.FALTA == undefined ? [] : oTareoByStatus.FALTA;

          oTareoByStatus.COMPLETO =
            oTareoByStatus.COMPLETO == undefined ? [] : oTareoByStatus.COMPLETO;

          oTareoByStatus.INCOMPLETO =
            oTareoByStatus.INCOMPLETO == undefined
              ? []
              : oTareoByStatus.INCOMPLETO;

          oTareoByStatus.REVISION =
            oTareoByStatus.REVISION == undefined
              ? []
              : oTareoByStatus.REVISION;

              if(parseInt(localDB.getItem("idColaborador")) == idColab){
                let status = JSON.stringify(response.data)
                localDB.setItem('allTareoStatus', status)
              }
          return { oTareoByStatus, aAllTareoStatus: response.data };
        })
      );
  }

  getAllGrouped(
    records: number,
    sort: string,
    order: string,
    numPage: number,
    getInputs = ""
  ): Observable<any> {
    let typoListado = getInputs
      .slice(getInputs.search("sTipo_listado=") + 14)
      .split("&");
    // let bIndividual = JSON.parse(typoListado[1].split('=')[1]);
    let idUsuario = parseInt(localDB.getItem("idUsuario"));

    let TaskEdit = this._accesService.validarPermisoSlug("TASK-BUTTON-UPDATE");

    let TaskObserve = this._accesService.validarPermisoSlug(
      "TASK-BUTTON-OBSERVE"
    );
    let TaskApprove = this._accesService.validarPermisoSlug(
      "TASK-BUTTON-APPROVE"
    );


    const requestUrl = `${env.api_url
      }api/tareo/listarAgrupado`;

    let data = {
      numPage: numPage + 1,
      order: order,
      sort: sort,
      records: records,
      numFilter: null,
      textFilter: null,
      sTipo_listado: "",
      sFilterOne: '',
      sFilterTwo: null,
      sFilterThree: null,
      sFilterFour: null,
      sFilterFive: null,
      sFilterSix: null,
      sFilterSeven: null,
      sFilterEight: null,
      dFecha_Inicio: null,
      dFecha_Fin: null,
      bDownload: false,
      bListado_individual: true,
      sFilterNotification: null
    }


    let newInputs = getInputsPart2(getInputs)

    data = mapInputsToSend(newInputs, data)
    this.data = data

    let blockListinRevision = ["TASK-LIST-INDIVIDUAL", "TASK-LIST-ALL"]

    return this.http.post<any>(requestUrl, data).pipe(
      map((response: any) => {

        if (response.data == null) response.data = [];
        // if(!response.isSuccess){
        //   this._smartAlertService.init(
        //     {
        //       type: 'warning',
        //       message: response.message,
        //       showCancelButton: false,
        //     }
        //   )
        // }

        response.data.forEach((data: any) => {

          let taskDelete = data.nId_Usuario == idUsuario ? this._accesService.validarPermisoSlug("TASK-BUTTON-DELETE") : this._accesService.validarPermisoSlug("TASK-BUTTON-DELETE-ALL");
          data.nId_Falso = 0;

          let name_pic: NameWithPic = {
            urlPic: data.sUrl_Foto,
            primaryText: { text: data.sNombre_Colaborador },
          };

          data.NameWithPic = getTextWithPic(name_pic);

          if (data.sNombre_Tipo_Horas != 'HORARIO REGULAR') {
            data.badgeHFinHini = obteniendoBadgeHfinHini(
              data.dFecha_Fin,
              data.dFecha_Registro,
              data.dHora_Fin,
              data.dHora_Inicio
            );
          } else {
            data.badgeHFinHini = obteniendoBadgeHfinHini(
              null,
              null,
              null,
              null
            );
          }

          data.badgeRango = data.badgeHFinHini.data ? getDateBagde(data.badgeHFinHini.data.dInicio, data.badgeHFinHini.data.dFin, true) : getDateBagde('', '', false);

          data.total = getRoundBadge(data.nCantidad_tareas !== 1 ? data.nCantidad_tareas.toString() + ' Actividades' : data.nCantidad_tareas.toString() + ' Actividad', data.nCantidad_tareas > 99 ? '+99' : data.nCantidad_tareas.toString());

          data.totalHours = getSimpleBadge(
            setDecimalHours(data.nMin_Total), undefined, 'blue'
          )

          data.regular = getSimpleBadge(
            setDecimalHours(data.nMin_Regulares)
          )

          data.extras = getSimpleBadge(
            setDecimalHours(data.nMin_Extras)
          )

          data.compensacion = getSimpleBadge(
            setDecimalHours(data.nMin_Compensacion)
          )

          data.recuperacion = getSimpleBadge(
            setDecimalHours(data.nMin_Recuperacion)
          )

          data.avance = getSimpleBadge(
            setDecimalHours(data.nMin_Avance)
          )

          data.aprobadas = getSimpleBadge(
            setDecimalHours(data.nMin_Aprobados), undefined, 'green'
          )

          data.pendientes = getSimpleBadge(
            setDecimalHours(data.nMin_Pendientes), undefined, 'orange'
          )

          data.pendDirector = getSimpleBadge(
            setDecimalHours(data.nMin_Pendientes_Director), undefined, 'orange'
          )

          data.badgeDateWithIconHistoric = data.badgeProjectWithIconHistoric = getArrayWithBadgeAndIcon(
            {
              label: moment(data.dFecha_Registro).format('DD MMM YYYY'),
              tooltip: "",
              color: "main",
              label2: moment(data.dFecha_Registro).format('dddd')
            },
            data.nContador_Historico
              ? {
                icon: {
                  type: 'material',
                  name: 'history'
                },
                tooltip: "Histórico de cambios",
                action: "tareo-historic",
                typeAction: "modal",
                color: COLORS_BADGE.main,
              }
              : null
          );

          data.icEliminarTarea = enabledToDelete(
            data.sEstado,
            taskDelete,
            typoListado[0],
            data.revisado);

          data.icDuplicarTarea = idUsuario == data.nId_Usuario ? getIconDuplicate(true, data.sEstado, typoListado[0], data.revisado) : false;

          data.icAprobarTarea = enabledToApprove(
            data.sEstado,
            data.sCodigo_Tipo_Horas,
            TaskApprove,
            typoListado[0],
            data.revisado,
            data.nUsuario_Revisor,
            (data.sAprobadores_Solicitud && data.sAprobadores_Solicitud.includes('[')) ? (JSON.parse(data.sAprobadores_Solicitud).length ? JSON.parse(data.sAprobadores_Solicitud)[0].id : 0) : 0
          );
          
          data.icObservarTarea = !data.nEstado_Pago ?  enabledToObserve(
            idUsuario,
            data.nId_Usuario,
            data.nContador_Obs,
            TaskObserve,
            data.sEstado,
            typoListado[0],
            data.revisado,
            data.nUsuario_Revisor
          ): '';

          data.icObservations = showObserver(data.nContador_Obs, typoListado[0], data.revisado)

          data.btnAprobarTarea = enabledToApprove(
            data.sEstado,
            data.sCodigo_Tipo_Horas,
            TaskApprove,
            typoListado[0],
            data.revisado,
            data.nUsuario_Revisor,
            (data.sAprobadores_Solicitud && data.sAprobadores_Solicitud.includes('[')) ? (JSON.parse(data.sAprobadores_Solicitud).length ? JSON.parse(data.sAprobadores_Solicitud)[0].id : 0) : 0
          );

          // let datesState = ``
          // let dModificacion = (data.dFechaModificacion != null && data.dFechaModificacion != '0001-01-01T00:00:00') ? data.dFechaModificacion : null
          // if(dModificacion != null) datesState = `${datesState}    Fecha modificación: ${moment(dModificacion).format('DD-MM-YYYY')}`

          // let toolTipState = `Fecha creación: ${moment(data.dFechaCreacion).format('DD-MM-YYYY')} ${datesState}`
          // data.badgeEstados = getStateBadge(data.sNombre_Estado, toolTipState );
          // data.badgeTipoHoras = getStateBadge(data.sNombre_Tipo_Horas, data.sNombre_Tipo_Horas);
          // data.sNombre_Requerimiento = data.sNombre_Requerimiento
          //   ? data.sNombre_Requerimiento
          //   : "-";
        });

        return response;
      })
    )
  }

}

export function mapInputsToSend(array: any[], object: any) {

  const propiedades = Object.entries(object);
  const startIndex = 4;

  for (let i = startIndex; i < propiedades.length; i++) {
    const [propiedad, valor] = propiedades[i];
    let a = array.find((obj) => obj.prop == propiedad)
    if (a) {
      object[propiedad] = a.value ? ((a.value == 'true' || a.value == 'false') ? JSON.parse(a.value) : a.value) : null;
    } else {
      if (propiedad == 'bListado_individual' || propiedad == 'bDownload') {
        object[propiedad] = false;
      } else {
        object[propiedad] = null;
      }
    }
  }

  return object

}

export function getInputsPart2(string: string) {
  let array: any[] = [];
  let x = string.split('&');

  x.forEach((elem) => {
    if (elem) {
      let obj = {
        prop: elem.split('=')[0],
        value: elem.split('=')[1] === 'null' ? null : elem.split('=')[1],
      };

      if (obj.value !== null) {
        array.push(obj);
      }
    }
  });

  return array;
}
