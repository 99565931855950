<ng-container *ngIf="showBanner">
  <div
    (click)="hidden()"
    (mouseenter)="hidden()"
    [class]="CssHiddenBanner? 'hidden' : '' "
    class="opacity-75 flex items-center space-x-6 justify-center bg-mg-accent-dark text-white px-6 py-1 text-sm" id="safari_banner">
    <div class="flex flex-wrap text-center justify-center items-center">
     Esta aplicación está en<strong>&nbsp;Beta&nbsp;</strong>para su uso en
     <strong>&nbsp;Safari,&nbsp;</strong> puede presentar inestabilidad o comportamientos inesperados.
    </div>
  </div>
</ng-container>