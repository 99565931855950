import { NgModule } from '@angular/core';
import { FormControlPipe } from './form-transform.pipe';
import { HourPipe } from './hours.pipe';

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [ 
    FormControlPipe,
    HourPipe,
  ],
  exports: [
    FormControlPipe,
    HourPipe,
  ]
})
export class PipesModule {}