import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconComponent } from '../icon/icon.component';

export interface ErrorPayload {
  title?: string;
  message: string;
}

interface ErrorStyles {
  titleCss: string;
  bodyCss: string;
  clickCss: string;
}

@Component({
  selector: 'mg-service-error-reload',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, IconComponent],
  templateUrl: './service-error-reload.component.html',
  styleUrls: ['./service-error-reload.component.scss'],
})
export class ServiceErrorReloadComponent implements OnInit {
  @Input() error: ErrorPayload;
  @Input() styleError: 'main' | 'purple-light' | 'accent';

  @Output() actionEmmiter = new EventEmitter();

  message = {
    title: '',
    titleCss: '',
    body: '',
    bodyCss: '',
    clickCss: '',
  };

  ngOnInit(): void {
    this.styleMessage(this.styleError);
    this.message.body = this.error.message;
    this.message.title = this.error.title ? this.error.title : '';
  }

  styleMessage(error: 'main' | 'purple-light' | 'accent') {
    const defaultValues = {
      titleCss: 'text-sm text-mg-gray text-center',
      bodyCss: 'text-sm text-mg-gray text-center',
      clickCss: 'mg-purple',
    };

    // Diccionario
    const data =
      {
        main: {
          titleCss: 'text-mg-main-blue text-xl font-bold text-center',
          bodyCss: 'text-mg-main-blue text-base',
          clickCss: 'mg-main-blue font-medium',
        },
        'purple-light': {
          titleCss: 'text-mg-purple-light text-xl font-bold text-center',
          bodyCss: 'text-mg-purple-light text-base font-bold',
          clickCss: 'mg-purple-light font-bold',
        },
        'accent': {
          titleCss: 'text-mg-accent text-xl font-bold text-center',
          bodyCss: 'text-mg-accent text-base font-bold',
          clickCss: 'mg-accent font-bold',
        }
      }[error] ?? defaultValues;

    this.setStyles(data);
  }

  setStyles(payload: ErrorStyles) {
    this.message.titleCss = payload.titleCss;
    this.message.bodyCss = payload.bodyCss;
    this.message.clickCss = payload.clickCss;
  }

  emmitAction() {
    this.actionEmmiter.emit();
  }
}
