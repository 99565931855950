import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import * as moment from 'moment';
import { Moment } from 'moment';
import {
  DateValues,
  TypeOfDateFilter,
  TypeOptions,
} from '../../../interfaces/filter-date-range-ymd.interface';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { IconComponent } from '../../icon/icon.component';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMenuModule } from '@angular/material/menu';
import { DateFormatModule } from '../date-format/data-format.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LayoutService } from 'src/app/core/services/layout.service';
import { clearQueryParamsService } from 'src/app/shared/services/clearQueryParams.service';


@Component({
  selector: 'mg-filter-date-range-ymd',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    IconComponent,
    MatMenuModule,
    MatTooltipModule,
    MatFormFieldModule,
    DateFormatModule,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './filter-date-range-ymd.component.html',
  styleUrls: ['./filter-date-range-ymd.component.scss'],
})
export class FilterDateRangeYmdComponent implements OnInit, OnChanges {
  @Input() label: string = 'Fecha';
  @Input() dStart: string | Date | Moment = '';
  @Input() dEnd: string | Date | Moment | null = '';
  @Input() minDate?: string | Moment | Date | null = null;
  @Input() maxDate?: string | Moment | Date = moment();
  @Input() rangeDatesDisabled?: string[] = [];
  @Input() aTypeOfDatesOptions?: TypeOptions | null = null;
  @Input() currentValueTypeOfDate?: TypeOfDateFilter | null = null;
  @Input() reset?: boolean = false;
  @Input() aplicateResponsive: boolean | null = false;
  @Output() dateRange = new EventEmitter<DateValues>();
  selectedItem: TypeOfDateFilter;
  isScreenLG$ = this._layoutService.isScreenLG$;
  range = new FormGroup({
    dateStart: new FormControl(),
    dateEnd: new FormControl(),
  });

  constructor(
    private _layoutService: LayoutService,
    private _clearQueryParamsService: clearQueryParamsService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dEnd'] || changes['dStart']) {
      this.patchValueDates();
    }
    if (changes['reset']) {
      this.patchValueDates();
      this.setDefaultTypeOfDateFilter();
    }

    if(changes['currentValueTypeOfDate']){
      this.setDefaultTypeOfDateFilter();
    }
  }

  ngOnInit(): void {
    this.setDefaultTypeOfDateFilter();
  }

  patchValueDates() {
    this.range.get('dateStart')?.patchValue(this.dStart);
    this.range.get('dateEnd')?.patchValue(this.dEnd);
  }

  setDefaultTypeOfDateFilter() {
    if (this.aTypeOfDatesOptions) {
      this.setTypeOfDateFilter(this.aTypeOfDatesOptions.initFilters);
    }
  }

  addEventEnd(event: MatDatepickerInputEvent<Date>) {
    if (event.value != null) {
      //this._clearQueryParamsService.checkIfQueryParamsExists();
      this.emitData();
    }
  }

  setTypeOfDateFilter(filterValue: TypeOfDateFilter) { 
    this.selectedItem = filterValue;
    this.aTypeOfDatesOptions!.options.forEach((e) => {
      if (e.value == filterValue) {
        this.label = e.label;
        if (e.maxDate != undefined) {
          this.maxDate = e.maxDate;
        }
        if (e.minDate != undefined) {
          this.minDate = e.minDate;
        }
      }
    });
    this.emitData();
  }

  emitData() {
    if (
      this.range.get('dateStart')?.value &&
      this.range.get('dateEnd')?.value
    ) {
      let data: DateValues = {
        key: this.selectedItem,
        dateStart:
          typeof this.range.get('dateStart')?.value == 'string'
            ? this.range.get('dateStart')?.value
            : this.range.get('dateStart')?.value.format('YYYY-MM-DD'),
        dateEnd:
          typeof this.range.get('dateEnd')?.value == 'string'
            ? this.range.get('dateEnd')?.value
            : this.range.get('dateEnd')?.value.format('YYYY-MM-DD'),
      };
      this.dateRange.emit(data);
    }
  }

  setDisableDates = (d: Moment): boolean => {
    if (d != null) {
      let boolReturn = true;
      this.rangeDatesDisabled?.forEach((e) => {
        let [dateStart, dateEnd] = e.split('/');
        if (
          (d.isBefore(dateEnd) && d.isAfter(dateStart)) ||
          d.isSame(dateStart) ||
          d.isSame(dateEnd)
        ) {
          boolReturn = false;
        }
      });
      
      return boolReturn;
    }
    return true;
  };
}
