export const groupBy = <T, K extends keyof any>(array: T[], key: (i: T) => K) =>
  array.reduce((groups, item) => {
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);

export function groupByToArray(arrayObjects: any[], key: string) {
  return arrayObjects.reduce(function (result, currentObject) {
    const val = currentObject[key];
    result[val] = result[val] || [];
    result[val].push(currentObject);
    return result;
  }, []);
}