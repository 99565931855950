<div class="w-[5rem] flex bg-mg-accent rounded-full lg:rounded-xl divide-x divide-mg-main-blue-dark">
    <button class="flex h-11 items-center p-3 justify-center gap-2" [matTooltip]="button.tooltip"
        (click)="setAction(button.value)">
        <mg-icon *ngIf="button.icon" [iconType]="button.icon.type" [iconClass]="button.icon.class" [iconName]="button.icon.name"></mg-icon>
        <span *ngIf="button.label" class="text-white"> {{button.label}} </span>
    </button>
    <button *ngIf="actionsArray.length > 0" [matMenuTriggerFor]="tipoContenido" class="flex h-11 items-center justify-center">
        <mg-icon iconClass="text-white" iconName="arrow_drop_down"></mg-icon>
    </button>
    <mat-menu #tipoContenido="matMenu" class="mt-2 flex">
        <button *ngFor="let item of actionsArray" class="flex items-center gap-2 py-3 px-5 w-full hover:bg-mg-base-background"
            (click)="setAction(item.value)">
            <mg-icon  *ngIf="item.icon" [iconType]="item.icon.type" [iconClass]="item.icon.class" [iconName]="item.icon.name"></mg-icon>
            <span *ngIf="item.label"> {{item.label}} </span>
        </button>
    </mat-menu>
</div>