<div class="mg_alert_container">
  <div class="mg_alert">
    <div class="header_section">
      <div *ngIf="dialoConfig.type" class="icon_section">
        <div class="icon_content">
          <mg-icon class="" iconClass="mg_icon text-white" [iconName]="icon"></mg-icon>
        </div>
      </div>
      <h3 class="mga_title text-mg-main-blue-dark">{{ data.title }}</h3>
    </div>

    <div class="info_section">
      <div class="mga_message" [class]="dialoConfig.type ? 'with_icon' : ''" [innerHTML]="messageSanitized">

      </div>
    </div>

    <div *ngIf="dialoConfig.showInput" class="input_section">
      <textarea rows="4" [(ngModel)]="inputValue" [placeholder]="dialoConfig.inputPlaceholder"></textarea>
      <div *ngIf="errorMessage !== ''" class="error_zone">
        <mg-icon iconClass="text-mg-red" iconName="error"></mg-icon>
        <span>{{errorMessage}}</span>
      </div>
    </div>

    <div class="action_buttons">
      <button *ngIf="dialoConfig.showCancelButton" class="mga_btn cancel_btn" (click)="cancel()">{{dialoConfig.cancelButtonText}}</button>
      <button *ngIf="dialoConfig.showConfirmButton" class="mga_btn confirm_btn" (click)="confirm()">{{dialoConfig.confirmButtonText}}</button>
      <button *ngIf="dialoConfig.showDenyButton" class="mga_btn deny_btn" (click)="deny()">{{dialoConfig.denyButtonText}}</button>
    </div>

</div>

<div class="progress-container">
  <div class="progress-bar" ></div>
</div>

<div *ngIf="dialoConfig.timerProgressBar" class="regresive_bar" [style.width]="progressValue + '%'">
</div>
</div>