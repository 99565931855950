import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { CookiesService } from './cookies.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigEnviromentService {

  constructor(private http: HttpClient, private cookiesService: CookiesService) { }

  async loadConfig(): Promise<void> {

    try {

      const hostname = this.obtenerHostname();

      this.cookiesService.cookieNameEnv = `8y43jvbjbv${hostname}`;

      //const COOKIE_NAME = `8y43jvbjbv${hostname}`;

      const subdomain = this.obtenerSubdominio(hostname);

      const apiUrl = this.obtenerApiUrl(subdomain);

      const encryptedConfig = await this.obtenerConfiguracionCifrada(apiUrl);

      //const encryptedConfig = await this.obtenerConfiguracionCifrada(apiUrl, this.cookiesService, COOKIE_NAME);
      const decryptedConfig = await this.desencriptarConfiguracion(apiUrl, encryptedConfig);

      //console.log("Configuración descifrada correctamente:", decryptedConfig.data);
      Object.assign(environment, decryptedConfig.data);  // Asigna las propiedades descifradas al objeto environment

      //console.log("Environment final seteado:", environment);

    } catch (error) {
      //console.error('Error loading ConfigEnviromentService:', error);  // Manejo de errores opcional
    }
  }

  obtenerHostname(): string {
    const hostname = window.location.hostname;
    //console.log("Hostname detectado:", hostname);

    if (!hostname) {
      throw new Error("Hostname no encontrado");
    }

    return hostname;
  }

  obtenerSubdominio(hostname: string): string {

    // Lógica para detectar el subdominio principal
    let subdomain: string = "";
    const parts = hostname.split('.');

    if (parts.length === 1) {
      // Caso para "localhost"
      subdomain = parts[0];
    } else if (parts.length === 2) {
      // Caso para "master.localhost"
      if (parts[parts.length - 1] === 'localhost') {
        subdomain = 'localhost';
      }
    } else if (parts.length === 3) {
      // Caso para "master.dev.localhost", "master.qa.localhost" 
      if (parts[parts.length - 1] === 'localhost') {
        subdomain = 'localhost';
      } else {
        // Caso para: qa.appsmart.pe, dev.appsmart.pe
        subdomain = parts[0];
      }
    } else if (parts.length === 4) {
      // Caso para "master.dev.appsmart.pe", "master.qa.appsmart.pe"
      subdomain = parts[1];
    } else {
      throw new Error("Formato de hostname inesperado");
    }

    if (!subdomain) {
      throw new Error("Subdomain no encontrado");
    }

    //console.log("Subdominio principal detectado:", subdomain);
    return subdomain;
  }
  obtenerApiUrl(subdomain: string): string {
    let apiUrl: string;

    // Definir las URLs según el subdominio
    if (subdomain === 'localhost') {
      apiUrl = `https://localhost:44333`;
    } else if (subdomain === 'dev') {
      apiUrl = `https://api.dev.appsmart.pe`;
    } else if (subdomain === 'qa') {
      apiUrl = `https://api.qa.appsmart.pe`;
    } else {
      apiUrl = `https://api.prod.appsmart.pe`;
    }

    //console.log("URL de API determinada:", apiUrl);
    return apiUrl;
  }
  async obtenerConfiguracionCifrada(apiUrl: string): Promise<string> {
    // Usar la propiedad cookieNameEnv desde el servicio de cookies
    const cookieName = this.cookiesService.cookieNameEnv;
    if (!cookieName) {
        throw new Error("El nombre de la cookie no está definido");
    }

    // Si la cookie no se encuentra, se asignará una cadena vacía
    let encryptedConfig = this.cookiesService.getCookie(cookieName) || '';

    //console.log("Configuración cifrada obtenida de la cookie:", encryptedConfig);

    if (!encryptedConfig) {
        //console.log("Cookie no encontrada, solicitando configuración cifrada al backend...");
        const config = await this.http.get<any>(`${apiUrl}/api/configuraciones/get_encrypt_env`).toPromise();
        //console.log("Configuración cifrada recibida del backend:", config);


        if (!config) throw new Error("No se pudo obtener el environment encriptado");
        if (!config.data) throw new Error("No hay data del environment encriptado");

        //console.log("Almacenando la configuración cifrada en una cookie segura...");
        this.cookiesService.setCookie(cookieName, config.data, 1, true, 'Strict');

        //console.log("Asignando el valor de la cookie ya guardada...");

        encryptedConfig = this.cookiesService.getCookie(cookieName) || '';  // Asegurarse de manejar posibles null
    }

    return encryptedConfig;
}
  async desencriptarConfiguracion(apiUrl: string, encryptedConfig: string): Promise<any> {
    // La cookie ya debe existir, enviarla al backend para descifrarla y obtener la configuración
    //console.log("Enviando configuración cifrada al backend para desencriptar...");
    const decryptedConfig = await this.http.post<any>(`${apiUrl}/api/configuraciones/decrypt_env`, { sEncryptedData: encryptedConfig }).toPromise();
    //console.log("Respuesta de desencriptación recibida:", decryptedConfig);

    if (!decryptedConfig) throw new Error("No se pudo obtener environment desencriptado");
    if (!decryptedConfig.data) throw new Error("No hay data de environment desencriptado");

    return decryptedConfig;
  }

}
