import { getSimpleBadge, getStateBadge } from "src/app/shared/helpers/badgesBuilder";
import { setTotalHours } from "src/app/shared/helpers/timeAndHours";
import { ACTIVITY_REVISION_STATUS, ACTIVITY_STATUS, TIME_TYPE } from "./enums";
import { DateBagde, IconBadge } from "src/app/shared/interfaces/badges.interface";
import { COLORS_BADGE } from "src/app/shared/enums/global-constants";
import { AAllTareoStatus, DateAndStatusTask, HourTypeWithValidations, SEstado, getArrayWithBadgeAndIcon } from "../interfaces/task-interfaces";
import { getIcon } from "src/app/shared/helpers/iconBuilder";
import * as moment from "moment";
import { setToTitleCase } from "src/app/shared/helpers/transformText";
import { localDB } from "src/app/shared/helpers/localStorage";
import { configurations } from "src/app/shared/interfaces/configurations.interface";

export function fromTaskDBToTaskList(task: any) {
    let taskList = {
      dFecha_Fin: task.dFecha_Fin,
      dFecha_Registro: task.dFecha_Registro + "T00:00:00",
      dHora_Fin: task.dHora_Fin,
      dHora_Inicio: task.dHora_Inicio,
      nContador_Historico: 0,
      nContador_Obs: 0,
      nEstado: 1,
      sDetalle: task.sDetalle,
      sNombre_Estado: task.sNombre_Estado,
      nMinutos: task.nMinutos,
  
      sCodigo_Categoria: task.sCodigo_Categoria,
      sCodigo_Proyecto: task.sCodigo_Proyecto,
      sCodigo_Tipo_Actividad: task.sCodigo_Tipo_Actividad,
      sCodigo_Tipo_Horas: task.sCodigo_Tipo_Horas,
      sCodigo_Requerimiento: task.sCodigo_Requerimiento,
  
      sNombre_Proyecto: task.sNombre_Proyecto,
      sNombre_Tipo_Actividad: task.sNombre_Tipo_Actividad,
      sNombre_Tipo_Horas: task.sNombre_Tipo_Horas,
      sNombre_Categoria: task.sNombre_Categoria,
      sNombre_Requerimiento: task.sNombre_Requerimiento,
  
      badgeHoraTareo: getSimpleBadge(setTotalHours(Number(task.nMinutos))),
      badgeTipoHoras: getStateBadge(task.sNombre_Tipo_Horas, task.sNombre_Tipo_Horas),
      badgeEstados: getStateBadge(task.sNombre_Estado),
      badgeHFinHini: obteniendoBadgeHfinHini(
        task.dFecha_Fin,
        task.dFecha_Registro,
        task.dHora_Fin,
        task.dHora_Inicio
      ),
  
      //REPLACE
      nId: 0,
      nId_Cliente: 0,
      nId_Colaborador: 0,
      nId_Falso: 0,
      nId_Usuario: 0,
      sNombre_Cliente: "-",
      sNombre_Colaborador: "-",
      sNombre_Coordinador: "-",
      sTipo_Servicio: "-",
  
      icEditarTarea: false,
      icEliminarTarea: false,
      icDuplicarTarea: false,
      icObservarTarea: false,
      icAprobarTarea: false,
      icReenviarTarea: false,
      icObservations: false,
      icHistorico: false,
  
      sColor: "new",
      resalt: "",
      //
    };
  
    return taskList;
  }

 export const toOrder = (input: string) => {
    const uniqueNumbers = new Set(input.split('-').map(Number));
    const result = Array.from(uniqueNumbers).sort((a, b) => b - a);
    return result.join('-');
};

export const compareFilters= (a:string,b:string)=>{
  return  toOrder(a) == toOrder(b)
}


  export function obteniendoBadgeHfinHini(
    dFecha_Fin: string | null,
    dFecha_Registro: string | null,
    dHora_Fin: string | null,
    dHora_Inicio: string | null
  ) {
    if (dFecha_Fin && dHora_Fin && dHora_Inicio) {
      let [dateIni, hoursIni] = dFecha_Registro!.split("T");
      let dInicio = dateIni + "T" + dHora_Inicio;
  
      let [dateFin, hoursFin] = dFecha_Fin.split("T");
      let dFin = dateFin + "T" + dHora_Fin;
  
      return {
        data: {
          dInicio,
          dFin,
        },
        css: "text-center font-medium p-1.5 rounded-full text-xs m-auto text-mg-main-blue bg-mg-main-blue-light border-mg-main-blue",
      };
    }
    return {
      data: null,
      css: null,
    };
  }
  

  export function getIconDuplicate(bPermiso: boolean, idStatusRequest: number, typeList: string, revisar: number): IconBadge {
    let iconObj: IconBadge = {
      tooltip: 'Duplicar',
      icon: null,
      css: '',
      action: null,
    };
    if (bPermiso && idStatusRequest != ACTIVITY_STATUS.ELIMINADO && typeList == "TASK-LIST-INDIVIDUAL" && revisar == ACTIVITY_REVISION_STATUS.NO_REVISION) {
      let generalCss = 'flex justify-center items-center p-1.5 w-fit rounded-full cursor-pointer ';
      iconObj.icon = {
        name: 'content_copy',
        type: 'material'
      }
      iconObj.css = generalCss + COLORS_BADGE.orange;
    }
    return iconObj;
  }

  export function getMaxValue(array: number[]) {
    let maxNumber = array.length ? Math.max(...array) : 1;
    return maxNumber;
  }

  
export function enabledToApprove(
  idStatusRequest: number,
  idTypeHour: number,
  TaskApprovePermission: boolean,
  typeList: string,
  revisar: number,
  idRevisor: number,
  idAprobador: number[],
  canApproveAllRequirements?: boolean, // solo para LA
): IconBadge {
  let iconObj: IconBadge = {
    tooltip: 'Aprobar',
    icon: null,
    css: '',
    action: null,
  };

  if (
    Number(idTypeHour) == TIME_TYPE.HORARIO_REGULAR && 
    ![ACTIVITY_STATUS.APROBADO,ACTIVITY_STATUS.APROBADO_CLIENTE,ACTIVITY_STATUS.OBSERVADO_CLIENTE,ACTIVITY_STATUS.CORREGIDO,ACTIVITY_STATUS.ELIMINADO].includes(Number(idStatusRequest)) &&
    TaskApprovePermission &&
    (typeList == 'TEAM-HAVE-SUBORDINATE' && Number(revisar) == ACTIVITY_REVISION_STATUS.REVISION && idRevisor == Number(localDB.getItem('idUsuario')))
  ) {
    let generalCss = 'flex justify-center items-center p-1.5 w-fit rounded-full cursor-pointer ';
    iconObj.icon = {
      name: 'check_circle',
      type: 'material'
    }
    iconObj.css = generalCss + COLORS_BADGE.green;
    };

  if (
    [TIME_TYPE.COMPENSACIÓN,TIME_TYPE.EXTRAS,TIME_TYPE.RECUPERACIÓN].includes(Number(idTypeHour)) &&
    ![ACTIVITY_STATUS.APROBADO,ACTIVITY_STATUS.APROBADO_CLIENTE,ACTIVITY_STATUS.OBSERVADO_CLIENTE,ACTIVITY_STATUS.CORREGIDO,ACTIVITY_STATUS.ELIMINADO].includes(Number(idStatusRequest)) &&
    TaskApprovePermission &&
    (typeList == 'TEAM-HAVE-SUBORDINATE' && Number(revisar) == ACTIVITY_REVISION_STATUS.REVISION 
    && idRevisor == Number(localDB.getItem('idUsuario')) 
    && (idAprobador.includes(Number(localDB.getItem('idUsuario'))))
    )
  ) {
    let generalCss = 'flex justify-center items-center p-1.5 w-fit rounded-full cursor-pointer ';
    iconObj.icon = {
      name: 'check_circle',
      type: 'material'
    }
    iconObj.css = generalCss + COLORS_BADGE.green;
    };

  if (
    Number(idTypeHour) == TIME_TYPE.HORARIO_REGULAR &&
    ![ACTIVITY_STATUS.APROBADO,ACTIVITY_STATUS.APROBADO_CLIENTE,ACTIVITY_STATUS.OBSERVADO_CLIENTE,ACTIVITY_STATUS.CORREGIDO,ACTIVITY_STATUS.ELIMINADO].includes(Number(idStatusRequest)) &&
    TaskApprovePermission &&
    ((['TASK-LIST-CLIENT-COORDINATOR','TASK-LIST-ALL'].includes(typeList) && Number(revisar) == ACTIVITY_REVISION_STATUS.NO_REVISION))
  ) {
    let generalCss = 'flex justify-center items-center p-1.5 w-fit rounded-full cursor-pointer ';
    iconObj.icon = {
      name: 'check_circle',
      type: 'material'
    }
    iconObj.css = generalCss + COLORS_BADGE.green;
  };

  // SOLO PARA LÍDER ADMINISTRATIVO:
  if (
    [TIME_TYPE.HORARIO_REGULAR, TIME_TYPE.EXTRAS, TIME_TYPE.COMPENSACIÓN, TIME_TYPE.RECUPERACIÓN].includes(Number(idTypeHour)) &&
    ![ACTIVITY_STATUS.APROBADO,ACTIVITY_STATUS.APROBADO_CLIENTE,ACTIVITY_STATUS.OBSERVADO_CLIENTE,ACTIVITY_STATUS.CORREGIDO,ACTIVITY_STATUS.ELIMINADO].includes(Number(idStatusRequest)) &&
    TaskApprovePermission &&
    ((['TASK-LIST-CLIENT-COORDINATOR','TASK-LIST-ALL'].includes(typeList) && Number(revisar) == ACTIVITY_REVISION_STATUS.NO_REVISION)) 
    // && canApproveAllRequirements
  ) {
    let generalCss = 'flex justify-center items-center p-1.5 w-fit rounded-full cursor-pointer ';
    iconObj.icon = {
      name: 'check_circle',
      type: 'material'
    }
    iconObj.css = generalCss + COLORS_BADGE.green;
  };

  return iconObj;
}

export function enabledToApproveClient(
  TaskApprovePermission: boolean,
  idStatusRequest: number,
  typeList: string,
): IconBadge {
  let iconObj: IconBadge = {
    tooltip: 'Aprobar',
    icon: null,
    css: '',
    action: null,
  };

  if (
    TaskApprovePermission && 
    idStatusRequest != ACTIVITY_STATUS.APROBADO &&
    (['TASK-LIST-CLIENT-COORDINATOR'].includes(typeList))
  ){
    let generalCss = 'flex justify-center items-center p-1.5 w-fit rounded-full cursor-pointer ';
    iconObj.icon = {
      name: 'check_circle',
      type: 'material'
    }
    iconObj.css = generalCss + COLORS_BADGE.green;
  }
  return iconObj;
}

export function enabledToObserve(
  idUser: number,
  idUserTask: number,
  nCountObs: number,
  TaskObsPermission: boolean,
  idStatusRequest: number,
  typeList: string,
  revisar: number,
  idRevisor: number
): IconBadge {
  let iconObj: IconBadge = {
    tooltip: 'Observar',
    icon: null,
    css: '',
    action: null,
  };
  idStatusRequest = Number(idStatusRequest)
  let generalCss = 'flex justify-center items-center p-1.5 w-fit rounded-full cursor-pointer ';
  if (idUser != idUserTask) {
    if (
      TaskObsPermission &&
      idStatusRequest != ACTIVITY_STATUS.ELIMINADO && 
      idStatusRequest != ACTIVITY_STATUS.APROBADO_CLIENTE && 
      idStatusRequest != ACTIVITY_STATUS.CORREGIDO && 
      idStatusRequest != ACTIVITY_STATUS.PAGADO && 
      idStatusRequest != ACTIVITY_STATUS.PENDIENTE_PAGO && 
      ((typeList == 'TEAM-HAVE-SUBORDINATE' && revisar == ACTIVITY_REVISION_STATUS.REVISION && idRevisor == Number(localDB.getItem('idUsuario')))
       || 
       (typeList == 'TASK-LIST-ALL' && revisar == ACTIVITY_REVISION_STATUS.NO_REVISION) ) 
    ) {
      if (nCountObs) {
        iconObj.icon = {
          name: 'sms_failed',
          type: 'material'
        }
        iconObj.css = generalCss + COLORS_BADGE.orange;
      } else {
        iconObj.icon = {
          name: 'add_comment',
          type: 'material'
        }
        iconObj.css = generalCss + COLORS_BADGE.teal;
      }
    }
  }
  else {
    if(typeList == 'TEAM-HAVE-SUBORDINATE' && revisar == ACTIVITY_REVISION_STATUS.REVISION && idRevisor == Number(localDB.getItem('idUsuario')) && idStatusRequest == ACTIVITY_STATUS.APROBADO){
      if (nCountObs) {
        iconObj.icon = {
          name: 'sms_failed',
          type: 'material'
        }
        iconObj.css = generalCss + COLORS_BADGE.orange;
      } else {
        iconObj.icon = {
          name: 'add_comment',
          type: 'material'
        }
        iconObj.css = generalCss + COLORS_BADGE.teal;
      }
    }
  }
  return iconObj;
}


export function enabledToObserveClient(
  nCountObs: number,
  TaskObsPermission: boolean,
  typeList: string,
): IconBadge {
  let iconObj: IconBadge = {
    tooltip: 'Observar',
    icon: null,
    css: '',
    action: null,
  };
  let generalCss = 'flex justify-center items-center p-1.5 w-fit rounded-full cursor-pointer ';
  if (
    TaskObsPermission &&
    (['TASK-LIST-CLIENT-COORDINATOR'].includes(typeList))
  ) {
    if (nCountObs) {
      iconObj.icon = {
        name: 'sms_failed',
        type: 'material'
      }
      iconObj.css = generalCss + COLORS_BADGE.orange;
    } else {
      iconObj.icon = {
        name: 'add_comment',
        type: 'material'
      }
      iconObj.css = generalCss + COLORS_BADGE.teal;
    }
  }
  return iconObj;
}


export function enabledToEdit(
  idUser: number,
  idUserTask: number,
  idStatusRequest: number,
  typeList: string,
  TaskEditPermission: boolean,
  revisar: number,
  tipoTarea: number,
  idRevisor: number
): IconBadge {
  let iconObj: IconBadge = {
    tooltip: 'Editar',
    icon: null,
    css: '',
    action: null,
  };

  if(typeList == "TASK-LIST-ALL" && tipoTarea == TIME_TYPE.HORARIO_REGULAR &&
  ![ACTIVITY_STATUS.APROBADO,ACTIVITY_STATUS.APROBADO_CLIENTE,ACTIVITY_STATUS.CORREGIDO,ACTIVITY_STATUS.ELIMINADO].includes(Number(idStatusRequest))
  && revisar == ACTIVITY_REVISION_STATUS.NO_REVISION){
    iconObj = getIconEdit(TaskEditPermission);
  }

  if(typeList == 'TEAM-HAVE-SUBORDINATE' && tipoTarea == TIME_TYPE.HORARIO_REGULAR &&
  ![ACTIVITY_STATUS.APROBADO_CLIENTE,ACTIVITY_STATUS.CORREGIDO,ACTIVITY_STATUS.ELIMINADO].includes(Number(idStatusRequest))
  && Number(revisar) == ACTIVITY_REVISION_STATUS.REVISION && idRevisor == Number(localDB.getItem('idUsuario'))){
    iconObj = getIconEdit(TaskEditPermission);
  }

  if(typeList == "TASK-LIST-INDIVIDUAL" && idUser == idUserTask &&
  ![ACTIVITY_STATUS.APROBADO,ACTIVITY_STATUS.APROBADO_CLIENTE,ACTIVITY_STATUS.CORREGIDO,ACTIVITY_STATUS.PAGADO, ACTIVITY_STATUS.PENDIENTE_PAGO,ACTIVITY_STATUS.ELIMINADO].includes(Number(idStatusRequest))
  && Number(revisar) == ACTIVITY_REVISION_STATUS.NO_REVISION){
    iconObj = getIconEdit(true);
  }

  if(typeList == "TASK-LIST-INDIVIDUAL" && idUser == idUserTask &&
  tipoTarea == TIME_TYPE.AVANCE && Number(revisar) == ACTIVITY_REVISION_STATUS.NO_REVISION){
    iconObj = getIconEdit(true);
  }

  return iconObj;
}

export function getIconEdit(bPermiso: boolean): IconBadge {
  let iconObj: IconBadge = {
    tooltip: 'Editar',
    icon: null,
    css: '',
    action: null,
  };
  if (bPermiso) {
    let generalCss = 'flex justify-center items-center p-1.5 w-fit rounded-full cursor-pointer ';
    iconObj.icon = {
      name: 'edit',
      type: 'material'
    }
    iconObj.css = generalCss + COLORS_BADGE.main;
  }
  return iconObj;
}

export function cleanArray(actual: any[]) {
  var newArray = new Array();
  for (var i = 0, j = actual.length; i < j; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

export function enabledToDelete(idStatusRequest: number, TaskDeletePermission: boolean, typeList: string, revisar: number): IconBadge {
  let iconObj: IconBadge = {
    tooltip: 'Eliminar',
    icon: null,
    css: '',
    action: null,
  };
  if (
    Number(idStatusRequest) != ACTIVITY_STATUS.APROBADO &&
    Number(idStatusRequest) != ACTIVITY_STATUS.OBSERVADO_CLIENTE &&
    Number(idStatusRequest) != ACTIVITY_STATUS.APROBADO_CLIENTE &&
    Number(idStatusRequest) != ACTIVITY_STATUS.ELIMINADO && 
    Number(idStatusRequest) != ACTIVITY_STATUS.PAGADO && 
    Number(idStatusRequest) != ACTIVITY_STATUS.PENDIENTE_PAGO && 
    TaskDeletePermission && revisar == ACTIVITY_REVISION_STATUS.NO_REVISION && typeList == "TASK-LIST-INDIVIDUAL"
  ) {
    iconObj = getIconTrash(TaskDeletePermission);
  }

  return iconObj;
}


export const enableToPay = (task: any, typeList: string, permission: boolean) => {
  let generalCss = 'flex justify-center cursor-pointer items-center p-1.5 w-fit rounded-full ';
  let iconObj: IconBadge = {
    tooltip: 'Enviar a pago',
    icon: null,
    css: '',
    action: null,
  };
  if(typeList ==  'TASK-LIST-ALL' && !task.nEstado_Pago && Number(task.sEstado) === ACTIVITY_STATUS.APROBADO && Number(task.sCodigo_Tipo_Horas) === TIME_TYPE.EXTRAS){
    iconObj = {
      tooltip: 'Enviar a pago',
      icon: {
        name:'payments',
        type: 'material'
      },
      css: generalCss + COLORS_BADGE.green_dark,
      action: null,
    };
  }
    return permission ? iconObj : null
}

export function getIconTrash(bPermiso: boolean): IconBadge {
  let iconObj: IconBadge = {
    tooltip: 'Eliminar',
    icon: null,
    css: '',
    action: null,
  };
  if (bPermiso) {
    let generalCss = 'flex justify-center items-center p-1.5 w-fit rounded-full ';
    iconObj.icon = {
      name: 'delete',
      type: 'material'
    }
    iconObj.css = generalCss + COLORS_BADGE.red;
  }
  return iconObj;
}

export function enabledToResend(typeList: string): IconBadge {
  let iconObj: IconBadge = {
    tooltip: 'Eliminar',
    icon: null,
    css: '',
    action: null,
  };
  if(typeList == "TASK-LIST-INDIVIDUAL"){
    let generalCss = 'flex justify-center items-center p-1.5 w-fit rounded-full cursor-pointer ';
    iconObj.icon = {
      name: 'send',
      type: 'material'
    }
    iconObj.css = generalCss + COLORS_BADGE.green;
  }
  return iconObj;
}


export function showObserver(nCountObs: any, typeList: any, revision: number): IconBadge{
  let iconObj: IconBadge = {
    tooltip: 'Eliminar',
    icon: null,
    css: '',
    action: null,
  };

  if((typeList == 'TASK-LIST-INDIVIDUAL') && nCountObs){
    iconObj.tooltip = '';
    iconObj.icon = {
      name: 'sms_failed',
      type: 'material'
    }
    iconObj.css = "flex justify-center items-center p-1.5 w-fit rounded-full text-mg-orange-dark bg-mg-orange-light border-mg-new-orange-dark cursor-pointer"
  }

  if((typeList == 'TEAM-HAVE-SUBORDINATE') && nCountObs && revision == ACTIVITY_REVISION_STATUS.NO_REVISION){
    iconObj.tooltip = '';
    iconObj.icon = {
      name: 'sms_failed',
      type: 'material'
    }
    iconObj.css = "flex justify-center items-center p-1.5 w-fit rounded-full text-mg-orange-dark bg-mg-orange-light border-mg-new-orange-dark cursor-pointer"
  }

  return iconObj;
}


// export function getArrayWithBadgeAndIcon(
//   badge:{
//   label: string,
//   tooltip: string,
//   color?: string,
//   typeBagde?: 'badge'|'badgeDate',
//   label2?: string
//   },
//   icon:{
//     icon: string,
//     tooltip: string,
//     action: string,
//     typeAction: "modal" | "submit"
//     color?: COLORS_BADGE,
//   }): getArrayWithBadgeAndIcon[]{
//   if(!badge.typeBagde){
//     badge.typeBagde = 'badge';
//   }
//   let arrayBadge = [];

//   let gBadge = badge.color ? getSimpleBadge(badge.label, badge.tooltip, badge.color) : getStateBadge(badge.label, badge.tooltip);

//   if(badge){
//     arrayBadge.push({
//       type: badge.typeBagde,
//       css: gBadge.css,
//       firstLabel: badge.label,
//       twoLabel: badge.label2,
//       tooltip: badge.tooltip
//     });
//   }
 
//   let gIcon = null;

//   if(icon){
//     gIcon = icon.color ? getIconForColor(icon.icon, icon.tooltip, icon.color, icon.action) : getIcon(icon.icon, icon.tooltip, true, icon.action);
//     arrayBadge.push({
//       type: "icon",
//       typeAction: icon.typeAction,
//       data: gIcon,
//     });
//   }

//   return arrayBadge;
// }



// export function getIconForColor(
//   iconName: string,
//   tooltip: string,
//   color: COLORS_BADGE,
//   action?: string
// ) {
//   let generalCss =
//     "flex justify-center items-center p-1.5 w-fit rounded-full " + color;

//   let iconObj: Icon = {
//     tooltip,
//     icon: {
//       type: "material",
//       name: iconName,
//     },
//     css: generalCss,
//     action: action ? action : null,
//   };

//   return iconObj;
// }


export function getDateBagde(
  startDate: string | Date,
  EndDate: string | Date,
  showHour: boolean
): DateBagde {
  moment.locale("es");
  let badge = getSimpleBadge("");
  if(startDate && EndDate){
    return {
      data: {
        dFecha_inicio: moment(startDate).format("DD MMM YYYY"),
        dFecha_Fin: moment(EndDate).format("DD MMM YYYY"),
        dHora_inicio: showHour ? moment(startDate).format("HH:mm") : "",
        dHora_fin: showHour ? moment(EndDate).format("HH:mm") : "",
      },
      css: badge.css
    };
  }else{
    return {
      data: {
        dFecha_inicio: null,
        dFecha_Fin: null,
        dHora_inicio: null,
        dHora_fin: null,
      },
      css: badge.css
    };
  }
}


export function formatDateToTareo(inputDate: any, hoursText: string) {
  let monthArray = [
    "",
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];
  let day = inputDate.split(", ");
  let dateDay = day[1].split(" de ");
  let dateMonth =
    "/" + monthArray.findIndex((element) => element == dateDay[1]);

  return setToTitleCase(day[0]) + " " + dateDay[0] + dateMonth + " " + hoursText +", ";
}

export function convertirCadenaAJSON(cadenaOriginal: string) {
  function cadenaAObjeto(cadena: string) {
      var pares = cadena.split('&');
      var objeto = {};

      pares.forEach(function (par) {
          var partes = par.split('=');
          var clave = partes[0];
          var valor = partes[1] || null; 
          // @ts-ignore
          objeto[clave] = valor;
      });

      return objeto;
  }

  var objetoOriginal = cadenaAObjeto(cadenaOriginal);
  var esquemaDeseado = {
      "nId_Usuario": null,
      "numFilter": null,
      "textFilter": null,
      "sTipo_listado": 'TEAM-HAVE-SUBORDINATE',
      "sFilterOne": null,
      "sFilterTwo": null,
      "sFilterThree": null,
      "sFilterFour": null,
      "sFilterFive": null,
      "sFilterSix": null,
      "sFilterSeven": null,
      "sFilterNotification": null,
      "dFecha_Inicio": null,
      "dFecha_Fin": null,
      "startDateTwo": null,
      "endDateTwo": null,
      "dFecha": null,
      "bDownload": false,
      "bListado_individual": false,
      "sFilterOneFormatted": null,
      "sFilterTwoFormatted": null,
      "sFilterThreeFormatted": null,
      "sFilterFourFormatted": null,
      "sFilterFiveFormatted": null,
      "sFilterSixFormatted": null,
      "sFilterSevenFormatted": null,
      "bDownloadAsInt": 0,
      "bListadoIndividualAsInt": 0,
      "numPage": 2,
      "order": "desc",
      "sort": "dFecha_Registro",
      "records": 50
  };

  var objetoFinal = Object.assign({}, esquemaDeseado, objetoOriginal);

  var cadenaFinal = JSON.stringify(objetoFinal);

  return cadenaFinal;
}



// VALIDACIONES DAVID 😊
const getLastWorkDayCompleted = (status: DateAndStatusTask[], condition: any) => {
  for (let i = status.length - 1; i >= 0; i--) {
    if (condition(status[i])) {
      return i;
    }
  }
  return -1;
};

const substractDate = (dateInString: string, quantity: number): string => {
  let date = new Date(dateInString);
  date.setDate(date.getDate() - quantity);
  let year: number = date.getFullYear();
  let month: string = (date.getMonth() + 1).toString().padStart(2, '0');
  let day: string = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}


const filterJournals = (status: DateAndStatusTask[], lastJournalDay: string): DateAndStatusTask[] => {
  return status.filter(estado => {
    let validator: string  = lastJournalDay;

    if (estado.dRegistrationDate === lastJournalDay) {
      validator = lastJournalDay
    } else {
      validator = substractDate(lastJournalDay, 0);
    }

    return estado.dDate <= validator;
  });
};

export function setInvalidOptionInSelectComponent(scheduleType: configurations[], status: DateAndStatusTask[]): HourTypeWithValidations[] {
  let lastJournalDate: string = JSON.parse(localDB.getItem('V-HORARIO')).dFecha_Jornada;
  let filteredStates: DateAndStatusTask[] = filterJournals(status.filter((x: any) => x.nReadyMark!==0), lastJournalDate);
  let incomplete: boolean = filteredStates.some((x: DateAndStatusTask) => x.sEstado !== SEstado.Completo || x.sEstado==null);

  let firstDateIncompleteIndex: number = filteredStates.findIndex(x => x.sEstado!==SEstado.Completo);
  let lastDateIncompleteIndex: number = getLastWorkDayCompleted(filteredStates, (x: DateAndStatusTask) => x.sEstado !== SEstado.Completo);

  if (filteredStates.length > 0) {
    return scheduleType.map((tipo_hora: HourTypeWithValidations) => 
      ({
        ...tipo_hora,
        firstDate: (filteredStates[firstDateIncompleteIndex]) ? filteredStates[firstDateIncompleteIndex].dDate : "",
        lastDate: (filteredStates[lastDateIncompleteIndex]) ? filteredStates[lastDateIncompleteIndex].dDate : "",
        bIsInvalidOption: (tipo_hora.sCodigo == "1") ? false : incomplete,
        // bIsInvalidOption: false,
      })
    );
  };

  return scheduleType.map((tipo_hora: HourTypeWithValidations) => ({
    ...tipo_hora,
    firstDate: "",
    lastDate: "",
    bIsInvalidOption: false,
  }));
};


export const getDateInMomentFormatSinceToday = (days: number = 0): string => {
  const currentDate = new Date();
  if (days !== 0) currentDate.setDate(currentDate.getDate() + days);
  return moment(currentDate).format("YYYY-MM-DD");
};