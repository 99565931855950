import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { REFRESH_INDEXED } from '../indexedDB/helpers/enums.enums';
import { ConfigurationsService } from 'src/app/shared/services/configurations.service';

@Injectable({
  providedIn: 'root'
})
export class RevisionService {
  public _revisionSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public readonly revision$: Observable<any> = this._revisionSubject.asObservable();

  constructor(private _configsService: ConfigurationsService,) { }

  fetchDataFromRevision() {
    this._configsService.getConfig(REFRESH_INDEXED.ALWAYS, ["revision"]).subscribe({
      next:(revision) => {
        this.setRevision(revision);
      },
      error:(error) => {
        console.error('Error al obtener datos desde la API', error);
      }
    });
  }

  setRevision(revision: any){
    this._revisionSubject.next(revision);
  }
}
