import { Injectable } from '@angular/core';
import { SmartAlertComponent } from './smart-alert.component';
import { mgDialogConfig } from './interfaces/custom-dialog.interface';
import { Dialog, DialogRef } from '@angular/cdk/dialog';


@Injectable({
  providedIn: 'root'
})
export class SmartAlertService {
  constructor(private dialogSmAl: Dialog) {}
  dRefSmAl: DialogRef<SmartAlertComponent, any>;

  init(config: mgDialogConfig, preventOpen?: boolean) {
    if(preventOpen && this.anotherOpenDialogs()){
      return false;
    }else{
      this.dRefSmAl = this.dialogSmAl.open(SmartAlertComponent, {
        data: config,
        panelClass:  ['animate__animated','animate__bounceIn']
      });
      return this.dRefSmAl.componentInstance!.actionStatus;
    }
  }

  anotherOpenDialogs(){
    return this.dialogSmAl.openDialogs.length > 0 ? true : false
  }

  close(){
    this.dRefSmAl.close();
  }
}