import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  Observable, filter, map, mergeMap, of } from 'rxjs';
import { configurations } from '../interfaces/configurations.interface';
import { localDB } from '../helpers/localStorage';
import { environment as env } from "./../../../environments/environment";
import { GenericResponse } from 'src/app/shared/interfaces/generic.interface';
import { groupBy } from '../helpers/groupBy';
import { groupArray, groupReturnIndexedDB } from 'src/app/core/indexedDB/helpers/formattedData';
import { REFRESH_INDEXED } from 'src/app/core/indexedDB/helpers/enums.enums';
import { IndexedDBConfigResponse } from 'src/app/core/indexedDB/interfaces/indexedDB.interface';
import { IndexedDBDexieService } from 'src/app/core/indexedDB/service/indexed-db-dexie.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationsService {
  constructor(
    private http: HttpClient,
  //  private _indexedDB: IndexedDBService,
    private _indexedDB: IndexedDBDexieService,
    ) {}

  getConfig(
    refresh: null | REFRESH_INDEXED,
    configuraciones: string[],
    idUser?: string | number,
    idProyecto?: string | number,
    idRequerimiento?: string | number,
    sSlugListado?: string | number,
    nId_Colaborador?: string | number,
  ): Observable<{ [key: string]: configurations[] }> {
    let idUsuario = parseInt(localDB.getItem("idUsuario"));

    const newParams = (keys?: string[]) => {
      return {
        aConfiguraciones: keys ? keys : configuraciones,
        nId_Usuario: idUser != undefined  ? idUser : idUsuario,
        nId_Proyecto: idProyecto != undefined ? idProyecto : 0,
        nId_requerimiento: idRequerimiento != undefined ? idRequerimiento : 0,
        sSlugListado: sSlugListado != undefined ? sSlugListado : '',
        nId_Colaborador: nId_Colaborador != undefined ? nId_Colaborador: 0,
      };
    };

    const newConfig = (keys: string[]) => {
      return this.http
        .post<GenericResponse<configurations[]>>(env.api_url + "api/configuraciones/listar", newParams(keys))
        .pipe(
          mergeMap((response:any) => {
            if(response.data.length > 0){
              if(refresh != REFRESH_INDEXED.ALWAYS ){
                return this._indexedDB.addBulkData('configurations', response.data, 'config', refresh, newParams(keys)).pipe(
                  map(x=>{
                    return groupBy(response.data, (i:any) => i.sTipo_Configuracion)
                  })
                )
              }else {
                return of(groupBy(response.data, (i:any) => i.sTipo_Configuracion) )
              }
            }else{
              return of({
                ...response,
                message: 'noData'
              })
            }
          })
        );
    }

  return this._indexedDB.getAllDataByType('configurations', 'config', newParams()).pipe(
    mergeMap((resp:IndexedDBConfigResponse[]) => {
      let newConfigs:string[] = [];
      let configs:any[] = []
      resp.map((e:IndexedDBConfigResponse) => {
        if(e.data.length > 0){
          configs.push(of(groupReturnIndexedDB(e.data, 'config')));
        }else{
          newConfigs.push(e.res);
        }
      });
      newConfigs.length > 0 && configs.push(newConfig(newConfigs))
      return groupArray(configs)
    }
    ),
    filter((result) => !!result)
    )
  }
  getVersion(version: string) {
    return this.http
      .get(env.api_url + `api/configuraciones/version/${version}`);
  }  
}