import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AccessService } from '../services/access.service';

export const authGuard = () => {
  const _authService = inject(AuthService);
  const _accessService = inject(AccessService);
  const _router = inject(Router);

  if (_authService.isAuthenticated()) {
    return true;
  }else{
    _accessService.removeAccess().subscribe(()=>{
      _router.navigate(
        ['/auth/login']
      );  
    
    });

    return false;
  }

};