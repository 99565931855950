export enum PROJECTS_STATUS {
  ACTIVO = 1,
  INACTIVO = 0,
  PAUSADO = 2,
  COMPLETADO = 3,
  DESESTIMADO = 4,
}
  
export enum REQUIREMENTS_STATUS {
  ACTIVO = 1,
  INACTIVO = 0,
  PAUSADO = 2,
  COMPLETADO = 3,
  DESESTIMADO = 4,
}