<div class="h-full w-full flex flex-col gap-2 items-center justify-between relative">
    <mg-spinner [show]="spinner"></mg-spinner>
    <div class="w-full h-full bg-white rounded-3xl p-2 sm:p-8" [class.overflow-auto]="notificationsData.length">
        <div class="flex flex-col gap-4 px-2 w-full h-full">
            <div class="w-full h-full flex items-center justify-center" *ngIf="!notificationsData.length">
                <div class="flex h-full flex-col items-center justify-center"
                    *ngIf="!errorLoadingData">
                    <img class="h-2/4" src="../../../../assets/svg/utils/sin_notificaciones.svg">
                    <div class="text-base font-medium text-center text-mg-main-blue">No tienes notificaciones por leer.</div>
                </div>
                <div class="flex flex-col items-center justify-center"
                    *ngIf="errorLoadingData">
                    <img class="h-2/4" src="../../../../assets/svg/errors/error_de_carga.svg">
                    <h2 class="text-xl text-center text-mg-main-blue">Ocurrió un error al cargar los datos</h2>
                </div>
            </div>
            <ng-container *ngFor="let notificationByDate of notificationsData; let i = index">
                <div class="flex flex-col gap-1">
                    <div class="text-mg-main-blue text-xs sm:text-sm my-2">{{notificationByDate.fecha}}</div>
                    <ng-container *ngFor="let notification of notificationByDate.notificaciones">
                        <div class="flex items-start gap-2 p-3 rounded-lg"
                            [ngClass]="{'bg-fondo' : notification.nEstado_Notificacion == 1}">
                            <div class="flex items-start gap-2 w-full">
                                <div class="flex gap-2 items-center">
                                    <div class="rounded-full h-2 w-2" [class]="!notification.bIsRead ? '!bg-mg-red' : '!bg-transparent'"></div>
                                    <div class="flex items-end">
                                        <div class="w-10 h-10 rounded-full bg-cover bg-center z-1"
                                            [style]="notification.sUrlFoto ? 'background-image: url('+ notification.sUrlFoto +')' : 'background-image: url(assets/svg/profile.svg)'">
                                        </div>
                                        <div class="bg-mg-main-blue rounded-full h-6 w-6 border border-white z-2 -ml-4">
                                            <mg-icon iconClass="text-white text-sm flex items-center justify-center"
                                                [iconName]="notification.sIcono"></mg-icon>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-col gap-1">
                                    <div class="text-xs sm:text-sm break-words text-mg-gray-dark cursor-pointer"
                                        (click)="goToModule(notification)"
                                        [matTooltip]="'Ir al módulo de ' + notification.sModulo.toLowerCase()"
                                        [innerHTML]="notification.sDescripcion">
                                    </div>
                                    <div class="text-xs text-mg-gray italic">{{notification.humanizatedTime}}</div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <mat-divider *ngIf="i != notificationsData.length-1" class="py-2"></mat-divider>
                </div>
            </ng-container>
        </div>
    </div>
    <mat-paginator class="!bg-white rounded-sm !w-full text-mg-variant" [class.hidden]="!notificationsData.length"
        [pageSizeOptions]="paginatorOptions.pageSizeOptions" [pageSize]="paginatorOptions.pageSize"
        [length]="paginatorOptions.pageLength" [showFirstLastButtons]="true" [hidePageSize]="!(isScreenSM$ | async)">
    </mat-paginator>
</div>
