import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { AbstractControl, FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import {
  MatCalendarCellCssClasses,
  MatDatepicker,
  MatDatepickerModule,
} from "@angular/material/datepicker";
import { ViewChild } from "@angular/core";
import { ElementRef } from "@angular/core";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import * as moment from "moment";
import { PipesModule } from "src/app/shared/pipes/pipe-module";
import { DateFormatModule } from "../../../filters/date-format/data-format.module";
import { modeType } from "src/app/shared/interfaces/inputype.interface";
// ------------------------------------------------------------------------------
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';


@Component({
  selector: "mg-input-date",
  templateUrl: "./date.component.html",
  styleUrls: ["./date.component.scss"],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    PipesModule,
    DateFormatModule,
  ], 
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],


})
export class DateComponent implements OnInit, OnChanges {
  @Input() inputControl: FormControl | AbstractControl<any, any> = new FormControl(null);
  @Input() label: string = "";
  @Input() required: boolean = false;
  @Input() placeholder: string = "";
  @Input() mode: modeType;
  @Input() min: Date;
  @Input() max: Date;
  @Input() isLoading: boolean = false;
  @Input() withLabel: boolean = true;
  @Input() hint: boolean = false;
  @Input() initDayFilter: Date;

  //Fechas
  @Input() serie1: string[] = [];
  @Input() serie2: string[] = [];
  @Input() serie3: string[] = [];
  @Input() serie4: string[] = [];
  @Input() cssserie1: string = "";
  @Input() cssserie2: string = "";
  @Input() cssserie3: string = "";
  @Input() cssserie4: string = "";
  @Input() titleserie1: string = "";
  @Input() titleserie2: string = "";
  @Input() titleserie3: string = "";
  @Input() titleserie4: string = "";
  @Input() aDisabledDaysByDayOfWeek: number[] = [];
  @Input() aDisabledDays: string[] = [];
  @Input() aEnableDays: string[] = [];
  @Input() arrayHoursPermiso?: any;

  @ViewChild("datepickerFooter", { static: false })
  datepickerFooter: ElementRef;
  @ViewChild("datepicker", { static: false }) datepicker: MatDatepicker<any>;

  dateClass = (cellDate: any, view: any): MatCalendarCellCssClasses => {
    // Only highligh dates inside the month view.
    let serie1 = this.serie1.map((d) => moment(d));
    let serie2 = this.serie2.map((d) => moment(d));
    let serie3 = this.serie3.map((d) => moment(d));
    let serie4 = this.serie4.map((d) => moment(d));
    if (view === "month") {
      try {
        let is_serie1 = serie1.find((d) => {
          return d.isSame(cellDate);
        });
        let is_serie2 = serie2.find((d) => {
          return d.isSame(cellDate);
        });
        let is_serie3 = serie3.find((d) => {
          return d.isSame(cellDate);
        });
        let is_serie4 = serie4.find((d) => {
          return d.isSame(cellDate);
        });
        if (is_serie1 != undefined) {
          return this.cssserie1;
        }

        if (is_serie2 != undefined) {
          return this.cssserie2;
        }

        if (is_serie3 != undefined) {
          return this.cssserie3;
        }

        if (is_serie4 != undefined) {
          return this.cssserie4;
        }
        return "";
      } catch (e) {
        return "";
      }
    }else{
      return ""
    }
    
  }; 

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['mode']){
      this.initMode();
    }
  }

  ngOnInit(): void {
    this.setValidators();
    // this.dateAdapter.setLocale("es-ES");
    this.initMode();
  }

  initMode(){
    switch(this.mode){
      case 'read':
        this.inputControl!.disable();
        break;

      case 'create':
      case 'update':
        this.inputControl!.enable();
        break;
    }
  }

  setValidators() {
    if (this.required) {
      this.inputControl!.setValidators([Validators.required]);
    }
  }

  // formatDate(event: any) {
  //   let date = this.datepipe.transform(event.value, "yyyy-MM-dd", "es-ES");
  //   this.inputControl!.setValue(date);
  // }

  onOpen() {
    let titles: string[] = [
      this.titleserie1,
      this.titleserie2,
      this.titleserie3,
      this.titleserie4,
    ];
    if (!titles.every((x) => x === "")) {
      this.appendFooter();
    }
  }

  private appendFooter() {
    const matCalendar = document.getElementsByClassName(
      "mat-datepicker-content"
    )[0] as HTMLElement;
    matCalendar.appendChild(this.datepickerFooter.nativeElement);
  }

  filters = (d: Date | null): boolean => {
    if (d != null) {
      let newDate: moment.Moment = moment(d);
      return this.searchDisabled(newDate);
    }
    return true;
  };

  searchDisabled(newDate: moment.Moment) {
    if(this.aEnableDays.length){
      if(this.aEnableDays.includes(newDate.format("YYYY-MM-DD"))){
        return true;
      }else{
        return false;
      }
    }else{
      if (
        this.aDisabledDays.length &&
        this.aDisabledDays.includes(newDate.format("YYYY-MM-DD"))
      ) {
        return false;
      }
  
      if (
        this.aDisabledDaysByDayOfWeek.length &&
        this.aDisabledDaysByDayOfWeek.includes(newDate.day())
      ) {
        return false;
      }
  
      return true;
    }
  }
}

