import { Pipe, PipeTransform } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';

@Pipe({
  name: 'formTransform',
})
export class FormControlPipe implements PipeTransform {
  transform(
    value: AbstractControl | null,
    args: 'formControl' | 'formGroup' | 'formArray' = 'formControl'
  ): any {
    switch (args) {
      case 'formArray':
        return value as FormArray;
      case 'formControl':
        return value as FormControl;
      case 'formGroup':
        return value as FormGroup;
      default:
        return value as FormControl;
    }
  }
}
