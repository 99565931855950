import { CardBoard, ColumnBoard, InitBoardConfigs, ThemeOption, PatternAsset } from "./interfaces/board.interfaces";

const themeOptions: ThemeOption[] = [
    {
      id: 0,
      themeName: '',
      accentColor: '#757de3',
      themeDetails: null,
      selected: false,
      colors: {
        first: '#7084E3',
        second: '#d0d8ff',
        third: '#00004d',
        base: '#f4f6ff',
      }
    },
    {
      id: 1,
      themeName: '',
      accentColor: '#d977dd',
      themeDetails: '/assets/svg/board/details/cosito-rosado.svg',
      selected: false,
      colors: {
        first: '#D977DD',
        second: '#ecbaee',
        third: '#97569a',
        base: '#F5ECF5',
      }
    },
    {
      id: 2,
      themeName: '',
      accentColor: '#77dea2',
      themeDetails: '/assets/svg/board/details/cosito-verde.svg',
      selected: false,
      colors: {
        first: '#77DEA2',
        second: '#baeed0',
        third: '#27834d',
        base: '#E9F8EF',
      }
    },
    {
      id: 3,
      themeName: '',
      accentColor: '#f78842',
      themeDetails: '/assets/svg/board/details/cosito-naranja.svg',
      selected: false,
      colors: {
        first: '#F78842',
        second: '#fbc3a0',
        third: '#af5f2e',
        base: '#F8ECE4',
      }
    },
    {
      id: 4,
      themeName: '',
      accentColor: '#9f77eb',
      themeDetails: '/assets/svg/board/details/cosito-morado.svg',
      selected: false,
      colors: {
        first: '#9F77EB',
        second: '#baacff',
        third: '#7164B1',
        base: '#ECE8FF',
      }
    },
    {
      id: 5,
      themeName: 'Sky-blue',
      accentColor: '#81e6e2',
      themeDetails: '/assets/svg/board/details/cosito-celeste.svg',
      selected: false,
      colors: {
        first: '#81E6E2',
        second: '#b3f0ed',
        third: '#449D99',
        base: '#EDF8F8',
      }
    },
    {
      id: 6,
      themeName: 'Eduard',
      accentColor: '#EAC696',
      themeDetails: null,
      selected: false,
      colors: {
        first: '#FD8D14',
        second: '#FFE17B',
        third: '#C51605',
        base: '#CECE5A',
      }
    },
    {
      id: 7,
      themeName: 'Halloween',
      accentColor: '#EAC696',
      themeDetails: null,
      selected: false,
      colors: {
        first: '#c5e747',
        second: '#eafda7',
        third: '#ee730d',
        base: '#7029a8',
      }
    }
  ]

  let cardsCols: ColumnBoard[] = [
    {
      id: 1,
      title: 'FirstCol',
      issues: []
    },
    {
      id: 2,
      title: "SecondCol",
      issues: []
    },
    {
      id: 3,
      title: "ThirdCol",
      issues: []
    },
    {
      id: 4,
      title: "FourthCol",
      issues: []
    }
  ];

  const cards: CardBoard[] = [
    {
      id: 1,
      title: 'Bienvenida',
      icon: '',
      cardCss: [''],
      show: true
    },
    {
      id: 2,
      title: 'Marcaciones',
      icon: 'icAlarm',
      cardCss: ['h-24'],
      show: true
    },
    {
      id: 3,
      title: 'Anuncios',
      icon: 'icCampaign',
      cardCss: ['h-96'],
      show: true
    },
    {
      id: 4,
      title: 'Celebraciones',
      icon: 'icGrinStars',
      cardCss: ['h-96'],
      show: true
    },
    {
      id: 5,
      title: 'Solicitudes',
      icon: 'icOpenInNew',
      cardCss: ['h-64'],
      show: true
    },
    {
      id: 6,
      title: 'Marcas extemporáneas',
      icon: 'icHistoryToggleOff',
      cardCss: ['h-64'],
      show: true
    },
    {
      id: 7,
      title: 'Ficha sintomatologica',
      icon: 'icMedicalServices',
      cardCss: ['h-64'],
      show: true
    },
    {
      id: 8,
      title: 'Tareo',
      icon: 'icTask',
      cardCss: ['h-64'],
      show: true
    },
    {
      id: 9,
      title: 'Horas Acumuladas',
      icon: 'icGrinStars',
      cardCss: ['h-64'],
      show: true
    },
    {
      id: 10,
      title: 'Asistencia',
      icon: 'icTimer',
      cardCss: ['h-64'],
      show: true
    },
    {
      id: 11,
      title: 'Tesorería',
      icon: 'icPaid',
      cardCss: ['h-64'],
      show: true
    }
  ]

export function initGetColor(sColor: any){
  return {
    nId_Color: sColor.nId_Color,
    sColores: sColor.sColores,
    jColors: JSON.parse(sColor.sColores),
    selected: true,
  }
}

export function initGetBG(sFondo: any){
  return {
    nId_Fondo: sFondo.nId_Fondo,
    sFondo:   sFondo.sFondo,
    sIcono:   sFondo.sIcono,
    jBackgrounds: JSON.parse(sFondo.sFondo),
    selected: true,
  }
}

const auxCard: CardBoard =  {
  id: 1,
  title: 'Bienvenida',
  icon: '',
  cardCss: [''],
  show: true
}

export function findCard(idCard: number,show: boolean){
  let card: CardBoard | undefined = cards.find(({id}) => id == idCard)
  card !== undefined ? card.show = show : card = auxCard;
  return card
}

export const initConfigs: InitBoardConfigs = {
  columnsBoard: cardsCols
}


export const defaultConfigsTheme = {
  nTheme: 1,
  oTheme:{
    nId_Color: 1,
    sColores: '{"first":"#757de3","second":"#d0d8ff","third":"#00004d","base":"#f4f6ff"}',
    jColors: {
      first: '#757de3',
      second: '#d0d8ff',
      third: '#00004d',
      base: '#f4f6ff'
    },
    selected: true,
  },
  nBackground_Theme: 1,
  oBackground_Theme: {
    nId_Fondo: 1,
    sFondo:   '{"url":"","previewUrl":""}',
    sIcono:   '',
    jBackgrounds: {
      url:'',
      previewUrl:''
    },
    selected: true,
  },
  nMode_Type_Theme: 1,
  sCards_Orden: '[[1, 3, 4], [2, 5,10], [6, 7,11], [8]]',
  sUrl: '{"boardTutoShow": 1,"tareoTutoSow": 1,"requestTutoShow": 1,"assistanceTutoShow":1}'
}