import { Injectable } from '@angular/core';
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServerTime } from '../interfaces/server-time.interface';
import { environment as env } from '../../../environments/environment';
import { SOCKETS_URLS } from '../interfaces/sockets.interface';

@Injectable({
  providedIn: 'root',
})

export class ServerTimeService {
  public connection: HubConnection;
  private timeInRealTime: any;
  private serverTimeSubject = new BehaviorSubject<ServerTime | undefined>(
    undefined
  );

  constructor() {
    this.connect();
    this.validateConnection = this.validateConnection.bind(this);
    this.getConnectionStatus = this.getConnectionStatus.bind(this);
    setInterval(this.validateConnection, 60 * 1000); // every 1 minute
    setInterval(this.getConnectionStatus, 10 * 1000); // every 10 seconds
  }

  public connect() {
    this.connection = new HubConnectionBuilder()
      .withUrl(env.api_url + SOCKETS_URLS.CONFIGURATIONS)
      .build();

    this.connection.on('ServerTime', (time: ServerTime, ot: any) => {
      this.serverTimeSubject.next(time);
    });



    this.connection
      .start()
      .then(() => {
        this.requestServerTime();
      })
      .catch((err) => {});
  }

  validateConnection() {
    if (this.connection.state == HubConnectionState.Disconnected) {
      this.connect();
    }
  }

  getConnectionStatus() {}

  getServerTime(): Observable<ServerTime | undefined> {
    return this.serverTimeSubject.asObservable();
  }

  getMyTimeInRealTime(): any {
    return this.timeInRealTime;
  }

  setMyTimeInRealTime(newValue: any): void {
    this.timeInRealTime = newValue;
  }

  public requestServerTime() {
    this.connection
      .invoke('GetServerTime')
      .then(() => {})
      .catch((err) => {});
  }
}
