import { Observable, forkJoin, map, mergeMap, of } from "rxjs";
import { desencriptar, encriptar } from "src/app/shared/helpers/encryption";
import { configurations } from "src/app/shared/interfaces/configurations.interface";
import { IndexedDBTable } from "../interfaces/indexedDB.interface";
import { REFRESH_INDEXED } from "./enums.enums";
import { localDB } from "src/app/shared/helpers/localStorage";
import { environment as env } from 'src/environments/environment';
// import { idToken } from "@angular/fire/auth";

const encrypt = (str: string | number)=> encriptar(env.securityKey, `${str}`);
const decrypt = (value: string) => desencriptar(env.securityKey, value);
let idUsuario = parseInt(localDB.getItem("idUsuario"));

export const groupConfigToIndexedDB = (
  data: configurations[] | any, 
  configParams?: {
    aConfiguraciones: string[],
    nId_Usuario: string | number | null,
    nId_Proyecto: string | number | null,
    nId_requerimiento: string | number | null,
    sSlugListado: string | number,
   // nId_Colaborador: string | number | null | any,
  } | null,
  type?: 'config' | 'list' | 'endpoint', 
  refresh?: null | REFRESH_INDEXED ) => {
  let formattedData:any = {}
  
  switch(type){
    case 'config':
      formattedData = data.reduce((acc: any, item: any) => {   
        refresh = refresh ? refresh : REFRESH_INDEXED.NEVER;
        let key = configParams!.aConfiguraciones.reduce((prevKey, currentKey) => (
        currentKey.toLowerCase().split('_').filter(word => item.sTipo_Configuracion.toLowerCase().includes(word)).length 
        > prevKey.toLowerCase().split('_').filter(word => item.sTipo_Configuracion.toLowerCase().includes(word)).length) 
        ? currentKey : prevKey, configParams!.aConfiguraciones[0]);

        let slug = configParams!.sSlugListado != (undefined || null) ? configParams!.sSlugListado : null;
        let user = configParams!.nId_Usuario == idUsuario ? null : encrypt(configParams!.nId_Usuario as string);
        let pry = configParams!.nId_Proyecto != (undefined || null) ? configParams!.nId_Proyecto  /* encrypt(configParam!.idProyecto) */ : null;
        let req = configParams!.nId_requerimiento != (undefined || null) ? configParams!.nId_requerimiento /* encrypt(configParam!.idRequerimiento) */: null
        //@ts-ignore
        let col = configParams!.nId_Colaborador != (undefined || null) ? configParams!.nId_Colaborador : null
      //  let id = `${item.sCodigo}-${item.sTipo_Configuracion}`
        const encrypttedItem: configurations = {
          sCodigo: encrypt(item.sCodigo),
          sDescripcion: encrypt(item.sDescripcion) ,
          sTipo_Configuracion: encrypt(item.sTipo_Configuracion)
        };
        const relevantConfig = acc.find((config:any) => config.name === item.sTipo_Configuracion);
        if (!relevantConfig) {
          const newConfig = {
            name: item.sTipo_Configuracion,
            key,
           // id,
            refresh,
            slug,
            pry,
            req,
            user,
            col,
            data: [encrypttedItem],
          };
          acc.push(newConfig);
        } else {
          relevantConfig.data.push(encrypttedItem);
        }
        return acc;
      }, []);
      break;
    case 'list':
      break;
  }
  return formattedData;
};

export function IndexedEndpointsToDB(data: any, name: string, refresh: null | REFRESH_INDEXED){
  const newData = {
    name: name,
    refresh: refresh,
    data: data,
  };
  return [newData];
}

export const groupReturnIndexedDB = (data: configurations[] | any, type?: 'config' | 'list') => {
  
  let formattedData:any = {}
  switch (type){
    case 'config':
      formattedData = data.map((item: any) => {
        const decryptedData = item.data.map((res: configurations) => ({
          ...res,
          sCodigo: decrypt(res.sCodigo),
          sDescripcion: decrypt(res.sDescripcion),
          sTipo_Configuracion: decrypt(res.sTipo_Configuracion),
        }));
    
        return {
          ...item,
          data: decryptedData,
        };
      }).reduce((res: any, item: any) => {
        res[item.name] = item.data;
        return res;
      }, {});
      break;
    case 'list':
      formattedData = data[0].map((resp:any)=>{
        const descryptedData = JSON.parse(decrypt(resp.data))
        return descryptedData
      })
      break
  }
  return formattedData;
};

export const deleteDuplicated = (arr: any[]) => {
  const uniqueArray = Array.from(new Set(arr.map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
  return uniqueArray;
};




export const groupArray = (data: Observable<any>[]) => {
  return forkJoin(data).pipe(
    map((res) => {
      return res.reduce((acc, obj) => {
        return { ...acc, ...obj };
      }, {});
    })
  );
}