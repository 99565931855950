import { Component, HostListener, OnInit } from '@angular/core';
import { Subscription, catchError, forkJoin, of } from 'rxjs';
import { localDB } from './shared/helpers/localStorage';
import { AccessService } from './core/services/access.service';
import { AuthService } from './core/services/auth.service';
import { RolesServiceService } from './modules/roles/services/roles-service.service';
import { RequestsService } from './modules/requests/services/request.service';
import { PreferencesService } from './modules/my-board/service/preferences-service.service';
import { IndexedDBDexieService } from './core/indexedDB/service/indexed-db-dexie.service';
import { Router } from '@angular/router';
import { REFRESH_INDEXED } from './core/indexedDB/helpers/enums.enums';
import { SwPush } from '@angular/service-worker';
import { NotificationsService } from './core/notifications/services/notifications.service';
import { desencriptar } from './shared/helpers/encryption';
import { environment } from 'src/environments/environment';
import { CacheService } from './shared/services/cache.service';
import { CookiesService } from './core/services/cookies.service';
import { VERSION } from '@angular/core';
  
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit {
  title = 'smart-web-system';
  subscription: Subscription;
  acceptedDomains: string[] = ['materiagris','dev','qa']
  public readonly VAPID_PUBLIC_KEY = 'BMYaMPu1sLX5PVA_pL5XlD8LUSbrGHHXqsJDAnA0sqxNfcH4hJ2KSk8exZKg_ANJcVCvQpZk1_zX5fek4aEiPO8';

  constructor(
    private _accessService: AccessService,
    private _authService: AuthService,
    private _rolesService: RolesServiceService,
    private _requestsService: RequestsService,
    private _preferencesService: PreferencesService,
    private _router: Router,
    private _swPush: SwPush,
    private _notificationService: NotificationsService,
    private _dexieDB: IndexedDBDexieService,
    private _cacheService: CacheService,
    private _cookiesService: CookiesService,
  ) {
    this._dexieDB.initIndexDB()

    //----------
      this.firstBackendConsult();
      this.itemsNavigationExist();
  }


  ngOnInit(): void {
    console.log(`%cVersión de angular: ${VERSION.full}`, 'color: red; font-size: 20px; font-weight: bold; background-color: white;');
    this._router.events.subscribe((data: any) => {
      if (!this._authService.isAuthenticated()) {
        let url = data.url;
        if (url && !url.includes('auth') && data.url != '/' ) {
          this._accessService.redirectionUrl$.next(url)
        }else{
          this._accessService.redirectionUrl$.next(null)
        }
      }
    });
    this.subscribeToNotifications();
  }

  firstBackendConsult(){
    forkJoin([
      this._rolesService.newBackendTest().pipe(catchError(e => of({
        "data": 0,
        "isSuccess": false,
        "message": '¡Api no respondeeeeee!',
        "code": 500
      })))
    ]).subscribe((resp) => {
      if (resp.some(objeto => !objeto.isSuccess)) {
          resp.forEach(async (api) => {
            if(!api.isSuccess){
              let message = `%c${api.message}`;
              console.log(message, "color: red; font-size: 18px; background: yellow");
              if (this._authService.isAuthenticated()) {
                await this._cacheService.refreshAllValues();
                if (this._cookiesService.cookieNameEnv) {
                  this._cookiesService.deleteCookie(this._cookiesService.cookieNameEnv);
                }
               localStorage.clear();
                window.location.reload();
              }
            }
            
            // this._router.navigate(['/service-unaviable']);
          })
      } else {

      }
    });
  }

  itemsNavigationExist() {
    if(this._authService.isAuthenticated()){
      this._preferencesService.setBoardConfig().subscribe()
      if(localDB.getItem('aNavigationItem') && localDB.getItem('aSlugPermissions')){
        let aNavigationItem = JSON.parse(localDB.getItem('aNavigationItem'));
        if (aNavigationItem)
          this._accessService.setNavigationItems(aNavigationItem);
          let aSlugPermissions = JSON.parse(localDB.getItem('aSlugPermissions'));
          if (aSlugPermissions) this._accessService.setPermissions(aSlugPermissions);
      }else{
        this._accessService.routeLogin();
      }
    }

    if(localDB.getItem('token') ){
      this._requestsService.getUserSchedule().subscribe((res)=>{
        if(res.isSuccess){
          localDB.setItem('horario',JSON.stringify(res.data));
        }
      });
    }
  }


  private keyCombination: Set<string> = new Set();

  @HostListener('document:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    this.keyCombination.add(event.key);

    const isWindows = navigator.platform.includes('Win');
    const isMac = navigator.platform.includes('Mac');

    const isCtrlPressed = isWindows ? this.keyCombination.has('Control') : this.keyCombination.has('Meta');
    const isShiftPressed = this.keyCombination.has('Shift');
    const isRPressed = event.key === 'r' || event.code === 'KeyR';

    if (isCtrlPressed && isShiftPressed && isRPressed) {
      event.preventDefault();
      this.handleKeyCombination();
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyUp(event: KeyboardEvent) {
    this.keyCombination.delete(event.key);
  }

  private async handleKeyCombination() {
    await localDB.cleanCache(['horario', 'c-my-marks', 'c-red-button', 'c-extemp-marks', 'c-assistance', 'c-requests', 'celebrate-banner', 'c-tareo', 'c-requests-treasury']);

    await forkJoin([
      this._dexieDB.refreshAllByTypeRefresh(REFRESH_INDEXED.EVERY_REFRESH),
      this._dexieDB.refreshAllByTypeRefresh(REFRESH_INDEXED.EVERY_DAY)
    ]).subscribe(() => {
      location.reload();
    });
  }

  subscribeToNotifications(){
    if(environment.activePush){
    let userID = Number(localDB.getItem('idUsuario'))

    this._swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    }).then(sub => {
      const token = JSON.parse(JSON.stringify(sub));

      localDB.setItem("pushtoken", JSON.stringify(sub));
      
      if(token){
        this._notificationService.savePushToken({sEndpoint: token.endpoint, sP256dh:token.keys.p256dh, sAuth:token.keys.auth, nId_Usuario: userID ? userID : undefined}).subscribe({
          next: (resp) => {
            //TODO BIEN, NO CONFUNDIR CON UN TO DO >:c
          },
          error: e => {
            console.warn(e.error.message)
          }
        })
      }

    }).catch(err => console.error('UPS:(', err))
  }
    }



}
