import { getSimpleBadge } from "src/app/shared/helpers/badgesBuilder";

export function hoursEstimConsumFormat(response: any) {
    if (!response.data) response.data.lproject = [];
    response.data.lproject.forEach((project: any) => {
      project.lRequirements.forEach((req: any) => {
        let withSubHeader = {
          sCatName: req.sNameRequeriment,
          nCantidad_Minuto: req.nEstimateTime,

          badgeTEstimado: getSimpleBadge(
            (req.nEstimateTime / 60).toFixed(2).replace('.', ',') + ' Hrs.',
            '',
            'green'
          ),
          badgeTConsumido: getSimpleBadge(
            (req.nConsumedTime / 60).toFixed(2).replace('.', ',') + ' Hrs.',
            '',
            'green'
          ),
        };

        req.lCategories.forEach((cat: any) => {
          cat.badgeTEstimado = getSimpleBadge(
            (cat.nCantidad_Estimado / 60).toFixed(2).replace('.', ',') + ' Hrs.'
          );
          cat.badgeTConsumido = getSimpleBadge(
            (cat.nCantidad_Consumido / 60).toFixed(2).replace('.', ',') +
              ' Hrs.'
          );
        });
        req.lCategories.subHeader = true;
        req.lCategories.toFooter = withSubHeader;
      });
    });
    return response;
  }
