import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from 'src/environments/environment';
import { AccessService } from 'src/app/core/services/access.service';
import { GenericResponseList } from 'src/app/shared/interfaces/table.interface';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { transforDataTeamTaskIndicatorList } from '../helpers/mapDataTeamTaskIndicatorList';
import { ListTeamTaskIndicatorResponse } from '../interfaces/task-interfaces';
import { NameWithPic } from 'src/app/shared/interfaces/textWithPic.interface';
import {
  BadgeStatus,
  LabelWithiconBadge,
} from 'src/app/shared/interfaces/badges.interface';
import { hoursEstimConsumFormat } from '../functions/hoursEstimConsumFormater';
import { localDB } from 'src/app/shared/helpers/localStorage';
import { GenericResponse } from 'src/app/shared/interfaces/generic.interface';
import { filter } from 'rxjs/operators';
import { rankingTareoHoursFormat } from '../helpers/mapRankingTareoHours';
import { getSimpleBadge } from 'src/app/shared/helpers/badgesBuilder';

interface ProyHoursExceededDto {
  nId_Estado: number;
  sEstado: string;
  nId_Lider: number;
  Nombre_Lider: string;
  nId_Proyecto: number;
  sNombre_Proyecto: string;
  Min_Estimados: number;
  Min_Pendientes: number;
  Min_Aprobadas: number;
  nNotificacion_Status: number;
}

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  PryHoursExceeded: Observable<any | null> = of(null);
  private pryRqdata: any | null = null; 
  slug: any;

  constructor(private http: HttpClient, private _accesService: AccessService) {
    this.pryRqdata =
      new BehaviorSubject<GenericResponse<ProyHoursExceededDto> | null>(null);
    this.PryHoursExceeded = this.pryRqdata.asObservable();
    this.getPryHoursExceeded();
  }

  getChartHorasEquipo(getFilter: any, slug: string) {
    return this.http.get<any>(
      `${env.api_url}api/graficos/listarHorasEquipo?${getFilter}&sSlug=${slug}`
    );
  }
  getChartHorasProyecto(getFilter: any, slug: string) {
    return this.http.get<any>(
      `${env.api_url}api/graficos/listarHorasProyecto?${getFilter}&sSlug=${slug}`
    );
  }
  getChartApoyo(getFilter: any, slug: string) {
    return this.http.get<any>(
      `${env.api_url}api/graficos/listarApoyo?${getFilter}&sSlug=${slug}`
    );
  }
  getChartHorasRequerimiento(getFilter: any, slug: string) {
    return this.http.get<any>(
      `${env.api_url}api/graficos/listarHorasProyectoRequerimiento?${getFilter}&sSlug=${slug}`
    );
  }
  getChartRankingTareoIncompleto(getFilter: any, slug: string) {
    return this.http.get<any>(
      `${env.api_url}api/graficos/listarRankingTareoIncompleto?${getFilter}&sSlug=${slug}`
    )
    .pipe(
      map((resp) => {
        return rankingTareoHoursFormat(resp);
      })
    );
  }
  getChartEstimadasRequeridas(getFilter: any, slug: string) {
    return this.http
      .get<any>(
        `${env.api_url}api/graficos/listarPryRqEstimadasConsumidas?${getFilter}&sSlug=${slug}`
      )
      .pipe(
        map((resp) => {
          return hoursEstimConsumFormat(resp);
        })
      );
  }
  getChartHorasEstimadasComparadasAprobadasPendientes(slug: string) {
    return this.http.get<any>(
      `${env.api_url}api/graficos/listarPryRqLiderEstado?sSlug=${slug}`
    );
  }
  updatestatusnotification(nId_Proyecto: number, nId_Requerimiento: number) {
    return this.http.get<any>(
      `${env.api_url}api/graficos/updateNotificationProyLider?nId_Proyecto=${nId_Proyecto}&nId_Requerimiento=${nId_Requerimiento}` 
    );
  }

  getAll(
    records: number,
    sort: string,
    order: string,
    numPage: number,
    getInputs = ''
  ): Observable<
    GenericResponseList<{
      [key: string]:
        | NameWithPic
        | string
        | LabelWithiconBadge
        | boolean
        | BadgeStatus;
    }>
  > {
    const requestUrl = `${
      env.api_url
    }api/tareo/listar/minutos-agrupado?records=${records}&sort=${sort}&order=${order}&numPage=${
      numPage + 1
    }${getInputs}`;

    return this.http
      .get<GenericResponseList<ListTeamTaskIndicatorResponse>>(requestUrl)
      .pipe(
        map((response) => {
          return transforDataTeamTaskIndicatorList(response, records, numPage);
        })
      );
  }


  getNotificationsRankingTareo(dFecha_Inicio:string, dFecha_Fin:string){

    let requestUrl = `${env.api_url}api/notificacion/tareo-incompleto?nId_Usuario=${localDB.getItem('idUsuario')}&dFecha_Inicio=${dFecha_Inicio}&dFecha_Fin=${dFecha_Fin}`;
    return this.http.get<any>(requestUrl).pipe(
      map(response => {
        response.data.forEach((resp:any) => {
          resp.sLabelFooter = 'Total:';
          resp.badgeCantNotifications = getSimpleBadge(resp.nNumero_Notificaciones)
        })
        response.oFooter = {
          footer: true,
          search: true,
          searchPlaceholder: 'Colaboradores',
          maxHeight: true,
        }

        return response
      })
    )
  }

  getPryHoursExceeded() {
    let requestUrl = `${env.api_url}api/graficos/listarPryLiderEstadoHoursExceeeded`;

    this.http.get<GenericResponse<ProyHoursExceededDto>>(requestUrl)
      .pipe(
        map((resp) => {
          return resp;
        })
      )
      .subscribe({
        next: (response) => {
          this.pryRqdata.next(response);
        },
        error: (errorResponse) => {
          console.error('Error traer data', errorResponse);
          this.pryRqdata.next({
            isSuccess: false,
            data: null,
          });
        },
      }); 
  }

  updategetPryHoursExceeded(data: any) {
    this.pryRqdata.next(data);
  }

  sendMassiveNotification(data: any[]){
    let requestUrl = `${env.api_url}api/notificacion/tareo-incompleto`;

    return this.http.post<GenericResponse<any>>(requestUrl,data);
  }
  
}
