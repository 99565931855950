import { CommonModule } from "@angular/common";
import { Output, ViewChild } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { OnChanges } from "@angular/core";
import { SimpleChanges } from "@angular/core";
import { Component, OnInit, Input } from "@angular/core";
import { FormControl, FormGroup, NgForm } from "@angular/forms";
import * as moment from "moment";
import { Subscription, concatAll, lastValueFrom } from "rxjs";
import { localDB } from "src/app/shared/helpers/localStorage";
import { AAllTareoStatus, SEstado, StatusTask, TempTaskModal } from "../../interfaces/task-interfaces";
import { configurations } from "src/app/shared/interfaces/configurations.interface";
import { ConfigurationsService } from "src/app/shared/services/configurations.service";
import { DateComponent } from "src/app/shared/components/inputs/simple/date/date.component";
import { PipesModule } from "src/app/shared/pipes/pipe-module";
import { MinutesTaskComponent } from "src/app/shared/components/inputs/simple/minutes-task/minutes-task.component";
import { SelectComponent } from "src/app/shared/components/inputs/simple/select/select.component";
import { SelectGroupComponent } from "src/app/shared/components/inputs/simple/select-group/select-group.component";
import { TimeComponent } from "src/app/shared/components/inputs/simple/time/time.component";
import { TextareaComponent } from "src/app/shared/components/inputs/simple/textarea/textarea.component";
import { REFRESH_INDEXED } from "src/app/core/indexedDB/helpers/enums.enums";
import { SmartAlertService } from "src/app/shared/components/smart-alert/smart-alert.service";
import { setInvalidOptionInSelectComponent } from "../../helpers/functions";
import { TaskService } from "../../services/task.service";

interface GroupOption {
  groupValue: string,
  groupName: string,
  groupOptions: configurations[],
};

@Component({
  selector: "mg-input-task-composed",
  templateUrl: "./task.component.html",
  styleUrls: ["./task.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    DateComponent,
    MinutesTaskComponent,
    SelectComponent,
    SelectGroupComponent,
    TimeComponent,
    TextareaComponent,
    PipesModule
  ]
})
export class TaskComponent implements OnInit, OnChanges {

  @ViewChild("formDirective") private formDirective: NgForm;

  @Output() addTask = new EventEmitter();
  @Output() updateTask = new EventEmitter();

  @Input() mode: "create" | "read" | "update";
  @Input() isTable: boolean = false;
  @Input() task: FormGroup;
  @Input() activityEdit: TempTaskModal | null = null;
  @Input() bdData?: TempTaskModal[] = [];
  @Input() initialDate: string | moment.Moment;
  @Input() valueToMakeAction: string | undefined;;
  @Input() data: any = {
    CATEGORIA_TAREA: [],
    TIPO_ACTIVIDAD: [],
    PROYECTOS: [],
    PROYECTOS_COLABORADOR: [],
    TIPO_HORA: [],
    PROYECTOS_SELECT: [],
    REQUERIMIENTO_POR_PROYECTO: ([] = []),
    aAllTareoStatus: [],
    oTareoByStatus: {},
  };
  

  @Input() lastPrjectId?: any | string = null;
  @Input() lastReqId?: any | string = null;
  @Input() lastItemSelected?: any | string = null;


  isSinAsistencia: boolean // = false

  subscription: Subscription[] = [];
  dHora_Inicio_Hint = false;
  optionsGroups: GroupOption[] = [];

  minDate: Date = new Date("2022-07-01");
  maxDate: Date = new Date();
  idUsuario: number = parseInt(localDB.getItem("idUsuario"));

  loadingCat: boolean = false;
  loadingReq: boolean = false;

  lastDateSelect: string | Date;
  lastMinutes: number;

  TIPOS_HORA_ARRAY: configurations[] = []

  // maxValueNMinutes = (24-this.currentValidations.nRequired)*60;
  maxValueNMinutes: number = 0; //this.data.aAllTareoStatus.find(x=>x.dDate == moment().format('YYYY-MM-DD'))!=undefined?1440-this.data.aAllTareoStatus.find(x=>x.dDate == moment().format('YYYY-MM-DD')).nRequired:1440;

  currentValidations = {
    sEstado: "FALTA",
    sEstado_Horario: "HORARIO_REGULAR",
    nRequired: 480,
    nReady: 0,
    entrada: "09:00:00",
    salida: "18:00:00",
    dHora_Inicio: "00:00:00",
    dHora_Fin: "00:00:00",
    nMinutos_Permiso: 0,
    dDate: moment().format("YYYY-MM-DD"),
    nMaxMinutesRegular:
      this.data.aAllTareoStatus.find(
        (x: StatusTask) => x.dDate == moment().format("YYYY-MM-DD")
      ) == undefined
        ? 0
        : this.data.aAllTareoStatus.find(
            (x: StatusTask) => x.dDate == moment().format("YYYY-MM-DD")
          ).nMaxMinutesRegular,
    nMaxMinutesRegularEditar:
      this.data.aAllTareoStatus.find(
        (x: StatusTask) => x.dDate == moment().format("YYYY-MM-DD")
      ) == undefined
        ? 0
        : this.data.aAllTareoStatus.find(
            (x: StatusTask) => x.dDate == moment().format("YYYY-MM-DD")
          ).nMaxMinutesRegularEditar,
    nMaxMinutesExtra:
      this.data.aAllTareoStatus.find(
        (x: StatusTask) => x.dDate == moment().format("YYYY-MM-DD")
      ) == undefined
        ? 0
        : this.data.aAllTareoStatus.find(
            (x: StatusTask) => x.dDate == moment().format("YYYY-MM-DD")
          ).nMaxMinutesExtra,
    nMaxMinutesExtraEditar:
      this.data.aAllTareoStatus.find(
        (x: StatusTask) => x.dDate == moment().format("YYYY-MM-DD")
      ) == undefined
        ? 0
        : this.data.aAllTareoStatus.find(
            (x: StatusTask) => x.dDate == moment().format("YYYY-MM-DD")
          ).nMaxMinutesExtraEditar,
  };


  constructor(
    private _configurationService: ConfigurationsService,
    public _smartAlert: SmartAlertService,
    private _taskService: TaskService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    for (let property in changes) {  
      if (property == "mode") {
        this.initMode();
        this.updateMaxMinutesValue(this.task.get("sCodigo_Tipo_Horas")!.value,
        );
        if (this.mode == "update") {
          this.lastDateSelect = this.task.get("dFecha_Registro")!.value;
          this.lastMinutes = this.task.get("nMinutos")!.value;
        }
      }
      if (property == "bdData") {
        this.updateMaxMinutesValue(this.task.get("sCodigo_Tipo_Horas")!.value);
        this.getTareoStatus()

      }
      if (property == "valueToMakeAction") {
        if (this.valueToMakeAction !== undefined) {
          this.makeAction(this.valueToMakeAction);
        }
      }
    }

      //TODO: ARREGLAR CONSULTA DE TAREO STATUS


    if(changes['data'] || changes['valueToMakeAction']){
      // let currentData = changes['data'] ?  changes['data'].currentValue : this.data
      // this.TIPOS_HORA_ARRAY = currentData.TIPO_HORA
      // let oAllTareoStatus = JSON.parse(localDB.getItem('allTareoStatus'))
      // let statusTaskForValidation = oAllTareoStatus.map((x: any) => ({
      //   sEstado: x.sEstado,
      //   dDate: x.dDate,
      //   dRegistrationDate: this.currentValidations.dDate,
      //   nReadyMark: x.nReadyMark || 0,
      // }));


      // this.TIPOS_HORA_ARRAY = setInvalidOptionInSelectComponent(this.TIPOS_HORA_ARRAY, statusTaskForValidation);

    }

  }


  getTareoStatus(){
    this._taskService.getTareoStatus().subscribe(resp =>{
      let currentData = this.data
      this.TIPOS_HORA_ARRAY = currentData.TIPO_HORA
      let oAllTareoStatus = resp.aAllTareoStatus
      let statusTaskForValidation = oAllTareoStatus.map((x: any) => ({
        sEstado: x.sEstado,
        dDate: x.dDate,
        dRegistrationDate: this.currentValidations.dDate,
        nReadyMark: x.nReadyMark || 0,
      }));

      this.TIPOS_HORA_ARRAY = setInvalidOptionInSelectComponent(this.data.TIPO_HORA, statusTaskForValidation);

    })
  }


  async initMaxMinDate() {
    let confi:any = {}
    try{
     confi =  await lastValueFrom(this._configurationService.getConfig(REFRESH_INDEXED.EVERY_DAY, ['cierre-tareo']))
    }catch(err){
      console.error(err)
    }finally{
      this.minDate = new Date(confi['CIERRE_TAREO'][0].sDescripcion);
      this.maxDate = new Date();
    }
  }

  ngOnInit(): void {
    this.TIPOS_HORA_ARRAY = this.data.TIPO_HORA
    this.initMode();
    this.initMaxMinDate()
    // if(this.currentValidations ==  undefined) {
    //   this._smartAlert.init({
    //     type: 'danger',
    //     message: 'No tienes asistencia para este día',
    //     showCancelButton: false
    //   })
    // }
    this.task.get('dFecha_Registro')?.valueChanges.subscribe(res=>{
      this.getTareoStatus()
    })

  }

  initMode() {
    switch (this.mode) {
      case "create":
        this.task.markAsUntouched();
        this.initDFecha_Registro();
        this.initNMinutos_nHoras();
        this.initSCodigo_Proyecto();
        this.initSCodigo_Tipo_Actividad();
        this.init_sCodigo_Categoria();
        this.initSCodigo_Tipo_Horas();
        this.init_sCodigo_Requerimiento();
        this.task.get("dFecha_Registro")!.enable();
        this.task.get("sDetalle")!.patchValue("");
        break;
      case "read":
        this.task.get("dFecha_Registro")!.enable();
        this.initDFecha_Registro();
        this.initNMinutos_nHoras();
        this.initSCodigo_Tipo_Horas();
        break;
      case "update":
        this.currentValidations = this.data.aAllTareoStatus.find(
          (x: StatusTask) =>
            x.dDate == moment(this.task.get("dFecha_Registro")!.value).format("YYYY-MM-DD")
        );
        this.task.get("dFecha_Registro")!.disable();
        this.initDFecha_Registro();
        this.initNMinutos_nHoras();
        this.initSCodigo_Tipo_Horas();
        break;
      default:
        break;
    }
  }

  initDFecha_Registro() {
    let now: Date = new Date();
    if (this.initialDate != null) {
      now = new Date(this.initialDate.toString()+'T18:30:05.000Z');
    }

    if (this.mode == "create") {
      this.task.get("dFecha_Registro")!.patchValue(moment(now));
    }
  }

  initNMinutos_nHoras() {
    if (this.mode == "create") {
      this.task.get("nMinutos")!.patchValue(60);
      this.task.get("sCodigo_Tipo_Horas")?.patchValue('1')
    }

    this.task.get("nMinutos")!.valueChanges.subscribe((value) => {
      let nHours = Math.round((value / 60) * 100) / 100;
      this.task.get("nCantidad")!.patchValue(nHours);
    });

    this.task.get("dFecha_Registro")!.valueChanges.subscribe((value) => {
      let tipo_Horas = this.data.TIPO_HORA.find(
        (x: configurations) => x.sDescripcion == "HORARIO REGULAR"
      );
      let dDate = this.data.aAllTareoStatus.find(
        (x: StatusTask) => x.dDate == moment(value).format("YYYY-MM-DD")
      ); 

      this.currentValidations = dDate  != undefined ? dDate : {
      ...this.currentValidations, 
      sEstado_Horario: 'SIN_ASISTENCIA',
      dDate: this.task.get('dFecha_Registro')!.value.format("YYYY-MM-DD"),
      // nRequired: 0,
      // nReady: 0,
      // nEntryMark: 0,
      // sEstado: 'FALTA',
      // nMinutos_Permiso: 0,
      // sRango_Horas_Permiso: null,
      // entrada: '09:00:00',
      // salida: '18:00:00',
      // nMaxMinutesRegular: 0,
      // nMaxMinutesRegularEditar: 0,
      // nMaxMinutesExtra: 0,
      // nMaxMinutesExtraEditar: 0
      }; 
      this.isSinAsistencia =  this.currentValidations.sEstado_Horario == 'SIN_ASISTENCIA' 
      //this.validateHoraInicio();
      

      // let statusTaskForValidation = this.data.aAllTareoStatus.map((x: any) => ({
      //   sEstado: x.sEstado,
      //   dDate: x.dDate,
      //   dRegistrationDate: this.currentValidations.dDate,
      //   nReadyMark: x.nReadyMark || 0,
      // }));
      
      // this.data.TIPO_HORA = setInvalidOptionInSelectComponent(this.data.TIPO_HORA, statusTaskForValidation);
      
      if (this.task.get("sCodigo_Tipo_Horas")!.valid) {
        this.updateMaxMinutesValue(this.task.get("sCodigo_Tipo_Horas")!.value);
      }
    });

  }

  initSCodigo_Tipo_Actividad() {
    let sCodigo_Actividad = this.data.TIPO_ACTIVIDAD[0].sCodigo;
    this.task.get("sCodigo_Tipo_Actividad")!.patchValue(sCodigo_Actividad);

    this.task.get("sCodigo_Proyecto")!.valueChanges.subscribe((value) => {

      if (value == null || value == undefined) {
        if (this.lastPrjectId) {
          value = this.lastPrjectId;
          this.lastItemSelected = null;
          this.lastReqId = null;
        };
      }

      if (value != undefined && value != null) {
        let groupAndOption: {activityType: string, project: string};
        this.optionsGroups.forEach((group: GroupOption) => {
          group.groupOptions.filter((option) => {
            if (option.sCodigo.includes(value)) {
              groupAndOption = {
                activityType: group.groupValue,
                project: option.sCodigo,
              };
            }
          });
        });

        if (groupAndOption!) {
          this.task
            .get("sCodigo_Tipo_Actividad")!
            .patchValue(groupAndOption.activityType);
        }
      }
    });
  }

  initSCodigo_Proyecto() {
    this.optionsGroups = [];

    this.data.TIPO_ACTIVIDAD.forEach((x: configurations) => {
      let group: GroupOption;
      switch (x.sCodigo) {
        case "1":
          group = {
            groupValue: "1",
            groupName: "Mis Proyectos",
            groupOptions: this.data.PROYECTOS_COLABORADOR,
          };
          break;
        case "2":
          group = {
            groupValue: "2",
            groupName: "Todos",
            groupOptions: this.data.PROYECTOS_CON_ACCESO,
          };
          break;
        case "3":
          group = {
            groupValue: "3",
            groupName: "Todos",
            groupOptions: this.data.PROYECTOS_CON_ACCESO,
          };
          break;
      }
      this.optionsGroups.push(group!);
    });
    this.optionsGroups.unshift(this.optionsGroups.splice(this.optionsGroups.findIndex(item => item.groupValue === '1'), 1)[0])
    this.task.get("sCodigo_Proyecto")!.patchValue(null);
  }

  initSCodigo_Tipo_Horas() {

    let tipo_Horas = this.TIPOS_HORA_ARRAY.find(
      (x: configurations) => x.sDescripcion == "HORARIO REGULAR"
    );

    if (this.mode == "create") {
      if (tipo_Horas != undefined) {
        this.task.get("sCodigo_Tipo_Horas")!.patchValue(tipo_Horas.sCodigo);
      }
    }

    this.task.get("sCodigo_Tipo_Horas")!.valueChanges.subscribe((value) => {
      if (this.task.get("sCodigo_Tipo_Horas")!.valid) {
        this.task
          .get("sNombre_Tipo_Horas")!
          .patchValue(
            this.data.TIPO_HORA.find((x: configurations) => x.sCodigo == value).sDescripcion
          );
        this.updateMaxMinutesValue(value);
        if (this.task.get("sCodigo_Tipo_Horas")!.value != tipo_Horas?.sCodigo) {
          this.addExtraFields();
        } else {

          this.removeExtraFields();
        }
      }
    });
  }

  init_sCodigo_Requerimiento() {
    this.data.REQUERIMIENTO_POR_PROYECTO = [];

    this.task.get("sCodigo_Proyecto")!.valueChanges.subscribe((value) => {
      this.task.get("sCodigo_Requerimiento")!.patchValue(null);
      this.task.get("sCodigo_Categoria")!.patchValue(null);

      // this.task.get('sCodigo_Requerimiento').disable();
      // this.task.get('sCodigo_Categoria').disable();
      if (this.task.get("sCodigo_Proyecto")!.valid && value != null) {
        let groupAndOption: {activityType: string, project: string};
        this.loadingReq = true;
        this.loadingCat = true;
        this.optionsGroups.forEach((group: GroupOption) => {
          group.groupOptions.filter((option) => {
            if (option.sCodigo == value) {
              groupAndOption = {
                activityType: group.groupValue,
                project: option.sDescripcion,
              };
            }
          });
        });
        if (groupAndOption!) {
          this.task.get("sNombre_Proyecto")!.patchValue(groupAndOption.project);

          this._configurationService
            .getConfig(REFRESH_INDEXED.EVERY_REFRESH, 
              ["requerimientos"],
              this.idUsuario,
              parseInt(value)
            )
            .subscribe((response: any) => {
              if (response.REQUERIMIENTO != undefined) {

                this.data.REQUERIMIENTO_POR_PROYECTO = response.REQUERIMIENTO;

                this.task.get('sCodigo_Requerimiento')!.enable();
                this.loadingReq = false;

                this.task
                  .get("sCodigo_Requerimiento")!
                  .patchValue(this.data.REQUERIMIENTO_POR_PROYECTO[0].sCodigo);
                this.task
                  .get("sNombre_Requerimiento")!
                  .patchValue(
                    this.data.REQUERIMIENTO_POR_PROYECTO[0].sDescripcion
                  );

                if (
                  this.activityEdit != null &&
                  this.activityEdit.sCodigo_Proyecto ==
                    this.task.get("sCodigo_Proyecto")!.value
                ) {
                  this.task
                    .get("sCodigo_Requerimiento")!
                    .patchValue(
                      this.data.REQUERIMIENTO_POR_PROYECTO.find(
                        (x: configurations) =>
                          x.sCodigo == this.activityEdit!.sCodigo_Requerimiento
                      ).sCodigo
                    );
                  this.task
                    .get("sNombre_Requerimiento")!
                    .patchValue(
                      this.data.REQUERIMIENTO_POR_PROYECTO.find(
                        (x: configurations) =>
                          x.sCodigo == this.activityEdit!.sCodigo_Requerimiento
                      ).sDescripcion
                    );
                }
              } else {
                this.loadingReq = false;
                this.loadingCat = false;
                this.data.REQUERIMIENTO_POR_PROYECTO = [];
                this.data.CATEGORIA_TAREA = [];
              }
            });
        }
      } else {
        // this.data.REQUERIMIENTO_POR_PROYECTO = [];
      }
    });
  }

  init_sCodigo_Categoria() {
    this.data.CATEGORIA_TAREA = [];

    this.task.get("sCodigo_Requerimiento")!.valueChanges.subscribe((valueR) => {
      //this.task.get('sCodigo_Categoria').disable();

      if (this.lastReqId !== null && valueR) {
        valueR = this.lastReqId;
      }
      
      if (
        valueR != null &&
        this.task.get("sCodigo_Requerimiento")!.valid &&
        this.data.REQUERIMIENTO_POR_PROYECTO.find(
          (x: configurations) => x.sCodigo == valueR
        ) !== undefined
      ) {
        this.loadingCat = true;

        let requerimiento = this.data.REQUERIMIENTO_POR_PROYECTO.find(
          (x: configurations) => x.sCodigo == valueR
        );

        if (requerimiento != undefined) {
          this.task
            .get("sNombre_Requerimiento")!
            .patchValue(requerimiento.sDescripcion);

          this._configurationService
            .getConfig( REFRESH_INDEXED.ALWAYS,
              ["categorias"],
              this.idUsuario,
              undefined,
              parseInt(valueR)
            )
            .subscribe((response: any) => {
              if (response.CATEGORIAS != undefined) {
                this.data.CATEGORIA_TAREA = response.CATEGORIAS;
                
                let sCodigo_Categoria: string = this.data.CATEGORIA_TAREA[0].sCodigo;
                let sDescripcion_Categoria: string = this.data.CATEGORIA_TAREA[0].sDescripcion;

                if (this.lastItemSelected) {
                  sCodigo_Categoria = this.lastItemSelected;


                  if (this.data.CATEGORIA_TAREA.find((task: configurations) => task.sCodigo == sCodigo_Categoria)) {
                    sDescripcion_Categoria = this.data.CATEGORIA_TAREA.find((task: configurations) => task.sCodigo == sCodigo_Categoria).sDescripcion;
  
                    if (sDescripcion_Categoria) {
                      this.task
                      .get("sCodigo_Categoria")!
                      .patchValue(sCodigo_Categoria);
                    this.task
                      .get("sNombre_Categoria")!
                      .patchValue(sDescripcion_Categoria);                    
                    }
                  }
                }



                this.task
                  .get("sCodigo_Categoria")!
                  .patchValue(sCodigo_Categoria);

                this.task
                  .get("sNombre_Categoria")!
                  .patchValue(sDescripcion_Categoria);

                if (
                  this.activityEdit != null &&
                  this.task.get("sCodigo_Requerimiento")!.value ==
                    this.activityEdit.sCodigo_Requerimiento
                ) {
                  if (
                    this.data.CATEGORIA_TAREA.find(
                      (x: configurations) => x.sCodigo == this.activityEdit!.sCodigo_Categoria
                    ) != undefined
                  ) {
                    this.task
                      .get("sCodigo_Categoria")!
                      .patchValue(
                        this.data.CATEGORIA_TAREA.find(
                          (x: configurations) =>
                            x.sCodigo == this.activityEdit!.sCodigo_Categoria
                        ).sCodigo
                      );
                    this.task
                      .get("sNombre_Categoria")!
                      .patchValue(
                        this.data.CATEGORIA_TAREA.find(
                          (x: configurations) =>
                            x.sCodigo == this.activityEdit!.sCodigo_Categoria
                        ).sDescripcion
                      );
                  }
                }
                //this.task.get('sCodigo_Categoria').enable();
                this.loadingCat = false;
              } else {
                this.data.CATEGORIA_TAREA = [];
                this.loadingCat = false;
              }
            });
        }
      } else {
        // this.data.CATEGORIA_TAREA = [];
      }
    });

    this.task.get("sCodigo_Categoria")!.valueChanges.subscribe((value) => {
      if (
        this.task.get("sCodigo_Categoria")!.valid &&
        value != null &&
        this.data.CATEGORIA_TAREA.find((x: configurations) => x.sCodigo == value) != undefined
      ) {
        this.task
          .get("sNombre_Categoria")!
          .patchValue(
            this.data.CATEGORIA_TAREA.find((x: configurations) => x.sCodigo == value)
              .sDescripcion
          );
      }
    });
  }

  unsubscribe() {
    if (this.subscription.length > 0) {
      this.subscription.forEach((subs) => {
        subs.unsubscribe();
      });
    }
  }

  addExtraFields() {
    this.task.addControl("dHora_Inicio", new FormControl(null));
    this.task.addControl("dHora_Fin", new FormControl(null));
    this.task.addControl("dFecha_Fin", new FormControl(null));

    this.task.get("nMinutos")!.valueChanges.subscribe((value) => {
      if (value == "" && this.task.controls['dHora_Inicio'] != undefined) {
        this.task.get("dHora_Inicio")!.disable();
      }
      if (value != "" && this.task.get("dHora_Inicio")?.value) {
        this.task.get("dHora_Inicio")!.enable();
      }
    });

    this.task.get("dHora_Inicio")!.valueChanges.subscribe((value) => {
      if (value) {
        let dateOfStartActivity = this.task.get("dFecha_Registro")!.value;
        let dateTimeEndActivity = this.calculateEndHour(
          value,
          dateOfStartActivity
        );

        this.task.get("dHora_Fin")!.patchValue(dateTimeEndActivity.Time);
        this.task.get("dFecha_Fin")!.patchValue(dateTimeEndActivity.Date);
        this.dHora_Inicio_Hint = true
      } 
      else  this.dHora_Inicio_Hint = false
    });

    this.task
      .get("dFecha_Registro")!
      .valueChanges.subscribe((value) => {
        if (value && this.task.get("dHora_Inicio")) {
          if (this.task.get("dHora_Inicio")!.value) {
            let dateTimeEndActivity = this.calculateEndHour(
              this.task.get("dHora_Inicio")!.value,
              value
            );
            this.task
              .get("dHora_Fin")!
              .patchValue(dateTimeEndActivity.Time);
            this.task
              .get("dFecha_Fin")!
              .patchValue(dateTimeEndActivity.Date);
            this.task.get("dHora_Inicio")!.updateValueAndValidity();
          }
        }
      });
  }

  removeExtraFields() {
    this.task.removeControl("dHora_Inicio");
    this.task.removeControl("dHora_Fin");
    this.task.removeControl("dFecha_Fin");
  }

  calculateEndHour(inputTime: string, dateOfStartActivity: moment.Moment) {
    let [Hours, Minutes] = inputTime.split(":");

    if (Hours.length==4 && Minutes == undefined) {
      Minutes = Hours.slice(2, 4);
      Hours = Hours.slice(0, 2);
    }

    let timeOfActivity = this.task.get("nMinutos")!.value;
    let totalMinutes =
      Number(Hours) * 60 + Number(Minutes) + Number(timeOfActivity);

    let totalMinutesADay = 23 * 60 + 60;
    let EndHour = "00";
    let EndMinutes = "";
    let date = moment(dateOfStartActivity);

    let dateTimeEndActivity = {
      Time: "00:00",
      Date: "2022-06-04",
    };

    //PASS A DAY
    if (totalMinutes > totalMinutesADay) {
      EndHour = this.format2Digits(
        Math.floor((totalMinutes - totalMinutesADay) / 60)
      );
      EndMinutes = this.format2Digits((totalMinutes - totalMinutesADay) % 60);

      date.add(1, "days");
    }
    //DONT PASS A DAY
    if (totalMinutes < totalMinutesADay) {
      EndHour = this.format2Digits(Math.floor(totalMinutes / 60));
      EndMinutes = this.format2Digits(totalMinutes % 60);
    }

    //ITS A LIMIT DAY MIDNIGTH
    if (totalMinutes == totalMinutesADay) {
      EndHour = "00";
      EndMinutes = "00";
      date.add(1, "days");
    }
    dateTimeEndActivity.Time = EndHour + ":" + EndMinutes;
    dateTimeEndActivity.Date = date.format();

    return dateTimeEndActivity;
  }

  format2Digits(number: number) {
    if (number < 10) {
      return "0" + String(number);
    } else {
      return String(number);
    }
  }

  save() {
    this.task.markAllAsTouched();
    if (this.task.valid) {
      let data = this.task.getRawValue();
      this.addTask.emit(data);
      this.updateMaxMinutesValue(this.task.get("sCodigo_Tipo_Horas")!.value);
    } else {
      this.addTask.emit(null);
    }
    this.valueToMakeAction = undefined;
  }

  cancel() {
    this.updateTask.emit(null);
    this.updateMaxMinutesValue(this.task.get("sCodigo_Tipo_Horas")!.value);
    this.valueToMakeAction = undefined;
  }

  update() {
    this.task.markAllAsTouched();
    if (this.task.valid) {
      this.updateTask.emit({
        form: this.task.getRawValue(),
        beforeInfo: { date: this.lastDateSelect, minutes: this.lastMinutes },
      });
      // this.updateTask.emit(this.task.getRawValue())
      this.updateMaxMinutesValue(this.task.get("sCodigo_Tipo_Horas")!.value);
    }
    this.valueToMakeAction = undefined;
  }

  makeAction(action: string) {
    switch (action) {
      case "addToList":
        this.save();
        break;
      case "cancelUpdate":
        this.cancel();
        break;
      case "saveUpdate":
        this.update();
        break;
    }
  }

  updateMaxMinutesValue(tipo_Hora: string) {
    if (
      this.mode == "create" &&
      tipo_Hora == "1" &&
      this.currentValidations != undefined
    ) {
      this.maxValueNMinutes = this.currentValidations.nMaxMinutesRegular;
    }
    if (
      this.mode == "update" &&
      tipo_Hora == "1" &&
      this.currentValidations != undefined
    ) {
      this.maxValueNMinutes = this.currentValidations.nMaxMinutesRegularEditar;
    }
    if (
      this.mode == "create" &&
      tipo_Hora != "1" &&
      this.currentValidations != undefined
    ) {
      this.maxValueNMinutes = this.currentValidations.nMaxMinutesExtra;
    }
    if (
      this.mode == "update" &&
      tipo_Hora != "1" &&
      this.currentValidations != undefined
    ) {
      this.maxValueNMinutes = this.currentValidations.nMaxMinutesExtraEditar;
    }
  }

  keyUpEnter(e: KeyboardEvent | any) {
    e.preventDefault();
    switch (this.mode) {
      case "create":
        this.save();
        break;

      case "update":
        this.update();
        break;
    }
  }

  get MessageHint() {
    if (this.task.get("dHora_Fin")?.value && this.task.get("dFecha_Fin")?.value) {
      let day = moment(this.task.get("dFecha_Fin")!.value);
      return `Fin de la actividad a las ${
        this.task.get("dHora_Fin")!.value
      } del ${day.format("DD")} de ${day.format("MMMM")}`;
    }

    return null;
  }

  get isSmallScreen() {
    return window.screen.width < 460;
  }
}
