import * as moment from 'moment';
import { desencriptar, encriptar } from './encryption';
import { environment as env } from 'src/environments/environment';

export class localDB {
  static password: string = env.securityKey;

  static getItem(key: string): string {
    let value: string = '';
    let encryptedValue = localStorage.getItem(encriptar(this.password, key));

    if (encryptedValue) {
      value = desencriptar(this.password, encryptedValue);
    }

    return value;
  }

  static setItem(key: string, value: string): void {
    let encryptedValue = encriptar(this.password, value);
    localStorage.setItem(encriptar(this.password, key), encryptedValue);
  }

  static removeItem(key: string) {
    localStorage.removeItem(encriptar(this.password, key));
  }

  static setTemporalItem(
    key: string,
    value: string,
    secondsToExpired?: number
  ): void {
    secondsToExpired = secondsToExpired ? secondsToExpired : 180;

    let expiredDate = moment().add(secondsToExpired, 'seconds');

    let itemToSave = {
      date: expiredDate,
      value: value,
    };

    let encryptedValue = encriptar(this.password, JSON.stringify(itemToSave));
    localStorage.setItem(encriptar(this.password, key), encryptedValue);
  }

  static getTemporalItem(key: string): string {
    let value: string | null = null;
    let encryptedValue = localStorage.getItem(encriptar(this.password, key));

    let cleanItem : string = '';

    if (encryptedValue) {
      value = desencriptar(this.password, encryptedValue);
      if (value) {
        if (moment().isAfter(JSON.parse(value).date)) {
          this.removeItem(key);
        } else {
          cleanItem = JSON.parse(value).value;
        }
      }
    }
    return cleanItem;
  }

  static cleanCache(sLocalDB?: string[] | string) {
    if (typeof sLocalDB == "string") {
      localStorage.removeItem(encriptar(this.password, sLocalDB));
    }
  
    if (typeof sLocalDB == "object") {
      sLocalDB.forEach((element) => {
        localStorage.removeItem(encriptar(this.password, element));
      });
    }
  
    if (typeof sLocalDB == "undefined") {
      localStorage.clear();
    }
  }

  static printLocalStorage() {
    for (let i = 0; i < localStorage.length; i++) {
      let value;
      let key;
      
      key = localStorage.key(i);
      value = localStorage.getItem(key!);
      // console.log(`Key: ${desencriptar(env.securityKey,key!)}, Value: ${desencriptar(env.securityKey,value!)}`);
    }
  }

}
