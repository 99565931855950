import * as moment from "moment";
import { formatDateToTareo } from "./functions";
import { getSimpleBadge } from "src/app/shared/helpers/badgesBuilder";
import { getIcon } from "src/app/shared/helpers/iconBuilder";

export function rankingTareoHoursFormat(response: any){
    response.data.ranking_Tareo_Incompleto.forEach((colab: any) => {
        let faltaTotal = 0;
        let tooltip = '';
        
        colab.nCantidadFaltante.forEach((falta: any) => {
          let deudaEstado = falta.nRequerido - falta.nMinFaltantes
          let statusTareo = (deudaEstado == 0) ? `Falta Todo` : `Suma ${(falta.nMinFaltantes/60).toFixed(2)} Hrs`
          let fecha = formatDateToTareo(moment(falta.dFecha).locale('es').format('LLLL'), statusTareo);
          tooltip = tooltip + fecha;
          faltaTotal = faltaTotal + falta.nMinFaltantes;
        });
  
        colab.sLabelFooter = 'Total:'
       
        colab.nCantidadMinTotal = colab.nTotalMin = faltaTotal;

        colab.nCantidadDias = colab.nTotalDias = colab.nCantidadFaltante.length;

        colab.nHFaltante = colab.nCantidadMinTotal / 60;
        colab.nDDeuda = colab.nCantidadDias;

        colab.badgeCantHoras = getSimpleBadge(
          (colab.nHFaltante).toFixed(2).replace('.', ','),
          '',
          '',
          'max-w-32 to-image-cantHoras'
        );

        // tooltip.slice(0, -2)
        colab.badgeCantDias = getSimpleBadge(
          dayOrDays(colab.nDDeuda)!,
          '',
          '',
          'max-w-32 to-image-cantDias'
        );

        colab.sDateList = tooltip.slice(0, -2);
        colab.badgeDateList = getSimpleBadge(
          colab.sDateList,
          '',
          ((colab.nCantidadMinTotal / 60) >= 8 || colab.nCantidadDias > 1 )? 'red' : 'green',
          '!max-w-max obs_column border-2 !border-sky-500 !m-0 !whitespace-normal'
        );
        colab.icWhatasApp = getIcon(
          {
            type: 'mine',
            name: 'whatsapp_filled',
          },
          'Informar al colaborador',
          'green',
          true,
          'send-to-whatsapp',
          true
        );

      });

      response.data.ranking_Tareo_Incompleto.sort((a: any, b: any) => {
        if (a.nDDeuda > b.nDDeuda) return -1;
        if (a.nDDeuda < b.nDDeuda) return 1;


        if (a.nHFaltante > b.nHFaltante) return -1;
        if (a.nHFaltante < b.nHFaltante) return 1;


        return 0;
      });
  
  
      return response;
}

function dayOrDays(amount: number) {
    if (amount) {
      // return amount == 1 ? amount + " día" : amount + " días";
      return amount.toString();
    } else {
      return undefined;
    }
  }