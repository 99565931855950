import {
  AbstractControl,
  FormControl,
  ValidatorFn,
} from "@angular/forms";
import * as moment from "moment";
import { cleanCharactersInString } from "src/app/modules/treasury/helpers/functions";
import { localDB } from "src/app/shared/helpers/localStorage";

interface PermissionRange {
  dHora_Inicio: string;
  dHora_Fin: string;
}

interface EvaluateRange {
  hora1: moment.Moment;
  hora2: moment.Moment;
}

export class GenericValidators {

  // validación de horario colaborador para registro de tareo
  static isIntoSchedule(validators: any): ValidatorFn {
    return function (
      control: AbstractControl
    ): { [key: string]: boolean } | null {
      let res = null;

      if (control.value != null && control.value.toString().trim() != "" && control.parent) {
        let validator = validators.find(
          (x: any) =>
            x.dDate ==
            control.parent!.get("dFecha_Registro")!.value.format("YYYY-MM-DD")
        );


        let validatorNext = validators.find(
          (x: any) =>
            x.dDate ==
            moment(control.parent!.get("dFecha_Registro")!.value)
              .add(1, "days")
              .format("YYYY-MM-DD")
        );

        let validatorPrevious = validators.find(
          (x: any) =>
            x.dDate ==
            moment(control.parent!.get("dFecha_Registro")!.value)
              .subtract(1, "days")
              .format("YYYY-MM-DD")
        );

        console.warn(validator, "validator")
        console.warn(validatorNext,"validatorNext");
        console.warn(validatorPrevious,"validatorPrevious");

        let isTomorrowHoliday = JSON.parse(localDB.getItem("holidays")).find(
          (x: any) => x.sDescripcion === (validatorNext ? validatorNext.dDate : moment(control.parent!.get("dFecha_Registro")!.value.format("YYYY-MM-DD")).add(1, 'days'))
        );

        let isYesterdayHoliday = JSON.parse(localDB.getItem("holidays")).find(
          (x: any) => x.sDescripcion === (validatorPrevious ? validatorPrevious.dDate : moment(control.parent!.get("dFecha_Registro")!.value.format("YYYY-MM-DD")).subtract(1, 'days'))
        );

        let minutes = Number(control.parent!.get("nMinutos")!.value);
        console.warn(minutes,"minutes")
        let dias_laborales = localDB.getItem('horario') ? JSON.parse(localDB.getItem('horario')).aDias : [1, 2, 3, 4, 5];

        console.warn(dias_laborales,"dias_laborales")

        let isTomorrowWorkDay = dias_laborales.includes(moment(validator.dDate).add(1, 'days').day())
        let isYesterdayWorkDay = dias_laborales.includes(moment(validator.dDate).subtract(1, 'days').day())

        let miEntrada = moment(validator.dDate + ' ' + validator.entrada);
        let miSalida = validator.entrada < validator.salida ? moment(validator.dDate + ' ' + validator.salida) : moment(validator.dDate + ' ' + validator.salida).add(1, 'days');
        let initExtraHour = moment(validator.dDate + ' ' + control.value)
        let endExtraHour = moment(validator.dDate + ' ' + control.value).add(minutes, 'minutes');

        console.warn(miEntrada,"miEntrada");
        console.warn(miSalida,"miSalida");
        console.warn(initExtraHour,"initExtraHour");
        console.warn(endExtraHour,"endExtraHour");

        let entradaManiana = validatorNext != undefined ? moment(validatorNext.dDate + ' ' + validatorNext.entrada) : moment(control.parent!.get("dFecha_Registro")!.value.format("YYYY-MM-DD") + ' ' + validator.entrada).add(1, 'days');
        let salidaAyer

        if(validatorPrevious){
          if (validatorPrevious.entrada < validatorPrevious.salida) {
            salidaAyer = moment(validatorPrevious.dDate + ' ' + validatorPrevious.salida)
          } else {
            salidaAyer = moment(validatorPrevious.dDate + ' ' + validatorPrevious.salida).add(1, 'days');
          }
        }else{
            salidaAyer = moment(control.parent!.get("dFecha_Registro")!.value.format("YYYY-MM-DD") + ' ' + validator.salida).subtract(1, 'days');
        }

        console.warn(entradaManiana);
        console.warn(salidaAyer);

        // if (validatorPrevious.entrada < validatorPrevious.salida) {
        //     salidaAyer = validatorPrevious != undefined ? moment(validatorPrevious.dDate + ' ' + validatorPrevious.salida) : moment(control.parent!.get("dFecha_Registro")!.value.format("YYYY-MM-DD") + ' ' + validator.salida).subtract(1, 'days');
        // } else {
        //     salidaAyer = validatorPrevious != undefined ? moment(validatorPrevious.dDate + ' ' + validatorPrevious.salida).add(1, 'days') : moment(control.parent!.get("dFecha_Registro")!.value.format("YYYY-MM-DD") + ' ' + validator.salida).add(1, 'days');
        // }

        if (
          validator.sEstado_Horario != "FERIADO" &&
          validator.sEstado_Horario != "FUERA_HORARIO_REGULAR" && validator.sEstado_Horario != "SOLICITUD_POR_DIAS"
        ) {
          if(validator.sEstado_Horario == "SOLICITUD_POR_HORAS"){
            let allHours: moment.Moment[] = [miEntrada]

            JSON.parse(validator.sRango_Horas_Permiso).map((range: PermissionRange, key: any, arr: any[]) => {
              allHours.push(moment(validator.dDate+" "+range.dHora_Inicio));
              allHours.push(moment(validator.dDate+" "+range.dHora_Fin));
              if (Object.is(arr.length - 1, key)) {
                allHours.push(miSalida);
              }
            })

            let hoursToEvaluate: EvaluateRange[] = allHours.reduce((result: EvaluateRange[], value, index, array) => {
              if (index % 2 === 0) {
                let objetoPar: EvaluateRange = {
                  hora1: value,
                  hora2: array[index + 1]
                };
                result.push(objetoPar);
              }
              return result;
            }, []);

            hoursToEvaluate.map((hour: EvaluateRange) => {
              if(!hour.hora1.isSame(hour.hora2)){
                if (endExtraHour.isBetween(hour.hora1, hour.hora2)) {
                  res = { isIntoSchedule: true };
                }

                if (initExtraHour.isBetween(hour.hora1, hour.hora2)) {
                  res = { isIntoSchedule: true };
                }

                if (initExtraHour.isSameOrBefore(hour.hora1) && endExtraHour.isSameOrAfter(hour.hora2)) {
                  res = { isIntoSchedule: true };
                }
              }
            })

          }else{
            if (endExtraHour.isBetween(miEntrada, miSalida)) {
              res = { isIntoSchedule: true };
            }

            if (initExtraHour.isBetween(miEntrada, miSalida)) {
              res = { isIntoSchedule: true };
            }

            if (initExtraHour.isSameOrBefore(miEntrada) && endExtraHour.isSameOrAfter(miSalida)) {
              res = { isIntoSchedule: true };
            }
          }
        }

        if (isTomorrowWorkDay && !isTomorrowHoliday && endExtraHour.isAfter(entradaManiana)) {
          res = { isIntoSchedule: true };
        }

        if (isYesterdayWorkDay && !isYesterdayHoliday && initExtraHour.isBefore(salidaAyer)) {
          res = { isIntoSchedule: true };
        }
      }
      return res;
    };
  }


  /* Validar que todos los caracteres sean solo números o caracteres de alfabeto */
  static alphanumeric(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value != null && control.value.toString().trim() != "") {
      if (/^[0-9a-zA-Z]+$/.test(control.value)) return null;
      else return { alphanumeric: true };
    } else {
      return null;
    }
  }

  /* Validar que contenga al menos una numero */
  static hasNumber(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value != null && control.value.toString().trim() != "") {
      if (/\d/.test(control.value)) return null;
      else return { hasNumber: true };
    } else {
      return null;
    }
  }

  /* Validar que contenga al menos una letra mayuscula */
  static hasCapitalCase(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value != null && control.value.toString().trim() != "") {
      if (/[A-Z]/.test(control.value)) return null;
      else return { hasCapitalCase: true };
    } else {
      return null;
    }
  }

  /* Validar que contenga al menos una letra miniscula */
  static hasSmallCase(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value != null && control.value.toString().trim() != "") {
      if (/[a-z]/.test(control.value)) return null;
      else return { hasSmallCase: true };
    } else {
      return null;
    }
  }

  /* Validar que contenga al menos un caracter especial */
  static hasSpecialCharacters(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value != null && control.value.toString().trim() != "") {
      if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(control.value))
        return null;
      else return { hasSpecialCharacters: true };
    } else {
      return null;
    }
  }

  /* Validar que todos los caracteres sean solo números o caracteres de alfabeto */
  static document(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value != null && control.value.toString().trim() != "") {
      if (/^[a-zA-Z0-9]{7,12}$/.test(control.value)) return null;
      else return { document: true };
    } else {
      return null;
    }
  }

  /* Validar que todos los caracteres sean solo números o caracteres de alfabeto */
  static numeric(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value != null && control.value.toString().trim() != "") {
      if (/^[0-9.]+$/.test(control.value)) return null;
      else return { numeric: true };
    } else {
      return null;
    }
  }

  /* Validar que todos los caracteres sean solo números o caracteres de alfabeto */
  static numericDecimal(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value != null && control.value.toString().trim() != "") {
      if (/^[0-9]*[.]?[0-9]{1,2}$/.test(control.value)) return null;
      else return { numericDecimal: true };
    } else {
      return null;
    }
  }

  /* Validar que todos los caracteres sean solo números o caracteres de alfabeto */
  static numericMoneyDecimal(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value != null && control.value.toString().trim() != "") {
      let cleanValue: string = cleanCharactersInString(control.value);

      let isInteger: RegExp = /^\d+$/;
      let isNumberWithMilesSeparator: RegExp = /^\d{1,3}\.\d{3}\,\d{2}$/;
      let isNmberWithoutMilesSeparator: RegExp = /^\d{1,8}\,\d{2}$/;
      
      if (
        isNumberWithMilesSeparator.test(cleanValue) ||
        isNmberWithoutMilesSeparator.test(cleanValue) ||
        isInteger.test(cleanValue)) return null;

      return { numericMoneyDecimal: true };
    } else {
      return null;
    }
  }

  static onlyNumbersGreatersOrEqualMoney(numberGreater: number): ValidatorFn {
    return function (
      control: AbstractControl
    ): { [key: string]: boolean } | null {
      if (control.value != null && control.value.toString().trim() != "") {
        let cleanValue: string = cleanCharactersInString(control.value);
        let valor: string = cleanValue.replaceAll(".","").replaceAll(",",".");

        if (Number(valor) >= numberGreater) return null;
        else return { IsNotNumberGreaterOrEqualMoney: true };
      } else {
        return null;
      }
    };
  }

  /* Valida un email */
  static emailValidation(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value != null && control.value.toString().trim() != "") {
      if (
        /^[^@]+@[^@]+\.[a-zA-Z]{2,4}$/.test(
          control.value
        )
      )
        return null;
      else return { emailValidation: true };
    } else {
      return null;
    }
  }

  /* Valida un email del dominio materiagris.pe */
  static emailMateriaGrisValidation(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value != null && control.value.toString().trim() != "") {
      if (/^[a-z.]+@materiagris\.pe$/.test(control.value)) return null;
      else return { emailMateriaGrisValidation: true };
    } else {
      return null;
    }
  }

  /* Validar que todos los caracteres sean solo números */
  static onlyNumberValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value != null && control.value.toString().trim() != "") {
      if (/^[0-9]+$/.test(control.value)) return null;
      else return { IsNotNumber: true };
    } else {
      return null;
    }
  }

  /* Validar que todos los caracteres sean solo números mayores a 0 */
  static onlyNumbersGreaterZero(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value != null && control.value.toString().trim() != "") {
      if (/^[0-9]+$/.test(control.value) && control.value > 0) return null;
      else return { IsNotNumberGreaterZero: true };
    } else {
      return null;
    }
  }

  /* Validar que todos los caracteres sean caracteres de alfabeto y espacios */
  static onlyTextOrSpaceValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value != null && control.value.toString().trim() != "") {
      if (/^[A-ZÁÉÍÓÚÄËÏÖÜÑa-záéíóúäëïöüñ ]+$/.test(control.value)) return null;
      else return { IsNotTextOrSpace: true };
    } else {
      return null;
    }
  }

  /* Validar que no dos contraseñas coincidan */
  static passwordMatch(
    controlPassword: AbstractControl,
    controlConfirmPassword: AbstractControl
  ) {
    if (
      controlPassword.value != null &&
      controlPassword.value.toString().trim() != "" &&
      controlConfirmPassword.value != null &&
      controlConfirmPassword.value.toString().trim() != ""
    ) {
      if (controlPassword.value != controlConfirmPassword.value)
        controlConfirmPassword.setErrors({ NoPasswordMatch: true });
      else controlConfirmPassword.setErrors(null);
    }
  }

  /* Validar el tipo de archivo */
  static validateAllowedFileType(arrayTypes: any[]): ValidatorFn {
    return function (
      control: AbstractControl
    ): { [key: string]: boolean } | null {
      if (control.value) {
        let uploadFileIsAllowed = arrayTypes.includes(control.value.type);
        if (!uploadFileIsAllowed) {
          return { validate_file_type: true };
        }
      }
      return null;
    };
  }

  /* Validar el tamaño del archivo */
  static validateSizeFile(sizeMaxKB: number): ValidatorFn {
    return function (
      control: AbstractControl
    ): { [key: string]: boolean } | null {
      if (control.value) {
        let fileSize = parseInt(control.value.size);
        let fileSizeMb = fileSize / 1024;
        if (fileSizeMb > sizeMaxKB) {
          return { validate_file_size: true };
        }
      }
      return null;
    };
  }

  // Validar la cantidad de caractares mínimas
  static validateMinLength(number: number): ValidatorFn {
    return function (control: AbstractControl): {
      [key: string]: boolean
    } | null {
      if (control.value) {
        let str = control.value.toString();
        if (str.length < parseInt(String(number)))
          return { validate_min_length: true };
      }
      return null;
    }
  }

    // Validar la cantidad de caractares máxima
    static validateMaxLength(number: number): ValidatorFn {
      return function (control: AbstractControl): {
        [key: string]: boolean
      } | null {
        if (control.value) {
          let str = control.value.toString();
          if (str.length > parseInt(String(number)))
            return { validate_max_length: true };
        }
        return null;
      }
    }

    static forcedMinTime(forcedMinTime: number): ValidatorFn {
      return function (control: AbstractControl): {
        [key: string]: boolean
      } | null {
        if (forcedMinTime>0) {
            return { wrong_time: true };
        }
        return null;
      }
    }

  /* Validar que el numero sea mayor a un número específico */
  static onlyNumbersGreatersOrEqual(numberGreater: number): ValidatorFn {
    return function (
      control: AbstractControl
    ): { [key: string]: boolean } | null {
      if (control.value != null && control.value.toString().trim() != "") {
        if (Number(control.value) >= numberGreater) return null;
        else return { IsNotNumberGreaterOrEqual: true };
      } else {
        return null;
      }
    };
  }

  /* Validar que el numero sea menor a un número específico */
  static onlyNumbersLessOrEqual(numberLess: number): ValidatorFn {
    return function (
      control: AbstractControl
    ): { [key: string]: boolean } | null {
      if (control.value != null && control.value.toString().trim() != "") {
        if (Number(control.value) <= numberLess) return null;
        else return { IsNotNumberLessOrEqual: true };
      } else {
        return null;
      }
    };
  }
  // Validar espacios en blancos
  static notOnlyWhitespaceValidator(control: FormControl) {
    const value = control.value;
    const isWhitespace = (value ?? "").trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  /* Validar horas fuera del horario laboral */
  static isOutSchedule(schedule: any): ValidatorFn {
    return function (
      control: AbstractControl
    ): { [key: string]: boolean } | null {
      let return0 = null;
      if (control.value) {
        let controlValue = moment(
          schedule.fecha.format("YYYY-MM-DD") + "T" + control.value
        );
        if (schedule.dosDias) {
          let controlValueSplit = parseInt(control.value.split(":").join(""));
          let out = parseInt(
            schedule.salida.format("HH:mm").split(":").join("")
          );
          if (controlValueSplit >= 0 && controlValueSplit <= out) {
            controlValue = controlValue.add(1, "day");
          }
        }
        if (controlValue.isBefore(schedule.entrada)) {
          return0 = { isOutSchedule: true };
        }
        if (controlValue.isSameOrAfter(schedule.salida)) {
          return0 = { isOutSchedule: true };
        }
      }
      return return0;
    };
  }

  /* Validar que la hora no este dentro de un rango */
  static isIntoHourRange(rangeHours: any): ValidatorFn {
    return function (
      control: AbstractControl
    ): { [key: string]: boolean } | null {
      let return0 = null;
      if (control.value && rangeHours.length) {
        rangeHours.map((hour: any) => {
          let controlValue = moment(
            hour.fecha.format("YYYY-MM-DD") + "T" + control.value
          );
          if (hour.dosDias) {
            let controlValueSplit = parseInt(control.value.split(":").join(""));
            let out = parseInt(hour.fin.format("HH:mm").split(":").join(""));
            if (controlValueSplit >= 0 && controlValueSplit <= out) {
              controlValue = controlValue.add(1, "day");
            }
          }
          if (
            controlValue.isSameOrAfter(hour.inicio) &&
            controlValue.isBefore(hour.fin)
          ) {
            return0 = { isIntoHourRange: true };
          }
        });
      }
      return return0;
    };
  }

  /* Validar que la hora sea mayor a la hora + cantidad de minutos */
  static minimumHour(
    cantMin: number,
    dateParent: AbstractControl | null
  ): ValidatorFn {
    return function (
      control: AbstractControl
    ): { [key: string]: boolean } | null {
      let return0 = null;
      if (control.value && dateParent!.value) {
        let date = dateParent!.value.format('YYYY-MM-DD');
        let controlValue = moment(
          date + ' ' + control.value,
          'YYYY-MM-DD HH:mm'
        );
        let start = moment().add(cantMin, 'minutes');
        if (controlValue.isBefore(start)) {
          return0 = { minimumHour: true };
        }
      }
      return return0;
    };
  }

  /* Validar que la opcion elegida exista en el array de opciones */
  static optionExistInArray(aOptions: any[]): ValidatorFn {
    return function (
      control: AbstractControl
    ): { [key: string]: boolean } | null {
      if (control.value != null && control.value.toString().trim() != "") {
        if (aOptions.includes(control.value)) return null;
        else return { optionInvalid: true };
      } else {
        return null;
      }
    };
  }

  /* Validar que un array no este vacio */
  static emptyArrayOptions(arrayOptions: any[]): ValidatorFn {
    return function (
      control: AbstractControl
    ): { [key: string]: boolean } | null {
      if (!arrayOptions.length) {
        return { isEmptyArrayOptions: true };
      } else {
        return null;
      }
    };
  }

  /* Validar que todos los caracteres sean solo números o caracteres de alfabeto */
  static defaultDescription(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    if (control.value != null && control.value.toString().trim() != "") {
      if (/^[0-9 a-zA-Z.ÑñÁÉÍÓÚáéíóúÄËÏÖÜäëïöü]+$/.test(control.value))
        return null;
      else return { defaultDescription: true };
    } else {
      return null;
    }
  }
}
