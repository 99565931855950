import { FooterDataFormat } from "../interfaces/interfaces";

export const formatMoneyToString = (value: number | undefined): string => {
  if (!value) return '0,00';

  const formattedNumber = new Intl.NumberFormat('es-ES', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

  return formattedNumber;
};


export const formatMoneyToNumber = (value: string | undefined): number => {
  if (!value) return 0;
  if (value.length === 0) return 0;

  let valor: number = parseFloat(
    value.replaceAll('.', '').replaceAll(',', '.')
  );

  if (isNaN(valor)) return 0;
  return valor;
};


export const cleanCharacters = (event: Event): void => {
  const input = event.target as HTMLInputElement;
  const regex = /^[0-9.,]*$/;
  if (!regex.test(input.value)) {
    input.value = numbersOnly(input.value);
  }
}


export const cleanCharactersInString = (value: string): string => {
  const regex = /^[0-9.,]*$/;
  if (!regex.test(value)) {
    value = numbersOnly(value);
  }
  return value;
}


const numbersOnly = (cadena: string): string => {
  return cadena.replace(/[^0-9.,]/g, '');
}


export const sumByGrouping = (arryToBeSummarized: FooterDataFormat[]) => {
    if (arryToBeSummarized.length === 0) return '0,00';
  
    arryToBeSummarized = arryToBeSummarized.sort((a, b) => a.nTipoMoneda - b.nTipoMoneda);
    const sumasPorIcono: any = {};
  
    arryToBeSummarized.map((item) => {
      const { nMonto_Depositado, sIcono } = item;
      const monto = parseFloat(nMonto_Depositado);
      if (!sumasPorIcono[sIcono]) {
            sumasPorIcono[sIcono] = 0;
        }
        sumasPorIcono[sIcono] += monto;
      })
      
      let array = Object.entries(sumasPorIcono).map(([key, value]) => `${key} ${(value as number)!.toFixed(2)}`);
    return array.join(' - ').replaceAll(".", ",");
  }