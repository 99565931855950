<div #card class="flex justify-center items-center relative carousel rounded-3xl shadow-lg" (mouseenter)="stopInterval()"
    (mouseleave)="startInterval()"
    (touchstart)="onTouchStart($event)" (touchend)="onTouchEnd($event)">
    <div #slide class="slide" [ngStyle]="{'transform': 'translateX(-' + (index * 100) + '%)'}">
        <div *ngFor="let item of slides" class="carousel-item" [style.background-color]="item.colorDominator">
            <img [src]="item.media"/>
        </div>
    </div>

    <div class="flex absolute w-full h-full p-4 rounded-xl flex-col inset-y-0">

        <div  *ngIf="slides.length > 1" class="flex justify-between items-center h-full">

            <button class="w-6 h-6 p-1 bg-mg-accent-base rounded-full shadow-lg" type="button"
                    (click)="indexPrevious()" matTooltip="Anterior" matTooltipPosition="below">
                <mg-icon class="" iconClass="arrow text-mg-accent" iconName="arrow_back_ios"></mg-icon>
            </button>
            <button class="w-6 h-6 p-1 bg-mg-accent-base rounded-full shadow-lg" type="button"
                    (click)="indexNext()" matTooltip="Siguiente" matTooltipPosition="below">
                <mg-icon class="" iconClass="arrow text-mg-accent" iconName="arrow_forward_ios"></mg-icon>
            </button>

        </div>

        <div class="w-full flex justify-center">
            <div *ngIf="slides.length > 1"
                class="flex justify-center items-center p-1 bg-mg-accent-base rounded-xl shadow-lg dot-list w-fit">
                <button class="dot" type="button" *ngFor="let item of slides; let i = index" (click)="goTo(i)">
                    <mg-icon [iconClass]="i === indexToDots ? 'text-mg-accent dot' : 'text-mg-accent-light dot'" iconName="circle"></mg-icon>
                </button>
            </div>
        </div>
    </div>
</div>
