




<div [class]="' relative z-50  w-[500px] max-w-[500px]  ' + position ">
<div [class]="' rounded-lg flex w-full flex-col gap-2 content-between ' + theme.primary">

    <div class="w-full flex gap-2 items-center p-2">
        <div [class]="' w-[60px] h-[50px] rounded-full  flex items-center justify-center ' + theme.secondary"> 
            <mg-icon iconClass="text-3xl h-fit w-fit "  [iconName]="theme.icon"></mg-icon>
        </div>
        <div class="w-full">
            <div class="flex w-full items-center  gap-1">
                <div class=" w-full flex flex-col gap-1">
                    <h2 class="font-semibold w-full text-xl">{{label ? label : theme.title}}</h2>
                    <div class="w-full" [innerHTML]="body" ></div>
                </div>
                <button class=" self-start" (click)="closeToast()"><mg-icon [iconClass]="'font-bold  !text-sm'" iconName="close"></mg-icon></button>
             </div>
             <!-- ACTIONS -->
             <div  *ngIf="labelCheck || toastData.redirectionButtom" class="pt-1 w-full flex justify-between items-center">
                <!-- CHECK -->
                <div class="text-sm  flex gap-2 items-center">
                    <input *ngIf="labelCheck"  type="checkbox" class="toast_check" [(ngModel)]="checkbox" (ngModelChange)="handleCheck($event)"
                    [id]="'toast_check_'+typeToast" >
                    <span>{{labelCheck}}</span>
                </div>
                <!-- BUTTONS -->
                <div *ngIf="toastData.redirectionButtom">
                    <button [matTooltip]="'Ir a módulo'" (click)="showRedirect ? handleRedirect() : null"  
                    [class]="'w-[30px] h-[30px] transition-all rounded-full hover:rotate-12 active:scale-75 ' + theme.secondary"
                    [ngClass]="showRedirect ? 'opacity-100' : 'opacity-50 cursor-not-allowed'">
                        <mg-icon iconClass=" h-fit w-fit text-sm "  [iconName]="'arrow_outward'"></mg-icon>
                    </button>
                </div>
             </div>
        </div>
    </div>


<!-- PROGRESS BAR -->
     <div
     id="progress_bar"
     [class]=" 'h-1 rounded-r-lg transition-all    ' +
      theme.secondary "
     [style.width.%]="progressBar"
     *ngIf="toastData.timer"
   ></div>

</div>
<!-- BG SHADOW -->
    <div [class]=" 'w-full h-full rounded-lg absolute -left-3 top-0 -z-50 ' + theme.secondary"></div>
</div>

