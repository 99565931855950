

export enum ASSISTANCE_STATUS {
    SIN_MARCA = 1,
    PUNTUAL = 2,
    TARDANZA = 3,
    FALTA = 4,
    PERMISO = 5,
    VACACIONES = 6,
    FERIADO = 7,
    FUERA_DE_HORARIO = 8,
    EN_TOLERANCIA = 9,
    LICENCIA = 10,
    P_CONFIANZA = 11
}
