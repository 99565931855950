
export enum COLORS_BADGE {
  main = "text-mg-main-blue bg-mg-main-blue-light border-mg-main-blue",
  main_dark = "!text-white !bg-mg-main-blue !border-mg-main-blue",
  green = "!text-mg-green-dark !bg-mg-green-light !border-mg-green-dark",
  green_dark = "!text-white !bg-mg-green-dark !border-mg-green-dark",
  orange = "!text-mg-orange-dark !bg-mg-orange-light !border-mg-orange-dark",
  sunshine = "!text-mg-sunshine !bg-mg-sunshine-light !border-mg-sunshine",
  gray = "!text-mg-gray-dark !bg-mg-gray-light !border-mg-gray-dark",
  teal = "!text-mg-teal-dark !bg-mg-teal-light !border-mg-teal-dark",
  cyan = "!text-mg-cyan-dark !bg-mg-cyan-light !border-mg-cyan-dark",
  purple = "!text-mg-purple-dark !bg-mg-purple-light !border-mg-purple-dark",
  red = "!text-mg-red-dark !bg-mg-red-light !border-mg-red-dark",
  yellow = "!text-mg-yellow-dark !bg-mg-yellow-light !border-mg-yellow-dark",
  pink = "!text-mg-pink-dark !bg-mg-pink-light !border-mg-pink-dark",
  coral = "!text-mg-coral-dark !bg-mg-coral-light !border-mg-coral-dark",
  whatsapp = "!text-white !bg-mg-green !border-mg-green",
  main_stripes = "!text-mg-main-blue !bg-mg-default-stripes !bg-badge !border-mg-main-blue",
  blue = "!text-white !bg-mg-main-blue-dark !border-mg-main-blue-dark",
  success = "!text-mg-success-dark !bg-mg-success-light !border-mg-success-dark",
  danger = "!text-mg-danger-dark !bg-mg-danger-light !border-mg-danger-dark",
  accent = "!text-mg-accent-dark !bg-mg-accent-light !border-mg-accent-dark"
  
  }

  export enum USERS_ID{
    SMART_USER = 187
  }

  export enum SEARCH_T_EXPAN_OPTION {
    FATHER_ROW = 1,
    CHILD_ROW = 2,
  }



  export const CustomThemePalette = {
    main: '#7084E3',
    main_light: '#d0d8ff',
    main_dark: '#00004d',
    gray: '#9a9a9a',
    gray_light: '#D6D6D6',
    gray_dark: '#666666',
    green: '#77dea2',
    green_light: '#baeed0',
    green_dark: '#27834d',
    orange: '#f78842',
    orange_light: '#fbc3a0',
    orange_dark: '#af5f2e',
    coral: '#ee8f72',
    coral_light: '#f6c7b8',
    coral_dark: '#be6a50',
    teal: '#81e6e2',
    teal_light: '#b3f0ed',
    teal_dark: '#449D99',
    red: '#de7777',
    red_light: '#f2b0b0',
    red_dark: '#ac3d3d',
    yellow: '#ffce00',
    yellow_light: '#ffeb99',
    yellow_dark: '#a56c00',
    pink: '#d977dd',
    pink_light: '#ecbaee',
    pink_dark: '#97569a',
    purple: '#8f7ee3',
    purple_light: '#baacff',
    purple_dark: '#7164b1',
    purple_relight: '#f0f1ff',
    sunshine: '#ff9f3b',
    sunshine_light: '#FFF4E5',
    sunshine_dark: '#e2801b',
    cyan: '#3CA1AB',
    cyan_light: '#B3EDF4',
    cyan_dark: '#286B72',
    navy: '#1a202e',
    navy_light: '#494B74',
    navy_dark: '#141924',
    blue: '#0061FD60',
    blue_light: '#92C9F2',
    blue_dark: '#5784a5',
    preload: '#F3F4FF',
    preload_light: '#BFC4FF1F',
    preload_dark: '#2C303E',
    success: '#83C886',
    success_light: '#E7F4E8',
    success_dark: '#83C886',
    warning: '#FFB84C',
    warning_light: '#FFF1DC',
    warning_dark: '#FFB84C',
    danger: '#FF8A64',
    danger_light: '#FFE8E1',
    danger_dark: '#FF8A64',
    info: '#62AEEE',
    info_light: '#E1EFFB',
    info_dark: '#62AEEE',
  };
  

  export enum TIPO_HISTORICO {
    MODIFICACION = 1,
    OBSERVACION = 2,
    APROBACION = 3,
    RECHAZO = 4,
    CADUCADO = 5,
    ANULACION = 6,
    PENDIENTE_DE_PAGO = 7,
    PAGADO = 8,
    CREADO = 9
}