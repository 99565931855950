import * as moment from 'moment';
import { Moment } from 'moment';
import { REQUEST_FREQUENCY_TYPE } from 'src/app/modules/requests/helpers/enums';
import { formatDateMMofDDofYYYY } from 'src/app/shared/helpers/transformDate';
import {
  setFirstLetterUppercase,
  setToTitleCase,
} from 'src/app/shared/helpers/transformText';

// -- REQUEST
export function setDescriptionForNotificationByRequest(oMsj: any) {
  let conectorOwner = '';
  let sMsj = '<strong>' + setToTitleCase(oMsj.sInvolucrado) + '</strong> ';

  switch (oMsj.sAccion.toUpperCase()) {
    case 'APROBAR':
      sMsj =
        sMsj +
        'ha aprobado ' +
        (oMsj.existOwnerAndIsLoggedIn ? 'tu' : 'la') +
        ' solicitud de ';
      conectorOwner = 'de';
      break;

    case 'ANULAR':
      //sMsj = sMsj + 'ha anulado su solicitud de ';
      sMsj =
        sMsj +
        'ha anulado ' +
        (oMsj.existOwnerAndIsLoggedIn ? 'tu' : 'la') +
        ' solicitud de ';
      conectorOwner = 'de';
      break;

    case 'RECHAZAR':
      sMsj =
        sMsj +
        'ha rechazado ' +
        (oMsj.existOwnerAndIsLoggedIn ? 'tu' : 'la') +
        ' solicitud de ';
      conectorOwner = 'de';
      break;

    case 'CREAR':
      sMsj =
        sMsj +
        (oMsj.existOwnerAndIsLoggedIn ? 'te' : '') +
        ' ha creado una solicitud de ';
      conectorOwner = 'para';
      break;
  }

  sMsj =
    sMsj +
    '<strong> ' +
    oMsj.oSolicitud.sTipo_Solicitud.toLowerCase() +
    '</strong> ';
  if (oMsj.existOwnerAndIsNotLoggedIn)
    sMsj =
      sMsj +
      ' ' +
      conectorOwner +
      ' <strong> ' +
      setToTitleCase(oMsj.sPropietario) +
      '</strong>, ';

  return sMsj + setDescriptionForNotificationByRequestFrecuency(oMsj);
}

export function setDescriptionForNotificationByRequestFrecuency(oMsj: any) {
  let dStart = moment(oMsj.oSolicitud.dFecha_Inicio, 'YYYY-MM-DDTHH:mm:ss');
  let dEnd = moment(oMsj.oSolicitud.dFecha_Fin, 'YYYY-MM-DDTHH:mm:ss');
  switch (oMsj.oSolicitud.nFrecuencia_Solicitud) {
    case REQUEST_FREQUENCY_TYPE.POR_HORAS:
      if (dStart.isSame(dEnd, 'day')) {
        return (
          'para el <strong> ' +
          formatDateMMofDDofYYYY(dStart) +
          '</strong> desde las <strong> ' +
          dStart.format('HH:mm') +
          ' Hrs</strong> hasta las <strong> ' +
          dEnd.format('HH:mm') +
          ' Hrs</strong>.'
        );
      } else {
        return (
          'para el <strong> ' +
          formatDateMMofDDofYYYY(dStart) +
          '</strong> - <strong> ' +
          dStart.format('HH:mm') +
          ' Hrs</strong> hasta <strong> ' +
          formatDateMMofDDofYYYY(dEnd) +
          '</strong> - <strong> ' +
          dEnd.format('HH:mm') +
          'Hrs</strong>. '
        );
      }

    case REQUEST_FREQUENCY_TYPE.POR_DIAS:
    case REQUEST_FREQUENCY_TYPE.POR_MESES:
      if (dStart.isSame(dEnd, 'day')) {
        return (
          'para el <strong> ' + formatDateMMofDDofYYYY(dStart) + '</strong>.'
        );
      } else {
        return (
          'para el <strong> ' +
          formatDateMMofDDofYYYY(dStart) +
          '</strong> hasta el <strong>' +
          formatDateMMofDDofYYYY(dEnd) +
          '</strong>.'
        );
      }

    default:
      return (
        'para el <strong> ' + formatDateMMofDDofYYYY(dStart) + '</strong>.'
      );
  }
}

// -- TASK
export function setDescriptionForNotificationByTareo(oMsj: any) {
  let sMsj = '<strong>' + setToTitleCase(oMsj.sInvolucrado) + '</strong>';
  let hora = ''
  let dia = ''
  let mes = ''
  switch (oMsj.sAccion.toUpperCase()) {
    case 'EDITAR':
      sMsj =
        sMsj +
        ' ha resuelto ' +
        (oMsj.existOwnerAndIsLoggedIn ? 'tu' : 'la') +
        ' actividad observada';
      sMsj =
        sMsj +
        (oMsj.existOwnerAndIsNotLoggedIn
          ? ' de <strong> ' + setToTitleCase(oMsj.sPropietario) + '</strong>. '
          : '. ');
      oMsj.aTareo.forEach((change: any, index: number) => {
        sMsj =
          sMsj +
          '<strong>' +
          setFirstLetterUppercase(change.sCampo) +
          '</strong> cambió de <strong> ' +
          setFirstLetterUppercase(change.sData_Old) +
          '</strong> a <strong> ' +
          setFirstLetterUppercase(change.sData_New) +
          '</strong>';
        if (index < oMsj.aTareo.length - 1) {
          sMsj = sMsj + ', ';
        }
      });
      return sMsj + '.';

    case 'OBSERVAR':
      sMsj =
        sMsj +
        ' ha observado ' +
        (oMsj.existOwnerAndIsNotLoggedIn ? 'la ' : 'tu ') +
        'actividad';
      sMsj =
        sMsj +
        (oMsj.existOwnerAndIsNotLoggedIn
          ? ' de <strong> ' + setToTitleCase(oMsj.sPropietario) + '</strong>: '
          : ': ');
      sMsj = sMsj + '<strong>"' + oMsj.sObservacion + '"</strong>.';
      return sMsj;
    case 'LLENADO_TAREO':
      hora = oMsj.nHoursCount == 1 ? '1 hora' : `${oMsj.nHoursCount.toFixed(2)} horas`
      dia = oMsj.nDayCount == 1 ? '1 día' : `${oMsj.nDayCount} dias`
      sMsj = sMsj +`, no has registrado <strong>${hora}</strong> de actividades en <strong> ${dia}, </strong> por favor asegurate de hacerlo antes de que finalice el día.` 
      return sMsj;
    case 'LLENADO_TAREO_DIARIO':
      hora = oMsj.nHoursCount == 1 ? '1 hora' : `${oMsj.nHoursCount.toFixed(2)} horas`
      dia = oMsj.nDayCount == 1 ? '1 día' : `${oMsj.nDayCount} dias`
      mes = moment().format('MMMM')
      sMsj = sMsj +`, no has registrado <strong>${hora}</strong> de actividades en <strong> ${dia}, </strong> en el mes de <strong>${mes}</strong>. Por favor recuerda mantener actualizado tu tareo.` 
      return sMsj;
    default:
      console.warn('Error de acción en tareo notificación', oMsj.sAccion);
      return '';
  }
}

// --  ASSISTANCE
export function setDescriptionForNotificationByAssistance(oMsj: any) {
  let sMsj = '<strong>' + setToTitleCase(oMsj.sInvolucrado) + '</strong> ';
  let dFechaMarca: Moment = moment();
  switch (oMsj.sAccion.toUpperCase()) {
    case 'CREAR':
      sMsj =
        sMsj +
        (oMsj.existOwnerAndIsLoggedIn || oMsj.nPropietario == undefined
          ? 'te ha'
          : 'ha') +
        ' creado ';
      oMsj.aAsistencia.forEach((change: any, index: number) => {
        let dFecha = moment(change.dFecha_New);
        dFechaMarca = moment(change.dFecha_Marca);
        sMsj =
          sMsj +
          'la marca de <strong> ' +
          change.sNombre_Marca.toLowerCase() +
          '</strong> para las <strong>' +
          dFecha.format('HH:mm') +
          ' Hrs</strong>';
        if (index < oMsj.aAsistencia.length - 1) {
          sMsj = sMsj + ', ';
        }
      });
      break;

    case 'EDITAR':
      sMsj = sMsj + 'ha modificado ';
      oMsj.aAsistencia.forEach((change: any, index: number) => {
        dFechaMarca = moment(change.dFecha_Marca);
        let dFecha_New = moment(change.dFecha_New);
        let dFecha_Old = moment(change.dFecha_Old);
        sMsj =
          sMsj +
          (oMsj.existOwnerAndIsLoggedIn || oMsj.nPropietario == undefined
            ? 'tu'
            : 'la') +
          ' marca de <strong> ' +
          change.sNombre_Marca.toLowerCase() +
          '</strong> de las <strong> ' +
          dFecha_Old.format('HH:mm') +
          ' Hrs</strong> a las <strong>' +
          dFecha_New.format('HH:mm') +
          ' Hrs</strong>';
        if (index < oMsj.aAsistencia.length - 1) {
          sMsj = sMsj + ', ';
        }
      });
      break;

    case 'ELIMINAR':
      sMsj = sMsj + 'ha eliminado ';
      oMsj.aAsistencia.forEach((change: any, index: number) => {
        dFechaMarca = moment(change.dFecha_Marca);
        sMsj =
          sMsj +
          (oMsj.existOwnerAndIsLoggedIn || oMsj.nPropietario == undefined
            ? 'tu'
            : 'la') +
          ' marca de <strong> ' +
          change.sNombre_Marca.toLowerCase() +
          '</strong>';
        if (index < oMsj.aAsistencia.length - 1) {
          sMsj = sMsj + ', ';
        }
      });
      break;

    case 'INVALIDAR':
      let dFechaMarcaZ = moment(
        oMsj.oMarca.dFecha_Marca,
        'YYYY-MM-DD HH:mm:ss'
      );
      sMsj = 'Tu intento de marca </strong> para las <strong>';
      sMsj =
        sMsj +
        dFechaMarcaZ.format('HH:mm') +
        '</strong> del <strong>' +
        formatDateMMofDDofYYYY(dFechaMarcaZ) +
        '</strong> no pudo ser procesada. <br> Por favor, regulariza tu marca.';
      return sMsj;
  }

  if (oMsj.existOwnerAndIsNotLoggedIn)
    sMsj =
      sMsj + ' de <strong>' + setToTitleCase(oMsj.sPropietario) + '</strong>';
  sMsj =
    sMsj +
    ' del día <strong>' +
    dFechaMarca.format('DD') +
    ' de ' +
    dFechaMarca.format('MMMM');
  return sMsj;
}

// -- TESORERIA
export function setDescriptionForNotificationByTreasuryRequest(oMsj: any) {
  oMsj.sCategoria_Solicitud_Tesoreria = 'gastos';
  let conectorOwner = '';
  let sMsj = '<strong>' + setToTitleCase(oMsj.sInvolucrado) + '</strong> ';

  switch (oMsj.sAccion.toUpperCase()) {
    case 'REEMBOLSAR':
      sMsj = sMsj + 'ha efectuado el reembolso de tu solicitud de ';
      conectorOwner = 'de';
      break;

    case 'ANULAR':
      sMsj = sMsj + 'ha anulado su solicitud de ';
      break;

    case 'RECHAZAR':
      sMsj = sMsj + 'ha rechazado tu solicitud de ';
      conectorOwner = 'de';
      break;

    case 'CREAR':
      sMsj = sMsj + ' ha creado una solicitud de ';
      conectorOwner = 'para';
      break;
  }

  sMsj =
    sMsj +
    '<strong> ' +
    oMsj.sCategoria_Solicitud_Tesoreria +
    ' por ' +
    oMsj.oSolicitudTesoreria.sTipo_Solicitud_Tesoreria.toLowerCase() +
    '</strong> ';
  if (oMsj.existOwnerAndIsNotLoggedIn)
    sMsj =
      sMsj +
      ' ' +
      conectorOwner +
      ' <strong> ' +
      setToTitleCase(oMsj.sPropietario) +
      '</strong> ';
  sMsj =
    sMsj +
    'del día <strong>' +
    formatDateMMofDDofYYYY(moment(oMsj.oSolicitudTesoreria.dFecha_Efecto)) +
    '</strong>.';

  return sMsj;
}

// -- REQUERIMENT
export function setDescriptionForNotificationByRequirement(oMsj: any) {
  let sMsj = '<strong>' + setToTitleCase(oMsj.sInvolucrado) + '</strong>';
  switch (oMsj.sAccion.toUpperCase()) {
    case 'CREAR':
      return (
        sMsj +
        ' ha creado el requerimiento <strong> ' +
        oMsj.oRequerimiento.sNombre_Requerimiento +
        '</strong> para el proyecto <strong> ' +
        oMsj.oRequerimiento.sNombre_Proyecto +
        '</strong> y ha elegido como lider principal ha <strong> ' +
        oMsj.oRequerimiento.sNombre_Lider_principal_new +
        '</strong>'
      );

    case 'CREAR_LIDER_PRINCIPAL':
      return (
        sMsj +
        ' te ha asignado como lider principal para el requerimiento <strong> ' +
        oMsj.oRequerimiento.sNombre_Requerimiento +
        '</strong> del proyecto <strong> ' +
        oMsj.oRequerimiento.sNombre_Proyecto +
        '</strong>'
      );

    case 'EDITAR':
      sMsj =
        sMsj +
        ' ha editado el requerimiento <strong> ' +
        oMsj.oRequerimiento.sNombre_Requerimiento +
        '</strong> del proyecto <strong> ' +
        oMsj.oRequerimiento.sNombre_Proyecto +
        '</strong>. ';
      oMsj.oRequerimiento.aCambios.forEach((change: any, index: number) => {
        if (change.sNombre_Categoria != undefined) {
          if (change.sData_Old != undefined) {
            sMsj =
              sMsj +
              'Categoría <strong>' +
              setFirstLetterUppercase(change.sNombre_Categoria) +
              '</strong> cambió de <strong> ' +
              change.sData_Old +
              (change.sData_Old > 1 ? ' Hrs' : ' Hr') +
              '</strong> a <strong> ' +
              change.sData_New +
              (change.sData_New > 1 ? ' Hrs' : ' Hr') +
              '</strong>';
          } else {
            sMsj =
              sMsj +
              'Agregó la categoría <strong>' +
              setFirstLetterUppercase(change.sNombre_Categoria) +
              '</strong>';
          }
        } else {
          sMsj =
            sMsj +
            '<strong>' +
            setFirstLetterUppercase(change.sCampo) +
            '</strong> cambió de <strong> ' +
            setFirstLetterUppercase(change.sData_Old) +
            '</strong> a <strong> ' +
            setFirstLetterUppercase(change.sData_New) +
            '</strong>';
        }
        if (index < oMsj.oRequerimiento.aCambios.length - 1) {
          sMsj = sMsj + ', ';
        }
      });
      return sMsj + '.';

    case 'ACTUALIZAR_LP':
      return (
        sMsj +
        ' ha actualizado el lider principal en el requerimiento <strong> ' +
        oMsj.oRequerimiento.sNombre_Requerimiento +
        '</strong> del proyecto <strong> ' +
        oMsj.oRequerimiento.sNombre_Proyecto +
        '</strong>, cambiando a <strong> ' +
        oMsj.oRequerimiento.sNombre_Lider_principal_old +
        '</strong> por <strong> ' +
        oMsj.oRequerimiento.sNombre_Lider_principal_new +
        '</strong>'
      );

      case 'NOTIFICAR_LP_OLD':
        return (
          sMsj +
          ' te ha removido como lider principal en el requerimiento <strong> ' +
          oMsj.oRequerimiento.sNombre_Requerimiento +
          '</strong> del proyecto <strong> ' +
          oMsj.oRequerimiento.sNombre_Proyecto +
          '</strong>'
        );

      case 'NOTIFICAR_LP_NEW':
        return (
          sMsj +
          ' te ha asignado como lider principal en el requerimiento <strong> ' +
          oMsj.oRequerimiento.sNombre_Requerimiento +
          '</strong> del proyecto <strong> ' +
          oMsj.oRequerimiento.sNombre_Proyecto +
          '</strong>'
      );

    default:
      console.warn(
        'Error de acción en requerimiento notificación',
        oMsj.sAccion
      );
      return '';
  }
}

export function setDescriptionForNotificationByProject(oMsj: any) {
  let sMsj = '<strong>' + setToTitleCase(oMsj.sInvolucrado) + '</strong> ';
  switch (oMsj.sAccion.toUpperCase()) {
    case 'ASIGNAR_D':
      return (
        sMsj +
        'ha asignado como líder a <strong> ' +
        oMsj.oProyecto.sNombre_Lideres +
        '</strong> para el proyecto <strong> ' +
        oMsj.oProyecto.sNombre_Proyecto +
        '</strong>.'
      );

    case 'RETIRAR_D':
      return (sMsj =
        sMsj +
        'ha removido como líder a <strong> ' +
        oMsj.oProyecto.sNombre_Lideres +
        '</strong> para el proyecto <strong> ' +
        oMsj.oProyecto.sNombre_Proyecto +
        '</strong>.');

    case 'ASIGNAR_L':
      return (
        sMsj +
        'te ha asignado como líder del proyecto <strong> ' +
        oMsj.oProyecto.sNombre_Proyecto +
        '</strong>.'
      );

    case 'RETIRAR_L':
      return (sMsj =
        sMsj +
        'te ha removido como líder del proyecto <strong> ' +
        oMsj.oProyecto.sNombre_Proyecto +
        '</strong>.');

    default:
      console.warn('Error de acción en proyecto notificación', oMsj.sAccion);
      return '';
  }
}
