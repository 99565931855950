import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { NavigationDropdown, NavigationLink } from 'src/app/core/interfaces/navigation-item.interface';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { dropdownAnimation } from 'src/app/shared/animations/dropdown.animation';  

@Component({
  selector: 'mg-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  animations: [dropdownAnimation],
  encapsulation: ViewEncapsulation.None
})
export class SidenavItemComponent implements OnInit {
  @Input() item: NavigationLink | NavigationDropdown;
  @Input() level: number;
  @Input() isCollapsed: boolean | null;
  @Input() isDesktop: boolean | null;  
  @Output() clickItem = new EventEmitter<boolean>(); 
  @Output() clickItem2 = new EventEmitter<boolean>(); 
  isOpen: boolean = false;
  isActive: boolean =  false;

  isLink = this.navigationService.isLink;
  isDropdown = this.navigationService.isDropdown;

  constructor(private cd: ChangeDetectorRef,
    private router: Router,
    private navigationService: NavigationService) { }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      filter(() => this.isDropdown(this.item)),
    ).subscribe(() => this.onRouteChange());

    this.navigationService.openChange$.pipe(
      filter(() => this.isDropdown(this.item)), 
    ).subscribe(item => this.onOpenChange(item));
  }

  toggleOpen(){ 
    this.isOpen = !this.isOpen; 
    this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
    this.cd.markForCheck(); 
  }

  onOpenChange(item: NavigationDropdown) {
    if (this.isChildrenOf(this.item as NavigationDropdown, item)) {
      return;
    }

    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      return;
    }

    if (this.item !== item) {
      this.isOpen = false;
      this.cd.markForCheck();
    }
  }

  onRouteChange() {
    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      this.isActive = true;
      this.isOpen = true;
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cd.markForCheck();
    } else {
      this.isActive = false;
      this.isOpen = false;
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cd.markForCheck();
    }
  }

  isChildrenOf(parent: NavigationDropdown, item: NavigationDropdown): any {
    if (parent.children.indexOf(item) !== -1) {
      return true;
    }

    return parent.children
      .filter(child => this.isDropdown(child))
      .some(child => this.isChildrenOf(child as NavigationDropdown, item));
  }

  hasActiveChilds(parent: NavigationDropdown): any {
    return parent.children.some(child => {
      if (this.isDropdown(child)) {
        return this.hasActiveChilds(child);
      }

      if (this.isLink(child) && !this.isFunction(child.route)) {
        return this.router.isActive(child.route as string, false);
      }
    });
  }

  isFunction(prop: NavigationLink['route']) {
    return prop instanceof Function;
  } 

  clickItemAction(){
    this.clickItem.emit(this.isOpen);
  }

  //TODO: BUSDCAR UNA MEJOR FORMA

  clickItemAction2(){
    this.clickItem2.emit(this.isOpen);
  }

}
