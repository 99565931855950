import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AccessService } from "src/app/core/services/access.service";
import { environment as env } from "src/environments/environment";
import { GenericResponse } from "src/app/shared/interfaces/generic.interface";
import { Approver, DataScheduleByUser, RequestDetail, RequestList, cancelRequestData, footerList } from "../interfaces/interfaces";
import { TableService } from "src/app/shared/helpers/list-table";
import { footerTransform, transforDataRequestList } from "../helpers/mapDataRequestList";
import { GenericResponseList } from "src/app/shared/interfaces/table.interface";
import { catchError, switchMap, tap } from 'rxjs/operators';

import { REFRESH_INDEXED } from "src/app/core/indexedDB/helpers/enums.enums";
import { IndexedDBDexieService } from "src/app/core/indexedDB/service/indexed-db-dexie.service";
import { localDB } from "src/app/shared/helpers/localStorage";
import { ConfigurationsService } from "src/app/shared/services/configurations.service";

interface MinutesBalance {
  nMinutosRecuperar: number;
  nMinutosCompesacion: number;
}

@Injectable({
  providedIn: "root",
})

// implements DefaultService
export class RequestsService implements TableService {
  requestCard:any = {
    isSuccess:true,
    code: 200,
    message: 'init',
    data: []
  }
  requestCardSubjet = new BehaviorSubject<any>(this.requestCard)
  getLastRequest = this.requestCardSubjet.asObservable()

  constructor(
    private http: HttpClient,
    private _accessService: AccessService,
    private _indexDB: IndexedDBDexieService,
    private _ConfigService: ConfigurationsService
    ) { }

  getAll (
    records: number,
    sort: string,
    order: string,
    numPage: number,
    getInputs = ""
  ): Observable<GenericResponseList<RequestList>> {
    const requestUrl = `${env.api_url}api/solicitud/listar?records=${records}&sort=${sort}&order=${order}&numPage=${numPage+1}${getInputs}`;

    return forkJoin([
      this.http.get<GenericResponseList<RequestList>>(requestUrl),
      this._ConfigService.getConfig(REFRESH_INDEXED.EVERY_REFRESH,['proyectos', 'todo_requerimiento', 'categorias_all', 'lideres_directores_all', 'tipo_hora', 'tipo_actividad', 'tipo_solicitud'])
    ]).pipe(
      map((response) => {
        return transforDataRequestList(response[0], this._accessService, response[1]);
      })
    );
  }

  getFooter (getInputs:string): Observable<GenericResponse<footerList>> {
    const requestUrl = `${env.api_url}api/solicitud/listar_total_general?${getInputs}`;
    return this.http.get<GenericResponse<footerList>>(requestUrl).pipe(
      map(response => {
        return footerTransform(response);
      })
    );
  }

  requestRegister(data: any): Observable<GenericResponse<string>> {
    const endpoint = env.api_url + "api/solicitud/crear";
    const headers = new HttpHeaders();
    headers.set("Content-Type", "multipart/form-data; charset=utf-8");
    return this.http.post<GenericResponse<string>>(endpoint, data, { headers: headers });
  }

  requestRegisterOLD(data: any): Observable<any> {
    const endpoint = env.api_url + "api/solicitud/crear-old";
    return this.http.post<any>(endpoint, data);
  }

  requestMassiveRegister(data: any): Observable<GenericResponse<string>> {
    const endpoint = env.api_url + "api/solicitud/masivo/crear";
    const headers = new HttpHeaders();
    headers.set("Content-Type", "multipart/form-data; charset=utf-8");
    return this.http.post<GenericResponse<string>>(endpoint, data, { headers: headers });
  }

//   getLastRequest(): Observable<any> {
//     const endpoint = env.api_url + 'api/solicitud';
    
//  //   return this._indexDB.getRequestData<any>('api/solicitud', 'endpoints', REFRESH_INDEXED.EVERY_DAY)
//   }

  requestLastRequest(){
    const endpoint = env.api_url + 'api/solicitud';
    return this.http.get<any>(endpoint).pipe(
      map(resp =>{
  
        localDB.setItem('c-requests', resp.data)
        this.requestCardSubjet.next(resp)
      }),
      catchError(err => {
        console.log(err)
        throw err
      })
    )
  }

  changeStateRequest(nId: number, type: number, data: cancelRequestData, canApproveOrRefuseAnyRequest: boolean = false): Observable<GenericResponse<RequestDetail>> {
    let dataForm: FormData = this.setFormData(data)
    const api = `${env.api_url}api/solicitud/${(canApproveOrRefuseAnyRequest) ? 'aprobarRechazarLiderAdm' : 'aprobarRechazarnew'}?nId_Soli=${nId}`
    const headers = new HttpHeaders();
    headers.set("Content-Type", "multipart/form-data; charset=utf-8");
    return this.http
      .post<GenericResponse<RequestDetail>>(
        api, dataForm, {headers:headers}
      );
  }

  private setFormData(data:cancelRequestData){
    let formData: FormData = new FormData();
    for(let clave in data){
      switch(clave){
        case 'aFiles':
          data[clave].forEach((file: File) => {
            formData.append("aFiles", file);
          });
          break
        default:
          //@ts-ignore
          formData.append(clave, data[clave]);
          break
      }
    }

    return formData;
  }

  getRequestDetail(idRequest: number): Observable<GenericResponse<RequestDetail>>{
    return this.http.get<any>(`${env.api_url}api/solicitud/detalle-solicitud?nId_Solicitud=${idRequest}`);
  }

  getUserSchedule() {
    return this._indexDB.getRequestData<any>("api/solicitud/horario-colaborador", 'endpoints', REFRESH_INDEXED.EVERY_DAY).pipe(
      map(resp=>{
        localDB.setItem('V-HORARIO', JSON.stringify(resp.data))
        return resp
      })
    );
  }

  getScheduleByUser(idUsuario: number): Observable<any>{
    let nId_Usuario = localDB.getItem('idUsuario');
    if(nId_Usuario === idUsuario.toString()){
      return this._indexDB.getRequestData<GenericResponse<DataScheduleByUser>>('api/horario/obtenerHorario?nId_User=', 'endpoints', REFRESH_INDEXED.EVERY_DAY, {query: idUsuario.toString()});
    }else{
      const request = env.api_url + 'api/horario/obtenerHorario?nId_User=' + idUsuario.toString();
      return this.http.get<any>(request).pipe(
        catchError((err:any) => {
          console.log(err);
          return of(null);
        })
      )
    }
  }

  getApprovers(): Observable<GenericResponse<Approver>> {
    return this._indexDB.getRequestData<GenericResponse<Approver>>('api/solicitud/futuro-aprobador', 'endpoints', REFRESH_INDEXED.EVERY_DAY)
  }

  cancelRequest(nId: number, data: cancelRequestData): Observable<GenericResponse<string>> {
    return this.http
      .put<GenericResponse<string>>(
        env.api_url + "api/solicitud/anularSolicitud?nId_Solicitud=" + nId,
        data
      );
  }
}