import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplitButton, typeSplitValue } from '../../../interfaces/buttons.interface';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { IconComponent } from '../../icon/icon.component';

@Component({
  selector: 'mg-split-button',
  standalone: true,
  imports: [CommonModule, MatTooltipModule, MatMenuModule, IconComponent],
  templateUrl: './split-button.component.html',
  styleUrls: ['./split-button.component.scss']
})
export class SplitButtonComponent implements OnChanges, OnInit {
  @Input() configButtons: SplitButton[] = [];
  @Output() doThisFunction = new EventEmitter<typeSplitValue>();
  actionsArray: SplitButton[] = [];
  button: SplitButton={
    type: "button",
    label: "",
    tooltip: "",
    icon: null,
    value: 0,
  };;

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['configButtons']){
      this.buildButton();
    }
  }

  ngOnInit(): void {
    this.buildButton();
  }

  buildButton() {
    this.actionsArray = []
    this.configButtons.forEach((element) => {
      if (element.type == "button") {
        this.button.icon = element.icon;
        this.button.label = element.label;
        this.button.value = element.value;
      } else if(element.type == "action"){
        this.actionsArray.push(element);
      }
    });
  }

  setAction(action: number) {
    this.doThisFunction.emit(action);
  }

}
