export enum MODAL_SIZING_ONE_COLUMN_REDUCE {
  WIDHT = '90%',
  MAX_WIDTH = '400px',
  HEIGHT = 'auto',
}

export enum MODAL_SIZING_ONE_COLUMN {
  WIDHT = '90%',
  MAX_WIDTH = '500px',
  HEIGHT = 'auto',
}

export enum MODAL_SIZING_TWO_COLUMNS {
  MIN_WIDTH = '450px',
  WIDHT = '90%',
  MAX_WIDTH = '800px',
  HEIGHT = 'auto',
  MAX_HEIGHT = '800px',
}

export enum MODAL_SIZING_TWO_COLUMNS_REDUCE {
  WIDHT = '90%',
  MAX_WIDTH = '650px',
  HEIGHT = 'auto',
}

export enum MODAL_SIZING_THREE_COLUMNS {
  WIDHT = '90%',
  MAX_WIDTH = '1200px',
  MIN_WIDTH = '450px',
  HEIGHT = 'auto',
}

export enum MODAL_SIZING_EMAILS {
  WIDHT = '90%',
  MAX_WIDTH = '500px',
  HEIGHT = 'auto',
}