export function setToTitleCase(str: string) {
    let aStr: string[] = str.toLowerCase().split(" ");
    for (var i = 0; i < aStr.length; i++) {
        aStr[i] = aStr[i].charAt(0).toUpperCase() + aStr[i].slice(1);
    }
    return aStr.join(" ");
  }
  
  export function setFirstLetterUppercase(str: string) {
    let first = (str) ? str.charAt(0).toUpperCase() : "";
    let noFirst = (str) ? str.slice(1).toLowerCase() : "";
    return first + noFirst;
  }

  export function getInitialsLetters(nombreCompleto: string): string {
    // Dividir el nombre completo en palabras
    const palabras = nombreCompleto.split(" ");
    
    // Obtener la primera letra del primer nombre
    let primeraLetraApellido = "";
    if (palabras.length > 0) {
      primeraLetraApellido = palabras[0].charAt(0);
    }
    
    // Obtener la primera letra del primer apellido
    let primeraLetraNombre = "";
    if (palabras.length > 2) {
        primeraLetraNombre = palabras[2].charAt(0);
    }

    // Devolver las iniciales
    return primeraLetraNombre + primeraLetraApellido;
}
  
  export function getFirstLetterOfWord(str: string) {
    return str
      .split(" ")
      .map((e) => e.charAt(0))
      .join("");
  }

  export function getFirstLetterOfWordandCountLeadersAssigned(str: string, cant: number) {
    let result = "";
    if(cant > 1)
    {
      result = ' (' +cant +'*)';
    }
    else
    {
      result = '';
    } 
    
    return str
      .split(" ")
      .map((e) => e.charAt(0))
      .join("") + result;
  } 

  export function getShortText(texto: string, maxLength: number) {
    let shortMessage = "";
  
    let cantidadCaracteres = texto.length;
  
    if (cantidadCaracteres > maxLength) {
      shortMessage = texto.slice(0, maxLength) + "...";
    } else {
      shortMessage = texto;
    }
  
    return shortMessage;
  }

  export function getLetterForExcel(indexLetter: number) {
    switch(indexLetter){
      case 1:
        return 'A';
      case 2:
        return 'B';
      case 3:
        return 'C';
      case 4:
        return 'D';
      case 5:
        return 'E';
      case 6:
        return 'F';
      case 7:
        return 'G';
      case 8:
        return 'H';
      case 9:
        return 'I';
      case 10:
        return 'J';
      case 11:
        return 'K';
      case 12:
        return 'L';
      case 13:
        return 'M';
      case 14:
        return 'N';
      case 15:
        return 'O';
      case 16:
        return 'P';
      case 17:
        return 'Q';
      case 18:
        return 'R';
      case 19:
        return 'S';
      case 20:
        return 'T';
      case 21:
        return 'U';
      case 22:
        return 'V';
      case 23:
        return 'W';
      case 24:
        return 'X';
      case 25:
        return 'Y';
      case 26:
        return 'Z';
      case 27:
        return 'AA';
      case 28:
        return 'AB';
      case 29:
        return 'AC';
      case 30:
        return 'AD';
      default:
        return 'A';
    }
  }