import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AccessService } from '../services/access.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizedGuard implements CanActivate {
  constructor(private accessService: AccessService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const modulePath = state.url.split('/').filter(Boolean)[0];
    if (this.accessService.isAuthorizedToVisitModule(modulePath)) {
      return true;
    } else {
      this.router.navigate(['/common/forbidden']);
      return false;
    }
  }
}
