import { Component, OnInit } from '@angular/core';
import { ServerTimeService } from '../../../../../../shared/services/server-time.service';
import { NavigatorService } from '../../../../../../shared/services/navigator.service';
import { Observable, interval, map } from 'rxjs';
import { ServerTime } from '../../../../../../shared/interfaces/server-time.interface';
import { HubConnectionState } from '@microsoft/signalr';
import * as moment from 'moment';
import { AuthService } from 'src/app/core/services/auth.service';
import { ConfigurationsService } from 'src/app/shared/services/configurations.service';
import { environment as env } from '../../../../../../../environments/environment';
import { REFRESH_INDEXED } from 'src/app/core/indexedDB/helpers/enums.enums';
import { thereAreRecentversions } from 'src/app/shared/helpers/versions';
import { IndexedDBDexieService } from 'src/app/core/indexedDB/service/indexed-db-dexie.service';
import { localDB } from 'src/app/shared/helpers/localStorage';
import { CacheService } from 'src/app/shared/services/cache.service';

import { ConfigEnviromentService } from 'src/app/core/services/configEnviroment.service';
import { CookiesService } from 'src/app/core/services/cookies.service';

import { VersionAlertComponent } from 'src/app/shared/components/version-alert/version-alert.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'mg-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss'],
})
export class ClockComponent implements OnInit {
  public serverTime$: Observable<ServerTime | undefined>;
  public serverDateTime = new Date();
  public showTime: string = '--:--:--';
  env = env;
  public hideModal: boolean = false;

  constructor(
    private _serverTimeService: ServerTimeService,
    private _navigatorService: NavigatorService,
    private _authService: AuthService,
    private _configurationsService: ConfigurationsService,
    private _indexedDB: IndexedDBDexieService,
    private _cacheService: CacheService,

    private _configEnvService: ConfigEnviromentService,
    private _cookiesService: CookiesService,

    private dialog: MatDialog 

  ) {
    this.serverTime$ = _serverTimeService.getServerTime();
    this._navigatorService.visibilityChange(() => {
      if (!document.hidden) {
        this._serverTimeService.requestServerTime();
        this.serverTime$.pipe(
          map((serverDate) => {
            if (
              this._serverTimeService.connection.state ===
              HubConnectionState.Connected
            ) {
              if (serverDate!.currentDatetime) {
                this.serverDateTime = new Date(serverDate!.currentDatetime);
              }
            }
          })
        ).subscribe();
      }
    });
  }

  ngOnInit(): void {
    this.suscribeToSeverClock();
    this.getTimeToShow();
    interval(6000).subscribe(() => this.versionValidation());
  }

  private suscribeToSeverClock() {
    try {
      this.serverTime$.subscribe((serverDate) => {
        if (
          this._serverTimeService.connection.state ===
          HubConnectionState.Connected
        ) {
          this.serverDateTime = new Date(serverDate!.currentDatetime);
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  getTimeToShow() {
    interval(1000).subscribe(() => {
      this.serverDateTime.setSeconds(this.serverDateTime.getSeconds() + 1);
      this.showTime = moment(this.serverDateTime).format('HH:mm:ss');
      this._serverTimeService.setMyTimeInRealTime(this.serverDateTime);
    });
  }

  versionValidation() {
    if (this._authService.isAuthenticated()) {
      const aConfiguraciones = ['frontend_version'];
      this._configurationsService

        .getConfig(REFRESH_INDEXED.ALWAYS, aConfiguraciones)
        .subscribe(async (response) => {

          // david zone
          if (!response['FRONTEND_VERSION']) return;

          const publishVersion = response['FRONTEND_VERSION'][0].sDescripcion;
          const compareResult = thereAreRecentversions(env.version, publishVersion);
  
          if (compareResult && !this.hideModal) {
            this.hideModal = true;
            this._cacheService.refreshAllValues();
  
            if (this._cookiesService.cookieNameEnv) {
              this._cookiesService.deleteCookie(this._cookiesService.cookieNameEnv);
            }

            
            this._configurationsService.getVersion(publishVersion)
              .subscribe((versionResponse: any) => {
                console.log(versionResponse)
              const versionData = versionResponse.data;
              const dialogRef = this.dialog.open(VersionAlertComponent, {
                data: versionData,
                disableClose: true
            });

            dialogRef.afterClosed().subscribe(async (result: any) => {
              if (result) {
                await this._indexedDB.refreshAllData();
                localDB.cleanCache([
                  'horario',
                  'c-my-marks',
                  'c-red-button',
                  'c-extemp-marks',
                  'c-assistance',
                  'c-requests',
                  'celebrate-banner',
                  'c-tareo',
                  'c-requests-treasury',
                ]);
                await this._configEnvService.loadConfig();
                setTimeout(() => {
                  window.location.reload();
                }, 0);
              }
            });

          }
        )
        }
      });
    }
  }
}