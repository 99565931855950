import { Component,  Input, OnInit } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import {  ToastConfig, ToastInsideConfig,  ToastResponse,  ToastTheme, TypeToast } from './toast.config';
import { CommonModule } from '@angular/common';
import { ToasService } from './service/toas-service.service';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { FormControlPipe } from '../../pipes/form-transform.pipe';
import { PipesModule } from '../../pipes/pipe-module';


@Component({
  selector: 'mg-toast-alert',
  templateUrl: './toast-alert.component.html',
  styleUrls: ['./toast-alert.component.scss'],
  standalone: true,
  imports: [
    IconComponent,
    MatProgressBarModule,
    MatCheckboxModule,
    MatTooltipModule,
    CommonModule,
    FormsModule,
    PipesModule
  ]
})
export class ToastAlertComponent implements OnInit {

  @Input() toastData: ToastInsideConfig


  timeProgresBar: number = 3
  labelCheck: string = ''
  typeToast: TypeToast = 'warning'
  label: string | undefined = undefined;
  body: string | undefined = undefined;
  theme: ToastConfig;
  progressBar: number = 100
  progressInterval: any;
  position: string
  formToast: FormGroup
  checkbox: boolean = false

  toalToast: number = 0
  showRedirect:boolean = false


  constructor(
    private _toastService: ToasService
  ){
    this._toastService.closeAllToastObs.subscribe(resp => {
      if(resp) this.closeToast()
    })

    this._toastService.toastObs.subscribe({
      next:(response) => {
        this.toalToast = Object.entries(response).length
      }
    })

  }

  ngOnInit(): void {
    this.initToastData()
    
  }



  initFormToast(){
    this.formToast = new FormGroup({
      isChecked: new FormControl(false),
      confirmRedirection: new FormControl(false)
    })
  }


 initToastData(){
  this.initFormToast()
  this.toastData.redirectionButtom = this.toastData.redirectionButtom != undefined ? this.toastData.redirectionButtom : true
  this.typeToast = this.toastData.type ?  this.toastData.type : this.typeToast
  this.labelCheck = this.toastData.labelCheck ? this.toastData.labelCheck : '';
  this.theme = ToastTheme[this.typeToast];
  this.label = this.toastData.title;
  this.body = this.toastData.message ? this.toastData.message : '';
  this.position = this.toastData.positionCss!.init
  if(this.toastData.timer){
    this.timeProgresBar = this.toastData.timer 
    if(this.toalToast) 
    this.startCountDown();
  }


  setTimeout(() => {
    this.showRedirect = this.toastData.redirectionButtom!
  }, 500);
 }




 closeToast(){
  this.position = this.toastData.positionCss!.end
  let toastDataNew = {...this.toastData, 
    bMostrar: false, 
    isChecked: this.formToast.get('isChecked')!.value,
    confirmRedirection: this.formToast.get('confirmRedirection')!.value
  }

  this.progressBar = 0
  this._toastService.updateToastData(toastDataNew)
}

handleRedirect(){
  this.formToast.get('confirmRedirection')?.patchValue(true)
  setTimeout(() => {
    this.closeToast()
  }, 50);

}

handleCheck(e:boolean){
  this.formToast.get('isChecked')?.patchValue(e)
}

  startCountDown() {
    this.progressInterval = setInterval(() => {
      this.progressBar--;
      if (this.progressBar <= 0) {
        clearInterval(this.progressInterval);
        this.closeToast()
      }
    }, this.timeProgresBar * 10);
  }


}
