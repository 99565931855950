import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ToasService } from "../service/toas-service.service";
import { ToastInsideConfig, PositionToast, ToastPositions } from "../toast.config";
import { ToastAlertComponent } from "../toast-alert.component";

@Component({
  selector: 'mg-toast-group',
  templateUrl: './toast-group.component.html',
  styleUrls: ['./toast-group.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ToastAlertComponent
  ]
})
export class ToastGroupComponent implements OnInit {
  allToast: { [key: string]: ToastInsideConfig[] } = {
    'top-r': [],
    'top-l': [],
    'bottom-r': [],
    'bottom-l': []
  };

  toastPositions: { [key: string]: PositionToast } = ToastPositions;
  isSmallScreen: boolean = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 740;

  private toastQueue: ToastInsideConfig[] = [];
  private isQueueProcessing: boolean = false;

  constructor(private _toastService: ToasService) { }

  ngOnInit(): void {
    this._toastService.toastObs.subscribe({
      next: (response) => {
        this.addToQueue(Object.values(response));
      }
    });
  }

  private async addToQueue(toasts: ToastInsideConfig[]) {
    toasts.map(t => {
      let index =  this.toastQueue.findIndex(i => i.count == t.count)
      let indexAllToast = this.allToast[t.position!].findIndex(i => i.count == t.count)
      if(index > -1 ){
        this.toastQueue[index] = t;
      }else{
       if(indexAllToast == -1 ){
        this.toastQueue.push(...toasts)
      }else {
        this.handleCloseToast(t)
      };
      }

    })
    if (!this.isQueueProcessing) {
      this.processQueue();
    }
  }

  private async processQueue() {
    if (this.toastQueue.length > 0) {
      this.isQueueProcessing = true;
      const toast = this.toastQueue.shift()!;
      this.initHandleToast(toast);
      await setTimeout(() => {
        this.processQueue();
      }, 50);
    } else {
      this.isQueueProcessing = false;
    }
  }

  private initHandleToast(toast: ToastInsideConfig) {
    let index = this.allToast[toast.position!].findIndex(x => x.count === toast.count);
    if (index == -1) {
      this.allToast[toast.position!].push(toast);
    }
  }

  private async handleCloseToast(data: ToastInsideConfig) {
    if (!data.bMostrar) {
      await this._toastService.deleteToast(data);
      await setTimeout(() => {
        this.allToast[data.position!] = this.allToast[data.position!].filter(t => t.count !== data.count);
      }, 500);
    }
  }
}
