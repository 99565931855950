import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';
import { authGuard } from './core/guards/auth.guard';
import { AuthorizedGuard } from './core/guards/authorized.guard';
import { EndRevisionGuard } from './core/guards/end-revision.guard';
import { layoutResolver } from './core/layout/layout-resolver.resolver';

const childrenRoutes: Routes = [
  {
    path: 'tablero',
    loadChildren: () =>
      import('./modules/my-board/my-board.module').then((m) => m.MyBoardModule),
    canActivate: [authGuard, AuthorizedGuard],
    canLoad: [authGuard],
  },
  {
    path: 'asistencias',
    loadChildren: () =>
      import('./modules/attendance/attendance.module').then(
        (m) => m.AttendanceModule
      ),
    canActivate: [authGuard, AuthorizedGuard],
    canLoad: [authGuard],
  },
  {
    path: 'solicitudes',
    loadChildren: () =>
      import('./modules/requests/requests.module').then(
        (m) => m.RequestsModule
      ),
    canActivate: [authGuard, AuthorizedGuard],
    canLoad: [authGuard],
  },
  {
    path: 'preguntas-frecuentes',
    loadChildren: () =>
      import('./modules/faqs/faqs.module').then((m) => m.FaqsModule),
    canActivate: [authGuard],
    canLoad: [authGuard],
    data: {
      containerEnabled: true,
    },
  },
  {
    path: 'contratos',
    loadChildren: () =>
      import('./modules/contract/contract.module').then((m) => m.ContractModule),
    canActivate: [authGuard, AuthorizedGuard],
    canLoad: [authGuard],
  },
  {
    path: 'tareo',
    loadChildren: () =>
      import('./modules/task/tasks.module').then((m) => m.TasksModule),
    canActivate: [authGuard, AuthorizedGuard],
    canLoad: [authGuard],
    canDeactivate: [EndRevisionGuard],
  },
  {
    path: 'coordinadores',
    loadChildren: () =>
      import('./modules/coordinators/coordinators.module').then(
        (m) => m.CoordinatorsModule
      ),
    canActivate: [authGuard, AuthorizedGuard],
    canLoad: [authGuard],
  },
  // !!!!!!! BORRAR ESTOS MENÚS:
  {
    path: 'configuraciones',
    loadChildren: () =>
      import('./modules/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
     canActivate: [authGuard, AuthorizedGuard],
     canLoad: [authGuard],
  },

  // {
  //   path: 'plantillas',
  //   loadComponent: () =>
  //     import('./modules/contract/components/template-crud/template-crud.component').then((m) => m.TemplateCrudComponent),
  //   canActivate: [authGuard, AuthorizedGuard],
  //   canLoad: [authGuard],
  // },

  // !!!!!!! BORRAR ESTOS MENÚS:
  {
    path: 'proyectos-requerimientos',
    loadChildren: () =>
      import(
        './modules/projects-requirements/projects-requirements.module'
      ).then((m) => m.ProjectsRequirementsModule),
    canActivate: [authGuard, AuthorizedGuard],
    canLoad: [authGuard],
  },
  {
    path: 'colaboradores',
    loadChildren: () =>
      import('./modules/collaborators/collaborators.module').then(
        (m) => m.CollaboratorsModule
      ),
    canActivate: [authGuard, AuthorizedGuard],
    canLoad: [authGuard],
  },
  {
    path: 'empresas',
    loadChildren: () =>
      import('./modules/company/company.module').then((m) => m.CompanyModule),
    canActivate: [authGuard, AuthorizedGuard],
    canLoad: [authGuard],
  },
  {
    path: "celebraciones",
    loadChildren: () =>
      import('./modules/celebrations/celebrations.module').then((m) => m.CelebrationsModule),
      canActivate: [authGuard, AuthorizedGuard],
      canLoad: [authGuard],
  },
  {
    path: "comunicaciones",
    loadChildren: () =>
      import('./modules/communications/communications.module').then((m) => m.CommunicationsModule),
      canActivate: [authGuard, AuthorizedGuard],
      canLoad: [authGuard],
  },
  {
    path: "tesoreria",
    loadChildren: () => import("./modules/treasury/treasury.module").then((m) => m.TreasuryModule),
    canActivate: [authGuard, AuthorizedGuard],
    canLoad: [authGuard],
  },
  {
    path: "notificaciones",
    loadChildren: () =>
      import("./core/notifications/notifications.module").then((m) => m.NotificationsModule),
    canActivate: [authGuard],
    canLoad: [authGuard],
    data: {
      containerEnabled: true,
    },
  },
  {
    path: 'common',
    loadChildren: () =>
      import('./core/common-pages/common-pages.module').then(
        (m) => m.CommonPagesModule
      ),
    canActivate: [authGuard],
    canLoad: [authGuard],
  },
  // {
  //   path: '**',
  //   redirectTo: 'common',
  // },
];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./core/auth/auth.module').then((m) => m.AuthModule),
  },
  // {
  //   path: 'service-unaviable',
  //   component : ServiceUnaviableComponent,
  //   // pathMatch: 'full',
  // },
  {
    path: '',
    component: LayoutComponent,
    children: childrenRoutes,
    canActivate: [authGuard],
    resolve: {
      preferences: layoutResolver}
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
