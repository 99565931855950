<div class="text-mg-accent md:text-sm text-xs " *ngIf="!isLoading && withLabel">
    {{label}}<span *ngIf="mode!= 'read' && required" class="text-mg-red">*</span>
</div>
<div class="animate-pulse bg-mg-preload rounded h-4 max-w-1/2" *ngIf="isLoading && withLabel"> </div>
<!-- DROPDOWN -->
<mat-form-field appearance="outline" class="w-full" *ngIf="!isLoading">
    <div class="w-full flex " [ngClass]="optionIcon && inputControl.value ? '-mt-1' : ''">
        <div class="p-1 rounded-lg flex items-center w-7 h-7 mr-2" *ngIf="optionIcon && IconOnInput" [class]="IconOnInput.class">
            <mg-icon class="flex-none" [iconName]="IconOnInput.name" [iconType]="IconOnInput.type"></mg-icon>
        </div>   
        <input type="text" class="flex md:text-sm text-xs " [placeholder]="placeholder" matInput
            [formControl]="inputControl" [matAutocomplete]="optionsAutocomplete" autocomplete="off"
            [disabled]="inputControl.disabled">
        <ng-container *ngIf="mode!= 'read'">
            <button *ngIf="inputControl.value" @scaleInOut (click)="inputControl.reset()" class="mr-4"
                [ngClass]="{'text-gray' :inputControl.disabled, 'text-black':inputControl.enabled}" type="button"
                [disabled]="inputControl.disabled">
                <mg-icon class="flex-none" iconClass="text-mg-gray text-sm" iconName="close"></mg-icon>
            </button>
            <mg-icon class="text-xl text-mg-main-blue-light flex items-center" iconClass="text-mg-gray text-sm" iconName="arrow_drop_down"></mg-icon>
        </ng-container>
    </div>
    <mat-hint *ngIf="hint_active">
        <div class="rounded bg-primary-light px-2 md:px-4 py-2 md:py-2 flex items-center w-full">
            <div class="mr-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                    <path fill="currentColor" viewBox="0 0 16 16"
                        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                </svg>
            </div>
            <div class="ml-1">
                <span [innerHTML]="hint_message"></span>
            </div>
        </div>
    </mat-hint>
    <mat-error class="whitespace-pre-line" *ngIf="inputControl.hasError('invalid')">* Seleccione una opción válida. </mat-error>
    <mat-error class="whitespace-pre-line" *ngIf="inputControl.hasError('required')">* Este campo es obligatorio. </mat-error>
    <mat-error class="whitespace-pre-line" *ngIf="inputControl.hasError('bIsInvalidarReq')">* Solo se pueden seleccionar proyectos activos.</mat-error>

    <mat-autocomplete #optionsAutocomplete="matAutocomplete" [displayWith]="mostrarDropdrown.bind(this)">
        <mat-optgroup *ngFor="let group of opcionesfiltradas | async" [label]="group.groupName">
            <ng-container *ngIf="!isScreenXS$">
                <mat-option *ngFor="let option of group.groupOptions" [value]="option.sCodigo"
                matTooltip="{{option.sDescripcion}}" matToolTipPosition="after" >
                {{option.sDescripcion}}
                </mat-option>
            </ng-container>
            <ng-container *ngIf="isScreenXS$">
                <mat-option *ngFor="let option of group.groupOptions" [value]="option.sCodigo">
                {{option.sDescripcion}}
                </mat-option>
            </ng-container>
        </mat-optgroup>
    </mat-autocomplete>
</mat-form-field>


<div class="animate-pulse bg-mg-preload rounded h-[3.25rem] mt-1 min-w-36 mb-5" *ngIf="isLoading"></div>