import * as moment from 'moment';
import { from, groupBy, mergeMap, of, toArray, zip } from 'rxjs';
import { localDB } from 'src/app/shared/helpers/localStorage';
import { getTimeHumanized } from 'src/app/shared/helpers/transformDate';
import {
  getShortText,
  setFirstLetterUppercase,
} from 'src/app/shared/helpers/transformText';
import {
  setDescriptionForNotificationByAssistance, 
  setDescriptionForNotificationByRequest,
  setDescriptionForNotificationByRequirement,
  setDescriptionForNotificationByTareo,
  setDescriptionForNotificationByTreasuryRequest,
  setDescriptionForNotificationByProject,
} from './template';
import { encriptar } from 'src/app/shared/helpers/encryption';
import { NotificationsDetail, NotificationsDetailMap } from '../interfaces/notifications.interface';
import { environment as env } from 'src/environments/environment';


export function groupByDates(data: NotificationsDetail[]) {
  let notificationsDataTemp: NotificationsDetailMap[] = [];
  from(data)
    .pipe(
      groupBy(
        (notification: NotificationsDetail) => notification.dFecha_Creacion.split('T')[0]
      ),
      mergeMap((group) => zip(of(group.key), group.pipe(toArray())))
    )
    .subscribe((finalGroup) => {
      let day = moment(finalGroup[0], 'YYYY-MM-DD');
      let sDay = day.format('DD') + ' de ' + day.format('MMMM');
      sDay = moment().isSame(day, 'day') ? 'Hoy, ' + sDay : sDay;
      notificationsDataTemp.push({
        fecha: sDay,
        notificaciones: setArrayOfNotifications(finalGroup[1]),
      });
    });
  return notificationsDataTemp;
}

function setArrayOfNotifications(aNotifications: NotificationsDetail[]) {
  aNotifications.map((notification) => {
    notification.sIcono = getIconByNotificationType(notification.sModulo);
    notification.humanizatedTime = setFirstLetterUppercase(
      getTimeHumanized(notification.dFecha_Creacion)
    );
    // notification.bMostrarBotones = getShowIconsApproveOrReject(notification);
    notification.sDescripcion =
      setDescriptionForNotification(
        notification.sMensaje,
        notification.sModulo
      ) ?? '';
    notification.sDescripcionCorto = getShortText(
      notification.sDescripcion,
      230
    );
    notification.bMostrarDescripcionCorto =
      notification.sDescripcionCorto == notification.sDescripcion ? null : true;
  });
  return aNotifications;
}

function getIconByNotificationType(type: string) {
  switch (type.toUpperCase()) {
    case 'SOLICITUD':
      return 'receipt_long';

    case 'TAREO':
      return 'task';

    case 'ASISTENCIA':
      return 'timer';

    case 'REQUERIMIENTO':
      return 'business_center';

    case 'TESORERÍA':
      return 'paid';

    case 'PROYECTO':
      return 'business_center';

    default:
      return 'task';
  }
}

export function setDescriptionForNotification(mensaje: string, type: string) {
  let oMsj = JSON.parse(mensaje);
  let idUserLoggedIn = parseInt(localDB.getItem('idUsuario'));
  oMsj.existOwnerAndIsLoggedIn =
    oMsj.nPropietario != undefined && oMsj.nPropietario == idUserLoggedIn;
  oMsj.existOwnerAndIsNotLoggedIn =
    oMsj.nPropietario != undefined && oMsj.nPropietario != idUserLoggedIn;
  switch (type.toUpperCase()) {
    case 'SOLICITUD':
      return setDescriptionForNotificationByRequest(oMsj);

    case 'TAREO':
      return setDescriptionForNotificationByTareo(oMsj);

    case 'ASISTENCIA':
      return setDescriptionForNotificationByAssistance(oMsj);

    case 'REQUERIMIENTO':
      return setDescriptionForNotificationByRequirement(oMsj);

    case 'TESORERÍA':
      return setDescriptionForNotificationByTreasuryRequest(oMsj);

    case 'PROYECTO':
      return setDescriptionForNotificationByProject(oMsj);

    default:
      console.warn(
        'Error de acción en tipo de solicitudes notificaciones',
        type
      );
      return '';
  }
}

export function goToModuleNotifications(notification: NotificationsDetail) {
    if (notification.sLink_Notificacion) {
      let urlComplete = notification.sLink_Notificacion
        .split("/")
        .slice(3, 5)
        .join("/");
      let url = urlComplete.split("?")[0];
      let queryParams = urlComplete.split("?")[1].split("&");
      let newQueryParams: any = new Object();
      queryParams.forEach((param:string) => {
        let [key, value] = param.split("=");
        newQueryParams[ encriptar(env.securityKey, key)] = encriptar(env.securityKey, value);
      });
  
      return {url, newQueryParams};
    } else {
      return null;
    }
  }
