<div class="text-mg-accent md:text-sm text-xs" *ngIf="!isLoading && withLabel">
    {{label}}<span *ngIf="mode!= 'read' && required" class="text-mg-red">*</span>
</div>

<div class="animate-pulse bg-mg-preload rounded h-4 max-w-1/2" *ngIf="isLoading && withLabel"></div>

<mat-form-field appearance="outline" class="w-full " *ngIf="!isLoading">
    <input readonly matInput [matDatepicker]="pickeres" [placeholder]='placeholder' [matDatepickerFilter]="filters"
        class="flex-grow md:text-sm text-xs" autocomplete="off" [formControl]="inputControl | formTransform:'formControl'" [min]="min" [max]="max">
    <mat-datepicker-toggle matSuffix [for]="pickeres"></mat-datepicker-toggle>
    <mat-datepicker [dateClass]="dateClass" (opened)="onOpen()" #pickeres></mat-datepicker>
    <mat-error *ngIf="inputControl.hasError('matDatepickerFilter')">* Fecha no válida.</mat-error>
    <mat-error *ngIf="inputControl.hasError('required')">* Este campo es obligatorio. </mat-error>
    <mat-error *ngIf="inputControl.hasError('matDatepickerMin')">* La Fecha no puede ser menor a
        {{inputControl.errors!['matDatepickerMin'].min| date:'dd/MM/yyyy' }}. </mat-error>
    <mat-error *ngIf="inputControl.hasError('matDatepickerMax')">* La Fecha no puede ser mayor a
        {{inputControl.errors!['matDatepickerMax'].max| date:'dd/MM/yyyy' }}. </mat-error>
</mat-form-field>

<mat-hint *ngIf="inputControl.value && hint && arrayHoursPermiso.length">
    <div class="rounded bg-primary-light px-1 mb-2 md:px-2 py-2 md:py-2 flex items-center w-full">
        <div class="mr-1">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                <path fill="currentColor" viewBox="0 0 16 16"
                    d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
            </svg>
        </div>
        <div class="ml-1 text-xxs">
            <ng-container *ngFor="let i of arrayHoursPermiso">
                <span>Permiso de <b>{{i.inicio}}</b> a <b>{{i.fin}}</b><br></span>
            </ng-container>
        </div>
    </div>
</mat-hint>

<div style="display: none">
    <div class="grid grid-cols-2 gap-3 p-4" #datepickerFooter>
        <div class="flex flex-col items-center text-xs">
            <div [class]="cssserie1" style="height: 15px; width: 15px;"></div>{{titleserie1}}
        </div>
        <div class="flex flex-col items-center text-xs">
            <div [class]="cssserie2" style="height: 15px; width: 15px;"></div>{{titleserie2}}
        </div>
        <div class="flex flex-col items-center text-xs">
            <div [class]="cssserie3" style="height: 15px; width: 15px;"></div>{{titleserie3}}
        </div>
        <div class="flex flex-col items-center text-xs">
            <div [class]="cssserie4" style="height: 15px; width: 15px;"></div>{{titleserie4}}
        </div>
    </div>
</div>
<div class="animate-pulse bg-mg-preload rounded h-[3.25rem] mt-1 min-w-36 mb-5" *ngIf="isLoading"></div>