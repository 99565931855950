import { NgModule, LOCALE_ID, isDevMode, APP_INITIALIZER } from '@angular/core'; // <-- Agregado APP_INITIALIZER
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from "@angular/common";
import localeEs from "@angular/common/locales/es";
import { LayoutModule } from './core/layout/layout.module';
import { Interceptor } from './core/interceptors/interceptor.interceptor';
import { AuthService, socialAuthServiceConfigFactory } from './core/services/auth.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { DatePipe } from '@angular/common';
 
import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { MatMomentDateModule } from '@angular/material-moment-adapter'; 
import { ConfigEnviromentService } from './core/services/configEnviroment.service'; // <-- Agregado

registerLocaleData(localeEs);
 
// Función de inicialización para cargar la configuración antes del bootstrap
export function initializeApp(configService: ConfigEnviromentService) { // <-- Agregado
  return () => configService.loadConfig(); // <-- Agregado
}

@NgModule({
  declarations: [AppComponent],
  imports: [ 
    BrowserModule, 
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatDialogModule,
    MatMomentDateModule,
    LayoutModule, 
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
       enabled: !isDevMode(),
      //enabled: true,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    SocialLoginModule 
  ],
  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: "es" },
    { provide: MAT_DATE_LOCALE, useValue: "es-ES" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
    /*{
      provide: 'SocialAuthServiceConfig',
      useValue: socialAuthServiceConfigFactory(),
    },*/
    AuthService,
    ConfigEnviromentService, // <-- Agregado
    {
      provide: APP_INITIALIZER, // <-- Agregado
      useFactory: initializeApp, // <-- Agregado
      deps: [ConfigEnviromentService], // <-- Agregado
      multi: true // <-- Agregado
    },
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: socialAuthServiceConfigFactory,
      deps: [ConfigEnviromentService], // Se añade la dependencia del servicio de configuración
      multi: false
    }
  ],
  bootstrap: [AppComponent], 
})
export class AppModule {}
