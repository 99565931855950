import { Injectable } from '@angular/core';
import { IndexedDBDexieService } from 'src/app/core/indexedDB/service/indexed-db-dexie.service';
import { TareoComponent } from 'src/app/modules/my-board/components/tareo/tareo.component';
import { localDB } from 'src/app/shared/helpers/localStorage';
import { MarkButtonsComponent } from '../components/marking/mark-buttons/mark-buttons.component';
import { RequestsComponent } from 'src/app/modules/my-board/components/requests/requests.component';
import { RequestTreasuryComponent } from 'src/app/modules/my-board/components/request-treasury/request-treasury.component';
import { GreetingComponent } from 'src/app/modules/my-board/components/greeting/greeting.component';
import { ExtemporaneousMarksComponent } from 'src/app/modules/my-board/components/extemporaneous-marks/extemporaneous-marks.component';
import { CelebrationsComponent } from 'src/app/modules/my-board/components/celebrations/celebrations.component';
import { AttendanceIndicatorsComponent } from 'src/app/modules/my-board/components/attendance-indicators/attendance-indicators.component';
import { AnnouncementBannerComponent } from 'src/app/modules/my-board/components/announcements/announcements.component';
//import { ContextMenuComponent } from '../../components/context-menu/context-menu.component';
@Injectable({
  providedIn: 'root'
})
export class CacheService {
    private refresfId = new Set<number>();
    //contextMenu: ContextMenuComponent;
    attendanceCard: AttendanceIndicatorsComponent;
    celebrationsCard: CelebrationsComponent;
    extemporaneousMarksRequestComponent: ExtemporaneousMarksComponent;
    greetingCard: GreetingComponent;
    treasuryCard: RequestTreasuryComponent;
    requestCard: RequestsComponent;
    MarksCard: MarkButtonsComponent;
    taskCard: TareoComponent;
    announcementCard: AnnouncementBannerComponent;

  constructor(private _indexedDB: IndexedDBDexieService) {}

  async refreshAllValues() {
    await this._indexedDB.refreshAllData().then(() => {
      if (this.refresfId.has(2)) this.MarksCard.reloadData();
      if (this.refresfId.has(3)) this.announcementCard.setAnnouncement();
      if (this.refresfId.has(4)) this.celebrationsCard.reloadData();
      if (this.refresfId.has(5)) this.requestCard.reloadData();
      if (this.refresfId.has(6)) this.extemporaneousMarksRequestComponent.reloadData();
      if (this.refresfId.has(8)) this.taskCard.reloadData();
      if (this.refresfId.has(10)) this.attendanceCard.reloadData();
      if (this.refresfId.has(11)) this.treasuryCard.reloadData();
      localDB.cleanCache([
        'horario', 'c-my-marks', 'c-red-button', 'c-extemp-marks', 
        'c-assistance', 'c-requests', 'celebrate-banner', 
        'c-tareo', 'c-requests-treasury'
      ]);
    });
  }
}
