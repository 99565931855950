<section [ngClass]="isSmallScreen ? 'hidden' : ''"
class="fixed !z-[999999999999999999999999999] flex items-end w-screen h-screen top-0 right-0 pointer-events-none">
<section class="w-full h-full">
    <div *ngIf="allToast['top-r'].length" [class]="'absolute  !pt-[3rem] flex flex-col gap-5 top-4 right-4' ">
        <div *ngFor="let toast of allToast['top-r']" class="flex flex-col  gap-4 pointer-events-auto">
            <mg-toast-alert [toastData]="toast"></mg-toast-alert>
        </div>
    </div>
    <div *ngIf="allToast['top-l'].length" [class]="'absolute  !pt-[3rem] flex flex-col gap-5 top-4 left-4' ">
        <div *ngFor="let toast of allToast['top-l']" class="flex flex-col gap-4 pointer-events-auto">
            <mg-toast-alert [toastData]="toast"></mg-toast-alert>
        </div>
    </div>
    <div *ngIf="allToast['bottom-r'].length" [class]="'absolute flex flex-col-reverse gap-5 bottom-4 right-4'">
        <div *ngFor="let toast of allToast['bottom-r']" class="flex flex-col gap-4 pointer-events-auto">
            <mg-toast-alert [toastData]="toast"></mg-toast-alert>
        </div>
    </div>
    <div *ngIf="allToast['bottom-l'].length" [class]="'absolute flex flex-col-reverse gap-5 bottom-4 left-4' ">
        <div *ngFor="let toast of allToast['bottom-l']" class="flex flex-col gap-4 pointer-events-auto">
            <mg-toast-alert [toastData]="toast"></mg-toast-alert>
        </div>
    </div>
</section>
</section>
