import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable, debounceTime, lastValueFrom, map, startWith } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  OptionSearch,
  ReturnSearch,
} from '../../../interfaces/suggestions-search-box.interface';
import { localDB } from 'src/app/shared/helpers/localStorage';
import { configurations } from 'src/app/shared/interfaces/configurations.interface';
import { ConfigurationsService } from 'src/app/shared/services/configurations.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { IconComponent } from '../../icon/icon.component';
import { scaleInOutAnimation } from '../../../animations/scale-in-out.animation';
import { REFRESH_INDEXED } from 'src/app/core/indexedDB/helpers/enums.enums';

@Component({
  selector: 'mg-suggestions-search-box',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatMenuModule,
    MatAutocompleteModule,
    IconComponent,
  ],
  templateUrl: './suggestions-search-box.component.html',
  styleUrls: ['./suggestions-search-box.component.scss'],
  animations: [scaleInOutAnimation],
})
export class SuggestionsSearchBoxComponent implements OnChanges {
  observable: Observable<configurations[]>;
  form: FormGroup;
  showAutocomplete: boolean = false;
  validation_desc: string = '';
  isActive: boolean = false;
  itemsSearch: configurations[] = [];
  itemsType: { [key: string]: configurations[] };
  typeSelected: number;
  optionSearch: OptionSearch;

  @Input() refresh: boolean = false;
  @Input() clearText: boolean = false;
  @Input() refreshFromConfigurations: boolean = false;

  @Input() searchOptions: OptionSearch[] = [];
  @Input() currentValue: string = '';
  @Input() changeTypeList: string = '';
  @Output() search = new EventEmitter<ReturnSearch>();
  @Input() isSmallScreen: boolean =   (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 740;

  constructor(
    private fb: FormBuilder,
    private _configurationsService: ConfigurationsService
  ) {
    this.form = this.fb.group({
      searchData: [''],
      searchType: [''],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["clearText"]) {
      if (changes["clearText"]["currentValue"]==true) {
        this.setValue("");
        return;
      }
    }

    if (changes['currentValue']) {
      this.setValue(changes['currentValue']['currentValue']);
    }

    if (changes['refresh'] && !changes['changeTypeList'] && !changes['currentValue']){
      this.changeSelection(this.searchOptions[0]);
    }

    if (changes['refreshFromConfigurations']) this.setValue("");

    if (changes["searchOptions"]) {
      if (
        changes["searchOptions"]["currentValue"]
      ) {
        this.searchOptions = changes["searchOptions"]["currentValue"];
        this.optionSearch = this.searchOptions[0];
        this.getAsyncInfo(this.optionSearch.configList, "");
      }
    }
  }

  ngOnInit(): void {
    this.changeSelection(this.searchOptions[0]);
    this.form.get('searchData')?.valueChanges.subscribe((e) => {
      if (e) {
        if (e.trim() == '') this.submit(true);
      }
    });
  }

  setSearchStringValidation(validation: string[], minLength: number) {
    let searchData = this.form.get('searchData');
    searchData?.clearValidators();
    let setValidation = [];
    setValidation.push(Validators.required);
    setValidation.push(Validators.minLength(minLength));
    if (validation) {
      validation.forEach((e) => {
        setValidation.push(e);
      });
    }
    searchData?.setValidators(setValidation);
  }

  changeSelection(option: OptionSearch) {
    let slugFind = option.aSlugList
      .filter((x) => x.typeList == this.changeTypeList)
      .map((x) => x.slugSearch);
    this.getAsyncInfo(option.configList, slugFind[0]);
    this.optionSearch = option;
    this.typeSelected = option.type;
    this.form.get('searchType')?.setValue(option.type);
    this.validation_desc = option.validation_desc ? option.validation_desc : '';
    let min_length: number = option.min_length ? option.min_length : 1;
    this.setSearchStringValidation(option.validation, min_length);
  }

  reset() {
    this.form.get('searchData')?.setValue("");
    this.submit(true, true);
    this.currentValue = "";
    this.form.get('searchData')?.setValue(null);
  }

  submitOption(dataOption: string) { 
    let data = this.form.getRawValue();
    data.searchData = dataOption;
    data.searchType = this.optionSearch?.type;
    this.search.emit(data);
  }

  submit(reset: boolean = false, resetAllValues: boolean = false) { 
    if (!reset) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }

    let data = this.form.getRawValue();
    data.searchData = data.searchData.trim();
    data.searchType = this.optionSearch?.type;

    if (resetAllValues) {
      data.resetValue = true;
      this.search.emit(data);
      return;
    }
    this.search.emit(data);
  }

  private async getAsyncInfo(searchType: string, slugListado: string) {
    this.itemsType = await lastValueFrom(
      this._configurationsService.getConfig( REFRESH_INDEXED.EVERY_REFRESH,
        [searchType],
        Number(localDB.getItem('idUsuario')),
        undefined,
        undefined,
        slugListado
      )
    );
    if (JSON.stringify(this.itemsType) !== '{}') {
      this.itemsSearch = this.itemsType[Object.keys(this.itemsType)[0]];
      this.observable = this.form.get('searchData')!.valueChanges.pipe(
        startWith(''),
        map((item: string) =>
          // item ? this._filter(item) : this.itemsSearch.slice()
          item ? this._filter(item) : (this.itemsSearch) ? this.itemsSearch.slice() : []
        )
      );
    } else {
      this.itemsSearch = [];
      this.showAutocomplete = false;
    }
  }

  private _filter(searchData: string): configurations[] {
    const filterValue = searchData.toLowerCase();
      return this.itemsSearch?.filter((item) =>
        item.sDescripcion.toLowerCase().includes(filterValue)
      );
  }

  updatedValue(e: string) {
    if (e && e.length >= 3) {
      this.showAutocomplete = true;
    } else {
      this.showAutocomplete = false;
    }
  }

  setValue(itemSearch: any) {
    this.form.get('searchData')?.setValue(itemSearch);
  }
}