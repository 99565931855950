export function thereAreRecentversions(a: string, b: string): boolean {
    let x = a.split('.');
    let y = b.split('.');
    let z = "";

    for (let i = 0; i < Math.max(x.length, y.length); i++) {
        let xPart = parseInt(x[i]) || 0;
        let yPart = parseInt(y[i]) || 0;

        if (xPart === yPart) {
            z += "e";
        } else if (xPart > yPart) {
            z += "m";
        } else {
            z += "l";
        }
    }

    if (!z.match(/[l|m]/g)) {
        // Igual
        return false;
    } else if (z.split('e').join('')[0] == "m") {
        // Mayor
        return false;
    } else {
        // Menor
        return true;
    }
}