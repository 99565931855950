import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AccessService } from 'src/app/core/services/access.service';
import { RevisionService } from 'src/app/core/services/revision.service';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { encriptar } from 'src/app/shared/helpers/encryption';
import { Router, NavigationEnd } from '@angular/router';
import { environment as env } from 'src/environments/environment';

@Component({
  selector: 'mg-revision-banner',
  templateUrl: './revision-banner.component.html',
  styleUrls: ['./revision-banner.component.scss'],
  standalone: true,
  imports: [CommonModule, IconComponent]
})
export class RevisionBannerComponent implements OnInit {
  showBanner = false;
  currentRoute = ""

  constructor(
      private _revisionService: RevisionService,
      public router: Router,
      private _accessService: AccessService) { 
        
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.onRouteChange();
          }
        });
  }

  ngOnInit(): void {
    this.currentRoute = location.pathname 
    this.getRevision();
  }
  
  getRevision(){
    if(this._accessService.validarPermisoSlug('TASK-BUTTON-REVISION')){
      this._revisionService.revision$.subscribe((resp) => {
        if (resp && resp['REVISION'][0].sCodigo) {
          this.showBanner = true;
        } else {
          this.showBanner = false;
        }
      });
    }
  }

  goToTareo(){
    let params:any = new Object({})
    params[encriptar(env.securityKey,'sTipo_Listado')] = encriptar(env.securityKey,'TEAM-HAVE-SUBORDINATE'),
    
    this.router.navigate(["tareo/lista"], { queryParams: params})
  }

  onRouteChange() {
    this.currentRoute = location.pathname 
  }
  
}
