import { BadgeStatus, IconBadge } from "src/app/shared/interfaces/badges.interface";
import { actionMenuCards } from "src/app/shared/interfaces/list-cards.interface";
import { Moment } from "moment";
import { configurations } from "src/app/shared/interfaces/configurations.interface";

export interface tareoStatus {
  oTareoByStatus: OTareoByStatus;
  aAllTareoStatus: AAllTareoStatus[];
};

export interface AAllTareoStatus {
  nId_Colaborador?: number;
  sEstado: SEstado;
  sEstado_Horario: SEstadoHorario;
  nRequired: number;
  nReady: number;
  entrada: string;
  salida: string;
  dHora_Inicio: string;
  dHora_Fin: string;
  nMinutos_Permiso: number;
  dDate: string;
  nEntryMark: number;
  nMaxMinutesRegular: number;
  nMaxMinutesRegularEditar: number;
  nMaxMinutesExtra: number;
  nMaxMinutesExtraEditar: number;
  nReadyMark?: number;
};

export interface OTareoByStatus {
  COMPLETO: string[];
  INCOMPLETO: string[];
  FALTA: string[];
  WITHOUT_MARKS: string[];
  REVISION: string[];
};

export enum SEstado {
  Completo = "COMPLETO",
  Falta = "FALTA",
  Incompleto = "INCOMPLETO",
};
  
enum SEstadoHorario {
  Feriado = "FERIADO",
  FueraHorarioRegular = "FUERA_HORARIO_REGULAR",
  HorarioRegular = "HORARIO_REGULAR",
  PermisoDias = "PERMISO_DIAS",
  PermisoHoras = "PERMISO_HORAS",
};

export interface ITaskList {
  nId: number;
  nId_Usuario: number;
  nId_Colaborador: number;
  sNombre_Colaborador: string;
  dFecha_Registro: string | null;
  nMinutos: number;
  sCodigo_Tipo_Actividad: string;
  sNombre_Tipo_Actividad: string;
  sCodigo_Proyecto: number;
  sNombre_Proyecto: string;
  sCodigo_Categoria: string;
  sNombre_Categoria: string;
  sCodigo_Tipo_Horas: string;
  sNombre_Tipo_Horas: string;
  sDetalle: string;
  sTipo_Servicio: string;
  nId_Cliente: number;
  sNombre_Cliente: string;
  sNombre_Coordinador: string;
  dHora_Inicio: string | null;
  dHora_Fin: string | null;
  dFecha_Fin: string | null;
  sEstado: string;
  sNombre_Estado: string;
  nContador_Obs: number;
  nContador_Historico: number;
  sCodigo_Requerimiento: string;
  sNombre_Requerimiento: string;
  revisado: number | null;
  dFechaCreacion: Date;
  total: number | null;
  
  // Additional properties added on the frontend
  nId_Falso: number;
  badgeHFinHini: any;
  bgRange: any;
  sMensaje_Error: string | null;
  icHistorico: boolean | IconBadge;
  badgeDateWithIconHistoric: BadgeStatus;
  icEditarTarea: boolean | IconBadge;
  icEliminarTarea: boolean | IconBadge;
  icDuplicarTarea: boolean | IconBadge;
  icAprobarTarea: boolean | IconBadge;
  icObservarTarea: boolean | IconBadge;
  icObservations: boolean | IconBadge;
  badgeHoraTareo: BadgeStatus;
  badgeStates: BadgeStatus;
  badgeTipoHoras: BadgeStatus;
  badgeProjectWithIconHistoric?: any;
  actionMenuCards: actionMenuCards[];
};
// MODAL DE CREACION

export interface TempTaskModal {
  dFecha_Registro:        Moment | string;
  nMinutos:               number;
  nCantidad:              number;
  sCodigo_Tipo_Actividad: string;
  sCodigo_Proyecto:       string;
  sCodigo_Requerimiento:  string;
  sCodigo_Categoria:      string;
  sCodigo_Tipo_Horas:     string;
  sNombre_Tipo_Actividad?:string;
  sNombre_Proyecto:       string;
  sNombre_Categoria:      string;
  sNombre_Tipo_Horas:     string;
  sNombre_Requerimiento:  string;
  sDetalle:               string;
  sAnotaciones:           string;
  id?:                    number;
  editState?:             boolean;
  sFecha_Registro?:       string;
  sHoras?:                string;
  sMensaje_Error?:        string;
};

export interface StatusTask {
  nId_Colaborador:          number;
  dDate:                    string;
  nRequired:                number;
  nReady:                   number;
  nEntryMark:               number;
  sEstado:                  string;
  sEstado_Horario:          string;
  nMinutos_Permiso:         number;
  entrada:                  string;
  salida:                   string;
  nMaxMinutesRegular:       number;
  nMaxMinutesRegularEditar: number;
  nMaxMinutesExtra:         number;
  nMaxMinutesExtraEditar:   number;
};

export interface TaskFocus {
  action: string;
  id:     number;
};

export interface TaskUpdate {
  form:       TempTaskModal;
  beforeInfo: BeforeInfo;
};

export interface BeforeInfo {
  date:    Moment;
  minutes: number;
};


// historico 

export interface TaskHistoric {
  nId_Historico:   number;
  sNombre_Creador: string;
  dFecha_Creador: string;
  sCambios:        string;
  nTipo_Historico: number;
  sNombre_Tipo:    string;
  sTipo_Historico: string;
  css_Historico: string;
  sCambiosSinEtiqueta: string;
  marcas?: Marca[];
  sProcedencia_Historico?: string;
}

interface Marca {
  css: string;
  hora_marca: string;
  sIcono: string;
  tipo_marca: string;
}

//listado
export interface TaskData {
  nId:                          number;
  nId_Usuario:                  number;
  nId_Colaborador:              number;
  sNombre_Colaborador:          string;
  dFecha_Registro:              Date;
  nMinutos:                     number;
  sCodigo_Tipo_Actividad:       string;
  sNombre_Tipo_Actividad:       string;
  sCodigo_Proyecto:             number;
  sNombre_Proyecto:             string;
  sCodigo_Categoria:            string;
  sNombre_Categoria:            string;
  sCodigo_Tipo_Horas:           string;
  sNombre_Tipo_Horas:           string;
  sDetalle:                     string;
  sTipo_Servicio:               string;
  nId_Cliente:                  number;
  sNombre_Cliente:              string;
  sNombre_Coordinador:          string;
  dHora_Inicio:                 null;
  dHora_Fin:                    null;
  dFecha_Fin:                   null;
  sEstado:                      string;
  sNombre_Estado:               string;
  nContador_Obs:                number;
  nContador_Historico:          number;
  sCodigo_Requerimiento:        string;
  sNombre_Requerimiento:        string;
  revisado:                     number;
  dFechaCreacion:               Date;
  total:                        number;
  nId_Falso:                    number;
  badgeHFinHini:                BadgeHFinHiniClass;
  badgeRango:                   BadgeHFinHiniClass;
  sMensaje_Error:               null;
  icHistorico:                  boolean;
  badgeProjectWithIconHistoric: BadgeWithIconHistoric[];
  badgeDateWithIconHistoric:    BadgeWithIconHistoric[];
  icEditarTarea:                ICArTarea;
  icEliminarTarea:              boolean;
  icDuplicarTarea:              boolean;
  icAprobarTarea:               ICAprobarTarea;
  icObservarTarea:              ICArTarea;
  icObservations:               boolean;
  badgeHoraTareo:               BadgeHoraTareo;
  badgeEstados:                 BadgeEstadosClass;
  badgeTipoHoras:               BadgeEstadosClass;
  actionMenuCards:              ActionMenuCard[];
  sColor:                       string;
  resalt:                       string;
};

export interface ActionMenuCard {
  label:   string;
  icon:    string;
  cssIcon: string[];
  type:    string;
  action:  string;
  visible: boolean;
};

export interface BadgeWithIconHistoric {
  type:       string;
  css:        string;
  firstLabel: string;
  twoLabel:   string;
  tooltip:    string;
};

export interface BadgeEstadosClass {
  tooltip: string;
  label:   string;
  css:     string;
};

export interface BadgeHFinHiniClass {
  data: Data | null;
  css:  null | string;
};

export interface Data {
  dFecha_inicio: null;
  dFecha_Fin:    null;
  dHora_inicio:  null;
  dHora_fin:     null;
};

export interface BadgeHoraTareo {
  label: string;
  css:   string;
};

export interface ICAprobarTarea {
  iconObj: IconObj;
  iconCss: string[];
  tooltip: string;
};

export interface IconObj {
  body:   string;
  width:  number;
  height: number;
};

export interface ICArTarea {
  iconObj: IconObj;
  iconCss: string[];
};

export interface TaskObservInfo {
  nId_Observacion:     number;
  sNombre_Colaborador: string;
  sNombre_rol:         string;
  sComenario_Obs:     string;
  sDetalle_Obs:        string;
  sNombre:             string;
  date:                string;
};

export interface Observation{
  sObservacion: string;
  aId_Tareas?: number[];
};

export interface ColabBadgeMin {
  tooltip: string;
  label:   string;
  css:     string;
};

// UPDATE TASK
export interface UpdateTaskConfig {
  loadingInput:               LoadingInput;
  minDate:                    Date;
  maxDate:                    Date;
  maxValueNMinutes:           number;
  currentValidation:          CurrentValidation;
  dHora_Inicio_Hint?:         boolean;
  CATEGORIA_TAREA:            configurations[];
  PROYECTOS:                  configurations[];
  PROYECTOS_COLABORADOR:      configurations[];
  PROYECTOS_MOSTRAR:          ProyectosMostrar[];
  PROYECTOS_LIDER_DIRECTOR?:  configurations[];
  REQUERIMIENTOS_LIDER?:      configurations[];
  TIPO_ACTIVIDAD:             configurations[];
  TIPO_HORA:                  configurations[];
  REQUERIMIENTO_POR_PROYECTO: configurations[];
  aAllTareoStatus:            CurrentValidation[];
  oTareoByStatus:             OTareoByStatus;
};

export enum STipoConfiguracion {
  Categorias = "CATEGORIAS",
  Proyectos = "PROYECTOS",
  ProyectosColaborador = "PROYECTOS_COLABORADOR",
  Requerimiento = "REQUERIMIENTO",
  TipoActividad = "TIPO_ACTIVIDAD",
  TipoHora = "TIPO_HORA",
};

export interface ProyectosMostrar {
  groupValue:   string;
  groupName:    string;
  groupOptions: configurations[];
};

export interface CurrentValidation {
  nId_Colaborador?:          number;
  dDate:                    string;
  nRequired:                number;
  nReady:                   number;
  nEntryMark:               number;
  sEstado:                  SEstado;
  sEstado_Horario:          SEstadoHorario;
  nMinutos_Permiso:         number;
  entrada:                  string;
  salida:                   string;
  nMaxMinutesRegular:       number;
  nMaxMinutesRegularEditar: number;
  nMaxMinutesExtra:         number;
  nMaxMinutesExtraEditar:   number;
};

export interface LoadingInput {
  all:   boolean;
  rq:    boolean;
  categ: boolean;
};

export interface getArrayWithBadgeAndIcon{
  type: string;
  typeAction?: string;
  data: BadgeStatus | boolean;
};


export interface AllPry_RqFilter{
  aProyectos: PryRqFilter[];
  aEquipos: PryRqFilter[];
  aTodos: PryRqFilter[];
  //aRequerimeintos_Categorias: CatFilter[];
};

export interface PryRqFilter {
  nId_Proyecto: number;
  sNombre_Proyecto: string;
  dFecha_Inicio_Pendiente:string | Moment | null;
  dFecha_Fin_Pendiente:string | Moment | null;
  aRequerimiento: PryRqFilterDetail[];
  nMin_Pendiente: number;
  nMin_Aprobadas: number;
  nMin_Estimadas: number;
  nMin_Restantes: number;
  nEstado_Proyecto:number;
  sEstado_Proyecto:string;
};

interface PryRqFilterDetail {
  nId_Requerimiento: number;
  dFecha_Inicio_Pendiente:string | Moment | null;
  dFecha_Fin_Pendiente:string | Moment | null;
  sNombre_Requerimiento: string;
  nMin_Pendiente: number;
  nMin_Aprobadas: number;
  nMin_Estimadas: number;
  nId_Property: number;
  nMin_Restantes: number;
};

export interface CatFilter {
  nId_Requerimiento: number;
  nId_Lider: number;
  dFecha_Inicio_Pendiente:string | Moment | null;
  dFecha_Fin_Pendiente:string | Moment | null;
  nEstado_Requerimiento: number;
  sEstado_Requerimiento: string;
  sNombre_Requerimiento: string;
  aCategorias: CatFilterDetail[];
  nMin_Pendiente: number;
  nMin_Aprobadas: number;
  nMin_Estimadas: number;
  nMin_Restantes: number;
  principalAction?: boolean;
  nLider:number;
};

export interface AllCatfilter {
  aRequerimientos: CatFilter[];
};

interface CatFilterDetail {
  nId_Categoria: number;
  sNombre_Categoria: string;
  dFecha_Inicio_Pendiente:string | Moment | null;
  dFecha_Fin_Pendiente:string | Moment | null;
  nMin_Pendiente: number;
  nMin_Aprobadas: number;
  nMin_Estimadas: number;
  nMin_Restantes: number;
};

export interface ListTeamTaskIndicatorResponse{
  nId_Colaborador: number;
  sUrl_Foto: string;
  sNombre_Colaborador: string;
  nEstado_Asistencia: number;
  dFecha: Date | string;
  nMinutos: number;
  nId_Team: number;
  sId_Solicitudes: null | string;
  sEstado: string;
};


export interface DateAndStatusTask {
  sEstado: string;
  dDate: string;
  dRegistrationDate: string;
  nReadyMark: number;
};



export interface HourTypeWithValidations {
  sCodigo: string;
  sDescripcion: string;
  sTipo_Configuracion: string;
  bIsInvalidOption?: boolean;
  firstDate?: string;
  lastDate?: string
};