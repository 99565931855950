import * as CryptoJS from 'crypto-js';

function validarCifrado(startEncryptedValue: string) {
  if (startEncryptedValue !== 'U2FsdGVkX1') {
    localStorage.clear();
    return false;
  }
  return true;
}

export function encriptar(password: string, planeText: string): string {
  var key = CryptoJS.enc.Utf8.parse(password);
  var iv = CryptoJS.enc.Utf8.parse(password);    
  var encrypted = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(planeText.toString()),
    key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ); 
  return encrypted.toString();
}

export function desencriptar(password: string, base64Encryption: string): string {
  var key = CryptoJS.enc.Utf8.parse(password);
  var iv = CryptoJS.enc.Utf8.parse(password);    
  var decrypted = CryptoJS.AES.decrypt(
    base64Encryption, key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ); 
  return decrypted.toString(CryptoJS.enc.Utf8);
}