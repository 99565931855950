import { NameWithPic } from "../interfaces/textWithPic.interface";

export function getTextWithPic(nameWithPic: NameWithPic): NameWithPic {
    // if (nameWithPic.primaryText.action && nameWithPic.primaryText.iconHover) {
    //   nameWithPic.primaryText.iconHover.name = nameWithPic.primaryText.iconHover.name;
    // }
  
    // if (nameWithPic.secundaryText) {
    //   if (
    //     nameWithPic.secundaryText.action &&
    //     nameWithPic.secundaryText.iconHover
    //   ) {
    //     nameWithPic.secundaryText.iconHover.name =  nameWithPic.secundaryText.iconHover.name;
    //   }
    // }
  
    return {
      urlPic: nameWithPic.urlPic ? nameWithPic.urlPic : "assets/svg/utils/avatar_default.svg",
      primaryText: nameWithPic.primaryText,
      secundaryText: nameWithPic.secundaryText ? nameWithPic.secundaryText : null,
      cssProperty: "text-sm min-w-max max-w-[450px]"
    };
  }