import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { Observable, map, startWith } from "rxjs";
import { CommonModule } from "@angular/common";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { scaleInOutAnimation } from "src/app/shared/animations/scale-in-out.animation";
import { IconComponent } from "../../../icon/icon.component";

export interface Group {
  groupValue:   string;
  groupName:    string;
  groupOptions: Option[];
  bVisible?:    boolean;
}

export interface Option {
  sCodigo:             string;
  sDescripcion:        string;
  sTipo_Configuracion: string;
}

@Component({
  selector: "mg-input-select-group",
  templateUrl: "./select-group.component.html",
  styleUrls: ["./select-group.component.scss"],
  animations: [scaleInOutAnimation],
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    IconComponent
  ],
})
export class SelectGroupComponent implements OnInit, OnChanges {
  @Input() inputControl: FormControl = new FormControl(null);
  @Input() label: string = "Input";
  @Input() placeholder: string = "";
  @Input() aOpciones?: any;
  @Input() required: boolean = false;
  @Input() mode: "create" | "read" | "update";
  @Input() isLoading: boolean = false;
  @Input() withLabel: boolean = true;
  @Input() hint_active: boolean = false;
  @Input() hint_message: string = "";
  @Input() firstValue?: boolean = false;
  @Input() optionIcon: boolean = false;
  opcionesfiltradas: Observable<any>;

  isScreenXS$ = screen.width <= 460 ;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    for (let property in changes) {
      if (property === "aOpciones") {
        // if (changes['aOpciones'].previousValue !== undefined) {
        //   this.opcionesfiltradas = this._filter("", this.aOpciones);
        // }
        // if (changes['aOpciones'].currentValue !== undefined) {
        //   this.opcionesfiltradas = this._filter("", this.aOpciones);
        //   if (changes['aOpciones'].currentValue.length === 0) {
        //     this.inputControl.reset();
        //   }
        // }

        // if (this.firstValue == true && this.aOpciones.length > 0) {
        //   this.inputControl.setValue(this.aOpciones[0].sCodigo);
        // }
      }
      if (property === "mode") {
        this.initMode();
      }
      if (property === "required") {
        this.setValidators();
      }
    }
  }

  ngOnInit(): void {
    this.initMode();
  }

  initMode() {
    this.setValidators();

    this.opcionesfiltradas = this.inputControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.aOpciones)),
    );

    this.inputControl.valueChanges.subscribe((value) => {
      this.checkOption(this.inputControl.value, this.aOpciones);
    });

    //Validar el mod
    switch (this.mode) {
      case "create":
        this.inputControl.enable();
        break;
      case "read":
        this.inputControl.disable();
        break;
      case "update":
        this.inputControl.enable();
        break;
      default:
        break;
    }
  }

  setValidators() {
    if (this.required) {
      this.inputControl.setValidators([Validators.required]);
      this.inputControl.updateValueAndValidity();
    } else {
      this.inputControl.clearValidators();
      this.inputControl.updateValueAndValidity();
    }
  }

  //METHODS DROPDOWN
  mostrarDropdrown(sCodigo: string) {
    let selectValue = null;

    if (sCodigo) {
      this.aOpciones.forEach((group: Group) => {
        if (
          group.groupOptions.find((opcion) => opcion.sCodigo == sCodigo) !=
          undefined
        ) {
          let Opcion = group.groupOptions.find(
            (opcion) => opcion.sCodigo == sCodigo
          );
          selectValue = Opcion != undefined ? Opcion.sDescripcion : null;
        }
      });
    }

    return selectValue!;
  }

  _filter(value: string | any, aOpciones: Group[]): any {
    let filterValue = "";
    let aOptionsFiltered: Group[] = [];

    if (typeof value === "string") {
      filterValue = value.toLowerCase();
    }
    if (typeof value === "object") {
      filterValue = value.sDescripcion.toLowerCase();
    }

    if (aOpciones != undefined && aOpciones.length > 0) {
      aOptionsFiltered = aOpciones.map((group: Group) => ({
        groupName: group.groupName,
        groupValue: group.groupValue,
        groupOptions: this.filterOptions(group.groupOptions, filterValue),
      }));

      this.placeholder = this.label;
    } else {
      this.placeholder = "El listado de " + this.label + " está vacio";
    }

    aOptionsFiltered = aOptionsFiltered.filter(
      (x) => x.bVisible == true || x.bVisible == undefined
    );

    return aOptionsFiltered;
  }

  filterOptions(opt: any[], value: string) {
    return opt.filter((item) => {
      return item.sDescripcion.toLowerCase().includes(value);
    });
  }

  checkOption(value: string | any, aOpciones: Group[]): any {
    let aCodigos: Group[] = [];

    aOpciones.forEach((group: any) => {
      if(group){
        aCodigos = aCodigos.concat(
          group.groupOptions.map((opcion: Option) => opcion.sCodigo)
        );
      }
    });

    let isValid = aCodigos.includes(value);

    if (isValid) {
      this.inputControl.reset;
    } else {
      if (this.required) this.inputControl.setErrors({ invalid: true });
    }
  }

  get IconOnInput() {
    if(this.inputControl.valid || this.inputControl.disabled){
      let iconPpal = this.aOpciones.find((opt: Option) => opt.sCodigo == this.inputControl.value)
      return iconPpal.icon
    }else{
      return false
    }
  }
}