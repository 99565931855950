
export const toCapitalize = (str:string) => {
    let newStr = str.toLocaleLowerCase()
    if(str.includes('-')){
        let aStr = newStr.split('-')
        newStr = aStr.map((s:string) => {
            return capitalize(s.trim())
        }).join(' - ')
    }else{
        let aStr = newStr.split('')
        newStr = aStr.map((s:string)=> {
            return capitalize(s)
        }).join('')
    }
    return newStr
}

const capitalize = (s:string) => {
    let aS = s.split('')
    aS[0] = aS[0].toLocaleUpperCase()
    return aS.join('')
}