export const environment = {
  version: "0.0.0",
  production: false,
  filenameCsv: "",
  api_url: "",
  googleClientId: "",
  microsoftClientId: "",
  lambdaTokenDrive: "",
  awsBucked: '',
  securityKey: '',
  activePush: 0,
  google_maps_key: "",
};