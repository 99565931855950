import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationsRoutingModule } from './notifications-routing.module';
import { NotificationsPopUpComponent } from './components/notifications-pop-up/notifications-pop-up.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ServiceErrorReloadComponent } from '../../shared/components/service-error-reload/service-error-reload.component';
import { SuggestionsSearchBoxComponent } from '../../shared/components/filters/suggestions-search-box/suggestions-search-box.component';
import { FilterDateRangeYmdComponent } from '../../shared/components/filters/filter-date-range-ymd/filter-date-range-ymd.component';
import { IconComponent } from '../../shared/components/icon/icon.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { SplitButtonComponent } from '../../shared/components/buttons/split-button/split-button.component';
import { NotificationsCenterComponent } from './components/notifications-center/notifications-center.component';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import { SpinnerComponent } from '../../shared/components/spinner/spinner.component';
@NgModule({
  declarations: [NotificationsPopUpComponent, NotificationsCenterComponent, NotificationsListComponent],
  imports: [
    CommonModule,
    NotificationsRoutingModule,
    MatExpansionModule,
    MatDividerModule,
    MatTooltipModule,
    ServiceErrorReloadComponent,
    SuggestionsSearchBoxComponent,
    FilterDateRangeYmdComponent,
    MatPaginatorModule,
    MatMenuModule,
    SplitButtonComponent,
    SplitButtonComponent,
    IconComponent,
    SpinnerComponent
  ],
  exports: [NotificationsPopUpComponent]
})
export class NotificationsModule {}
