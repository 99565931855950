<div class="w-full h-[4rem] flex gap-2 justify-between items-center p-2 xs:p-8 relative z-10 bg-mg-accent-base">
    <img @scaleFadeIn src="assets/svg/logo.svg" class="w-32 select-none flex-none" [class.hidden]="!isDesktop" id="logo-smart">
    <div class="w-full h-[4rem] flex gap-5 justify-end items-center" [class.!justify-between]="!isDesktop">
        <div class="flex items-center gap-5">
            <button (click)="sidenav.toggle()" type="button" [class.hidden]="isDesktop">
                <mg-icon iconClass="text-mg-variant" iconName="menu"></mg-icon>
            </button>
        <!-- 
            <div class="hidden lg:block xl:hidden">
                <div class="flex items-center" *ngIf="title">
                    <mg-icon @scaleIn iconClass="text-2xl text-mg-variant" [iconName]="title!.iconName" [iconType]="title!.iconType"></mg-icon>
                    <div @fadeInRight class="text-base sm:text-xl font-medium italic text-mg-variant ml-2 ">{{title!.text}}</div>
                </div>
            </div> -->
        </div>
        <div class="flex items-center gap-3">
            <div (click)="goNotifications()" *ngIf="!isDesktop"
                class="cursor-pointer flex gap-4 p-1 items-center " [matTooltip]="!isDesktop ? 'Notificaciones' : ''"
                matTooltipPosition="right">
                <div [matBadgeHidden]="counterNotifications <= 0"
                    [matBadge]="counterNotifications >= 100 ? '99+' : counterNotifications" matBadgeColor="warn">
                    <mg-icon iconClass="text-mg-green" iconName="notifications"></mg-icon>
                </div>
            </div>
            <mg-version-news class="cursor-pointer hidden xxs:inline-block" (click)="openNewsModal()"></mg-version-news>
            <div *ngIf="isDesktop && !(isMainDashboard$ | async)">
                <mg-Activity></mg-Activity>
            </div>
            <mg-clock></mg-clock>
            <button (click)="goFaqs()" matTooltip="Ayuda" class="flex items-center">
                <mg-icon iconClass="text-mg-variant text-3xl" iconType="mine" iconName="ask_filled"></mg-icon>
            </button>
            <mg-toolbar-user></mg-toolbar-user>
        </div>
    </div>
</div>