import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { NotificationsDetail } from 'src/app/core/notifications/interfaces/notifications.interface';
import { NotificationsService } from 'src/app/core/notifications/services/notifications.service';
import { LayoutService, TitleToolbar } from 'src/app/core/services/layout.service';
import { RevisionService } from 'src/app/core/services/revision.service';
import { scaleFadeIn400ms } from 'src/app/shared/animations/scale-fade-in.animation';
import { MatDialog } from '@angular/material/dialog';
import { VersionAlertComponent } from 'src/app/shared/components/version-alert/version-alert.component';
import { ConfigurationsService } from 'src/app/shared/services/configurations.service';
import { environment as env } from 'src/environments/environment';
@Component({
  selector: 'mg-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [ scaleFadeIn400ms]
})
export class ToolbarComponent implements OnInit {
  @Input() isDesktop: boolean | null;
  @Input() sidenav: MatSidenav;
  @ViewChild('menuTriggerNotifications') menuTriggerNotifications: MatMenuTrigger;
  public readNotifications: boolean = false;
  public title: TitleToolbar | null;
  public notificationsData: NotificationsDetail[] = [];
  public counterNotifications: number = 0;
  public messages = [];
  public isNewsButtonDisabled: boolean = false;
  isScreenSMD$ = this._layoutService.isScreenSMD$;
  isDesktop$ = this._layoutService.isScreenXL$;
  isMainDashboard$ = this._layoutService.currentRouteObservable;
  env = env;

  constructor(
    private _router: Router,
    private _revisionService: RevisionService,
    private _layoutService: LayoutService,
    private _configurationsService: ConfigurationsService,
    private _notificationsService: NotificationsService,
    private dialog: MatDialog
  ){}

  ngOnInit(): void {
    this._revisionService.fetchDataFromRevision();
    this._layoutService.titleToolbar$.subscribe((titleConfig) => {
      this.title = titleConfig;
    }); 

    this._notificationsService.countNotifications$.subscribe(x=> {
      this.badgeCounterNotifications(x);
    })
  }

  badgeCounterNotifications(counter: number){
    setTimeout(() => {
      this.counterNotifications = counter;
    }, 0);
  } 

  goNotifications(){
    this._notificationsService.getNotifications()
    .subscribe({
      next: (resp) => {
        if(resp){
          if (resp.isSuccess) {
            let ids: number[] = [];
            this.notificationsData = resp.data;

            this.notificationsData.forEach((notification: NotificationsDetail) => {
              if (!notification.bIsRead) ids.push(notification.nId_Notificacion);
            });

            if(ids.length) {
              this._notificationsService.countNotifications.next(0);
              this._notificationsService
                .updateStateNotificationsCondition(ids)
                .subscribe(() => {});
            }
        }
      }
    },
    });

    this.readNotifications = true;
    this._router.navigate(["notificaciones"]);
    setTimeout(() => {
      this.readNotifications = false;
    }, 500);
  }

  goFaqs() {
    this._router.navigate(["preguntas-frecuentes"]);
  }

  openNewsModal() {
    if (!this.isNewsButtonDisabled) {
      this.isNewsButtonDisabled = true;
      setTimeout(() => {
        this.isNewsButtonDisabled = false;
      }, 5000);

      this._configurationsService.getVersion(env.version)
        .subscribe((versionResponse: any) => {
          const versionData = versionResponse.data;
          this.dialog.open(VersionAlertComponent, {
            data: versionData,
            disableClose: true
          });
        });
    }
  }
}