<div class="text-mg-accent md:text-sm text-xs mb-2" *ngIf="!isLoading && withLabel">
    {{label}}<span *ngIf="mode!= 'read' && required" class="text-mg-red">*</span>
</div>

<div class="animate-pulse bg-mg-preload rounded h-4 max-w-1/2" *ngIf="isLoading && withLabel"></div>

<mat-form-field appearance="outline" class="w-full" *ngIf="!isLoading">
    <textarea style="resize: none;" cdkTextareaAutosize [rows]="minRows" #autosize="cdkTextareaAutosize" [attr.id]="maxlength == 200 ? '_textArea' : null"
        [cdkAutosizeMinRows]="minRows" [cdkAutosizeMaxRows]="maxRows" class="md:text-sm text-xs"
        (focusout)="focusout($event)"
        (focus)="focus($event)" matInput [placeholder]="placeholderValue" [formControl]="inputControl"
        autocomplete="off" [maxlength]="maxlength" [minlength]="minlength">
    </textarea>
    <mat-hint *ngIf="mode!='read'" class="w-full flex justify-end">{{inputControl.value? inputControl.value.length:0}} / {{maxlength}}</mat-hint>
    <mat-error class="whitespace-pre-line" *ngIf="inputControl.hasError('required')">*Este campo es obligatorio. </mat-error>
    <mat-error class="whitespace-pre-line" *ngIf="inputControl.hasError('minlength')">*Ingrese al menos
        {{inputControl.errors?.['minlength'].requiredLength}} carácteres.
    </mat-error>
    <mat-error class="whitespace-pre-line" *ngIf="inputControl.hasError('whitespace')">*No puede contener solo espacios en blanco.</mat-error>
</mat-form-field>

<div class="animate-pulse bg-mg-preload rounded h-[3.25rem] mt-1 min-w-36 mb-5" *ngIf="isLoading"></div>