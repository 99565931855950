export function setMinToHours(min: number) {
  return (min / 60).toFixed(2) + " Hrs.";
}

export function setTotalHours(min: number): string {
  let nHours = Math.floor(min / 60);
  let nMinutes = Math.round(min % 60);
  let sHours = nHours > 1 ? nHours + " Hrs. " : nHours + " Hr. ";
  let sMinutes = nMinutes + " Min.";

  if (nHours <= 9) {
    sHours = "0" + sHours;
  }

  if (nMinutes <= 9) {
    sMinutes = "0" + sMinutes;
  }

  return sHours + sMinutes;
}

export function setTotalDays(day: number): string {
  let sDias = day == 1 ? day + " Día " : day + " Días ";

  return sDias;
}

// export function setDecimalHours(min: number): string {
//   let nHours = Number(min / 60).toFixed(2);
//   let sHours = parseInt(nHours) > 1 ? nHours + " Hrs. " : nHours + " Hr. ";

//   return sHours;
// }

export function setDecimalHours(min: number): string {
  let nHours = Number(min / 60).toFixed(2);
  let sHours = parseInt(nHours) > 1 ? nHours + " Hrs. " : nHours + " Hr. ";

  return sHours;
}

export function setHoursMins(min: number): string {
  let nHours = Math.floor(min / 60);
  let nMinutes = Math.round(min % 60);
  let sHour = nHours > 1 ? nHours + " hrs. " : nHours + " hr.";
  let sMin =  nMinutes + " min."
  let formatted: string = `${sHour} ${sMin}`
  return formatted;
}


export function setHourByType(min:number, type:'number' | 'HH:mm' | 'HoursMin' = 'HoursMin'){
  let hour: string = ''
  switch(type){
    case 'HoursMin':
      hour = setHoursMins(min)
      break
    case 'HH:mm':
      hour = setTotalHours(min)
      break
    case 'number':
      hour = setDecimalHours(min)
  }

  return hour
}