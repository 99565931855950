import { Injectable } from '@angular/core';
import { Observable, concatMap,  forkJoin,  from,  map, of, switchMap, } from 'rxjs';
import { desencriptar, encriptar } from 'src/app/shared/helpers/encryption';
import { deleteDuplicated, groupConfigToIndexedDB, groupReturnIndexedDB } from '../helpers/formattedData';
import { REFRESH_INDEXED } from '../helpers/enums.enums';
import { environment as env } from 'src/environments/environment';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { ConfigurationsTable, DefaultTable, IndexedDBConfigResponse, IndexedDBListResponse, RefreshDataList, RefreshDetailList, RefreshJustification, dataListIndexed } from '../interfaces/indexedDB.interface';

import { catchError, tap } from 'rxjs/operators';
import Dexie, { Table } from 'dexie';
import { aId_DataToRefresh, errorHandler, hasNavigatorMemory, isMatchedData } from '../helpers/helpers';


@Injectable({
  providedIn: 'root'
})
export class IndexedDBDexieService {
  aTables:string[] = []
  configurations: Table<ConfigurationsTable, number>
  endpoints: Table<DefaultTable, number>;
  dynamicsValues: Table<DefaultTable, number>;
  user: Table<{nId_Usuario: string}, number>;

  today: string = moment().format('DD-MM-YY');
  
  dbVersion = 1
   _dexieDB: Dexie

   


  constructor(
    private _http: HttpClient
  ) { 
    

  }

  
async initIndexDB(){
 // @ts-ignore
  Dexie.debug = false

  this._dexieDB = new Dexie('smartLocalDB')
  this._dexieDB.version(this.dbVersion).stores({
    configurations: '++id, pry, req, user, name, slug, refresh, col, date, key', 
    endpoints: '++id, name, refresh, date',
    dynamicsValues: '++id, name, date',
    user: '++id, nId_Usuario, date',
  })

  this._dexieDB.open().catch(err => errorHandler(err))
  this.aTables = this._dexieDB.tables.map(x => x.name);

  let databases = await Dexie.getDatabaseNames()

  if(databases.includes('smart')) await Dexie.delete('smart')

  }




//---- USAR SOLO EN EL SERVICIO INIT

//---CRUD
  private async bulkAdd(tableName:string, data:any[]){
    let dataIndex = data.map(x => ({ ...x, date: this.today, refresh: x.refresh ? x.refresh : REFRESH_INDEXED.EVERY_REFRESH }));
    let table = this._dexieDB.table(tableName)
    if(await hasNavigatorMemory(dataIndex)) return table.bulkAdd(dataIndex).catch((err: any) =>  errorHandler(err))
    else return []
  }

  private async addOne(tableName:string, data:any){
    let dataIndex =  {...data, date:this.today}
    let table = this._dexieDB.table(tableName)
    if(await hasNavigatorMemory(dataIndex)) return table.add(dataIndex).catch((err: any) =>  errorHandler(err))
    else return []
  }

  private async bulkDelete(tableName:string, aId:number[]){
    let table = this._dexieDB.table(tableName)
    return await table.bulkDelete(aId).catch(err => errorHandler(err))
  }

  private async deleteOne(tableName:string, nId:number){
    let table = this._dexieDB.table(tableName)
    return await table.delete(nId).catch(err => errorHandler(err))
  }

  private async bulkUpdate(tableName:string, aData:any[]){
    let dataIndex = aData.map(x => ({ ...x, date: this.today }));
    let table = this._dexieDB.table(tableName)
    return await table.bulkPut(dataIndex).catch(err => errorHandler(err))
  }

  private async updateOne(tableName:string, data:any, key:number){ // la key es la llave primaria (id) segun dexie, se optiene al acceder a la data
    let dataIndex =  {...data, date:this.today}
    let table = this._dexieDB.table(tableName)
    return await table.update(key, dataIndex).catch(err => errorHandler(err))
  }

  private async getAllDataByTable(tableName:string){
    let table = this._dexieDB.table(tableName)
    return await table.toArray().catch(err =>  errorHandler(err))
  }

  private async getAllIndexedDBData() {
    try {
      let aDataTables:{table: string, data:any[]}[] = [];
      await Promise.all(
        this.aTables.map(async (table) => {
          const data = await this.getAllDataByTable(table);
          if(Array.isArray(data)) aDataTables.push({ table, data });
        })
      );
      return aDataTables
    } catch (error) {
      errorHandler(error)
      return 
    }
  }
  
  private async clearTable(tableName: string){
    return await this._dexieDB.table(tableName).clear().catch(err => errorHandler(err))
  }




  //---- USAR SOLO EN EL SERVICIO FIN

//-------MÉTODOS NUEVOS CON DEXIE INIT


addBulkData(
  tableName: string,
  data: any[],
  type?: 'config' | 'list',
  refresh?: null | REFRESH_INDEXED ,
  configParams?: {
    aConfiguraciones: string[],
    nId_Usuario: string | number | null,
    nId_Proyecto: string | number | null,
    nId_requerimiento: string | number | null,
    sSlugListado: string | number,
    nId_Colaborador: string | number,
  }
):Observable<any>{
  if(type == 'config'){
    let newData = groupConfigToIndexedDB(data, configParams, 'config', refresh)
    newData = deleteDuplicated(newData)
    return from(this.bulkAdd(tableName, newData))
  }else{
    let newData:any[] = []
   
    data.map((item:any)=>{
        newData.push({
          name: item.name,
          key: item.key ? item.key : null,
          refresh: refresh,
          data: encriptar('SMART-MG', JSON.stringify(item.data))
        })
      })
    return from(this.bulkAdd(tableName, newData))
  }
}


getAllDataByType(
  tableName: string,
  type: 'config' | 'list',
  configParams?: {
    aConfiguraciones: string[],
    nId_Usuario: string | number,
    nId_Proyecto: string | number,
    nId_requerimiento: string | number,
    sSlugListado: string | number,
  } | null,
  keysList?: string[] | number[],
):Observable<IndexedDBConfigResponse[] | IndexedDBListResponse[] | any>{
  const keys = keysList ? keysList : configParams!.aConfiguraciones
  const data = from(this.getAllDataByTable(tableName)).pipe(
    map((dataIndex:any)=>{
      switch(type){
        case 'config':
          let slug = configParams!.sSlugListado != '' ? configParams!.sSlugListado.toString() : null ;
          let pry = configParams!.nId_Proyecto != 0 ? configParams!.nId_Proyecto.toString() : null;
          let req = configParams!.nId_requerimiento != 0 ? configParams!.nId_requerimiento.toString() : null;
          let user = configParams!.nId_Usuario
          let configIndex:IndexedDBConfigResponse[] = []
          keys.map((key: number | string) =>{
              let data:ConfigurationsTable[] = []
              dataIndex.map((config:ConfigurationsTable) =>{
              if(key == config.key){
                if(user && user == config.user){
                  if(isMatchedData(slug, config.slug) && isMatchedData(pry, config.pry) && isMatchedData(req, config.req)){
                    data.push(config)
                  } 
                }else{
                  if(isMatchedData(slug, config.slug) && isMatchedData(pry, config.pry) && isMatchedData(req, config.req)){
                    data.push(config)
                  } 
                }
              }
            })
            configIndex.push({data:data, res:key as string})
          })
          return configIndex
        case 'list':
          let dsecrydData:any[] = []
              dataIndex.map((resp:any)=>{
                dsecrydData.push({
                  name: resp.name,
                  data: resp.data,
                  key: resp.key,
                  id: resp.id
                })
              })
              return dsecrydData
      }

    })
  )
  return data
}

refreshDataAtDay(): Observable<any>{
  return from(this.getAllDataByTable('dynamicsValues'))
  .pipe(
    switchMap( dataIndex => {
      if(Array.isArray(dataIndex) && dataIndex.length){
        let todayIndex = Array.isArray(dataIndex) && dataIndex.find(x => x.name == 'today')
        if(todayIndex && todayIndex.data !== this.today){
          return forkJoin([
            from(this.refreshAllByTypeRefresh(REFRESH_INDEXED.EVERY_DAY)),
            from(this.updateOne('dynamicsValues', {name: 'today', data: this.today}, todayIndex.id))
          ]).pipe( map(()=> true))
        }else return of(false)
      }else{
        return from(this.addOne('dynamicsValues', {name: 'today', data: this.today})).pipe(map(()=> true))
      }
    })
  )
}

refreshAllByTypeRefresh(nId_Refresh:REFRESH_INDEXED):Observable<any>{
  return  from(this.getAllIndexedDBData()).pipe(
    switchMap(dataIndex =>{
      let dataToDelete: any = dataIndex?.map(data =>{
        if(data.data.length){
          let  aIdToDelete = aId_DataToRefresh(nId_Refresh, data.data)
          return aIdToDelete.length ? from(this.bulkDelete(data.table, aIdToDelete)) : of(null)
        }else return of(null)
      })
      return forkJoin(dataToDelete)
    })
  )
}

refreshDataById(nId_Usuario: string | number):Observable<any>{
  let nId_User_Encryd = encriptar('SMART-MG', nId_Usuario as string)
  return from(this.getAllDataByTable('user')).pipe(
    switchMap(dataIndex =>{
      if(Array.isArray(dataIndex) && dataIndex.length){
        let nId_User_Index = desencriptar('SMART-MG', dataIndex[0].nId_Usuario)
        if(nId_User_Index != nId_Usuario){
          let tablesToClear = this.aTables.map(table => from(this.clearTable(table)))
          return forkJoin([...tablesToClear, this.addOne('user', {nId_Usuario: nId_User_Encryd})])
        }
      }else{
        this.addOne('user', {nId_Usuario: nId_User_Encryd})
      }
      return of(null)
    })
  )
}

async refreshAllData(){
    let tableForClear = this.aTables.map(table => this.clearTable(table))
    await Promise.all(tableForClear)
}

refreshDataByAnyKey(tableName:string, keyName:string,  names:string[]){
  return from(this.getAllDataByTable(tableName)).pipe(
    //@ts-ignore
    switchMap(dataIndex => {
      let nId_Index =  null
      let deletion = of(undefined)
      if(Array.isArray(dataIndex) && dataIndex.length){
        nId_Index = Array.isArray(dataIndex) && dataIndex.filter(data => names.some( n => n == data[keyName])).map( x => x.id)
        //@ts-ignore
        deletion = nId_Index.length ? from(this.bulkDelete(tableName, nId_Index)) : of(undefined)
        
      }
      return deletion
    })
  )
}


 getDataByAnyKey(tableName:string, keyName:string,  name:string){
  return from(this.getAllDataByTable(tableName)).pipe(
    map(dataIndex =>{
      let data =  null
      if(Array.isArray(dataIndex) && dataIndex.length){
        data = Array.isArray(dataIndex) && dataIndex.find(data => data[keyName] == name)
        data = data ? {...JSON.parse(desencriptar('SMART-MG', data.data)), id: data.id} : null
      }

      return data
    })
  )
 }


 getRequestData<T>(url: string, tableName:string, refresh:REFRESH_INDEXED, params?:{ dataPost?: any, query?:string }):Observable<T>{
  return this.getDataByAnyKey(tableName, 'name', url).pipe(
    switchMap((dataFromIndexedDB: any) => {
      if (dataFromIndexedDB) {

        return [dataFromIndexedDB];
      } else {
        const fullUrl = env.api_url + url;
        let request = this._http.get<T>(fullUrl);
        if (params) {
          if (params.dataPost) {
            request = this._http.post<T>(fullUrl, params.dataPost);
          } else if (params.query) {
            request = this._http.get<T>(`${fullUrl}${params.query}`);
          }
        }
        return this.handleRequestData<T>(request, url, tableName, refresh)
      }
    })
  );
 }

 handleRequestData<T>(request: Observable<T>, url:string, tableName:string, refresh:REFRESH_INDEXED):Observable<T>{
  return request.pipe(
    tap((httpData: T) => {
      const newData = {
        name: url,
        data: httpData,
        key: null
      }
      this.addBulkData(tableName, [newData], 'list', refresh)
      .subscribe();
    }),
    catchError(error => {
      errorHandler(error);
      return [];
    })
  )

 }

 bulkUpdateData(
  tableName:string, 
  data:any[]){
  let newData:any[] = data.map(data => {
    return {
      ...data,
      data: encriptar('SMART-MG', JSON.stringify(data.data))
    }
  })

  return from(this.bulkUpdate(tableName, newData))
 }


//-------MÉTODOS NUEVOS CON DEXIE END

//--------MÉTODOS PARA ACTUALIZAR DATA SEGUN SOCKETS

  newDataBySocket<T>(tableName:string, keyName:string,  newData:string){
    return from(this.getAllDataByTable(tableName)).pipe(
      switchMap(dataIndex => {
        const indexToUpdate = Array.isArray(dataIndex) && dataIndex.find(data => data.name == keyName)
        let dataToUpdate:any
        let newDataIndex: any
        if(indexToUpdate){
          dataToUpdate = JSON.parse(desencriptar('SMART-MG', indexToUpdate.data))
          dataToUpdate.data.push(JSON.parse(newData))
          newDataIndex = {...indexToUpdate, data:dataToUpdate}
        }
        return this.bulkUpdateData(tableName, [newDataIndex]).pipe(
        map(() =>{
            return true
          })
        )
      })
    )

  }

  updateDateBySocket<T>(tableName:string, keyName:string,  newData:string, sIdName: string):Observable<boolean>{
    return from(this.getAllDataByTable(tableName)).pipe(
      switchMap(dataIndex => {
        const indexToUpdate = Array.isArray(dataIndex) && dataIndex.find(data => data.name == keyName)
        let dataToUpdate:any
        let newDataIndex: any
        let newDataToUpdate = JSON.parse(newData)
        if(indexToUpdate){
          dataToUpdate = JSON.parse(desencriptar('SMART-MG', indexToUpdate.data))
          dataToUpdate.data = dataToUpdate.data.map((x:any) => {
            if(x[sIdName] == newDataToUpdate[sIdName]) return newDataToUpdate
            return x
          })
          newDataIndex = {...indexToUpdate, data:dataToUpdate}
        }
        return this.bulkUpdateData(tableName, [newDataIndex]).pipe(
        map(() =>{
            return true
          })
        )
      })
    )
  }

  deleteDataBySocket<T>(tableName:string, keyName:string,  newData:string, sIdName: string):Observable<boolean>{
    return from(this.getAllDataByTable(tableName)).pipe(
      switchMap(dataIndex => {
        const indexToUpdate = Array.isArray(dataIndex) && dataIndex.find(data => data.name == keyName)
        let dataToUpdate:any
        let newDataIndex: any
        let idToDelete = newData
        if(indexToUpdate){
          dataToUpdate = JSON.parse(desencriptar('SMART-MG', indexToUpdate.data))
          dataToUpdate.data = dataToUpdate.data.filter((x:any) => x[sIdName] != idToDelete)
          newDataIndex = {...indexToUpdate, data:dataToUpdate}
        }
        return this.bulkUpdateData(tableName, [newDataIndex]).pipe(
        map(() =>{
            return true
          })
        )
      })
    )
  }
  


 }
