<div class="flex flex-col gap-4 px-6 pb-8">
    <div class="flex flex-col gap-4 sticky top-0 bg-white z-10 py-4">
        <div class="flex justify-between items-center gap-2 flex-wrap">
            <div class="flex items-center gap-2">
                <div class="text-sm sm:text-base text-mg-main-blue">
                    Notificaciones
                </div>
                <mg-icon iconClass="text-mg-main-blue cursor-pointer text-lg" iconName="open_in_new"
                    matTooltip="Ir al centro de notificaciones" (click)="goToNotificationsCenter()"></mg-icon>
            </div>
            <div class="cursor-pointer" matTooltip="Cerrar notificaciones" (click)="closeMatMenu()">
                <mg-icon iconClass="text-mg-main-blue text-xl" iconName="close"></mg-icon>
            </div>
        </div>
        <div class="flex justify-between items-center gap-2 flex-wrap">
            <div class="flex gap-2">
                <button class="p-2 text-xs sm:text-sm rounded-full border border-transparent hover:text-mg-main-blue"
                    [ngClass]="isAll ? 'px-5 text-mg-main-blue font-semibold bg-mg-main-blue-light duration-500' : 'text-mg-gray duration-500'"
                    (click)="setIsAll(true)">
                    Todas
                </button>
                <button class="p-2 text-xs sm:text-sm rounded-full border border-transparent hover:text-mg-main-blue"
                    [ngClass]="!isAll ? 'px-5 text-mg-main-blue font-semibold bg-mg-main-blue-light duration-500' : 'text-mg-gray duration-500'"
                    (click)="setIsAll(false)">
                    No leídas{{unreadNotificationCounter > 0 ? ' (' + unreadNotificationCounter + ')': ''}}
                </button>
            </div>
            <button *ngIf="unreadNotificationCounter > 0"
                class="flex items-center gap-2 px-2 sm:px-4 py-2 rounded-full sm:rounded-lg bg-mg-teal-light text-mg-teal-dark"
                matTooltip="Marcar todo como leído" (click)="markAllNotificationsAsRead()">
                <mg-icon iconName="mark_email_read"></mg-icon>
                <div class="text-xs sm:text-sm hidden sm:block">Marcar todo como leído</div>
            </button>
        </div>
    </div>
    <div class="flex flex-col gap-4 px-2" *ngIf="loading">
        <ng-container *ngFor="let j of [].constructor(3)">
            <div class="flex flex-col gap-5 mt-4">
                <div class="animate-pulse bg-mg-base-background h-2 w-30 rounded-xl"></div>
                <ng-container *ngFor="let i of [].constructor(3)">
                    <div class="flex gap-2 items-center">
                        <div class="w-12 h-10 rounded-full animate-pulse bg-mg-base-background"></div>
                        <div class="flex flex-col gap-1 w-full">
                            <div class="animate-pulse bg-mg-base-background rounded-xl h-2 w-full"></div>
                            <div class="animate-pulse bg-mg-base-background rounded-xl h-2 w-full"></div>
                        </div>
                        <div class="w-12 h-10 rounded-full animate-pulse bg-mg-base-background"></div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <div class="flex flex-col gap-4 px-2" *ngIf="!loading">
        <div class="flex flex-col items-center justify-center"
            *ngIf="!notificationsDataShow.length && !errorLoadingData">
            <img class="h-2/5" src="../../../../assets/svg/utils/sin_notificaciones.svg">
            <div class="text-base font-medium text-center text-mg-main-blue">{{isAll ? 'No tienes notificaciones' : 'Has leído todas tus notificaciones'}}</div>
        </div>
        <div class="flex flex-col items-center justify-center"
            *ngIf="!notificationsDataShow.length && errorLoadingData">
            <img class="h-2/5" src="../../../../assets/svg/errors/error_de_carga.svg">
            <h2 class="text-xl text-center text-mg-main-blue">Ocurrió un error al cargar los datos</h2>
        </div>
        <ng-container *ngFor="let notificationByDate of notificationsDataShow; let i = index">
            <div class="flex flex-col gap-1">
                <div class="text-mg-main-blue text-xs sm:text-sm my-2">{{notificationByDate.fecha}}</div>
                <ng-container *ngFor="let notification of notificationByDate.notificaciones">
                    <div class="flex items-start gap-2 p-3 rounded-lg"
                        [ngClass]="{'bg-fondo' : notification.nEstado_Notificacion == 1}">
                        <div class="flex items-start gap-2 w-full">
                            <div class="flex gap-2 items-center">
                                <div class="rounded-full h-2 w-2" [class]="!notification.bIsRead ? 'bg-mg-red' : 'bg-transparent'"></div>
                                <div class="flex items-end">
                                    <div class="w-10 h-10 rounded-full bg-cover bg-center z-1"
                                        [style]="notification.sUrlFoto ? 'background-image: url('+ notification.sUrlFoto +')' : 'background-image: url(assets/svg/utils/avatar_default.svg)'">
                                    </div>
                                    <div class="bg-mg-main-blue rounded-full h-6 w-6 border border-white z-2 -ml-4">
                                        <mg-icon iconClass="text-white text-sm flex items-center justify-center"
                                            [iconName]="notification.sIcono"></mg-icon>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col gap-1">
                                <div class="text-xs sm:text-sm break-words text-mg-gray-dark cursor-pointer"
                                    (click)="goToModule(notification)"
                                    [matTooltipDisabled]="!notification.sLink_Notificacion"
                                    [matTooltip]="'Ir al módulo de ' + notification.sModulo.toLowerCase()"
                                    [innerHTML]="notification.bMostrarDescripcionCorto ? notification.sDescripcionCorto : notification.sDescripcion">
                                </div>
                                <div class="flex justify-between gap-2">
                                    <div class="text-xxs sm:text-xs text-mg-gray italic">
                                        {{notification.humanizatedTime}}</div>
                                    <div class="text-xxs sm:text-xs text-mg-main-blue italic cursor-pointer"
                                        (click)="notification.bMostrarDescripcionCorto = !notification.bMostrarDescripcionCorto"
                                        *ngIf="notification.bMostrarDescripcionCorto != null">
                                        {{notification.bMostrarDescripcionCorto ? 'Ver más' : 'Ver menos'}}</div>
                                </div>
                                <!-- -------------------------
                                <div *ngIf="notification.bMostrarBotones" class="flex gap-2">
                                    <button class="text-xs sm:text-sm px-4 py-1 transition duration-300 rounded-lg border border-mg-red text-mg-red hover:border-mg-red-light hover:bg-mg-red-light hover:text-white">Rechazar</button>
                                    <button class="text-xs sm:text-sm px-6 py-1 transition duration-300 rounded-lg bg-mg-green text-white hover:bg-mg-green-light">Aprobar</button>
                                </div>
                                ------------------------- -->
                            </div>
                        </div>
                        <!-- [ngClass]="{'normal' : 'bg-mg-teal-light text-mg-teal-dark', 'loading' : 'bg-mg-gray-light text-mg-gray-dark', 'error' : 'bg-mg-red-light text-mg-red-dark'}[notification.status]" -->
                        <button [class]="notification.nEstado_Notificacion == 1 ? 'visible' : 'invisible'"
                            class="rounded-full text-lg bg-mg-teal-light text-mg-teal-dark min-w-[2.25rem] max-w-[2.25rem] max-h-[2.25rem] min-h-[2.25rem]"
                            matTooltip="Marcar como leído" (click)="markNotificationAsRead(notification)">
                            <mg-icon iconClass="flex items-center justify-center text-xl sm:text-2xl"
                            iconName="mark_email_read"></mg-icon>
                        </button>
                    </div>
                </ng-container>
                <mat-divider *ngIf="i != notificationsDataShow.length-1" class="py-2"></mat-divider>
            </div>
        </ng-container>
    </div>
</div>