import * as moment from "moment";
import { getLabelWithIconBadge, getSimpleBadge } from "src/app/shared/helpers/badgesBuilder";
import { groupByToArray } from "src/app/shared/helpers/groupBy";
import { getTextWithPic } from "src/app/shared/helpers/textWithPic";
import { Icon } from "src/app/shared/interfaces/icon.interface";
import { GenericResponseList } from "src/app/shared/interfaces/table.interface";
import { ListTeamTaskIndicatorResponse } from "../interfaces/task-interfaces";
import { NameWithPic } from "src/app/shared/interfaces/textWithPic.interface";
import { BadgeStatus, LabelWithiconBadge } from "src/app/shared/interfaces/badges.interface";
import { ASSISTANCE_STATUS } from "../../attendance/helpers/enums";

export function transforDataTeamTaskIndicatorList(
    response: GenericResponseList<ListTeamTaskIndicatorResponse>, 
    records: number,
    numPage: number
  ){
    if(response.data == null) response.data = [];
        let totalColumns = 0;
        let aCollab: any = (groupByToArray(response.data, 'nId_Colaborador')).filter((item: any) => item !== null);
        let startIndex = (records*(numPage));
        let endIndex = startIndex + records; 
        let aCollabPaginate = aCollab.slice(startIndex, endIndex); 
        let respReturn: GenericResponseList<{[key: string]: NameWithPic | string | LabelWithiconBadge | boolean | BadgeStatus}> = {
          paginate: { nRecord: records, nRegisters: 0},
          data: [],
          footer: {},
          isSuccess: response.isSuccess,
          message: response.message,
          code: response.code
        }
        let totalRegisters: number = 0;
        totalRegisters = aCollab.length;
        aCollabPaginate.forEach((collab: any) => {
          if (collab != null){
            let resp : { [key: string]: NameWithPic | string | LabelWithiconBadge | boolean | BadgeStatus} = {};
            let totalRow = 0; 
            resp['nId_Colaborador'] = collab[0].nId_Colaborador;
            resp['NameWithPic'] = getTextWithPic({
                urlPic: collab[0].sUrl_Foto,
                primaryText: {
                  text: collab[0].sNombre_Colaborador,
                },
              }); 
            collab.forEach((date: ListTeamTaskIndicatorResponse) => { 
              totalRow = totalRow + date.nMinutos;
              let columnName = moment(date.dFecha).format("YYYY-MM-DD");
              resp[columnName] = getLabelWithIconBadge(
                    (date.nMinutos/60).toFixed(2).replace(".", ",") + " Hrs.",
                    validateIconByPermissionInProjectManager(date.sId_Solicitudes, date.sEstado),
                    setColorByState(date.sEstado),
                    "",
                    attendanceState(date.nEstado_Asistencia)
                  );
            });
            totalColumns = totalColumns + totalRow;
            resp['ntotalRow'] = (totalRow / 60).toFixed(2);
            resp['totalRow'] = getSimpleBadge(
              (totalRow/60).toFixed(2).replace(".", ",") + " Hrs.",
              "",
              "main_dark",
              "!w-32 !h-10 flex justify-center items-center"
            );  
            respReturn.data.push(resp)
          }
        });
        respReturn.footer = {
          generalTotal: (totalColumns/60).toFixed(2).replace(".", ",") + " Hrs.",
        }; 
        respReturn.paginate.nRegisters = totalRegisters;
        return respReturn;
        
  }

  function setColorByState(sStatusTareo: string): string {
    if (["INCOMPLETO", "FALTA"].includes(sStatusTareo)) {
      return "red";
    }
  
    if (["PERMISO", "VACACIONES", "FUERA-DE-HORARIO", "SIN-MARCA"].includes(sStatusTareo)) {
      return "gray";
    }
  
    if (["SOBRETIEMPO"].includes(sStatusTareo)) {
      return "green";
    }
  
    return "main";
  }

  function validateIconByPermissionInProjectManager(sPermission: string | null, sStatusTareo: string): null | Icon{ 
    if(sPermission != null){
      return {
        type: 'material',
        class: '',
        name: 'receipt_long',
      };
    } 
    return null;
  }

  function attendanceState( nAttendnace: number): string {
    switch(nAttendnace){
      case ASSISTANCE_STATUS.EN_TOLERANCIA:
      case ASSISTANCE_STATUS.TARDANZA:
        return 'TARDANZA';
      
      case ASSISTANCE_STATUS.FALTA:
        return 'FALTA';

      case ASSISTANCE_STATUS.FERIADO:
        return 'FERIADO';
      
      case ASSISTANCE_STATUS.FUERA_DE_HORARIO:
        return 'FUERA DE HORARIO';

      case ASSISTANCE_STATUS.LICENCIA:
        return 'LICENCIA';
      
      case ASSISTANCE_STATUS.PERMISO:
        return 'PERMISO';

      case ASSISTANCE_STATUS.PUNTUAL:
        return 'PUNTUAL';

      case ASSISTANCE_STATUS.SIN_MARCA:
        return 'SIN MARCA';

      case ASSISTANCE_STATUS.VACACIONES:
        return 'VACACIONES';

      case ASSISTANCE_STATUS.P_CONFIANZA:
        return 'PERSONAL DE CONFIANZA';

      default:
        return nAttendnace.toString();
    }
  }