import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class clearQueryParamsService {
    constructor(
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
    ) {}

    checkIfQueryParamsExists(): void {
      let params = [];
      this._activatedRoute.queryParams.subscribe(x => {
        if (Object.keys(x).length > 0) params.push(x);
      })
      
      if (params.length > 0) this.clearQueryParams();
    }

    clearQueryParams(): void {
      const url = window.location.origin + window.location.pathname;
      window.history.replaceState(null, '', url);
      // this._router.navigate([], {
      //   relativeTo: this._activatedRoute,
      //   queryParams: {}
      // });
    }
}