import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { FileCopy, DriveListFolderResponse, FileInfo, FolderInfo, FilePatch, DriveAPIToken, FolderCreated } from  'src/app/modules/contract/interfaces/drive.interface';
import { localDB } from 'src/app/shared/helpers/localStorage';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {
  folderId = "1IJXi971M08Rvjf0wpHftIvuHgEd3DW79";
  private driveToken: BehaviorSubject<string> = new BehaviorSubject<string>('');

  driveToken$ = this.driveToken.asObservable();

  constructor(private http: HttpClient) { 
    if(localDB.getItem('accessToken')){ 
        this.driveToken.next(localDB.getItem('accessToken'));
    }
  }

  getaccessToken(renew: boolean): Observable<DriveAPIToken> {
    return this.http.get<DriveAPIToken>(`${environment.lambdaTokenDrive}?renew=${renew}`).pipe(
      tap(accessToken => {
        if (accessToken.token) {
          localDB.setItem('accessToken', accessToken.token);
          this.driveToken.next(accessToken.token);
        }
      })
    );
  }

  updateValueToken(newData: string) {
    this.driveToken.next(newData);
  }

  getFolderInfo() {   
    return this.http.get<FolderInfo>(`https://www.googleapis.com/drive/v3/files/${this.folderId}?fields=name,owners`)
  }
   
  getFilesInFolder() {
    let query = `'${this.folderId}' in parents`;
    let fields = 'files(name, id, thumbnailLink, mimeType)';
    
    return this.http.get<DriveListFolderResponse>(`https://www.googleapis.com/drive/v3/files?q=${query}&fields=${fields}`)
  }

  getFileInfo(fileId: string){
    return this.http.get<FileInfo>(`https://www.googleapis.com/drive/v3/files/${fileId}?fields=id,name,owners,size,mimeType,createdTime,thumbnailLink`)
  }

  duplicateFile(idOriginalFile: string, idFolder: string){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = {
      parents: [idFolder]
    };

    return this.http.post<FileCopy>(`https://www.googleapis.com/drive/v3/files/${idOriginalFile}/copy`, body, { headers });
  }

  renameFile(fileId: string, newName: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = {
      name: newName
    };

    return this.http.patch<FileCopy>(`https://www.googleapis.com/drive/v3/files/${fileId}`, body, { headers: headers });
  }

  batchUpdateDocument(idFile: string, varsInTemplate: string[], valueToVars: string[]): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    let finds = Array.isArray(varsInTemplate) ? varsInTemplate : [varsInTemplate];
    let replaces = Array.isArray(valueToVars) ? valueToVars : [valueToVars];

    let requests = [];
  
    for (let i = 0; i < finds.length; i++) {
      requests.push(
        {
          replaceAllText: {
            containsText: {
              text: finds[i],
              matchCase: true,
            },
            replaceText: replaces[i],
          },
        }
      );
    }

    const requestBody = { requests: requests };

    return this.http.post<FilePatch>(`https://docs.googleapis.com/v1/documents/${idFile}:batchUpdate`, requestBody, { headers });
  }

  deleteFilePermanently(fileId: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  
    return this.http.delete(`https://www.googleapis.com/drive/v3/files/${fileId}`, { headers });
  }

  getDocumentContent(fileId: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(`https://www.googleapis.com/drive/v3/files/${fileId}/export?mimeType=text/html`, { headers });
  }

  getDocumentUrl(fileId: string): Observable<any> {
    const headers = new HttpHeaders();

    return this.http.get(`https://www.googleapis.com/drive/v3/files/${fileId}?fields=webViewLink`, {
      headers: headers
    });
  }

  uploadFileToFolder(file: File): Observable<any> {
    const headers = new HttpHeaders({
    });

    // Crear un objeto FormData para enviar el archivo
    const formData = new FormData();
    formData.append('file', file);

    // Agregar el ID de la carpeta como parámetro de consulta
    formData.append('parents', this.folderId);

    return this.http.post<any>('https://www.googleapis.com/upload/drive/v3/files?uploadType=media', formData, { headers });
  }

  downloadPdf(idFile:string): Observable<any> {
    const headers = new HttpHeaders({
    });

    const params = new HttpParams()
      .set('mimeType', 'application/pdf');

    return this.http.get(`https://www.googleapis.com/drive/v3/files/${idFile}/export`, {
      headers: headers,
      responseType: 'blob',
      params: params
    });
  }

  createFolderInsideFolder(folderName: string) {
    let parentFolderId = "1kc4tA7ar_xHSY6fuSygsJvow0lyoHARb"
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = {
      name: folderName,
      mimeType: 'application/vnd.google-apps.folder',
      parents: [parentFolderId]
    };

    return this.http.post<FolderCreated>('https://www.googleapis.com/drive/v3/files', body, { headers });
  }

}
