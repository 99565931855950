<div mat-dialog-content [class]="loading ? 'cursor-wait' : ''">
    <div [class]="loading ? 'pointer-events-none' : ''">
        <div class="w-auto">
            <div class="flex justify-between">
                <h1 mat-dialog-title class="text-base text-mg-accent">Registrar actividad</h1>
                <button (click)="cancel()" class="-mt-4">
                    <mg-icon iconClass="text-mg-main-blue" iconName="close"></mg-icon>
                </button>
            </div>
            <div class="gap-6 flex flex-col md:flex-row w-auto">
                <div class="max-w-[22rem]">
                    <mg-input-task-composed
                        [task]="taskFormGroup"
                        [data]="data"
                        [mode]="mode"
                        [activityEdit]="activityEdit"
                        (addTask)="addTask($event)"
                        [isTable]="true"
                        [bdData]="bdArray"
                        [initialDate]="data.dateSelected"
                        (updateTask)="updateTask($event)"
                        [valueToMakeAction]="valueToMakeAction"

                        class="col-span-5"
                        [lastItemSelected]="lastItemSelected"
                        [lastReqId]="lastReqId"
                        [lastPrjectId]="lastPrjectId"

                    >
                    </mg-input-task-composed>
                </div>

                <div class="flex flex-col h-full w-full md:w-[28rem] md:max-h-168 md:overflow-y-auto"
                    [ngClass]="listArray.length > 0 ? '' : 'hidden'" #topList>
                    <div class="flex flex-col gap-4 bg-mg-base-background rounded-xl mb-4 p-4"
                        *ngFor="let task of listArray.slice().reverse()"
                        [ngClass]="task.editState ? 'border-2 border-mg-main-blue-light' : ''">
                        <div class="flex flex-col md:flex-row justify-between">
                            <div>
                                <div class="flex items-start gap-2 mb-2">
                                    <div class="bg-mg-main-blue-light flex items-center justify-center rounded-full">
                                        <mg-icon class="m-2" iconClass="text-mg-main-blue" iconName="schedule"></mg-icon>
                                    </div>
                                    <div>
                                        <div class="flex gap-2">
                                            <div class="text-xs font-bold text-mg-main-blue">Fecha: </div>
                                            <p class="text-xs text-mg-gray">{{task.sFecha_Registro}}</p>
                                        </div>
                                        <div class="flex gap-2">
                                            <div class="text-xs font-bold text-mg-main-blue">Tipo de Hora: </div>
                                            <p class="text-xs text-mg-gray">{{task.sNombre_Tipo_Horas}}</p>
                                        </div>
                                        <div class="flex gap-2">
                                            <div class="text-xs font-bold text-mg-main-blue">Cant. Horas: </div>
                                            <p class="text-xs text-mg-gray">{{task.sHoras}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex items-start gap-2 mb-2">
                                    <div class="bg-mg-main-blue-light flex items-center justify-center rounded-full">
                                        <mg-icon class="m-2" iconClass="text-mg-main-blue" iconName="assignment"></mg-icon>
                                    </div>
                                    <div>
                                        <div class="flex gap-2">
                                            <div class="text-xs font-bold text-mg-main-blue">Proyecto: </div>
                                            <p class="text-xs text-mg-gray">{{task.sNombre_Proyecto |
                                                titlecase}}</p>
                                        </div>
                                        <div class="flex gap-2">
                                            <div class="text-xs font-bold text-mg-main-blue">RQ: </div>
                                            <p class="text-xs text-mg-gray">{{task.sNombre_Requerimiento |
                                                titlecase}}</p>
                                        </div>
                                        <div class="flex gap-2">
                                            <div class="text-xs font-bold text-mg-main-blue">Categoría: </div>
                                            <p class="text-xs text-mg-gray">{{task.sNombre_Categoria |
                                                titlecase}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex items-start gap-2">
                                    <div class="bg-mg-main-blue-light flex items-center justify-center rounded-full">
                                        <mg-icon class="m-2" iconClass="text-mg-main-blue" iconName="sticky_note_2"></mg-icon>
                                    </div>
                                    <div>
                                        <div class="text-xs font-bold text-mg-purple">Detalle: </div>
                                        <p class="text-xs text-mg-gray" style="overflow-wrap: anywhere;">
                                            {{task.sDetalle}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col mt-2 md:mt-0 items-end md:items-center">
                                <div *ngIf="!task.editState" class="flex md:flex-col gap-2 h-9">
                                    <div (click)="taskClick({action:'editar-actividad', id:task.id!})" class="bg-mg-main-blue-light border-mg-main-blue cursor-pointer flex items-center justify-center p-1.5 rounded-full w-fit">
                                        <mg-icon iconClass="text-mg-main-blue" iconName="edit"></mg-icon>
                                    </div>
                                    <div (click)="taskClick({action:'eliminar-actividad', id:task.id!})" class="bg-mg-red-light border-mg-red-dark cursor-pointer flex items-center justify-center p-1.5 rounded-full w-fit">
                                        <mg-icon iconClass="text-mg-red-dark" iconName="delete"></mg-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <mat-hint *ngIf="task.sMensaje_Error"
                            class="p-2 flex items-start gap-2 w-full bg-mg-hint-error rounded-xl">
                            <mg-icon iconClass="text-xl text-mg-red-dark" iconName="warning"></mg-icon>
                            <div class="text-xs break-normal">{{task.sMensaje_Error}}</div>
                        </mat-hint>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div mat-dialog-actions class="flex flex-col flex-nowrap md:flex-row w-auto gap-2 md:gap-6">
    <div class="w-full" *ngIf="loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <ng-container *ngIf="!loading">
        <div class="w-full md:w-[22rem]">
            <div class="flex w-auto">
                <button *ngIf="mode=='create' || mode=='read'" mat-button type="button"
                    class="bg-mg-main-blue rounded-xl text-white font-medium w-full h-10 mat-button flex justify-center items-center"
                    (click)="inputsActionEmit('addToList')">
                    <mg-icon iconClass="text-white" iconName="add_circle"></mg-icon>
                    &nbsp; Agregar actividad
                </button>
            </div>
            <div class="w-full items-center flex gap-2" *ngIf="mode=='update'">
                <button *ngIf="mode=='update'" mat-button type="button"
                    class="text-mg-accent hover:bg-mg-preload border-mg-main-blue-light border-2 font-medium rounded-xl h-10 text-sm w-full"
                    (click)="inputsActionEmit('cancelUpdate')">
                    Cancelar
                </button>
                <button *ngIf="mode=='update'" mat-button type="button"
                    class="bg-mg-main-blue rounded-xl text-white font-medium h-10 text-sm w-full"
                    (click)="inputsActionEmit('saveUpdate')">
                    Guardar
                </button>
            </div>
        </div>
        <div class="flex w-full md:w-[28rem] gap-2" *ngIf="listArray.length > 0" [class.hidden]="mode=='update'">
            <button mat-button type="button" class="text-mg-accent hover:bg-mg-preload border-mg-main-blue-light border-2 font-medium rounded-xl h-10 text-sm w-full"
                (click)="cancel()">
                Cancelar
            </button>
            <button mat-button type="button" [disabled]="mode=='update'"
                class="bg-mg-green rounded-xl text-white font-medium h-10 text-sm w-full"
                (click)="confirmSave()">
                Registrar actividades
            </button>
        </div>
    </ng-container>

</div>