import { Component } from '@angular/core';

@Component({
  selector: 'mg-version-news',
  templateUrl: './version-news.component.html',
  styleUrls: ['./version-news.component.scss']
})
export class VersionNewsComponent {

}
