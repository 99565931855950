<div *ngIf="!disableAllValidations else noValidations">
    <div class="text-mg-accent md:text-sm text-xs" *ngIf="!isLoading && withLabel"> {{label}}<span *ngIf="mode!= 'read' && required" class="text-mg-red">*</span>
    </div>
    <div class="animate-pulse bg-mg-preload rounded h-4 max-w-1/2" *ngIf="isLoading && withLabel"></div>
    <div class="animate-pulse bg-mg-preload rounded h-[3.25rem] mt-1 min-w-36 mb-5" *ngIf="isLoading"></div>
    <div [class]="hintPosition == 'down' ? 'flex flex-col' : 'w-full flex gap-2'" *ngIf="!isLoading">
        <div [class]="hintPosition == 'down' ? 'w-full' : (inputSelected.valid && hint) ? 'w-1/2' : 'w-full'">
            <mat-form-field appearance="outline" [class]=" 'w-full '+customStyle" id="mat_form_field_time_component">
                <div class="flex items-center justify-between">
                    <!-- <input matInput type="time" [formControl]="inputSelected" [placeholder]="placeholderValue" inputFormat="12"
                        class="md:text-sm text-xs"> -->
                    <input type="text" [mask]="timeFormat" class="w-full h-hull p-0 md:text-sm text-xs" [placeholder]="placeholderValue"
                        [leadZeroDateTime]="true" [leadZero]="true"
                        matInput  [formControl]="inputSelected"
                        (blur)="setValue(inputSelected.value)"
                        (mouseleave)="autocomplette ? setValue(inputSelected.value) : null"
                        (keyup)="autocomplette ? setValueInTareo(inputSelected.value) : null"
                        [required]="required"
                    >
    
                    <svg *ngIf="withIcon" class="text-gray text-base" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" width="1em" height="1em" preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                        style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);">
                        <path
                            d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8s8 3.58 8 8s-3.58 8-8 8zm-.22-13h-.06c-.4 0-.72.32-.72.72v4.72c0 .35.18.68.49.86l4.15 2.49c.34.2.78.1.98-.24a.71.71 0 0 0-.25-.99l-3.87-2.3V7.72c0-.4-.32-.72-.72-.72z"
                            fill="currentColor">
                        </path>
                    </svg>
                </div>
    
                <mat-error class="whitespace-pre-line" *ngIf="inputSelected.hasError('required') && showErrors">* Este campo es obligatorio.</mat-error>
                <mat-error class="whitespace-pre-line" *ngIf="inputSelected.hasError('isOutSchedule')">* Esta hora se encuentra fuera del horario laboral</mat-error>
                <mat-error class="whitespace-pre-line" *ngIf="inputSelected.hasError('isIntoHourRange')">* Horas dentro de un rango no permitido</mat-error>
                <mat-error class="whitespace-pre-line" *ngIf="inputSelected.hasError('minimumHour')">* {{anticipationMinutesMessage}} </mat-error>
                <mat-error class="whitespace-pre-line" *ngIf="inputSelected.hasError('isInvalidTime')">* Ingrese una hora correcta </mat-error>
                <mat-error class="whitespace-pre-line" *ngIf="inputSelected.hasError('markError')">*Obligatorio </mat-error>
                <mat-error class="whitespace-pre-line" *ngIf="inputSelected.hasError('minTime') && minTimeMessage">{{minTimeMessage}}</mat-error>
    
                <!-- <mat-error class="whitespace-pre-line" *ngIf="inputSelected.hasError('notBetween00AndCurrentServerTime')">* Hora de marca debe ser menor o igual
                    a {{inputSelected.errors.notBetween00AndCurrentServerTime.requiredValue}} </mat-error>
                <mat-error class="whitespace-pre-line" *ngIf="inputSelected.hasError('notBetween18And24')">* Hora de marca debe estar entre las {{myOutMarkTime}}
                    y 23:59</mat-error>
                <mat-error class="whitespace-pre-line" *ngIf="inputSelected.hasError('notBetween18AndCurrentServerTime')">* Hora de marca debe estar entre las
                    {{myOutMarkTime}} y {{inputSelected.errors.notBetween18AndCurrentServerTime.requiredValue}} </mat-error> -->
                <mat-error class="whitespace-pre-line" *ngIf="inputSelected.hasError('isIntoSchedule')">* No puede registrar este tipo de hora dentro de su horario laboral. </mat-error>
            </mat-form-field>
        </div>
        <mat-hint [class]="hintPosition == 'down' ? 'w-full' : 'w-1/2'" *ngIf="inputSelected.valid && hint" class="mat_form_field_time_mat_hint">
            <div class="rounded-lg p-2 flex items-start gap-2 w-full bg-mg-base-background -mt-12">
                <mg-icon class="" iconClass="text-xl text-mg-gray-dark" iconName="info"></mg-icon>
                <div class="text-xxs break-words text-mg-gray-dark" [innerHTML]="messageHint">
                </div>
            </div>
        </mat-hint>
    </div>
</div>

<ng-template #noValidations>
    
<div class="h-full w-[6rem] flex justify-center items-center gap-1" >
    <!-- <div class="w-fit">
        <svg *ngIf="withIcon" class="text-accent text-base" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" width="1em" height="1em" preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 24 24"
        style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);">
        <path
            d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8s8 3.58 8 8s-3.58 8-8 8zm-.22-13h-.06c-.4 0-.72.32-.72.72v4.72c0 .35.18.68.49.86l4.15 2.49c.34.2.78.1.98-.24a.71.71 0 0 0-.25-.99l-3.87-2.3V7.72c0-.4-.32-.72-.72-.72z"
            fill="currentColor">
        </path>
        </svg> 
    </div> -->

    <input type="text"  class="h-hull tex-center w-[3rem] p-0 md:text-sm text-xs " [placeholder]="placeholderValue"
    matInput  [formControl]="inputSelected"
    (blur)="setValue(inputSelected.value)"
    [maxLength]="5"
    (mouseleave)="autocomplette ? setValue(inputSelected.value) : null"
    (keyup)="autocomplette ? setValueInTareo(inputSelected.value) : null"
    [required]="false"
    >
    <div>Hrs.</div>
</div>

</ng-template>

