import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotificationsCenterComponent } from './components/notifications-center/notifications-center.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: "lista",
    pathMatch: "full",
  },
  {
    path: "lista",
    component: NotificationsCenterComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificationsRoutingModule { }
