import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NavigationService } from 'src/app/core/services/navigation.service';
import { scaleFadeIn400ms } from 'src/app/shared/animations/scale-fade-in.animation';
import { NavigationItem } from '../../../interfaces/navigation-item.interface';
import { LayoutService } from 'src/app/core/services/layout.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AccessService } from 'src/app/core/services/access.service';
import { SplashScreenService } from 'src/app/core/services/splash-screen.service';
import { environment as env } from '../../../../../environments/environment';
import { MatMenuTrigger } from '@angular/material/menu';
import { WebSocktesService } from 'src/app/shared/services/webSockets.service';
import { NotificationsService } from 'src/app/core/notifications/services/notifications.service';

@Component({
  selector: 'mg-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [scaleFadeIn400ms],
})
export class SidenavComponent {
  @Input() collapsed: boolean | null;
  @Input() isDesktop: boolean | null; 
  @ViewChild('menuTriggerNotifications') menuTriggerNotifications: MatMenuTrigger;
  @Output() clickItem = new EventEmitter<boolean>(); 
  items: NavigationItem[] = this._navigationService.items;
  counterNotifications: number = 0;
  env = env;

  constructor(
    private _navigationService: NavigationService,
    private _authService: AuthService,
    private _accessService: AccessService,
    private _dialog: MatDialog,
    private _layoutService: LayoutService,
    private _splashScreenService: SplashScreenService,
    private _webSocketsService: WebSocktesService,
    private _notificationsService: NotificationsService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["isDesktop"]["currentValue"]) {
      this._notificationsService.countNotifications$.subscribe(x=> {
        this.badgeCounterNotifications(x);
      })
    }
  }

  toggleCollapse() {
    this.collapsed
      ? this._layoutService.expandSidenav()
      : this._layoutService.collapseSidenav();
  }


  logout() {
    this._webSocketsService.stopAllConections().then(()=>{
      this._splashScreenService.show();
      this._authService.logout().subscribe({
        next: (resp) => {
            if(resp){
              this._accessService.removeAccess().subscribe(()=>{
                this._dialog.closeAll();
                window.location.href = 'auth/login';
              });
            }
        },
        error: e => {
          console.warn(e.error.message)
        }
      });
    })

  }

  badgeCounterNotifications(counter: number){
    setTimeout(() => {
      this.counterNotifications = counter;
    },0)
  } 

  clickItemAction(e:boolean){
    console.log(e)
    this.clickItem.emit(e);
  }
}
