import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { catchError, forkJoin, map, of } from 'rxjs';
import { PreferencesService } from 'src/app/modules/my-board/service/preferences-service.service';
import { ConfigurationsService } from 'src/app/shared/services/configurations.service';
import { groupArray } from '../indexedDB/helpers/formattedData';
import { REFRESH_INDEXED } from '../indexedDB/helpers/enums.enums';
import { IndexedDBDexieService } from '../indexedDB/service/indexed-db-dexie.service';
import { SOCKETS_URLS } from 'src/app/shared/interfaces/sockets.interface';
import { WebSocktesService } from 'src/app/shared/services/webSockets.service';

export const layoutResolver: ResolveFn<any> = (
  route, 
  state,
  _preferenceService: PreferencesService = inject(PreferencesService),
  _configurationsService: ConfigurationsService = inject(ConfigurationsService),
  _indexDB: IndexedDBDexieService = inject(IndexedDBDexieService),
  _webSocketsService: WebSocktesService = inject(WebSocktesService)
  ) => {
  
  const preferences = _preferenceService.getBoardConfig()
  const configurationsIndexed = _configurationsService.getConfig(REFRESH_INDEXED.NEVER, ["feriados"]);
  const configuration = _configurationsService.getConfig(REFRESH_INDEXED.ALWAYS, ["solicitud_por_dias_mes"]);
  const configurationsService$ = groupArray([configurationsIndexed, configuration])
  const newThemes$ = _preferenceService.getTodayThemes();
  const refreshIndexAtDay = _indexDB.refreshDataAtDay();
 // const webSocketsConections = _webSocketsService.getUrlConnections()
  return forkJoin([preferences, configurationsService$, newThemes$, refreshIndexAtDay]).pipe(
    catchError((err) => {
      return of([null, null]);
    })
  );
};
