import { Pipe, PipeTransform } from '@angular/core';
import { setDecimalHours, setTotalHours } from '../helpers/timeAndHours';

@Pipe({
  name: 'hourPipe',
})
export class HourPipe implements PipeTransform {
  transform(
    value: number,
    args: 'totalHours' | 'decimalHours' = 'totalHours'
  ): any {
    if(value < 0){
      value =  value * -1
      switch (args) {
        case 'totalHours':
          return `- ${this.setTotalHours(value)}`;
        case 'decimalHours':
          return `- ${this.setDecimalHours(value)}`;
      }
    }else{
      switch (args) {
        case 'totalHours':
          return this.setTotalHours(value);
        case 'decimalHours':
          return this.setDecimalHours(value)
      }
    }

  }


  setTotalHours(min: number): string {
    let nHours = Math.floor(min / 60);
    let nMinutes = Math.round(min % 60);
    let sHours = nHours > 1 ? nHours + " Hrs. " : nHours + " Hr. ";
    let sMinutes = nMinutes + " Min.";
  
    if (nHours <= 9) {
      sHours = "0" + sHours;
    }
  
    if (nMinutes <= 9) {
      sMinutes = "0" + sMinutes;
    }
  
    return sHours + sMinutes;
  }
  
 setDecimalHours(min: number): string {
    let nHours = Number(min / 60).toFixed(2);
    let sHours = parseInt(nHours) > 1 ? nHours + " Hrs. " : nHours + " Hr. ";
  
    return sHours;
}

}
