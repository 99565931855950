
export enum ACTIVITY_STATUS {
    PENDIENTE = 1,
    OBSERVADO = 2,
    APROBADO = 3,
    APROBADO_CLIENTE = 4,
    OBSERVADO_CLIENTE = 5,
    ELIMINADO = 6,
    REVISADO = 7,
    CORREGIDO = 8,
    PENDIENTE_DIRECTOR = 9,
    PENDIENTE_PAGO = 11,
    PAGADO = 10,
}

export enum STATUS_PAY {
    PENDIENTE_PAGO = 1,
    PAGADO = 2,
}
  
export enum ACTIVITY_REVISION_STATUS {
    NO_REVISION = 0,
    REVISION = 1,
}

export enum TIME_TYPE {
    HORARIO_REGULAR = 1,
    EXTRAS = 2,
    COMPENSACIÓN = 3,
    AVANCE = 4,
    RECUPERACIÓN = 5,
}

export enum LIST_TYPE {
    // PROYECTO = 1,
    // EQUIPO = 0,
    PROYECTO = 1,
    EQUIPO = 2,
}
