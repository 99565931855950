import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { Subscription, interval } from 'rxjs';
// import { structuredCloneMG } from '../../functions/helpers';
import { AnimationBuilder, AnimationFactory, AnimationPlayer, animate, style } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { IconComponent } from '../icon/icon.component';

export interface Slide {
  title: string,
  description: string,
  media: string,
  position?: number
  key?: number,
  colorDominator?: string
}

@Component({
  selector: 'mg-custom-carousel',
  templateUrl: './custom-carousel.component.html',
  styleUrls: ['./custom-carousel.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule,  MatDialogModule,  MatIconModule, IconComponent, MatCheckboxModule, FormsModule],
  encapsulation: ViewEncapsulation.None
})
export class CustomCarouselComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() slideChanged = new EventEmitter<Slide>();
  @Input() autoPlay = true;
  @Input() data: any;
  @ViewChild('slide') carousel: ElementRef;

  private isDragging = false;
  private initialX = 0;

  slides: Slide[] = [];
  index = 0;
  intervalSubscription: Subscription;
  
  //Color primario
  @ViewChild('card', { static: false }) card: ElementRef;

  //Animacion
  timing = '0.5s ease-in-out';
  private player: AnimationPlayer;
  private itemWidth: number;

  slidesCopy: Slide[] = [];
  indexToDots = 0;

  constructor(
    private builder: AnimationBuilder
  ) { }

  ngOnDestroy(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }
  

  ngOnInit(): void {
    this.slides = this.data.slides;
    this.slides = this.slides.map((item, key) => {
      return { ...item, key };
    });
    this.slidesCopy = structuredClone(this.slides)
    if (this.autoPlay) {
      this.startInterval();
    }

  }

  ngAfterViewInit() {
    let elemento = this.card.nativeElement;
    let observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        this.itemWidth = this.card.nativeElement.offsetWidth;
      }
    });
    observer.observe(elemento);
  }

  indexNext() {
    if (this.index === this.slides.length - 1) {
      let arr: any = [...this.slides];
      const first = arr.shift();
      arr = [...arr, first];
      this.slides = arr;
      this.index = this.index - 1;
      this.transitionCarousel(0);
    }
    this.index = (this.index + 1) % this.slides.length;
    this.updateDotIndex(this.slides[this.index].key);
    this.transitionCarousel(null);

    this.slideChanged.emit(this.slides[this.index]);

  }
  
  indexPrevious() {
    if (this.index === 0) {
      let arr: any = [...this.slides];
      const last = arr.pop();
      arr = [last, ...arr];
      this.slides = arr;
      this.index = this.index + 1;
      this.transitionCarousel(0);
    }
    this.index = (this.index - 1 + this.slides.length) % this.slides.length;
    this.updateDotIndex(this.slides[this.index].key);
    this.transitionCarousel(null);

    this.slideChanged.emit(this.slides[this.index]);

  }

  startInterval() {
    this.intervalSubscription = interval(5000).subscribe(() => {
      this.indexNext();
    });
  }

  stopInterval() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  transitionCarousel(time: any) {
    const offset = this.index * this.itemWidth;
    const myAnimation: AnimationFactory = this.buildAnimation(offset, time);
    this.player = myAnimation.create(this.carousel.nativeElement);
    this.player.play();
  }

  private buildAnimation(offset: any, time: any) {
    return this.builder.build([
      animate(
        time == null ? this.timing : 0,
        style({ transform: `translateX(-${offset}px)` })
      ),
    ]);
  }

  updateDotIndex(key: number | undefined){
    if(key || key == 0) this.indexToDots = key
  }

  goTo(indexDot: number){
    this.index = this.slides.findIndex(slide => slide.key === indexDot);
    this.transitionCarousel(null);
    this.updateDotIndex(this.slides[this.index].key);

    this.slideChanged.emit(this.slides[this.index]);

  }

  get isSmallScreen() {
    return window.screen.width < 1280;
  }

  onTouchStart(event: TouchEvent) {
    if (this.isSmallScreen) {
      this.isDragging = true;
      this.initialX = event.touches[0].clientX;
    }
  }

  onTouchEnd(event: TouchEvent) {
    if (this.isSmallScreen && this.isDragging) {
      const distanceDragged = event.changedTouches[0].clientX - this.initialX;

      if (distanceDragged > 15) {
        this.indexPrevious();
      } else if (distanceDragged < -15) {
        this.indexNext();
      } else {
        // No movement
      }

      this.isDragging = false;
    }
  }

  
}
