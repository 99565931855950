import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { wsErrorHandler } from '../helpers/websoketsErrors';
import { BehaviorSubject, Observable, combineLatest, connect, map, of } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { GenericResponse } from '../interfaces/generic.interface';
import { HttpClient } from '@angular/common/http';
import { BehaviorSHubData, HubData, IndexedHubData, SOCKETS_EVENTS, SOCKETS_URLS } from '../interfaces/sockets.interface';
import { localDB } from '../helpers/localStorage';
import { AccessService } from 'src/app/core/services/access.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { IndexedDBDexieService } from 'src/app/core/indexedDB/service/indexed-db-dexie.service';
import { WSRetryPolicy } from '../helpers/webSocketsRetryPolicy';
import * as moment from 'moment';

// NOTAS:
// LAS URL DE LAS CONEXIONES SE SOLICITAN EN EL LAYOUT RESOLVER
// USAR ESTE SERVICIO EN VEZ DEL SIGNALR DIRECTAMENTE PARA CENTRALIZAR ERRORES

@Injectable({
  providedIn: 'root'
})
export class WebSocktesService {
  hubConnections: { [key: string]: signalR.HubConnection } = {}; // AQUI SE CENTRALIZAN LAS CONEXIONES
  hubConnetionsSubjet = new BehaviorSubject<{ [key: string]: signalR.HubConnection }>(this.hubConnections)
  hubConnetionsSubjet$ = this.hubConnetionsSubjet.asObservable()
  private hubDataConnections:{ [key:string]: any }= {}
  private hubEventSucces:{ [key:string]: boolean }= {}
  private hubEventsSuccesSubject = new BehaviorSubject<{ [key:string]: boolean }>({});
  private hubDataConnectionsSubject = new BehaviorSubject<{ [key:string]: any }>({});
  private token: string
  oUrlsConnections: any = {}
  automaticConnection: boolean = false
  reconnectPolicy: signalR.IRetryPolicy 
  aUrl:string[] = []

  constructor(
   private _authService: AuthService,
   private _accessService: AccessService,
   private _indexedDB: IndexedDBDexieService,
  ) { 
    this.automaticConnection = this._authService.isAuthenticated()
    this.token = localDB.getItem('token')
    this.reconnectPolicy = new WSRetryPolicy(this._authService)
  }

// PEDIR CONEXIONES EXISTENTES DES AQUI
  getHubDataConnections(): BehaviorSubject<{ [key:string]: any }> {
    return this.hubDataConnectionsSubject;
  }

  lintenHubsEvents(): BehaviorSubject<{ [key:string]: boolean }> {
    return this.hubEventsSuccesSubject;
  }


//--- MANEJO DE CONEXCIONES

// AQUI SE INICIA CADA CONEXIÓN POR SEPARADO
  async initConnection(url:string){ 
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${env.api_url}${url}?access_token=${this.token}`)
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect(this.reconnectPolicy)
      .build();
    this.hubConnections[url] = connection;
    this.hubConnetionsSubjet.next(this.hubConnections)
    return connection
  }


  async initAllConnections(): Promise<void> {
    const aUrl = this.getAUrlHubs()
    //CREANDO HUBS
    // if(aUrl.length){
    //   try {
    //     const connectionsPromises = aUrl.map(url => this.initConnection(url));
    //     await Promise.all(connectionsPromises)
    //   } catch (err) {
    //     wsErrorHandler('pull of connections', err);
    //   }finally{
    //     try {
    //       const allConnections = aUrl.map(connect => this.startConnection(connect))
    //       await Promise.all(allConnections)
    //     } catch (err) {
    //       wsErrorHandler('stard all connections', err);
    //     } finally{
    //       this.hubConnetionManteiner()
    //       this.registerAllEvents(aUrl)
    //     }
    //   }
    // }


  }



  listenAllActionSockets(){
    this.aUrl.map(hub => {
      this.hubConnections[hub].onclose(err => {
        //console.log(`Connection lost with ${hub}`, err)
        if(err instanceof Error) wsErrorHandler(`Connection lost with ${hub}`, err)
      })
      this.hubConnections[hub].onreconnecting(err => {
        //console.log(err, 'reconnet  sockets')
        if(err instanceof Error) wsErrorHandler(`Reconnecting to ${hub}`, err)
      })
      this.hubConnections[hub].onreconnected(err => {
        //console.log(err, `Reconnected to ${hub}`)
        if(typeof err == 'string'){
          if(this.hubConnections[hub].state === signalR.HubConnectionState.Disconnected){
            this.startConnection(hub).then(()=>{ 
              this.registerAllEvents([hub])
              this.hubConnetionManteiner()
            })
          }else{
            this.registerAllEvents([hub])
            this.hubConnetionManteiner()
          }
          this.hubEventSucces[`${hub}-${SOCKETS_EVENTS.RECONNET}`] = true
          this.hubEventsSuccesSubject.next(this.hubEventSucces)
          setTimeout(() => {
            delete this.hubEventSucces[`${hub}-${SOCKETS_EVENTS.RECONNET}`]
            this.hubEventsSuccesSubject.next(this.hubEventSucces)
          }, 200);
        }
        
      })
    })

  }

  async stopAllConections(){
    // if(this.aUrl.length){
    //   try{
    //     let connetions = this.aUrl.map(url => this.stopConnection(url))
    //       await Promise.all(connetions)
  
    //   }catch(err){
    //     wsErrorHandler('ERROR AL DETENER EVENTOS', err);
    //   }
    // }

    
  }



  registerHubEvent<T>(hub: SOCKETS_URLS, event: SOCKETS_EVENTS){
    // this.hubConnections[hub].on(event, (data:T, callback:any )=> {
    //   this.hubDataConnections[`${hub}-${event}`] = data
    //   this.hubDataConnectionsSubject.next(this.hubDataConnections)
    //   this.hubConnections[hub].invoke( 'SendManteiner', localDB.getItem('idUsuario'), this.hubConnections[hub].connectionId, event)

    // })
  }

  //HUBS SEGUN PERMISOS
  getAUrlHubs(){
    let aUrl:string[] = []
    if(this._accessService.validarPermisoSlug('MI-TABLERO-CARD-SOLICITUDES')) aUrl.push(SOCKETS_URLS.REQUEST)

    // PONER OTORS PERMISOS...
    this.aUrl = aUrl
    return aUrl
  }



  registerAllEvents(aUrl:string[]){
    if(aUrl.includes(SOCKETS_URLS.REQUEST)){
      this.registerHubEvent(SOCKETS_URLS.REQUEST, SOCKETS_EVENTS.CREATE)
      this.registerHubEvent(SOCKETS_URLS.REQUEST, SOCKETS_EVENTS.DELETE)
      this.registerHubEvent(SOCKETS_URLS.REQUEST, SOCKETS_EVENTS.UPDATE)
    //  this.registerHubEvent(SOCKETS_URLS.REQUEST, SOCKETS_EVENTS.MANTEINER)
    }

    //REGISTRAR OTROS EVENTOS
  }

  numManteiner: number = 1
  hubConnetionManteiner(){
        this.aUrl.map(hub => {
          this.hubConnections[SOCKETS_URLS.REQUEST].invoke( 'KeepAlive',`INVOKE KeepAlive: CALL = ${this.numManteiner}`, localDB.getItem('idUsuario'))
          this.hubConnections[hub].on('manteiner',  (data, callback)=>{
            this.numManteiner = this.numManteiner + 1
            setTimeout(() => {
              this.hubConnections[hub].invoke( 'KeepAlive',`INVOKE KeepAlive:: connectionID= ${this.hubConnections[hub].connectionId}, userID=  ${localDB.getItem('idUsuario')}, call= ${this.numManteiner}`,  localDB.getItem('idUsuario'))
            }, 30*1000);
              // this.hubConnections[hub].invoke('UpdateConnectionId', localDB.getItem('idUsuario'), this.hubConnections[hub].connectionId)
              // .then(()=> console.log('ENTRA AL INVOKE'))
              // .catch((error) => {
              //   console.error('Error al invocar UpdateConnectionId:', error);
              // });

          })

          this.hubConnections[hub].on('manteinerV2',  (data, callback)=>{
            // console.log(`SI SE MANDA ESTA VAINA ${hub} ----> ${data}`)
          })
        })


        


  }


  



  startConnection(url: string): Promise<void> { 
    const connection = this.hubConnections[url];
      return connection.start()
      .then()
      .catch(error => {
        wsErrorHandler(url, error)
        throw error; 
      });
  }

  


  // APLICAR EN CADA ON DESTROY DE COMPONENTES
  stopConnection(url: string): Promise<void> { 
    const connection = this.hubConnections[url];
      return connection.stop()
        .then()
        .catch(error => {
          wsErrorHandler(url, error)
          throw error;
        });
  }


  //---- MANEJO DE CONEXIONES FIN

  //---- MANEJO GLOBAL DE EVENTOS CON INDEXEDB INIT

  handlHubEventsOnBehaviorS<T>(data: HubData){
    data.events.map(event => {{
      //  if(event === SOCKETS_EVENTS.MANTEINER) console.log('manteinerResp' , data.connectionData)
        const newDataEvent = data.connectionData[`${data.hubUrl}-${event}`]
        if(newDataEvent){
          this.updateDataBehaviorSByHubEvent(event, {...data.BehaviorData!, newData: newDataEvent}).subscribe(resp => {
            if(resp){
              this.hubEventSucces[`${data.hubUrl}-${event}`] = resp
              this.hubEventsSuccesSubject.next(this.hubEventSucces)
              setTimeout(() => {
                delete this.hubEventSucces[`${data.hubUrl}-${event}`]
                delete this.hubDataConnections[`${data.hubUrl}-${event}`]
                this.hubDataConnectionsSubject.next(this.hubDataConnections)
                this.hubEventsSuccesSubject.next(this.hubEventSucces)
              }, 200);
            }
          })
        }
      }})
  }

  updateDataBehaviorSByHubEvent(event: SOCKETS_EVENTS, data:BehaviorSHubData):Observable<boolean>{
    switch (event){
      case SOCKETS_EVENTS.CREATE:
        return  this.newDataBySocketBS<any>(data)
      case SOCKETS_EVENTS.UPDATE:
        return  this.updateDateBySocketBS<any>(data)
      case SOCKETS_EVENTS.DELETE:
        return  this.deleteDataBySocketBS<any>(data)
      default:
      //  console.log(event)
        return of(false)
    }

}

newDataBySocketBS<T>(data:BehaviorSHubData):Observable<T>{
  let dataUpdated:any = data.oldData.data;
  dataUpdated.push(JSON.parse(data.newData))
  return of({...data.oldData, data:dataUpdated})
}


updateDateBySocketBS<T>(data:BehaviorSHubData):Observable<T>{
  let dataUpdated:any = data.oldData.data;
  let newData = JSON.parse(data.newData)
  dataUpdated = dataUpdated.map((change:any) => {

    if (change[data.sId_Name] === newData[data.sId_Name]) {
      return newData
    } else {
      return change;
    }
  })
  return of({...data.oldData, data:dataUpdated})
}

deleteDataBySocketBS<T>(data:BehaviorSHubData):Observable<T>{
  let dataUpdated:any = data.oldData.data.filter((fil:any) => fil[data.sId_Name] != data.newData);

  return of({...data.oldData, data:dataUpdated})
}





  async handleHubEventsOnIndexedDB<T>(data:HubData){
    try{
      data.events.map(event => {{
      //  if(event === SOCKETS_EVENTS.MANTEINER) console.log('manteinerResp' , data.connectionData)
        const newDataEvent = data.connectionData[`${data.hubUrl}-${event}`]
        if(newDataEvent){
          this.updateDataIndexDByHubEvent(event, {...data.indexedDBData!, newData: newDataEvent}).subscribe(resp => {
            if(resp){
              this.hubEventSucces[`${data.hubUrl}-${event}`] = resp
              this.hubEventsSuccesSubject.next(this.hubEventSucces)
              setTimeout(() => {
                delete this.hubEventSucces[`${data.hubUrl}-${event}`]
                delete this.hubDataConnections[`${data.hubUrl}-${event}`]
                this.hubDataConnectionsSubject.next(this.hubDataConnections)
                this.hubEventsSuccesSubject.next(this.hubEventSucces)
              }, 200);
            }
          })
        }
      }})
    }catch(err){
      wsErrorHandler('fail update dexie', err)
    }

  } 

  updateDataIndexDByHubEvent(event: SOCKETS_EVENTS, data:IndexedHubData):Observable<boolean>{
      switch (event){
        case SOCKETS_EVENTS.CREATE:
          return  this._indexedDB.newDataBySocket<any>(data.tableName, data.keyName, data.newData!)
        case SOCKETS_EVENTS.UPDATE:
          return  this._indexedDB.updateDateBySocket<any>(data.tableName, data.keyName, data.newData!, data.sId_Name!)
        case SOCKETS_EVENTS.DELETE:
          return  this._indexedDB.deleteDataBySocket<any>(data.tableName, data.keyName, data.newData!, data.sId_Name!)
        default:
        //  console.log(event)
          return of(false)
      }

  }

  //---- MANEJO GLOBAL DE EVENTOS CON INDEXEDB FIN


}
