import { Injectable } from '@angular/core';
import { addAlpha, brightnesColor, lightenColor } from 'src/app/modules/my-board/helpers/functions';
import { ThemeColors } from 'src/app/modules/my-board/interfaces/board.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  constructor() { }

  setTheme(theme: ThemeColors) {
    if(theme){
      var colorsConfig = document.querySelector(':root') as HTMLElement;

      colorsConfig!.style.setProperty('--accent-color', theme.first);
      colorsConfig!.style.setProperty('--accent-color-light', theme.second);
      colorsConfig!.style.setProperty('--accent-color-dark', theme.third);
      colorsConfig!.style.setProperty('--accent-color-base', theme.base);
      colorsConfig!.style.setProperty('--accent-color-design', lightenColor(theme.base,65));
      colorsConfig!.style.setProperty('--accent-color-transparent', addAlpha(theme.first,0.3));

      if(brightnesColor(theme.base)){
        var logo = document.getElementById("logo-smart") as HTMLImageElement;
        if(logo){
          logo.src="assets/svg/logo_login.svg";
        }
        colorsConfig!.style.setProperty('--text-var-color','white');
      }else{
        var logo = document.getElementById("logo-smart") as HTMLImageElement;
        if(logo){
          logo.src="assets/svg/logo.svg";
        }
        colorsConfig!.style.setProperty('--text-var-color', theme.third);
      }

      let PWATheme = document.querySelector('meta[name="theme-color"]');
      if(PWATheme){
        PWATheme.setAttribute('content', theme.base);
      }

    }
  }

}



