import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone, Injector  } from '@angular/core';
import { Router, ResolveFn } from '@angular/router';
import { NavigationService } from './navigation.service';
import { BehaviorSubject, Observable, forkJoin, lastValueFrom, map, of } from 'rxjs';
import { GenericResponse } from '../../shared/interfaces/generic.interface';
import { DataAccess } from '../interfaces/access.interface';
import { environment as env } from "../../../environments/environment";
import { NavigationDropdown, NavigationItem, NavigationLink } from '../interfaces/navigation-item.interface';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';
import { localDB } from 'src/app/shared/helpers/localStorage';
import { IndexedDBDexieService } from '../indexedDB/service/indexed-db-dexie.service';
import { REFRESH_INDEXED } from '../indexedDB/helpers/enums.enums';
import { WebSocktesService } from 'src/app/shared/services/webSockets.service';
// import { GoogleService } from './googleApi.service';

@Injectable({
  providedIn: 'root'
})
export class AccessService {
  private _dialog: MatDialog;
  private _injector: Injector;
  aSlugPermissions: string[] = [];
  redirectionUrl$: any | null = null
  redirectionObs$: Observable<any | null> = of(null)

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _navigationService: NavigationService,
    private _authService: AuthService,
    private _zone: NgZone,
    // private _googleService: GoogleService,
  //  private _indexedDB: IndexedDBService,
   private _indexedDB: IndexedDBDexieService,
    // private _webSocketsServices:WebSocktesService,
    injector: Injector
  ) {
    this._injector = injector;
    if (!this._dialog) {
      this._dialog = this._injector.get(MatDialog);
    }

    this.redirectionUrl$ = new BehaviorSubject<any | null>(null)
    this.redirectionObs$ = this.redirectionUrl$.asObservable()

   }

  obtenerAccesos(bUse_Collaborator:number = 0): Observable<GenericResponse<DataAccess[]>> {
    return this._http.get<GenericResponse<DataAccess[]>>(env.api_url + `api/accesos?nUse_Collaborator=${bUse_Collaborator}`)
    
    // .pipe(
    //   map((resp) =>{
    //     let personal = resp.data.find((module) => module.sNombre_Modulo == "Personal");
    //     let configs = resp.data.find((module) => module.sNombre_Modulo == "Configuración")

    //     let contratos;
    //     let plantillasContratos;

    //     if(personal){
    //       contratos = personal.aSub_Modulo!.find((subModule) => subModule.sNombre_Modulo == "Contratos")
    //     }

    //     if(configs){
    //       plantillasContratos = configs.aSub_Modulo!.find((subModule) => subModule.sNombre_Modulo == "Plantillas Contratos")
    //     }

    //     if(contratos || plantillasContratos){
    //       this._googleService.getaccessToken(false).subscribe({
    //         next: (apiToken) => {
    //           console.warn('TODO CORRECTO')
    //         },
    //         error: () => {
    //           console.error('TODO MAL')
    //         }
    //       })
    //     }

    //     return resp;
    //   })
    // );
  }

  
  removeAccess() {
     return this._indexedDB.refreshAllByTypeRefresh(REFRESH_INDEXED.EVERY_DAY).pipe(
      map(()=>{
      localStorage.clear();
      sessionStorage.clear();

      this._navigationService.items = [];
      this.aSlugPermissions = [];
    }))
  }

  validarPermisoSlug(sSlug: string): boolean {
    return this.aSlugPermissions.includes(sSlug);
  }

  isAuthorizedToVisitPage(aSlug : string[]) {
    aSlug.some((slug) => {
      return this.validarPermisoSlug(slug);
    });
  }

  isAuthorizedToVisitModule(path: string): boolean{
    let aPaths : string[] = [];

    this._navigationService.items.forEach(item => {
        if(item.type == 'link' && item.bMostrar){
          aPaths.push(item.route.split('/').filter(Boolean)[0]);
        }
        if(item.type == 'dropdown'){
          item.children.forEach(item2 => {
            if(item2.type == 'link'){
              aPaths.push(item2.route.split('/').filter(Boolean)[0])
            }
          })
        }
    });

    return aPaths.includes(path);
  }

  setNavigationItems(items: NavigationItem[]): void {
    this._navigationService.items = [];
    this._navigationService.items = items;
  }

  setPermissions(aSlugPermissions: string[]) {
    this.aSlugPermissions = [];
    this.aSlugPermissions = aSlugPermissions;
  }

  async setNavigationItem(): Promise<GenericResponse<DataAccess[]|null>> {
    let menuItem: NavigationItem | null = null;
    let navigationItems: NavigationItem[] = [];
    let responseReturn: GenericResponse<DataAccess[]|null> = {
      isSuccess: false,
      code: 401,
      message: 'Sesión experida',
      data: null
    };
    if (this._authService.isAuthenticated()) {
      let response  = await lastValueFrom(this.obtenerAccesos(1));
      responseReturn = response;
      if(response.isSuccess){
        response.data.forEach(module => {
          menuItem = this.getItemNav(module);
          navigationItems.push(menuItem);
          this.setSlugsPermissions(module);
        })

        localDB.setItem("aSlugPermissions", JSON.stringify(this.aSlugPermissions));
        localDB.setItem(
          "aNavigationItem",
          JSON.stringify(navigationItems)
        );
        this.setNavigationItems(navigationItems);
      }
    }
    return responseReturn;
  }

  setSlugsPermissions(module: DataAccess) {
    if (module.aOpciones) {
      module.aOpciones.forEach((opcion) => {
        this.aSlugPermissions.push(opcion.sSlug);
      });
    }
    if (module.aSub_Modulo) {
      module.aSub_Modulo.forEach((subModule) => {
        this.setSlugsPermissions(subModule);
      });
    }
  }

  getItemNav(modulo: DataAccess) {

    if (modulo.aSub_Modulo!.length > 0) {
      return this.getNavigationDropdown(modulo);
    }

    return this.getNavigationLink(modulo);
  }

  getNavigationDropdown(modulo: DataAccess): NavigationDropdown {
    let dropdown: NavigationDropdown;
    dropdown = {
      type: "dropdown",
      label: modulo.sNombre_Modulo,
      icon: modulo.sIcono_Modulo,
      children: [],
      bMostrar: modulo.bMostrar
    }

    

    modulo.aSub_Modulo!.forEach((subModulo) => {
      dropdown.children.push(this.getNavigationLink(subModulo));
    });
    return dropdown;
  }

  getNavigationLink(modulo: DataAccess):  NavigationLink {
    let link:  NavigationLink;
    link = {
      type: "link",
      label: modulo.sNombre_Modulo,
      route:  modulo.sUrl_Link_Modulo,
      icon: modulo.sIcono_Modulo,
      bMostrar: modulo.bMostrar
    }
    return link;
  }

  routeLogin() {
    this._dialog.closeAll();
    this.removeAccess().subscribe((x)=>{
      this._zone.run(() => {
        this._router.navigate(
          ['/auth/login'],
          { queryParams: { force: true }}
        ).then((y:any) =>{ 
          window.location.reload()});
      })
    });
  }


 routeNavigation() {
  let board = this._navigationService.items.find(
    (x) => x.label == "Mi Tablero"
  );
  let dashboard = this._navigationService.items.find(
    (x) => x.label == "Dashboard"
  );
  this._authService.isAuthenticatedUser.next(true)
   this.redirectionObs$.subscribe(url => {
    if (board && !dashboard) {
      if(url){
        this._router.navigateByUrl(url)
        setTimeout(() => {
          this.redirectionUrl$.next(null)
        }, 1000);
      }else{
        this._zone.run(() => {
          this._router.navigate(["/tablero/lista"]);
        })
      }
    } else if (!board && dashboard) {

      if(url){

        this._router.navigateByUrl(url)
        setTimeout(() => {
          this.redirectionUrl$.next(null)
        }, 1000);
      }else{
        this._zone.run(() => {
         this._router.navigate(["/dashboard/lista"]);
        })
      }
    } else {

      if(url){

        this._router.navigateByUrl(url)
        setTimeout(() => {
          this.redirectionUrl$.next(null)
        }, 1000);
      }else{
        this._zone.run(() => {
          this._router.navigate(["/common/bienvenido"]);
        })
      }
    }
  }).unsubscribe()


 }
}
