<mg-safari-banner></mg-safari-banner>

<div class="w-full h-full bg-mg-accent-base overflow-hidden flex flex-col"  id="contentBg">
    <div class="w-full h-fit relative z-50 ">
        <mat-progress-bar *ngIf="loading" color="primary" mode="indeterminate"></mat-progress-bar>
        <mg-revision-banner></mg-revision-banner>
        <mg-toolbar [isDesktop]="isDesktop$ | async" [sidenav]="sidenav"></mg-toolbar>
    </div>
    <mat-sidenav-container class="h-full w-full">
        <!-- [fixedInViewport]="true"  -->
        <mat-sidenav 
         class="!border-0" #sidenav [disableClose]="isDesktop$ | async" [mode]="!(isDesktop$ | async) ? 'over' : 'side'"
            [opened]="(isDesktop$ | async)">
            <mg-sidenav [collapsed]="sidenavCollapsed$ | async" [isDesktop]="isDesktop$ | async" (clickItem)="clickItem()"></mg-sidenav>
        </mat-sidenav>

        <mat-sidenav-content [class]="(isDesktop$ | async) ? '!ml-[4.5rem]': '!ml-0'">
            <div class="h-full flex flex-col ">
                <!-- [class]="((isDesktop$ | async) && !(sidenavCollapsed$ | async)) ? 'ml-[13.5rem]' : 'ml-0'" -->
                <main class="h-full transition-all duration-300 overflow-hidden">
                    <mg-toast-group></mg-toast-group>
                    <router-outlet></router-outlet>
                </main>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>