import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ClockComponent } from './components/toolbar/components/clock/clock.component';
import { ToolbarUserComponent } from './components/toolbar/components/toolbar-user/toolbar-user.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from "@angular/material/button";
import { RouterModule } from '@angular/router';
import { MatSidenavModule } from '@angular/material/sidenav';
import { IconComponent } from '../../shared/components/icon/icon.component';
import { SidenavItemComponent } from './components/sidenav/components/sidenav-item/sidenav-item.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { NotificationsModule } from '../notifications/notifications.module';
import { SafariBannerComponent } from './components/safari-banner/safari-banner.component';
import { RevisionBannerComponent } from './components/revision-banner/revision-banner.component';
import { layoutResolver } from './layout-resolver.resolver';
import { ToastGroupComponent } from 'src/app/shared/components/toast-alert/toastGroup/toast-group.component';
import { ActivityComponent } from './components/toolbar/components/activity-task/activity.component';
import { TasksCreateModalComponent } from 'src/app/modules/task/components/tasks-create-modal-new/tasks-create-modal.component';
import { VersionNewsComponent } from './components/toolbar/components/version-news/version-news.component';
@NgModule({
  declarations: [
    LayoutComponent,
    ToolbarComponent,
    SidenavComponent,
    ClockComponent,
    ToolbarUserComponent,
    SidenavItemComponent,
    ActivityComponent,
    VersionNewsComponent,
  ],
  imports: [
    CommonModule,
    ToastGroupComponent,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatButtonModule,
    RouterModule,
    MatSidenavModule,
    IconComponent,
    MatProgressBarModule,
    MatBadgeModule,
    NotificationsModule,
    SafariBannerComponent,
    RevisionBannerComponent,
  ], 
  exports: [
    LayoutComponent,
    ToolbarComponent,
    SidenavComponent,
    ActivityComponent,
  ]
})
export class LayoutModule { }
