import {
  Component,
  Input,
  OnInit,
  NgZone,
  ViewChild,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormControl, ReactiveFormsModule, ValidatorFn, Validators } from "@angular/forms";
import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import { take } from "rxjs/operators";
import { GenericValidators } from "../validators/generic-validators";
import { CommonModule } from "@angular/common";
import { MatInputModule } from "@angular/material/input";
import { modeType } from "src/app/shared/interfaces/inputype.interface";

@Component({
  selector: "mg-input-textarea",
  templateUrl: "./textarea.component.html",
  styleUrls: ["./textarea.component.scss"],
  standalone: true,
  imports: [CommonModule, MatInputModule, ReactiveFormsModule]
})
export class TextareaComponent implements OnInit, OnChanges {
  @Input() inputControl: FormControl = new FormControl(null);
  @Input() label: string = "Text";
  @Input() placeholder: string = "";
  @Input() required: boolean = false;
  @Input() mode: modeType = "create";
  @Input() maxlength: number = 100;
  @Input() minlength: number = 15;
  @Input() onFocusChange: boolean = true;
  @Input() isLoading: boolean = false;
  @Input() withLabel: boolean = true;
  @Input() maxRows: number = 99;
  @Input() minRows: number = 1;


  @Output() onFocus = new EventEmitter<boolean>();
  validators : ValidatorFn[] = [];

  constructor(private _ngZone: NgZone) {}
  ngOnChanges(changes: SimpleChanges): void {
    for (let property in changes) {
      if (property === "mode") {
        this.initMode();
      }
      if (property === "required") {
        this.setValidators();
      }
    }
  }

  @ViewChild("autosize", { static: false }) autosize: CdkTextareaAutosize;

  ngOnInit(): void {
    this.initMode();
    this.setOneError();
  }

  private initMode() {
    this.setValidators();
    switch (this.mode) {
      case "create":
        this.inputControl.enable();
        break;
      case "read":
        this.inputControl.disable();
        break;
      case "update":
        this.inputControl.enable();
        break;
      default:
        break;
    }
  }

  get placeholderValue() {
    return this.mode !== "read" ? this.placeholder : "";
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  autoGrowTextZone(e: Event) {
    (e.target as HTMLTextAreaElement).style.height = "0px";
    (e.target as HTMLTextAreaElement).style.height = (e.target as HTMLTextAreaElement).scrollHeight + 10 + "px";
  }

  focus(e: FocusEvent) {
    this.onFocus.emit(true);
  }

  focusout(e: FocusEvent) {
    // (e.target as HTMLTextAreaElement).style.height = "20px";
    this.onFocus.emit(false);
  }

  setOneError() {
    this.inputControl.valueChanges.subscribe(() => {
      if (this.inputControl.errors) {
        let claves = Object.keys(this.inputControl.errors);
        let clave: string = claves[0];
        let error: any = {};
        error[clave] = this.inputControl.errors[clave];
        this.inputControl.setErrors(error);
      } else {
        this.inputControl.setErrors(null);
      }
    });
  }

  setValidators() {
    this.validators = [];
    if (this.required) {
      this.validators.push(Validators.required);
      this.validators.push(GenericValidators.notOnlyWhitespaceValidator as ValidatorFn);
    }

    if (this.minlength) {
      this.validators.push(Validators.minLength(this.minlength));
    }

    this.inputControl.setValidators(this.validators as ValidatorFn[]);
    this.inputControl.updateValueAndValidity();
  }
}
