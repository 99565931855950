import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SplashScreenService {
  splashScreenElem: HTMLElement;
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.splashScreenElem = this.document.body.querySelector(
      '#mg-splash-screen-initial'
    )!;
  }

  hide() {
    this.splashScreenElem!.style.display = 'none';
  }
  show() {
    this.splashScreenElem!.style.display = 'block';
  }
}
