import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginResponseEncrypt, loginStart } from '../interfaces/auth.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { GenericResponse } from '../../shared/interfaces/generic.interface';
import { environment as env, environment } from "../../../environments/environment";
import { localDB } from '../../shared/helpers/localStorage';
import { GoogleInitOptions, GoogleLoginProvider, MicrosoftLoginProvider, SocialAuthService, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { ConfigEnviromentService } from './configEnviroment.service'; // Importa el servicio de configuración

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData: any;
  isAuthenticatedUser = new BehaviorSubject<boolean>(false)
  isAuthenticatedUser$ = this.isAuthenticatedUser.asObservable()

  private readonly googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
  private readonly clientId = '997300048963-80gpfiidkrpjgbeh4bs57il1kq6pu5s7.apps.googleusercontent.com';
  private readonly redirectUri = 'http://localhost:4200';
  private readonly scopes = 'openid email profile';
  private readonly prompt = 'select_account';

  constructor(private http: HttpClient,
    private _socialAuthService: SocialAuthService,
    ) { }

  login(data: loginStart, type: string, ) {
    return this.http.post<LoginResponseEncrypt>(env.api_url +  `api/login?auth_type=${type}`, data);
  }

  PhotoGmailUpdate(data: {sUrl_Foto : string}): Observable<GenericResponse<boolean>> {
    return this.http.put<GenericResponse<boolean>>(env.api_url + "api/colaborador/actualizarFoto", data);
  }

  logout(): Observable<GenericResponse<boolean>> {
    this.isAuthenticatedUser.next(false)
    return this.http.put<GenericResponse<boolean>>(env.api_url + "api/logout", null);
  }

  isAuthenticated(): boolean {
    const token = localDB.getItem("token");
   // this.isAuthenticatedUser.next(token ? true : false)
    return token ? true : false;
  }

  getSession(){
    return this._socialAuthService.authState
  }

  loginMicrosoft(){
    return this._socialAuthService.signIn(MicrosoftLoginProvider.PROVIDER_ID)
  }

  signOut(){
    return this._socialAuthService.signOut()
  }
}


const googleLoginOptions: GoogleInitOptions = {
  scopes:  ['https://www.googleapis.com/auth/drive', 'https://www.googleapis.com/auth/drive.file', 'https://www.googleapis.com/auth/documents']
};


export function socialAuthServiceConfigFactory(configService: ConfigEnviromentService): Promise<SocialAuthServiceConfig> {
  return new Promise((resolve, reject) => {
    configService.loadConfig().then(() => {
      // Después de cargar la configuración, accedemos a environment
      const providers: any[] = [
        {
          id: MicrosoftLoginProvider.PROVIDER_ID,
          autologin: true,
          provider: new MicrosoftLoginProvider(environment.microsoftClientId, {
            prompt: 'select_account',
          })
        }
      ];

      // Solo añadimos Google si no hay token y la página no es de error
      if (!localDB.getItem('token') && window.location.pathname != '/service-unaviable') {
        providers.push({
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(environment.googleClientId, googleLoginOptions)
        });
      }

      resolve({
        autoLogin: false,
        providers,
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig);
    }).catch((error) => {
      console.error('Error loading config', error);
      reject(error);
    });
  });
}
/*
export function socialAuthServiceConfigFactory(): any {
  let providers: any[] = [
    {
      id: MicrosoftLoginProvider.PROVIDER_ID,
      autologin: true,
      provider: new MicrosoftLoginProvider(environment.microsoftClientId, {
        prompt: 'select_account',
      })
    }
  ];

  if (!localDB.getItem('token') && window.location.pathname != '/service-unaviable') {
    providers.push({
      id: GoogleLoginProvider.PROVIDER_ID,
      provider: new GoogleLoginProvider(environment.googleClientId, googleLoginOptions)
    });
  }

  return {
      autoLogin: false,
      providers,
      onError: (err) => {
        console.error(err);
      }
  } as SocialAuthServiceConfig;
}*/
