<mat-label *ngIf="(isScreenLG$ | async)" class="text-mg-variant text-xs font-medium">{{label}}</mat-label>
<div [class]="!(isScreenLG$ | async) && aplicateResponsive ? 'rounded-full bg-mg-accent h-11 flex items-center justify-center' : 'bg-white rounded-xl border border-mg-main-blue-light h-11 w-full flex gap-2'"
    [class.w-11]="!aTypeOfDatesOptions" [matTooltip]="label" [matTooltipDisabled]="(isScreenLG$ | async)">
    <div class="flex items-center justify-between w-full"
        [style]="!(isScreenLG$ | async) && aplicateResponsive ? 'padding-left: 0px;' : 'padding-left: 15px; padding-top: 2px;'">
        <mat-date-range-input [class.!hidden]="!(isScreenLG$ | async) && aplicateResponsive" [formGroup]="range" [rangePicker]="fDate" [max]="maxDate"
            [min]="minDate" [dateFilter]="setDisableDates">
            <input readonly matStartDate formControlName="dateStart" placeholder="dd/mm/aaaa">
            <input readonly matEndDate formControlName="dateEnd" placeholder="dd/mm/aaaa"
                (dateInput)="addEventEnd($event)">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="fDate">
            <mg-icon matDatepickerToggleIcon [iconClass]="!(isScreenLG$ | async) && aplicateResponsive ? '!text-white -ml-1' : '!text-mg-main-blue-dark'" iconName="today"></mg-icon>
        </mat-datepicker-toggle>
        <mat-date-range-picker #fDate [touchUi]="!(isScreenLG$ | async) && aplicateResponsive"></mat-date-range-picker>
    </div>
    <ng-container *ngIf="aTypeOfDatesOptions">
        <button [class]="(isScreenLG$ | async) ? 'bg-mg-accent rounded-r-xl' : 'border-l border-mg-main-blue-dark'"
            [matMenuTriggerFor]="tipoContenido" class="flex items-center justify-center h-full">
            <mg-icon iconClass="text-white" iconName="arrow_drop_down"></mg-icon>
        </button>
        <mat-menu #tipoContenido="matMenu" class="mt-2 flex">
            <button *ngFor="let item of aTypeOfDatesOptions.options"
                class="flex pl-5 pr-6 py-3 w-full hover:bg-mg-base-background" (click)="setTypeOfDateFilter(item.value)"
                [ngClass]="{'bg-mg-base-background': selectedItem === item.value}">
                {{item.label}}
            </button>
        </mat-menu>
    </ng-container>
</div>