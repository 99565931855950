<button [matMenuTriggerFor]="menu_profile" class="flex items-center gap-2 py-1 px-2 rounded-md hover:backdrop-blur-3xl ">
    <div class="font-normal text-sm text-mg-variant">{{user.name | titlecase}}</div>
    <img class="w-10 h-10 rounded-full"
        [src]="user.pic ? user.pic :  'assets/svg/utils/avatar_default.svg'"
        alt="Profile">
</button>

<mat-menu #menu_profile="matMenu" class="!rounded-2xl  !max-w-xs !min-w-[20rem]">
    <div class="bg-mg-accent flex gap-4 items-center p-4">
        <img class="w-20 h-20 rounded-full !border-4 !border-white"
            [src]="user.pic ? user.pic :  'assets/svg/utils/avatar_default.svg'"
            alt="Profile">
        <div class="text-white">
            <p class="text-sm">{{user.name | titlecase}}</p>
            <p class="text-xs">{{user.rol | titlecase}}</p>
        </div>
    </div>
    <ng-container *ngFor="let item of items;">
        <button class="flex w-full gap-2 items-center justify-between !p-4 hover:bg-mg-base-background"
            [routerLink]="[item.route]" [queryParams]="item.queryParms">
            <div class="flex gap-2 items-center">
                <mg-icon iconClass="text-mg-accent text-4xl" [iconName]="item.icon"></mg-icon>
                <div class="text-left">
                    <p class="text-sm text-mg-gray-dark">{{ item.label }}</p>
                    <p class="text-xs text-mg-gray">{{ item.description }}</p>
                </div>
            </div>
            <mg-icon iconClass="text-mg-gray" iconName="chevron_right"></mg-icon>
        </button>
    </ng-container>
</mat-menu>