<div class="text-mg-accent  md:text-sm text-xs " *ngIf="!isLoading && withLabel">
    {{label}}<span *ngIf="mode!= 'read' && required && label != ''" class="text-mg-red">*</span>
</div>
<div class="animate-pulse bg-mg-preload rounded h-4 max-w-1/2" *ngIf="isLoading && withLabel"> </div>
<mat-form-field appearance="outline" class="w-full" *ngIf="!isLoading && !disableValidatios" [matTooltip]="(aOpciones == undefined || aOpciones?.length == 0) ? 'El listado esta vacío' : '' " matTooltipPosition="above" id="mat_form_field_select_component">
    <div class="w-full flex" [ngClass]="optionIcon && inputControl.value ? '-mt-1' : ''">

        <div class="p-1 rounded-lg flex items-center w-7 h-7 mr-2" *ngIf="optionIcon && IconOnInput" [class]="IconOnInput.class">
            <mg-icon class="flex-none" [iconName]="IconOnInput.name" [iconType]="IconOnInput.type"></mg-icon>
        </div>          

        <input #myInputToBlur type="text" class="flex " [placeholder]="placeholder" matInput
            [formControl]="inputControl" [matAutocomplete]="optionsAutocomplete" autocomplete="off"
            [disabled]="inputControl.disabled" >
        <ng-container *ngIf="mode!= 'read'">
            <button *ngIf="inputControl.value" @scaleInOut (click)="inputControl.reset()" class="mr-4"
                [ngClass]="{'text-gray' :inputControl.disabled, 'text-black':inputControl.enabled}" type="button"
                [disabled]="inputControl.disabled">
                <mg-icon class="flex-none" iconClass="text-mg-gray text-sm" iconName="close"></mg-icon>
            </button>
            <mg-icon class="text-xl text-mg-main-blue-light flex items-center" iconClass="text-mg-gray text-sm" iconName="arrow_drop_down"></mg-icon>
        </ng-container>
    </div>
    <mat-hint *ngIf="hint_active">
        <div class="rounded bg-primary-light px-2 md:px-4 py-2 md:py-2 flex items-center w-full">
            <div class="mr-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                    <path fill="currentColor" viewBox="0 0 16 16"
                        d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                </svg>
            </div>
            <div class="ml-1">
                <span [innerHTML]="hint_message"></span>
            </div>
        </div>
    </mat-hint>
    <mat-error class="whitespace-pre-line" *ngIf="inputControl.hasError('invalid')">* Seleccione una opción válida. </mat-error>
    <mat-error class="whitespace-pre-line" *ngIf="inputControl.hasError('required')">* Este campo es obligatorio. </mat-error>
    <mat-error class="whitespace-pre-line" *ngIf="inputControl.hasError('bIsInvalidOption')">* Debe completar primero las horas regulares. Desde: {{firstDate}} hasta {{lastDate}}</mat-error>
    <mat-error class="whitespace-pre-line" *ngIf="inputControl.hasError('bIsInvalidarReq')">* Solo se pueden seleccionar requerimientos activos.</mat-error>

    <mat-autocomplete #optionsAutocomplete="matAutocomplete" [displayWith]="mostrarDropdrown.bind(this)">
        <mat-option class="truncate" *ngFor="let option of opcionesfiltradas | async" [value]="option.sCodigo"
            matTooltip="{{option.sDescripcion}}" matToolTipPosition="after"
            [matTooltipDisabled]="isSmallScreen"
            >
            <div *ngIf="option.icon" class="p-1 rounded-lg flex items-center w-7 h-7" [class]="option.icon.class">
                <mg-icon class="flex-none" iconClass="text-xl" [iconName]="option.icon.name" [iconType]="option.icon.type"></mg-icon>
            </div>            
            {{option.sDescripcion}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>

<div [ngClass]="mode == 'read' ? 'bg-mg-gray-light bg-opacity-45' : 'bg-white'"
 class="w-full p-1 px-2 h-11 border border-mg-accent-light  border-1 rounded-xl flex justify-between items-center" *ngIf="!isLoading && disableValidatios" [matTooltip]="(aOpciones == undefined || aOpciones?.length == 0) ? 'El listado esta vacío' : '' " matTooltipPosition="above" >
    <div class="w-full">
        <div class="w-full flex justify-between" [ngClass]="optionIcon && inputControl.value ? '' : ''">

            <div class="p-1 rounded-lg flex items-center w-7 h-7 mr-2" *ngIf="optionIcon && IconOnInput" [class]="IconOnInput.class">
                <mg-icon class="flex-none" [iconName]="IconOnInput.name" [iconType]="IconOnInput.type"></mg-icon>
            </div>          
            <div class="w-full flex gap-2 justify-between items-center">
            <input #myInputToBlur type="text" class="flex text-sm  w-full"  [placeholder]="placeholder" matInput
                [formControl]="inputControl" [matAutocomplete]="optionsAutocomplete" autocomplete="off"
                [disabled]="inputControl.disabled" >
        

                    <button *ngIf="inputControl.value" @scaleInOut (click)="inputControl.reset()"  
                    [ngClass]="{'text-gray' :inputControl.disabled, 'text-black':inputControl.enabled}" type="button"
                    [disabled]="inputControl.disabled">
                    <mg-icon class="flex-none" iconClass="text-mg-gray text-sm" iconName="close"></mg-icon>
                </button>
            </div>
                <mg-icon class="text-xl text-mg-main-blue-light flex items-center" iconClass="text-mg-gray text-sm" iconName="arrow_drop_down"></mg-icon>

        </div>
    
        <mat-autocomplete requireSelection class="w-full" #optionsAutocomplete="matAutocomplete" [displayWith]="mostrarDropdrown.bind(this)" >
            <mat-option class="w-full" *ngFor="let option of opcionesfiltradas | async" [value]="option.sCodigo"
                matTooltip="{{option.sDescripcion}}" matToolTipPosition="after"
                [matTooltipDisabled]="isSmallScreen"
                >
                <div *ngIf="option.icon" class="p-1 rounded-lg flex items-center w-7 h-7" [class]="option.icon.class">
                    <mg-icon class="flex-none" iconClass="text-xl" [iconName]="option.icon.name" [iconType]="option.icon.type"></mg-icon>
                </div> 
                <span class="!text-sm">{{option.sDescripcion}}</span>           
            </mat-option>
        </mat-autocomplete>
    </div>

</div>

<div class="animate-pulse bg-mg-preload rounded h-[3.25rem] mt-1 min-w-36 mb-5" *ngIf="isLoading  && !disableValidatios"></div>
<div class="animate-pulse bg-mg-preload rounded h-[3.25rem] my-1 min-w-36 " *ngIf="isLoading  && disableValidatios"></div>