import Dexie, { DexieError } from "dexie"
import { REFRESH_INDEXED } from "./enums.enums"
import { HttpErrorResponse } from "@angular/common/http"

export const hasNavigatorMemory = async (data?:any) => {
    let oStorage
    try{
      oStorage = await navigator.storage.estimate()
    }catch(err){
      errorHandler(err)
    }finally{
      let sData = data ?  JSON.stringify(data) : ''
      let nQuota = oStorage?.quota ? oStorage.quota : 0
      let nUsed = oStorage?.usage ? oStorage.usage : 0
      let nDataQuote =  new TextEncoder().encode(sData).length
      let maxUsage = nQuota * .8
      return (nUsed + nDataQuote) < maxUsage
    }
    }
  
    export const errorHandler = (err:any) => {
      if( err instanceof Dexie.DexieError){
        console.log(err.cause, 'DexieError dexie')
        console.log(err.inner, 'DexieError dexie')
        console.log(err.message, 'DexieError dexie')
        console.log(err.stack, 'DexieError dexie')
        dexieErrors(err)
      }else if(err instanceof HttpErrorResponse){
        console.log(err, 'HttpErrorResponse dexie')
      }else{
        console.log('Another error dexie')
      } 
    }
  
    export const isMatchedData = (key: string | number | null, value:string | number | null) => {
      return !key || key == value
    }
  
  
    export const  aId_DataToRefresh = (nRefreshID: REFRESH_INDEXED, data:any[]) => {
      return data
      .filter(dataR => dataR.refresh == nRefreshID)
      .map(dataR => dataR.id)
    }

    export const  deleteDuplicated = (data: any[]) => {
    return Array.from(new Set(data.map((value) => JSON.stringify(value)))).map((value)=>JSON.parse(value));
  }

  const dexieErrors = (error: DexieError) => {
    switch (error.name) {
      case "OpenFailedError":
        console.log('DEXIE OpenFailedError: ', error);
        break;
      case "VersionChangeError":
        console.log('DEXIE VersionChangeError: ', error);
        break;
      case "SchemaError":
        console.log('DEXIE SchemaError: ', error);
        break;
      case "UpgradeError":
        console.log('DEXIE UpgradeError: ', error);
        break;
      case "InvalidTableError":
        console.log('DEXIE InvalidTableError: ', error);
        break;
      case "MissingAPIError":
        console.log('DEXIE MissingAPIError: ', error);
        break;
      case "NoSuchDatabaseError":
        console.log('DEXIE NoSuchDatabaseError: ', error);
        break;
      case "InvalidArgumentError":
        console.log('DEXIE InvalidArgumentError: ', error);
        break;
      case "SubTransactionError":
        console.log('DEXIE SubTransactionError: ', error);
        break;
      case "UnsupportedError":
        console.log('DEXIE UnsupportedError: ', error);
        break;
      case "InternalError":
        console.log('DEXIE InternalError: ', error);
        break;
      case "DatabaseClosedError":
        console.log('DEXIE DatabaseClosedError: ', error);
        break;
      case "PrematureCommitError":
        console.log('DEXIE PrematureCommitError: ', error);
        break;
      case "ForeignAwaitError":
        console.log('DEXIE ForeignAwaitError: ', error);
        break;
      case "UnknownError":
        console.log('DEXIE UnknownError: ', error);
        break;
      case "ConstraintError":
        console.log('DEXIE ConstraintError: ', error);
        break;
      case "DataError":
        console.log('DEXIE DataError: ', error);
        break;
      case "TransactionInactiveError":
        console.log('DEXIE TransactionInactiveError: ', error);
        break;
      case "ReadOnlyError":
        console.log('DEXIE ReadOnlyError: ', error);
        break;
      case "VersionError":
        console.log('DEXIE VersionError: ', error);
        break;
      case "NotFoundError":
        console.log('DEXIE NotFoundError: ', error);
        break;
      case "InvalidStateError":
        console.log('DEXIE InvalidStateError: ', error);
        break;
      case "InvalidAccessError":
        console.log('DEXIE InvalidAccessError: ', error);
        break;
      case "AbortError":
        console.log('DEXIE AbortError: ', error);
        break;
      case "TimeoutError":
        console.log('DEXIE TimeoutError: ', error);
        break;
      case "QuotaExceededError":
        console.log('DEXIE QuotaExceededError: ', error);
        break;
      case "DataCloneError":
        console.log('DEXIE DataCloneError: ', error);
        break;
      default:
        console.log('DEXIE Error desconocido: ', error);
        break;
    }
  };
  

  