  export enum MODAL_SIZING_ONE_COLUMN_REDUCE {
    WIDHT = "90%",
    MAX_WIDTH = "400px",
    HEIGHT = "auto",
  }
  
  export enum MODAL_SIZING_ONE_COLUMN {
    WIDHT = "90%",
    MAX_WIDTH = "500px",
    HEIGHT = "auto",
  }

export enum REQUEST_STATUS {
  PENDIENTE = 1,
  RECHAZADO = 2,
  APROBADO = 3,
  PENDIENTE_POR_DIRECTOR = 4,
  PENDIENTE_POR_RRHH = 5,
  ANULADO = 6,
  CADUCADO = 7,
}


  