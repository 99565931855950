import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  cookieNameEnv: string | undefined;

  // Método para escribir una cookie
  setCookie(name: string, value: string, days: number, secure: boolean = false, sameSite: 'Lax' | 'Strict' | 'None' = 'Lax'): void {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    let cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/`;
    if (secure) cookie += "; Secure";
    cookie += `; SameSite=${sameSite}`;

    document.cookie = cookie;
  }

  // Método para leer una cookie
  getCookie(name: string): string | null {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();  // Usar trim para eliminar espacios innecesarios
      if (c.indexOf(nameEQ) === 0) {
        return decodeURIComponent(c.substring(nameEQ.length, c.length));
      }
    }
    return null;
  }

  // Método para eliminar una cookie
  deleteCookie(name: string): void {
    document.cookie = `${name}=; Max-Age=0; path=/; SameSite=Lax`;
  }
}
