import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IconType } from 'src/app/shared/interfaces/icon.interface';
import { NavigationEnd, Router, Event } from '@angular/router';

export interface TitleToolbar {
  iconName: string;
  iconType: IconType;
  text: string;
}

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private currentRoute$ = new BehaviorSubject<boolean>(this.getCurrentRoute());

  private _sidenavCollapsedSubject = new BehaviorSubject<boolean>(true);
  sidenavCollapsed$ = this._sidenavCollapsedSubject.asObservable();
  public _pageTitle: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public readonly titleToolbar$: Observable<any> = this._pageTitle.asObservable();

  isScreenXXL$ = this.breakpointObserver.observe(`(min-width: 1925px)`).pipe(map(state => state.matches));
  isScreenXL$ = this.breakpointObserver.observe(`(min-width: 1366px)`).pipe(map(state => state.matches));
  isScreenLG$ = this.breakpointObserver.observe(`(min-width: 1280px)`).pipe(map(state => state.matches));
  isScreenMD$ = this.breakpointObserver.observe(`(min-width: 960px)`).pipe(map(state => state.matches));
  isScreenSMD$ = this.breakpointObserver.observe(`(min-width: 768px)`).pipe(map(state => state.matches));
  isScreenSM$ = this.breakpointObserver.observe(`(min-width: 600px)`).pipe(map(state => state.matches));
  isScreenXS$ = this.breakpointObserver.observe(`(min-width: 460px)`).pipe(map(state => state.matches));
  isScreenXXS$ = this.breakpointObserver.observe(`(min-width: 400px)`).pipe(map(state => state.matches));

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {
    this.router.events
      .pipe(
        filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd), // Type guard para NavigationEnd
        map((event: NavigationEnd) => event.urlAfterRedirects)
      )
      .subscribe(url => {
        let isMainDashboard: boolean = url.includes("tablero");
        this.currentRoute$.next(isMainDashboard);
      });
  }

  collapseSidenav() {
    this._sidenavCollapsedSubject.next(true);
  }

  expandSidenav() {
    this._sidenavCollapsedSubject.next(false);
  }

  setTitleInToolbar(newTitle: TitleToolbar | null) {
    this._pageTitle.next(newTitle);
  }

  public getCurrentRoute(): boolean {
    if (window.location.pathname.includes("tablero")) {
      return true;
    }
    return false;
  }

  get currentRouteObservable() {
    return this.currentRoute$.asObservable();
  }
}
