import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import { GenericValidators } from "../validators/generic-validators";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { modeType } from "src/app/shared/interfaces/inputype.interface";
import { IconComponent } from "../../../icon/icon.component";

@Component({
    selector: "mg-input-minutes-task",
    templateUrl: "./minutes-task.component.html",
    styleUrls: ["./minutes-task.component.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        FormsModule,
        IconComponent
    ]
})
export class MinutesTaskComponent implements OnInit, OnChanges {
  @Input() inputControl: FormControl = new FormControl(null);
  @Input() label: string = "Input";
  @Input() placeholder: string = "";
  @Input() required: boolean = false;
  @Input() mode: modeType;
  @Input() currency: boolean = false;
  @Input() calculateHours: boolean = false;
  @Input() maxValue: number = 250;
  @Input() hint?: boolean = false;
  @Input() tasksStatus?: string = "";
  @Input() isLoading: boolean = false;
  @Input() withLabel: boolean = true;
  @Input() hintPosition? : 'down'|'right' | 'center' = 'down';

  calculate = 0;
  calculate2: number;
  hintCss = "";
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['maxValue']) {
      this.setValidators();
      this.inputControl.updateValueAndValidity();
    }
  }

  ngOnInit(): void {
    this.setValidators();
    this.initCalculate();
    switch (this.mode) {
      case "create":
        break;
      case "read":
        if (this.currency === true) {
          this.inputControl.disable();
        } else {
          this.inputControl.disable();
        }
        break;
      case "update":
        break;
      default:
        break;
    }
  }

  private initCalculate() {
    if (this.calculateHours) {
      if (this.inputControl.value != null) {
        this.calculate = Math.round((this.inputControl.value / 60) * 100) / 100;
      }
      this.inputControl.valueChanges.subscribe((value) => {
        if (value) {
          this.calculate = Math.round((value / 60) * 100) / 100;
        } else {
          this.calculate = 0;
        }
      });
    }
  }

  get placeholderValue() {
    return this.mode !== "read" ? this.placeholder : "";
  }

  setValidators() {
    this.calculate2 = Math.round((this.maxValue / 60) * 100) / 100;
    this.inputControl.setValidators([
      GenericValidators.onlyNumbersGreaterZero,
      Validators.max(this.maxValue),
    ]);
    if (this.required) {
      this.inputControl.setValidators([
        Validators.required,
        GenericValidators.onlyNumbersGreaterZero,
        Validators.max(this.maxValue),
      ]);
    }

    this.inputControl.updateValueAndValidity();
  }

  controlMinutes() { 
    if (this.inputControl.value > 1440) {
      this.inputControl.patchValue(1440);
      //Number((this.inputControl.value).toString().slice(0, 4))
    } 
    if(this.inputControl.value < 0){
      this.inputControl.patchValue(0);
    }
    // else {
    //   this.inputControl.patchValue(this.inputControl.value.slice(0, 4));
    // }
  }
}
