import { CommonModule } from "@angular/common";
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { Observable, map, startWith } from "rxjs";
import { scaleInOutAnimation } from "src/app/shared/animations/scale-in-out.animation";
import { IconComponent } from "../../../icon/icon.component";
import { modeType } from "src/app/shared/interfaces/inputype.interface";
import { Icon } from "src/app/shared/interfaces/icon.interface";
import { getDateInMomentFormatSinceToday, setInvalidOptionInSelectComponent } from "src/app/modules/task/helpers/functions";
import { configurations } from "src/app/shared/interfaces/configurations.interface";
import { STipoConfiguracion } from "src/app/modules/task/interfaces/task-interfaces";
import * as moment from "moment";

interface Option{
  icon?: Icon,
  sColor?: string,
  sCodigo: string,
  bVisible?: boolean,
  sDescripcion: string,
  bIsInvalidOption?: boolean,
}

@Component({
  selector: "mg-input-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [scaleInOutAnimation],
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    IconComponent
  ]
})
export class SelectComponent implements OnInit, OnChanges{
  @Input() inputControl: FormControl = new FormControl(null);
  @Input() label: string = "";
  @Input() placeholder: string = "";
  @Input() aOpciones?: any;
  @Input() required: boolean = false;
  @Input() mode: modeType;
  @Input() isLoading: boolean = false;
  @Input() withLabel: boolean = true;
  @Input() hint_active: boolean = false;
  @Input() hint_message: string = "";
  @Input() firstValue?: boolean = false;
  @Input() blurOn?: boolean = true;
  @Input() optionIcon: boolean = false;
  @Input() isSmallScreen: boolean =   (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) <= 740;
  @Input() taskListPerUser: any = null;
  @Input() selectedRowTask: any = null;
  @Input() lastItemSelected?: any | string = null;
  @Input() disableValidatios =  false

  public sCodigo_Categoria: any = null;


  opcionesfiltradas: Observable<Option[]>;

  public firstDate: string = "";
  public lastDate: string = "";

  @ViewChild("myInputToBlur") private myInputRef: ElementRef;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    for (let property in changes) {
      if (property === "aOpciones") {        
        if (changes['aOpciones'].previousValue !== undefined) {
          this.opcionesfiltradas = this.inputControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value || '', this.aOpciones)),
          );
        }

        if (changes["lastItemSelected"]) {
          if (changes["lastItemSelected"]["currentValue"]) {
            this.sCodigo_Categoria = changes["lastItemSelected"]["currentValue"];
          } else {
            this.sCodigo_Categoria = null;
          }
        }

        if (changes['aOpciones'].currentValue !== undefined) {
          this.opcionesfiltradas = this.inputControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value || '', this.aOpciones)),);

          if (this.sCodigo_Categoria) {
            this.inputControl.patchValue(this.sCodigo_Categoria);
          }
          this.inputControl.updateValueAndValidity();
        }

        if (this.firstValue == true && this.aOpciones.length > 0) {
          this.inputControl.setValue(this.aOpciones[0].sCodigo);
        }
      }

      if (property === "mode") {
        this.initMode();
      }
      
      if (property === "required") {
        this.setValidators();
      }

      if (property === "blurOn") {
        if(this.blurOn == false){
          this.myInputRef.nativeElement.blur();
        }
      }
    }
  }

  ngOnInit(): void {
    this.initMode();
  }

  private initMode() {
    this.setValidators();

    this.opcionesfiltradas = this.inputControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.aOpciones)),
    );

    this.inputControl.valueChanges.subscribe((value) => {
      if(value && this.aOpciones){
        let options = this.aOpciones;


        if (
          this.aOpciones.length > 0 &&        // si hay opciones para el combo
          this.taskListPerUser &&    // si el arreglo de tareas tiene elementos
          this.selectedRowTask &&             // si existe tarea seleccionada
          this.aOpciones.some((option: configurations) => option.sTipo_Configuracion == STipoConfiguracion.TipoHora) // si las opciones son de tipo hora
        ) {
          if (this.taskListPerUser.length>0) {
            if (this.selectedRowTask.value.dFecha_Registro) {
              let selectedTask: any = this.taskListPerUser.find((task: any) => (task.dDate === this.selectedRowTask.value.dFecha_Registro.format("YYYY-MM-DD")));

              options = setInvalidOptionInSelectComponent(this.aOpciones, 
                this.taskListPerUser.map((x: any) => ({
                  sEstado: x.sEstado || "",
                  dDate: x.dDate || getDateInMomentFormatSinceToday(-1),
                  dRegistrationDate: (selectedTask) ? selectedTask.dDate : getDateInMomentFormatSinceToday(-1),
                  nReadyMark: x.nReadyMark || 0,
                })),
              );
            }
          }
        };

        this.checkOption(this.inputControl.value, options);
      }
    });

    //Validar el mod
    switch (this.mode) {
      case "create":
        this.inputControl.enable();
        break;
      case "read":
        this.inputControl.disable();
        break;
      case "update":
        this.inputControl.enable();
        break;
      default:
        break;
    }
  }

  setValidators() {
    if (this.required) {
      this.inputControl.setValidators([Validators.required]);
      this.inputControl.updateValueAndValidity();
    } else {
      this.inputControl.clearValidators();
      this.inputControl.updateValueAndValidity();
    }
  }

  //METHODS DROPDOWN
  mostrarDropdrown(sCodigo: string) {
    let selectValue = null;
    if (sCodigo) {
      let Option = this.aOpciones.find((option: Option) => option.sCodigo === sCodigo);

      selectValue = Option != undefined ? Option.sDescripcion : null;
    }
    return selectValue;
  }

  private _filter(value: string | any, aOpciones: Option[]): Option[] {
    let filterValue = "";
    let aOptionsFiltered: Option[] = [];

    if (typeof value === "string") {
      filterValue = value.toLowerCase();
    }

    if (typeof value === "object") {
      filterValue = value.sDescripcion.toLowerCase();
    }

    if (aOpciones != undefined && aOpciones.length > 0) {
      aOptionsFiltered = aOpciones.filter((option: Option) => {
        return option.sDescripcion.toLowerCase().includes(filterValue);
      });
      this.placeholder = this.label;
    } else {
      this.placeholder = "El listado está vacio";
    }

    aOptionsFiltered = aOptionsFiltered.filter(
      (x) => x.bVisible == true || x.bVisible == undefined
    );
    return aOptionsFiltered;
  }

  private checkOption(value: string, aOpciones: Option[]): any {
    let aCodigos = aOpciones.map((opcion: Option) => opcion.sCodigo);
    let isValid = aCodigos.includes(value);
    let opcion: any = aOpciones.find((x: Option) => x.sCodigo === value)! || {};

    if (isValid) {
      this.inputControl.reset;
      if (this.inputControl.value==value && opcion.bIsInvalidOption) {
        this.firstDate = opcion.firstDate && moment(opcion.firstDate).format('DD-MM-YYYY');
        this.lastDate = opcion.lastDate && moment(opcion.lastDate).format('DD-MM-YYYY');
        this.inputControl.setErrors({bIsInvalidOption: true});
      } else {
        this.inputControl.setErrors(null);
      }
    } else {
      if (this.required) this.inputControl.setErrors({ invalid: true });
    }
  }

  get IconOnInput() {
    if(this.inputControl.valid || this.inputControl.disabled){
      let iconPpal = this.aOpciones.find((opt: Option) => opt.sCodigo == this.inputControl.value)
      if (iconPpal) {
        return iconPpal.icon
      }

      return false;
    }else{
      return false;
    }
  }
  
}